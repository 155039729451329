import { CHAIN_CONFIG, TobiChainName } from '@/app-constants/chains';
import { ITokenSearch } from '@/app-cores/api/bff';
import { countDecimals, formatUnits } from '@/app-helpers/number';
import { useDebounce } from '@/app-hooks/common';
import { ErrorMsg, SwapErrorInfo } from '@/app-hooks/swap';
import { SwapErrorType } from '@/app-hooks/swap/type';
import { CreateEvent, PumpFunEventHandlers, PumpFunSDK, PumpToken, PumpTrade } from '@/app-services/pump.fun';
import { debounce } from 'lodash';
import { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

export const PUMP_TOKEN_DECIMALS = 6;
export const PUMP_TOKEN_TOTAL_SUPPLY = 1000000000;

export enum TokenMode {
	NATIVE,
	MEME,
}

export const formatPumpTokenToTobiToken = (data: PumpToken): ITokenSearch => {
	const { mint: address, symbol, description, name, image_uri: imageUrl } = data || {};
	return {
		...data,
		tokenMutableDataDto: {
			platforms: { [TobiChainName.SOL]: address },
			description,
			totalSupply: null,
			circulatingSupply: null,
			imageUrl,
			socials: {},
			name,
		},
		tokenImmutableDataDto: {
			symbol,
			decimals: PUMP_TOKEN_DECIMALS,
			chainId: TobiChainName.SOL,
			isNative: false,
			address: address,
			maxSupply: null,
			idTobi: address,
		},
		tokenMarketDataDto: { idTobi: address, priceUsd: 0, marketCapUsd: '' } as any,
	};
};

export const useSubscribePumpFun = ({
	callback,
	disabled,
	event,
}: {
	callback: (event: CreateEvent | PumpTrade) => void;
	disabled?: boolean;
	event: keyof PumpFunEventHandlers;
}) => {
	const controller = useRef<AbortController>(new AbortController());
	const sdkRef = useRef<PumpFunSDK>();

	useEffect(() => {
		let removeFn;
		// todo f5
		const fn = async () => {
			try {
				if (disabled) return;
				const signal = controller.current.signal;
				let sdk = sdkRef.current;
				if (!sdk) {
					sdk = await PumpFunSDK.create();
					sdkRef.current = sdk;
				}
				const wrapCallback = (event: CreateEvent) => {
					if (signal.aborted) return;
					callback(event);
				};
				const debounceCallback = debounce(wrapCallback, 2000);
				const id = sdk.addEventListener(event, debounceCallback);
				removeFn = () => sdk.removeEventListener(id);
			} catch (error) {}
		};
		fn();
		return () => {
			controller.current?.abort();
			controller.current = new AbortController();
			removeFn?.();
		};
	}, [disabled, callback, event]);
};

export const useValidateInput = ({
	amountInDebounced,
	totalPay,
	parseNativeBalance,
	native,
	tokenMode,
}): SwapErrorInfo => {
	const { t } = useTranslation();

	const result = useMemo(() => {
		const errors: ErrorMsg[] = [];

		const decimals = tokenMode === TokenMode.MEME ? PUMP_TOKEN_DECIMALS : native.decimals;

		if (amountInDebounced && countDecimals(amountInDebounced) > decimals) {
			errors.push({
				type: 'error',
				msg: `Input is invalid, exceed decimals, max decimals is ${decimals}`,
				errorType: SwapErrorType.VALIDATE_AMOUNT,
			});
		}
		if (totalPay > parseNativeBalance) {
			errors.push({
				type: 'error',
				errorType: SwapErrorType.GAS,
				msg: t('memepad.notEnoughTokenCreateMeme', {
					token: native?.symbol,
					chainName: CHAIN_CONFIG[native?.chainId].name,
					amount: formatUnits(totalPay, native.decimals),
				}),
			});
		}

		return { hasError: errors.some((e) => e.type === 'error'), messages: errors };
	}, [amountInDebounced, totalPay, parseNativeBalance, native, t, tokenMode]);

	return useDebounce(result, 400);
};
