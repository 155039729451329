import * as React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { wrap } from 'popmotion';

const variants = {
	enter: (direction: number) => {
		return {
			x: direction > 0 ? 1000 : -1000,
			opacity: 0,
		};
	},
	center: {
		zIndex: 1,
		x: 0,
		opacity: 1,
	},
	exit: (direction: number) => {
		return {
			zIndex: 0,
			x: direction < 0 ? 1000 : -1000,
			opacity: 0,
		};
	},
};

// const swipeConfidenceThreshold = 10000;
// const swipePower = (offset: number, velocity: number) => {
// 	return Math.abs(offset) * velocity;
// };

interface CarouselProps {
	images: string[];
	currentPage: number;
	direction: number;
	onPageChange: (newDirection: number) => void;
}
export const Carousel: React.FC<CarouselProps> = ({ images, currentPage, direction }) => {
	const imageIndex = wrap(0, images.length, currentPage);
	return (
		<AnimatePresence initial={false} custom={direction}>
			<motion.img
				key={currentPage}
				style={{
					width: '100%',
					height: '100%',
					objectFit: 'cover',
					display: 'block',
					position: 'absolute',
					maxWidth: '100vw',
				}}
				src={images[imageIndex]}
				custom={direction}
				variants={variants}
				initial="enter"
				animate="center"
				exit="exit"
				transition={{
					x: { type: 'spring', stiffness: 300, damping: 30 },
					opacity: { duration: 0.2 },
				}}
				// drag="x"
				// dragConstraints={{ left: 0, right: 0 }}
				// dragElastic={1}
				// onDragEnd={(e, { offset, velocity }) => {
				// 	const swipe = swipePower(offset.x, velocity.x);

				// 	if (swipe < -swipeConfidenceThreshold) {
				// 		onPageChange(1);
				// 	} else if (swipe > swipeConfidenceThreshold) {
				// 		onPageChange(-1);
				// 	}
				// }}
			/>
		</AnimatePresence>
	);
};
