import { ChainId, TRON_NATIVE_ADDRESS } from '@/app-constants/chains';
import { TokenInfo } from '@/app-cores/api/bff';
import { isNativeToken } from '@/app-helpers/address';
import { displayMaxFee, isRouteParamsEmpty } from '@/app-hooks/swap';
import { ArgsGetRoute, SwapAbstract, UsdRouteInfo } from '@/app-hooks/swap/type';
import { ExtractRouteInfo, SwapProvider } from '@/app-hooks/swap/type';
import { SelectedRoute } from '@/app-store/swap';
import { useMutation } from '@tanstack/react-query';
import axios, { AxiosRequestConfig } from 'axios';
import { formatUnits, parseUnits } from 'ethers';
import { MpcWallet } from '@/app-cores/mpc-wallet/wallet';
import { AUTO_SLIPPAGE } from '@/app-views/swap/components/SlippageSetting';
import { uniqueId } from '@/app-helpers/random';
import {
	calcMinAmountOutFromSlippage,
	calcRateSwap,
	filterParams,
	formatListRoutesBestReturn,
	getMinAmount,
} from '@/app-hooks/swap/helper';
import { ONE_HOUR, ONE_MINUTE } from '@/app-hooks/api/portfolio/constant';
import { tronWallet } from '@/app-cores/mpc-wallet/tron';
import { useUserSettingsStore } from '@/app-store/settings';
import { EstimateGasTronParams, estimateTronGasFee } from '@/app-hooks/transactions/tron/useEstimateTronGasFee';
import { getNativeToken } from '@/app-helpers/token';
import { parse } from 'eth-url-parser';
import { formatCurrency, formatUsd, truncateToFixed } from '@/app-helpers/number';
import { useTransactionWatcherStore } from '@/app-store';
import { useTakeFeeSwap } from '@/app-hooks/swap/useTakeFeeSwap';

const API_DOMAIN = 'https://rot.endjgfsv.link';
const ROUTER_ADDRESS = 'TJ4NNy8xZEqsowCBhLvZ45LCqPdGjkET5j';

const getAddress = (token: TokenInfo) => (isNativeToken(token?.address) ? TRON_NATIVE_ADDRESS : token.address);

class Sun extends SwapAbstract<RouteSunSwap> {
	provider = SwapProvider.SUNSWAP;
	async getRoute(paramsSwap: ArgsGetRoute, signal: AbortSignal) {
		const payload = getRouteParamsSwap(paramsSwap, signal);
		if (!payload) return;
		const { data } = await axios(payload);
		if (!data?.data?.length) throw new Error('Empty route');
		const gasFee = await this.estimateFee(data.data[0], paramsSwap);
		const routes = data.data.map((e) => ({ ...e, gasFee }));
		return formatListRoute(routes, paramsSwap);
	}

	formatSlippage(slippage: string | number): number | string {
		return +slippage === AUTO_SLIPPAGE ? 0.5 : +slippage;
	}
	async estimateFee(route: RouteSunSwap, { slippage, tokenIn, tokenOut }: ArgsGetRoute) {
		try {
			return getMinAmount(SwapProvider.SUNSWAP);
			const params = getSwapParams({ slippage: this.formatSlippage(slippage), tokenIn, tokenOut, route });
			const { totalFeeBigInt } = await estimateTronGasFee(params);
			return totalFeeBigInt;
		} catch (error) {
			console.log('est error', error);
			return 0n;
		}
	}
	extractRoute(params: SelectedRoute<RouteSunSwap>, prices: UsdRouteInfo): ExtractRouteInfo {
		return getExtractRoute(params, prices);
	}
}
export const SunSwap = new Sun();
const getRouteParamsSwap = (args: ArgsGetRoute, signal?: AbortSignal) => {
	const { tokenIn, tokenOut, amountIn } = args;

	if (isRouteParamsEmpty(args)) return;
	const params = {
		fromToken: getAddress(tokenIn),
		toToken: getAddress(tokenOut),
		amountIn: amountIn,
		typeList: `PSM,CURVE,CURVE_COMBINATION,WTRX,SUNSWAP_V1,SUNSWAP_V2,SUNSWAP_V3`,
	};

	filterParams(params);
	const config: AxiosRequestConfig = { url: `${API_DOMAIN}/swap/router`, params, signal };
	return config;
};

export type RouteSunSwap = {
	amountIn: string;
	amountOut: string;
	inUsd: string;
	outUsd: string;
	impact: string;
	fee: string;
	tokens: string[];
	symbols: string[];
	poolFees: string[];
	poolVersions: string[];
	stepAmountsOut: string[];
	// custom
	gasFee: bigint;
	tokenIn: TokenInfo;
	tokenOut: TokenInfo;
};

const formatRoute = (routeData: RouteSunSwap, params: ArgsGetRoute): SelectedRoute<RouteSunSwap> => {
	const { tokenIn, tokenOut } = params;
	return routeData
		? {
				...routeData,
				tokenIn,
				tokenOut,
				route: routeData,
				routerAddress: ROUTER_ADDRESS,
				id: uniqueId(),
				provider: SwapProvider.SUNSWAP,
				timestamp: Date.now(),
				params,
		  }
		: undefined;
};

const formatListRoute = (routeResponse: RouteSunSwap[], params: ArgsGetRoute): SelectedRoute | undefined => {
	const allRoutes = formatListRoutesBestReturn(routeResponse?.map((el) => formatRoute(el, params)));
	const bestRoute = allRoutes?.[0];
	if (!bestRoute) return;
	return { ...bestRoute, allRoutes };
};

const getExtractRoute = (
	selectedRoute: SelectedRoute<RouteSunSwap>,
	{ usdPriceIn, usdPriceOut, usdPriceNative }: UsdRouteInfo = {},
): ExtractRouteInfo => {
	const routeSwap = selectedRoute?.route;
	const tokenIn = selectedRoute?.tokenIn;
	const tokenOut = selectedRoute?.tokenOut;

	const amountOut = routeSwap ? parseUnits(routeSwap?.amountOut, tokenOut.decimals).toString() : '0';
	const amountIn = routeSwap ? parseUnits(routeSwap?.amountIn, tokenIn.decimals).toString() : '0';
	const gasNative = routeSwap?.gasFee || getMinAmount(SwapProvider.SUNSWAP);
	const gasUsd = +tronWallet.tronWeb.fromSun(Number(gasNative)) * usdPriceNative;

	return {
		amountOut,
		amountIn,
		tokenIn,
		tokenOut,
		gasUsd,
		gasNative,
		gasDisplay: displayMaxFee({ gasNative, usdPriceNative, chainId: ChainId.TRON }),
		dappInfo: {
			logo: '/icons/brands/sun.jpeg',
			domain: SwapProvider.SUNSWAP,
		},
	};
};

const getPoolVersion = (version = []) => {
	if (!version.length) {
		return version;
	}
	const result = [version[0]];
	version.forEach((item) => {
		if (item !== result[result.length - 1]) {
			result.push(item);
		}
	});
	return result;
};

const getPathList = (version = []) => {
	// versionlen
	if (version.length === 1) {
		return [1];
	}
	const result = [];
	let count = 0;
	version.forEach((item, index) => {
		if (index === 0) {
			count += 1;
		} else {
			if (item === version[index - 1]) {
				count += 1;
				if (index === version.length - 1) {
					result.push(count);
				}
			} else {
				result.push(count);
				count = 1;
				if (index === version.length - 1) {
					result.push(count);
				}
			}
		}
	});
	return result;
}; // this returns the result[0] + 1

const getSwapParams = ({ slippage, tokenIn, tokenOut, route }): EstimateGasTronParams => {
	const amountOut = route ? parseUnits(route?.amountOut, tokenOut.decimals).toString() : '0';
	const amountIn = route ? parseUnits(route?.amountIn, tokenIn.decimals).toString() : '0';

	const minAmountOut = calcMinAmountOutFromSlippage({ amountOut, tokenOut, slippage });
	const functionSelector = 'swapExactInput(address[],string[],uint256[],uint24[],(uint256,uint256,address,uint256))';

	const deadline = Math.floor((Date.now() + 30 * ONE_MINUTE) / 1000);

	const versionLen = getPathList(route.poolVersions);
	versionLen[0] += 1;
	const parameters = [
		{ type: 'address[]', value: route.tokens },
		{ type: 'string[]', value: getPoolVersion(route.poolVersions) },
		{ type: 'uint256[]', value: versionLen },
		{ type: 'uint24[]', value: route.poolFees },
		{
			type: '(uint256,uint256,address,uint256)',
			value: [
				amountIn,
				minAmountOut.toString(),
				MpcWallet.getWalletAddress().evmAddress, // tron wallet will not work
				deadline,
			],
		},
	];
	return {
		contractAddress: ROUTER_ADDRESS,
		functionSelector,
		options: {
			feeLimit: 10000 * 1e6, // set appropriate fee limit
			callValue: isNativeToken(tokenIn.address) ? +amountIn : 0,
		},
		parameters,
	};
};

export const useExecuteRouteSunSwap = () => {
	const { slippage } = useUserSettingsStore();
	const { addPendingTronTransaction } = useTransactionWatcherStore();
	const takeFee = useTakeFeeSwap();
	const response = useMutation({
		mutationKey: ['build-route-sun'],
		mutationFn: async (routeData: SelectedRoute<RouteSunSwap>) => {
			const { tokenOut, route, tokenIn } = routeData;
			const { contractAddress, functionSelector, options, parameters } = getSwapParams({
				route,
				tokenOut,
				tokenIn,
				slippage: SunSwap.formatSlippage(slippage),
			});
			// return { contractAddress, hash: '9a84f2f8d615b37bb48be4f3fe073eb1090a89d3922479874e2742c260138da7' };

			const transaction = await tronWallet.tronWeb.transactionBuilder.triggerSmartContract(
				contractAddress,
				functionSelector,
				options,
				parameters,
			);
			const signedTransaction = await tronWallet.signTransaction(transaction.transaction);

			const txResults = await tronWallet.sendRawTransaction(signedTransaction);
			if (txResults.code) throw new Error(txResults.code as any);
			console.log({ txResults });
			return { hash: txResults.transaction.txID, contractAddress };
		},
		onSuccess: ({ hash, contractAddress }, route) => {
			takeFee({ hash, route });
			addPendingTronTransaction({
				hash,
				trackingData: route,
			});
		},
	});
	return response;
};
