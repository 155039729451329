import { ButtonSecondary, CTAButton, InputSearch, Loading, Toast } from '@/app-components/common';
import {
	Divider,
	Flex,
	HStack,
	Image,
	List,
	ListItem,
	Text,
	VStack,
	useDisclosure,
	Avatar,
	Spinner,
} from '@chakra-ui/react';
import React, { ReactNode, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useNavigate } from 'react-router';
import { NAVIGATE_PATHS } from '@/app-constants/router';
import { formatNumber, formatUnits, formatUsd } from '@/app-helpers/number';
import i18n from '@/app-cores/i18n';
import PageLayout from '@/app-components/layout/PageLayout';
import { BASE_BORDER_COLOR, colors } from '@/app-theme/theme';

import { ContactAccount } from '@/app-cores/api/contact';
import { useDebounce } from '@/app-hooks/common';
import { useRequestFundStore } from '@/app-store/request-fund';
import { RequestFundLayout, UserInfoRequestFund } from '@/app-views/request-fund';
import { useInviteFriend } from '@/app-views/account-setting/pages/InviteCode/InviteCode';
import { useCreateRequestPayment } from '@/app-hooks/api/payment/useClaimFund';
import { toast } from 'react-toastify';

export const UserItem = ({
	data,
	amount,
	isLast,
	onInvite,
}: {
	data: ContactAccount;
	amount: string;
	isLast: boolean;
	onInvite?: () => void;
}) => {
	const isWalletCreated = !!data.defaultWallet;
	return (
		<>
			<Flex direction={'column'}>
				<Flex p={0} align={'center'} gap={4} justifyContent={'space-between'}>
					<UserInfoRequestFund data={data} maxLengthUsername={30} />
					<Text fontSize={'14px'}>{formatUsd(amount)}</Text>
				</Flex>
				{!isWalletCreated && onInvite && (
					<Flex justify={'flex-end'}>
						<ButtonSecondary size={'sm'} width={'fit-content'} onClick={onInvite}>
							Send Invitation
						</ButtonSecondary>
					</Flex>
				)}
			</Flex>
			{!isLast && <Divider borderBottom={`1px dotted ${colors.gray[200]}`} />}
		</>
	);
};

const ConfirmRequestFund = () => {
	const { t } = useTranslation();
	const { friends = [], amountMapping, message, totalAmount } = useRequestFundStore();

	const navigate = useNavigate();

	const { mutateAsync, isPending } = useCreateRequestPayment();
	const onCreateRequest = async () => {
		try {
			if (isPending) return;
			const data = await mutateAsync({
				message,
				payers: friends.map((e) => ({
					telegramId: e.telegramId,
					amount: +amountMapping[e._id],
				})),
			});
			navigate(`${NAVIGATE_PATHS.RequestFund.Result}?requestId=${data?.id}`);
		} catch (error) {
			console.log('create request error', error);
			toast(<Toast type="error" message={t('requestPayment.sendFailed')} />);
		}
	};

	const { onInvite } = useInviteFriend();

	return (
		<RequestFundLayout
			title={'Confirmation'}
			action={
				<Flex p={4}>
					<CTAButton
						isDisabled={isPending}
						size={'lg'}
						colorScheme={'cyan'}
						height={'56px'}
						width={'100%'}
						onClick={onCreateRequest}
					>
						{isPending ? <Spinner /> : 'Confirm'}
					</CTAButton>
				</Flex>
			}
		>
			<Flex direction={'column'} gap={4} pb={4}>
				<Flex direction={'column'} gap={4} background={'white'} borderRadius={'12px'} p={4} px={6}>
					<Text fontSize={'12px'} color={colors.gray[400]}>
						{t('requestPayment.sendTo')}
					</Text>
					<Flex direction={'column'} gap={4} background={colors.gray[100]} borderRadius={'12px'} p={4}>
						{friends?.map((el, i) => {
							return (
								<UserItem
									key={el._id}
									data={el}
									amount={amountMapping[el._id]}
									isLast={i === friends.length - 1}
									onInvite={onInvite}
								/>
							);
						})}
					</Flex>
					<Flex fontSize={'12px'} color={colors.gray[400]} justify={'space-between'}>
						{t('requestPayment.total')}{' '}
						<Text fontSize={'14px'} color={'black'}>
							{formatUsd(totalAmount)}
						</Text>
					</Flex>
					{message && (
						<>
							<Divider borderBottom={`1px dotted ${colors.gray[200]}`} />
							<Flex fontSize={'12px'} width={'100%'} color={colors.gray[400]} justify={'space-between'}>
								Message{' '}
								<Text flex={1} textAlign={'right'} maxWidth={'70%'}>
									{message}
								</Text>
							</Flex>
						</>
					)}
				</Flex>
			</Flex>
		</RequestFundLayout>
	);
};

export default ConfirmRequestFund;
