import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useTokenPriceChart } from '../../../../../app-hooks/api/portfolio/useTokenPrices';
import { ONE_DAY, ONE_HOUR } from '@/app-hooks/api/portfolio/constant';
import { CHART_CONFIG_DEFAULT, ChartInterval, ChartMode, TimeFrame, useUserSettingsStore } from '@/app-store/settings';
import { TradingViewChart } from '@/app-components/common/Chart/TradingViewChart';
import { ChartData, ChartService, TokenPriceChartParams } from '@/app-cores/api/bff/chart';
import ChartSetting, { ChartSettingBottom } from '@/app-views/portfolio/pages/token/components/ChartSetting';

interface BasicInformationProps {
	chainId: string;
	tobiId: string;
}

const windowRanges = [
	{ label: TimeFrame.ONE_H, value: ONE_HOUR },
	{ label: TimeFrame.ONE_D, value: ONE_DAY },
	{ label: TimeFrame.ONE_W, value: ONE_DAY * 7 },
	{ label: TimeFrame.ONE_M, value: ONE_DAY * 30 },
	{ label: TimeFrame.ONE_Y, value: ONE_DAY * 365 },
].map((e) => ({ ...e, value: (e.value / 1000) | 0 }));

export const formatDataChart = (mode: ChartMode, data: ChartData) => {
	return mode === ChartMode.LINE ? formatLineData(data) : formatCandleData(data);
};

const formatCandleData = (chartData: ChartData) => {
	return (
		chartData
			?.map((e) => ({
				...e,
				time: e.timestamp,
			}))
			.filter(({ open, close, low, high }) => ![open, close, low, high].some((e) => e === null)) || []
	);
};

const formatLineData = (chartData: ChartData) => {
	return (
		chartData
			?.map(({ timestamp: time, high, low }) => ({
				value: high !== null && low ? (high + low) / 2 : null,
				time,
			}))
			.filter(({ value, time }) => ![value, time].some((e) => e === null)) || []
	);
};

const maxAllowedTimeFrame = {
	[ChartInterval.ONE_MIN]: TimeFrame.ONE_D,
	[ChartInterval.FIFTEEN_MIN]: TimeFrame.ONE_W,
	[ChartInterval.ONE_HOUR]: TimeFrame.ONE_M,
	[ChartInterval.TWO_HOUR]: TimeFrame.ONE_M,
	[ChartInterval.THREE_HOUR]: TimeFrame.ONE_M,
	[ChartInterval.FOUR_HOURS]: TimeFrame.ONE_M,
	[ChartInterval.EIGHT_HOURS]: TimeFrame.ONE_M,
};
const getWindowByResolution = (value: ChartInterval) => {
	return maxAllowedTimeFrame[value] || TimeFrame.ONE_Y;
};

export const PriceChartWithSettings: React.FunctionComponent<{
	onFetchMore: () => Promise<any>;
	data: ChartData;
	isLoading: boolean;
	onChangeInterval?: (v: ChartInterval) => void;
	chartInterval?: ChartInterval;
	defaultInterval: ChartInterval;
	sortInterval?: boolean;
}> = ({ onFetchMore, isLoading, data, onChangeInterval, chartInterval, defaultInterval, sortInterval }) => {
	const { chartConfig: { mode } = CHART_CONFIG_DEFAULT } = useUserSettingsStore();

	const { lineChartData, candleChartData } = useMemo(() => {
		const lineChartData = formatLineData(data);
		const candleChartData = formatCandleData(data);

		return { lineChartData, candleChartData };
	}, [data]);

	return (
		<>
			<ChartSetting
				sortInterval={sortInterval}
				onChangeInterval={onChangeInterval}
				chartInterval={chartInterval}
				defaultInterval={defaultInterval}
			/>
			<TradingViewChart
				onFetchMore={onFetchMore}
				mode={mode}
				isLoading={isLoading}
				chartData={mode === ChartMode.CANDLE ? candleChartData : lineChartData}
			/>
			<ChartSettingBottom />
		</>
	);
};

export const PriceChart: React.FunctionComponent<BasicInformationProps> = ({ tobiId }) => {
	const { chartConfig: { mode, interval } = CHART_CONFIG_DEFAULT } = useUserSettingsStore();

	const windowRange = useMemo(() => {
		const range = getWindowByResolution(interval);
		return windowRanges.find((e) => e.label === range) || windowRanges.find((e) => e.label === TimeFrame.ONE_Y);
	}, [interval]);

	const payload = useMemo(() => {
		const to = (Date.now() / 1000) | 0;
		const from = to - windowRange?.value;
		return {
			interval,
			from,
			to,
			tobiId,
		};
	}, [windowRange, interval, tobiId]);

	const { data, isLoading } = useTokenPriceChart(payload);

	const ref = useRef<TokenPriceChartParams>();
	useEffect(() => {
		ref.current = payload;
	}, [payload]);

	const fetching = useRef(false);
	const onFetchMore = useCallback(async () => {
		if (fetching.current) return;
		try {
			fetching.current = true;
			const newTo = ref.current.from - 1;
			const newPayload = { ...ref.current, from: newTo - windowRange.value, to: newTo };
			const data = await ChartService.fetchPriceChart(newPayload);
			ref.current = newPayload;
			return formatDataChart(mode, data);
		} catch (error) {
			return [];
		} finally {
			fetching.current = false;
		}
	}, [windowRange, mode]);

	return (
		<PriceChartWithSettings
			{...{ onFetchMore, isLoading, data, defaultInterval: ChartInterval.ONE_HOUR, sortInterval: false }}
		/>
	);
};
