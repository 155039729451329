import { LimitProvider } from '@/app-hooks/limit/type';
import { SwapProvider } from '@/app-hooks/swap/type';
import { Flex, Image } from '@chakra-ui/react';
import { CSSProperties, Fragment, ReactNode } from 'react';

const Wrapper = ({ children, style }: { children: ReactNode; style?: CSSProperties }) => (
	<Flex
		sx={style}
		lineHeight={'16px'}
		fontSize={'10px'}
		fontWeight={'500'}
		justifyContent={'center'}
		gap={'4px'}
		alignItems={'center'}
	>
		{children}
	</Flex>
);
// todo add image to property
export default function ProviderNote({
	provider,
	shorter,
}: {
	provider: SwapProvider | SwapProvider[] | LimitProvider;
	shorter?: boolean;
}) {
	if (Array.isArray(provider))
		return (
			<Flex gap={1} align={'center'}>
				{provider.map((e, i) => (
					<Fragment key={i}>
						<ProviderNote provider={e} key={i} shorter /> {i !== provider.length - 1 && ','}
					</Fragment>
				))}
			</Flex>
		);
	switch (provider) {
		case SwapProvider.KYBER:
			return (
				<Wrapper>
					<Image src="/icons/brands/kyberswap.png" height={'16px'} /> {!shorter && provider}
				</Wrapper>
			);
		case SwapProvider.SUNSWAP:
			return (
				<Wrapper>
					<Image src="/icons/brands/sun.jpeg" height={'16px'} /> {!shorter && provider}
				</Wrapper>
			);
		case SwapProvider.LIFI:
			return (
				<Wrapper>
					<Image borderRadius={'100%'} src="/icons/brands/lifi.jpeg" height={'16px'} /> {!shorter && provider}
				</Wrapper>
			);
		case SwapProvider.STON:
			return (
				<Wrapper>
					<Image borderRadius={'100%'} src="/icons/brands/ston.fi.jpeg" height={'16px'} />{' '}
					{!shorter && provider}
				</Wrapper>
			);
		case SwapProvider.ORCA:
			return (
				<Wrapper>
					<Image borderRadius={'100%'} src="/icons/brands/orca.png" height={'16px'} /> {!shorter && provider}
				</Wrapper>
			);
		case SwapProvider.ROCKET:
			return (
				<Wrapper style={{ background: 'rgba(0, 0, 0, 0.08)', borderRadius: '50px', padding: '1px 8px' }}>
					Agg
				</Wrapper>
			);
		case SwapProvider.JUPITER:
			return (
				<Wrapper>
					<Image borderRadius={'100%'} src="/icons/brands/jupiter.png" height={'16px'} />{' '}
					{!shorter && provider}
				</Wrapper>
			);
		case SwapProvider.RAYDIUM:
			return (
				<Wrapper>
					<Image borderRadius={'100%'} src="/icons/brands/raydium.webp" height={'16px'} />{' '}
					{!shorter && provider}
				</Wrapper>
			);
		case SwapProvider.BERA:
			return (
				<Wrapper>
					<Image borderRadius={'100%'} src="/icons/brands/bera.png" height={'16px'} /> {!shorter && provider}
				</Wrapper>
			);
		case SwapProvider.PUMPFUN:
			return (
				<Wrapper>
					<Image borderRadius={'100%'} src="/icons/brands/pumpfun.png" height={'16px'} />{' '}
					{!shorter && provider}
				</Wrapper>
			);
		case SwapProvider.DEBRIDGE:
			return (
				<Wrapper>
					<Image borderRadius={'100%'} src="/icons/brands/debridge.svg" height={'16px'} />{' '}
				</Wrapper>
			);

		case SwapProvider.SEVEN_K_SWAP:
			return (
				<Wrapper>
					<Image borderRadius={'100%'} src="/icons/brands/7k.png" height={'16px'} /> {!shorter && provider}
				</Wrapper>
			);
		case SwapProvider.OWLTO:
			return (
				<Wrapper>
					<Image borderRadius={'100%'} src="/icons/brands/owlto.jpeg" height={'16px'} />{' '}
					{!shorter && provider}
				</Wrapper>
			);
		case SwapProvider.SWING:
			return (
				<Wrapper>
					<Image borderRadius={'100%'} src="/icons/brands/swing.png" height={'16px'} /> {!shorter && provider}
				</Wrapper>
			);
		case SwapProvider.DEDUST:
			return (
				<Wrapper>
					<Image borderRadius={'100%'} src="/icons/brands/dedust.png" height={'16px'} />{' '}
					{!shorter && provider}
				</Wrapper>
			);

		case SwapProvider.RETROBRIDGE:
			return (
				<Wrapper>
					<Image src="/icons/brands/retrobridge.png?v=1" height={'18px'} /> {!shorter && provider}
				</Wrapper>
			);

		case SwapProvider.Sol2Tcat:
			return (
				<Wrapper>
					<Image src="/icons/brands/retrobridge.png" height={'8px'} />,
					<Image borderRadius={'100%'} src="/icons/brands/dedust.png" height={'16px'} />{' '}
					{!shorter && provider}
				</Wrapper>
			);
		case SwapProvider.CONTRACT:
			return (
				<Wrapper style={{ background: 'rgba(0, 0, 0, 0.08)', borderRadius: '50px', padding: '1px 8px' }}>
					{provider}
				</Wrapper>
			);
		default:
			return null;
	}
}
