import { ConfirmTransaction } from '@/app-views/swap/pages/ConfirmTransaction';
import SwapFormMini from '@/app-views/swap/pages';
import { BoxProps } from '@chakra-ui/react';

import TobiDrawer from '@/app-components/common/Drawer';
import { SwapProvider } from '@/app-hooks/swap/type';

const SwapWidget = ({
	onSwapSuccess,
	onBack,
	showConfirm,
	onBuildRouteSuccess,
	confirmContentProps,
	confirmActionProps,
	confirmAsModal = false,
	onViewActivity,
	...rest
}: {
	actionProps: BoxProps;
	containerProps: BoxProps;
	showConfirm: boolean;
	onBuildRouteSuccess: () => void;
	onSwapSuccess?: () => void;
	onBack?: () => void;
	onViewActivity?: () => void;
	disableTransition?: boolean;
	confirmContentProps?: BoxProps;
	confirmActionProps?: BoxProps;
	selectTokenProps?: BoxProps;
	confirmAsModal?: boolean;
	showTokenSelector?: boolean;
	providers?: SwapProvider[];
}) => {
	const confirmContent = (
		<ConfirmTransaction
			onViewActivity={onViewActivity}
			quickSwap
			onSwapSuccess={onSwapSuccess}
			onBack={onBack}
			disableTransition
			contentProps={confirmContentProps}
			actionProps={confirmActionProps}
		/>
	);

	const modal = confirmAsModal ? (
		<TobiDrawer isOpen={showConfirm} placement="bottom" isFullHeight onClose={onBack} title="Swap Confirmation">
			{confirmContent}
		</TobiDrawer>
	) : null;

	if (!showConfirm)
		return (
			<>
				<SwapFormMini quickSwap onBuildRouteSuccess={onBuildRouteSuccess} {...rest} />
				{modal}
			</>
		);

	return confirmAsModal ? modal : confirmContent;
};
export default SwapWidget;
