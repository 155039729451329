import { DappBrowserLayout } from '@/app-components/layout/DappBrowserLayout';
import { NAVIGATE_PATHS } from '@/app-constants/router';
import { TobiDapp } from '@/app-features/app-wallet-connect/views/Dapp';
import { DappBrowser } from '@/app-features/app-wallet-connect/views/DappBrowser';
import { GoogleSearchControl } from '@/app-features/app-wallet-connect/views/GoogleSearchControl';
import { WCConnections } from '@/app-features/app-wallet-connect/views/WCConnections';
import TobiFarm from '@/app-views/TobiFarm';
import AccountSetting from '@/app-views/account-setting/AccountSetting';
import InviteCodePage from '@/app-views/account-setting/pages/InviteCode/InviteCode';
import InviteCodeDetail from '@/app-views/account-setting/pages/InviteCode/InviteCodeDetail';
import PrivacyPolicy from '@/app-views/account-setting/pages/PrivacyPolicy/PrivacyPolicy';
import TermOfService from '@/app-views/account-setting/pages/TermOfService/TermOfService';
import { CancelPendingTransactions } from '@/app-views/cancel-all-transaction';
import DepositMethod from '@/app-views/deposit/pages/DepositMethod/DepositMethod';
import { DepositTransactionResult } from '@/app-views/deposit/pages/TransactionResult/TransactionResult';
import Transak from '@/app-views/deposit/pages/Transak/Transak';
import { HomePage } from '@/app-views/home';
import Affilate from '@/app-views/affiliate';
import RequestFund from '@/app-views/request-fund';
import ConfirmRequestFund from '@/app-views/request-fund/ConfirmRequestFund';
import CreateRequestFund from '@/app-views/request-fund/CreateRequestFund';
import RequestFundDetail from '@/app-views/request-fund/RequestFundDetail';
import RequestFundResult from '@/app-views/request-fund/RequestFundResult';
import { ConfirmTransaction } from '@/app-views/sent-token/pages';
import { SentToken } from '@/app-views/sent-token/pages/Sent';
import Wallet from '@/app-views/wallet/pages/Wallet';

import { RouteObject } from 'react-router';
import FeeEarn from '@/app-views/affiliate/FeeEarn';
import Group from '@/app-views/affiliate/Group';
import User from '@/app-views/affiliate/User';

export const WalletRouter: RouteObject[] = [
	{
		path: NAVIGATE_PATHS.Home,
		element: <HomePage />,
	},

	{
		path: NAVIGATE_PATHS.Affiliate.Main,
		children: [
			{
				index: true,
				element: <Affilate />,
			},
			{
				path: NAVIGATE_PATHS.Affiliate.FeeEarn,
				element: <FeeEarn />,
			},
			{
				path: NAVIGATE_PATHS.Affiliate.User,
				element: <User />,
			},
			{
				path: NAVIGATE_PATHS.Affiliate.Group,
				element: <Group />,
			},
		],
	},
	{
		path: NAVIGATE_PATHS.Wallet.Wallet,
		element: <Wallet />,
	},
	{
		path: NAVIGATE_PATHS.Wallet.Sent.Sent,
		children: [
			{
				index: true,
				element: <SentToken />,
			},
			{
				path: NAVIGATE_PATHS.Wallet.Sent.ConfirmTransaction,
				element: <ConfirmTransaction />,
			},
		],
	},
	{
		path: NAVIGATE_PATHS.Deposit.DepositMethod,
		children: [
			{
				index: true,
				element: <DepositMethod />,
			},
			{
				path: NAVIGATE_PATHS.Deposit.Transak,
				element: <Transak />,
			},
			{
				path: NAVIGATE_PATHS.Deposit.DepositResult,
				element: <DepositTransactionResult />,
			},
		],
	},
	{
		path: NAVIGATE_PATHS.AccountSettings.Menu,
		children: [
			{
				index: true,
				element: <AccountSetting />,
			},
			{
				path: NAVIGATE_PATHS.AccountSettings.PrivacyPolicy,
				element: <PrivacyPolicy />,
			},
			{
				path: NAVIGATE_PATHS.AccountSettings.TermOfService,
				element: <TermOfService />,
			},
			{
				path: NAVIGATE_PATHS.AccountSettings.InvitationCode,
				element: <InviteCodePage />,
			},
			{
				path: NAVIGATE_PATHS.AccountSettings.InvitationCodeDetail,
				element: <InviteCodeDetail />,
			},
		],
	},

	{
		path: NAVIGATE_PATHS.Wallet.WCconnection,
		element: <WCConnections />,
	},
	{
		element: <DappBrowserLayout />,
		children: [
			{
				path: NAVIGATE_PATHS.Dapp.DappBrowser,
				element: <DappBrowser />,
			},
			{
				path: NAVIGATE_PATHS.Dapp.TobiDapp,
				element: <TobiDapp />,
			},
			{
				path: NAVIGATE_PATHS.Dapp.GoogleSearchControl,
				element: <GoogleSearchControl />,
			},
		],
	},
	{
		path: NAVIGATE_PATHS.CancelPendingTransactions,
		element: <CancelPendingTransactions />,
	},
	{
		path: NAVIGATE_PATHS.TOBI_FARM,
		element: <TobiFarm />,
	},
	{
		path: NAVIGATE_PATHS.RequestFund.Main,
		children: [
			{
				index: true,
				element: <RequestFund />,
			},
			{
				path: NAVIGATE_PATHS.RequestFund.EditAmount,
				element: <CreateRequestFund />,
			},
			{
				path: NAVIGATE_PATHS.RequestFund.Confirm,
				element: <ConfirmRequestFund />,
			},
			{
				path: NAVIGATE_PATHS.RequestFund.Result,
				element: <RequestFundResult />,
			},
			{
				path: NAVIGATE_PATHS.RequestFund.requestDetail(':requestId'),
				element: <RequestFundDetail />,
			},
		],
	},
];
