import { Address, fromNano } from '@ton/ton';
import {
	Box,
	Button,
	Card,
	Center,
	Divider,
	Drawer,
	DrawerBody,
	DrawerContent,
	DrawerFooter,
	DrawerHeader,
	DrawerOverlay,
	Flex,
	Img,
	Text,
} from '@chakra-ui/react';
import { isEmpty, get } from 'lodash-es';
import { toast } from 'react-toastify';
import { JettonPreview, JettonVerificationType, SignRawMessage } from 'tonapi-sdk-js';
import { useModalStore } from '../store/ModalStore';
import {
	useMutationOutdateTransaction,
	useMutationRejectTransaction,
	useMutationSentTransaction,
} from '../hooks/useMutationSentTransaction';
import { Loading, Toast } from '@/app-components/common';
import { useEventsEmulate } from '../hooks/useEventEmulate';
import { MpcWallet } from '@/app-cores/mpc-wallet/wallet';
import { CommonTransactionInfo } from '../components/CommonTransactionInfo';
import { ChainId, NATIVE_TOKEN_ADDRESS } from '@/app-constants/chains';
import { TransactionItemInfo } from '@/app-features/app-wallet-connect/components/TransactionItemInfo';
import { TokenAmount } from '@/app-features/app-wallet-connect/components/TokenAmount';
import { ActionConfirm } from '@/app-features/app-wallet-connect/components/ActionConfirm';
import { tonConnectReturnStrategyManager } from '../utils/returnStrategyManager';
import { getShortAddress } from '@/app-helpers/address';
import { FiSwapIcon, FiWalletAddressIcon } from '@/assets/icons';
import { SwapTokenAmount } from '@/app-features/app-wallet-connect/components/SwapTokenAmout';
import { StackTokenAmount } from '@/app-features/app-wallet-connect/components/StackAmount';
import { useMemo } from 'react';
import { CHAIN } from '@tonconnect/protocol';
import { getValidReturnStrategy } from '../utils/common';
import { BadRequest } from '../components/BadRequest';

export type SignRawParams = {
	valid_until: number;
	messages: SignRawMessage[];
	network?: CHAIN;
	from?: string;
};

export const TON_TOKEN: JettonPreview = {
	address: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
	decimals: 9,
	image: 'https://assets.coingecko.com/coins/images/17980/large/ton_symbol.png?1696517498',
	name: 'Toncoin',
	symbol: 'TON',
	verification: JettonVerificationType.Whitelist,
};

const WHITE_LIST_ACTION_TYPES = [
	'JettonTransfer',
	'TonTransfer',
	'JettonSwap',
	'JettonBurn',
	'DepositStake',
	'WithdrawStake',
	'JettonMint',
	'WithdrawStake',
	'JettonBurn',
	'NftItemTransfer',
];
function getAddress(walletInfo: { address: string; is_wallet: boolean }) {
	if (isEmpty(walletInfo?.address)) return '';
	const address = Address.parse(walletInfo.address).toString({
		bounceable: !walletInfo.is_wallet,
	});
	return address;
}
export function SessionSendTransactionModal() {
	const { open, onClose, data, setReturnStrategyData, returnStrategyData } = useModalStore();
	const requestParams = data.requestParams;
	const { mutateAsync: sentTransaction, isPending: isLoadingApprove } = useMutationSentTransaction();
	const { mutateAsync: rejectTransaction, isPending: isLoadingReject } = useMutationRejectTransaction();
	const { mutateAsync: rejectOutdateTransaction, isPending: isPendingRejectOutdate } =
		useMutationOutdateTransaction();

	const validUnit = useMemo(() => {
		const now = Math.floor(Date.now() / 1000);
		const fiveMinutes = 5 * 60;
		const futureLimit = now + fiveMinutes;
		const dataStr = get(requestParams, 'request.params[0]');
		if (!dataStr) return Math.floor(Date.now() / 1000);
		const data = JSON.parse(dataStr);
		if (!data?.valid_until) return -1;
		if (data?.valid_until > futureLimit) return futureLimit;
		return data?.valid_until;
	}, [requestParams]);

	const isValidRequest = useMemo(() => {
		const dataStr = get(requestParams, 'request.params[0]');
		const rawTransaction = JSON.parse(dataStr) as SignRawParams;
		const isValid =
			rawTransaction &&
			Array.isArray(rawTransaction.messages) &&
			rawTransaction.messages.every((msg) => !!msg.address && !!msg.amount);
		return rawTransaction.valid_until ? isValid && typeof rawTransaction.valid_until === 'number' : isValid;
	}, [requestParams]);

	const isValidNetwork = useMemo(() => {
		const dataStr = get(requestParams, 'request.params[0]');
		const rawTransaction = JSON.parse(dataStr) as SignRawParams;
		return rawTransaction.network === CHAIN.MAINNET;
	}, [requestParams]);

	const isValidFrom = useMemo(() => {
		try {
			const dataStr = get(requestParams, 'request.params[0]');
			const rawTransaction = JSON.parse(dataStr) as SignRawParams;
			if (!rawTransaction.from) return true;
			return (
				Address.parse(rawTransaction.from).toString({
					bounceable: false,
				}) === MpcWallet.getTonWalletAddress()
			);
		} catch (error) {
			return false;
		}
	}, [requestParams]);

	const walletAddress = MpcWallet.getTonWalletAddress();
	const isBadRequest = !isValidRequest || !isValidNetwork || !isValidFrom;
	const { data: eventEmulates, isPending: isEmulating } = useEventsEmulate(
		requestParams?.request?.params[0],
		requestParams?.request?.id,
		!isBadRequest,
	);
	if (isEmpty(requestParams)) return null;
	if (isBadRequest) {
		const errorMessage = !isValidNetwork ? 'Wrong network' : 'Bad request';
		return <BadRequest errorMessage={errorMessage} />;
	}

	if (isEmulating) return <Loading />;

	const onApprove = async () => {
		let isError = false;
		try {
			const now = Math.floor(Date.now() / 1000);
			if (!!validUnit && validUnit < now && validUnit !== -1) {
				toast(<Toast type="error" title="Error" message="Transaction is outdated." />);
				await rejectOutdateTransaction({
					...data.requestParams,
				});
				isError = true;
			} else {
				await sentTransaction(data.requestParams);
				toast(<Toast type="success" title="Success" message="Approval transaction successfully" />);
			}
			onClose();
		} catch (error) {
			toast(<Toast type="error" title="Error" message={(error as Error)?.message} />);
			console.log('transfer error:', error);
		} finally {
			setReturnStrategyData({
				isOpen: true,
				returnStrategy: getValidReturnStrategy(
					tonConnectReturnStrategyManager.getReturnStrategy(requestParams.clientSessionId),
				),
				dappName: data?.requestParams?.connection?.manifest?.name || 'Dapp',
				errorMessage: isError ? 'Transaction is outdated.' : '',
			});
		}
	};

	const onReject = async () => {
		try {
			const res = await rejectTransaction(data.requestParams);
			onClose();
		} catch (error) {
			toast(<Toast type="error" title="Error" message={(error as Error)?.message} />);
			console.log('reject transaction error:', error);
		} finally {
			setReturnStrategyData({
				isOpen: true,
				returnStrategy: getValidReturnStrategy(
					tonConnectReturnStrategyManager.getReturnStrategy(requestParams.clientSessionId),
				),
				dappName: data?.requestParams?.connection?.manifest?.name || 'Dapp',
				isRejected: true,
			});
		}
	};

	const message = get(requestParams, 'request.params[0', '');
	const payload = JSON.parse(message);

	const defaultView = () => {
		return (
			<Card padding={'16px'} width={'100%'} gap={'8px'}>
				<Box>
					<TransactionItemInfo title="To" value={payload?.messages?.[0]?.address} />
					<TransactionItemInfo title="Value" value={fromNano(payload?.messages?.[0]?.amount)} />
				</Box>
			</Card>
		);
	};
	const renderContend = () => {
		if (!eventEmulates || isEmpty(eventEmulates?.actions)) {
			return defaultView();
		}
		return eventEmulates.actions.map((acItem, index) => {
			const type = acItem.type;
			if (!WHITE_LIST_ACTION_TYPES.includes(type)) return null;
			switch (type) {
				case 'JettonSwap': {
					const action = acItem.JettonSwap;
					const jetton_master_in = action?.jetton_master_in || TON_TOKEN;
					const jetton_master_out = action?.jetton_master_out || TON_TOKEN;
					const amount_in = action?.amount_in || action?.ton_in;
					const amount_out = action?.amount_out || action?.ton_out;

					return (
						<TransactionItemInfo
							title={
								<Center gap={2}>
									<Center width={8} height={8} bgColor="#00E9DB" borderRadius="100%" p={2}>
										<FiSwapIcon />
									</Center>
									<Box>
										<Text fontWeight={700}>Swap</Text>
										<Text>{action?.router?.name}</Text>
									</Box>
								</Center>
							}
							value={
								<Flex flexDirection="column" alignItems="end" gap={2}>
									<SwapTokenAmount
										amount={amount_out}
										decimals={jetton_master_out?.decimals}
										image={jetton_master_out?.image}
										symbol={jetton_master_out?.symbol}
										type="IN"
										address={
											jetton_master_out?.address === NATIVE_TOKEN_ADDRESS
												? NATIVE_TOKEN_ADDRESS
												: Address.parse(jetton_master_out?.address).toString({
														bounceable: true,
												  })
										}
										chainId={ChainId.TON as string}
									/>
									<SwapTokenAmount
										amount={amount_in}
										decimals={jetton_master_in?.decimals}
										image={jetton_master_in?.image}
										symbol={jetton_master_in?.symbol}
										type="OUT"
										address={
											jetton_master_in?.address === NATIVE_TOKEN_ADDRESS
												? NATIVE_TOKEN_ADDRESS
												: Address.parse(jetton_master_in?.address).toString({
														bounceable: true,
												  })
										}
										chainId={ChainId.TON as string}
									/>
								</Flex>
							}
						></TransactionItemInfo>
					);
				}
				case 'JettonTransfer': {
					const action = acItem.JettonTransfer;
					const jetton = action.jetton;
					const type = action.sender.address === Address.parse(walletAddress).toRawString() ? 'OUT' : 'IN';
					return (
						<TokenAmount
							amount={action?.amount}
							decimals={jetton?.decimals}
							image={jetton?.image}
							symbol={jetton?.symbol}
							type={type}
							chainId={ChainId.TON as string}
							address={Address.parse(jetton.address).toString()}
							showActionIcon
							receiverName={action.recipient.name}
						/>
					);
				}
				case 'TonTransfer': {
					const action = acItem.TonTransfer;
					const balanceChangeType =
						getAddress(action.recipient) === MpcWallet.getTonWalletAddress() ? 'IN' : 'OUT';
					return (
						<Box width="100%" key={index}>
							<TokenAmount
								amount={action?.amount}
								decimals={TON_TOKEN.decimals}
								image={TON_TOKEN.image}
								symbol={TON_TOKEN.symbol}
								type={balanceChangeType}
								chainId={ChainId.TON as any}
								address={TON_TOKEN.address}
								showActionIcon
							/>

							{balanceChangeType === 'OUT' && (
								<TransactionItemInfo
									title={
										<Center gap={2}>
											<FiWalletAddressIcon />
											<Text fontWeight={600}>To</Text>
										</Center>
									}
									value={getShortAddress(getAddress(action.recipient), {
										start: 12,
										end: 8,
									})}
								/>
							)}

							{action.comment && <TransactionItemInfo title="Comment" value={action.comment} />}
						</Box>
					);
				}
				case 'DepositStake': {
					const action = acItem.DepositStake;
					const token = TON_TOKEN;
					return (
						<StackTokenAmount
							key={index}
							amount={action?.amount}
							decimals={token?.decimals}
							image={token?.image}
							symbol={token?.symbol}
							type={'OUT'}
							chainId={ChainId.TON as string}
							address={token.address}
							showActionIcon
							receiverName={action?.pool?.name}
						/>
					);
				}
				case 'WithdrawStake': {
					const action = acItem.WithdrawStake;
					const token = TON_TOKEN;
					return (
						<StackTokenAmount
							key={index}
							amount={action?.amount}
							decimals={token?.decimals}
							image={token?.image}
							symbol={token?.symbol}
							type={'IN'}
							chainId={ChainId.TON as string}
							address={token.address}
							showActionIcon
							receiverName={action?.pool?.name}
						/>
					);
				}
				case 'JettonMint': {
					const action = acItem.JettonMint;
					const jetton = action.jetton;
					return (
						<TokenAmount
							key={index}
							amount={action?.amount}
							decimals={jetton?.decimals}
							image={jetton?.image}
							symbol={jetton?.symbol}
							type={'IN'}
							chainId={ChainId.TON as string}
							address={jetton.address}
							showActionIcon
						/>
					);
				}
				case 'JettonBurn': {
					const action = acItem.JettonBurn;
					const jetton = action.jetton;
					return (
						<TokenAmount
							key={index}
							amount={action?.amount}
							decimals={jetton?.decimals}
							image={jetton?.image}
							symbol={jetton?.symbol}
							type={'OUT'}
							chainId={ChainId.TON as string}
							address={jetton.address}
							showActionIcon
						/>
					);
				}
				case 'NftItemTransfer': {
					const action = acItem.NftItemTransfer;
					const isOwner = action.sender.address === Address.parse(walletAddress).toRawString();
					if (!isOwner) return null;
					return <Box>{acItem.simple_preview.description}</Box>;
				}

				default:
					return defaultView();
			}
		});
	};
	return (
		<Drawer isOpen={open} placement="bottom" onClose={onClose} trapFocus={false}>
			<DrawerOverlay />
			<DrawerContent>
				<DrawerHeader borderBottomWidth="1px" borderColor="rgba(0, 0, 0, 0.08)">
					<Center>
						<Text fontSize={'16px'} fontWeight={'500'}>
							Confirm Transaction
						</Text>
					</Center>
				</DrawerHeader>

				<DrawerBody>
					<Card px={4} py={2} width={'100%'}>
						<Center gap={2} borderRadius={52} px={2} py={1} mb={2}>
							<Img
								width={8}
								height={8}
								src={requestParams?.connection?.manifest?.iconUrl}
								alt=""
								borderRadius="100%"
							/>
							<Text fontSize="sm">{requestParams?.connection?.manifest?.url}</Text>
						</Center>
						<Divider borderBottom="1px dashed rgba(0, 0, 0, 0.16)" mb={4} />
						{renderContend()}
						<CommonTransactionInfo params={requestParams?.request?.params[0] as string} />
					</Card>
				</DrawerBody>

				<DrawerFooter gap={2}>
					<ActionConfirm
						onApprove={onApprove}
						onReject={onReject}
						isLoadingApprove={isLoadingApprove || isPendingRejectOutdate}
						isLoadingReject={isLoadingReject}
					/>
				</DrawerFooter>
			</DrawerContent>
		</Drawer>
	);
}
