import React from 'react';

interface IProps extends React.SVGAttributes<HTMLOrSVGElement> {}

export const FiChartIcon: React.FC<IProps> = ({ width = 16, height = 16, fill = 'white', ...rest }) => {
	return (
		<svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M13.8333 0.5H2.16667C1.25 0.5 0.5 1.25 0.5 2.16667V13.8333C0.5 14.75 1.25 15.5 2.16667 15.5H13.8333C14.75 15.5 15.5 14.75 15.5 13.8333V2.16667C15.5 1.25 14.75 0.5 13.8333 0.5ZM13.8333 13.8333H2.16667V2.16667H13.8333V13.8333ZM3.83333 6.33333H5.5V12.1667H3.83333V6.33333ZM7.16667 3.83333H8.83333V12.1667H7.16667V3.83333ZM10.5 8.83333H12.1667V12.1667H10.5V8.83333Z"
				fill="black"
			/>
		</svg>
	);
};
