import TobiTabs from '@/app-components/common/TobiTabs';
import { ChainId, TobiChainName } from '@/app-constants/chains';
import { NAVIGATE_PATHS } from '@/app-constants/router';
import { ITokenSearch, QueryTokenParam } from '@/app-cores/api/bff';
import { useNavigateToSwapPage } from '@/app-helpers/navigate';
import { getNativeTobiId, getTokenInfo } from '@/app-helpers/token';
import { useSearchListToken, useSearchSingleToken, useSearchToken } from '@/app-hooks/api/portfolio';
import { SwapProvider } from '@/app-hooks/swap/type';
import { PumpToken, TokenMetadata } from '@/app-services/pump.fun';
import { InputMode, useSwapStore } from '@/app-store/swap';
import { BASE_BORDER_COLOR, colors } from '@/app-theme/theme';
import { SwapSetting } from '@/app-views/swap/components/SwapSetting';
import SwapWidget from '@/app-views/swap/components/SwapWidget';
import ChartPopup from '@/app-views/tobi-fun/containers/ChartPopup';
import { FiChartIcon } from '@/assets/icons/fi-chart-icon';
import { Box, Button, Divider, Flex, Text } from '@chakra-ui/react';
import { memo, useEffect, useMemo, useState } from 'react';

const SwapMemeLocal = ({ token, memeInfo }: { token: ITokenSearch; memeInfo: PumpToken | undefined }) => {
	const [showConfirm, setShowConfirm] = useState(false);
	const { setPair, setInputMode } = useSwapStore(true);

	const providers = useMemo(() => (memeInfo?.complete ? undefined : [SwapProvider.PUMPFUN]), [memeInfo]);

	const { data: sol } = useSearchSingleToken({ tobiId: getNativeTobiId(ChainId.SOL), chainId: ChainId.SOL });
	const meme = useMemo(() => {
		const tokenInfo = token;
		if (tokenInfo?.tokenMutableDataDto && !tokenInfo?.tokenMutableDataDto?.imageUrl) {
			tokenInfo.tokenMutableDataDto.imageUrl = memeInfo?.image_uri;
		}
		return tokenInfo;
	}, [token, memeInfo]);

	useEffect(() => {
		(sol || meme) && setPair(sol, meme);
	}, [sol, meme, setPair]);

	const onBuy = () => {
		setTab(0);
		setPair(sol, meme, true);
	};
	const onSell = () => {
		setTab(1);
		setPair(meme, sol, true);
	};

	const [tab, setTab] = useState(0);
	const tabsOption = [
		{
			label: 'Buy',
			onClick: onBuy,
			value: 0,
		},
		{
			label: 'Sell',
			onClick: onSell,
			value: 1,
		},
	];

	return (
		<Box bgColor="white" borderRadius={8} p={4}>
			<Flex justifyContent={'space-between'} borderBottom={`1px solid ${BASE_BORDER_COLOR}`} pb={2}>
				<Flex
					alignItems={'center'}
					gap={2}
					fontSize={'12px'}
					fontWeight={'500'}
					onClick={() => {
						if (showConfirm) setShowConfirm(false);
					}}
				>
					<TobiTabs
						containerStyle={{ flex: 1 }}
						tabs={tabsOption}
						value={tab}
						activeStyle={{
							bg: !tab ? colors.cyan[400] : colors.orange[100],
							color: !tab ? 'black' : colors.white,
						}}
					/>
				</Flex>

				<Flex align={'center'} gap={3}>
					<SwapSetting style={{ width: 'fit-content', height: 'fit-content', padding: 0, color: 'black' }} />
					<Divider orientation="vertical" h={'16px'} borderStyle={'dotted'} borderColor={colors.gray[300]} />
					<ChartPopup memeInfo={memeInfo}>
						<Flex gap={1} align={'center'}>
							<FiChartIcon />
							<Text fontSize={'12px'}>Chart</Text>
						</Flex>
					</ChartPopup>
				</Flex>
			</Flex>

			<SwapWidget
				providers={providers}
				showTokenSelector={false}
				confirmAsModal
				disableTransition
				selectTokenProps={{ px: 0 }}
				onBuildRouteSuccess={() => setShowConfirm(true)}
				onBack={() => setShowConfirm(false)}
				onViewActivity={() => setShowConfirm(false)}
				showConfirm={showConfirm}
				containerProps={{ style: { paddingBottom: 0 } }}
				actionProps={{ borderBottom: 'none' }}
				confirmActionProps={{ p: 0, pt: 4 }}
			/>
		</Box>
	);
};
export const SwapMeme = memo(SwapMemeLocal);
