import { Box, Center, Divider, Flex, Skeleton, Text } from '@chakra-ui/react';
import { CommingSoon } from '@/app-components/common';
import { FiBillIcon, FiDollarSignIcon, FiRightIcon, FiStartIcon, FiSwapIcon } from '@/assets/icons';
import { useTranslation } from 'react-i18next';
import { BASE_BORDER_COLOR, colors } from '@/app-theme/theme';
import { FiSwapIconColor } from '@/assets/icons/fi-swap-icon-color';
import { Balance } from '@/app-views/affiliate/containers/TotalFee';
import { AffiliateFeeInfo } from '@/app-services/fee';

export const Transactions = ({
	isFetching: isShowSkeleton,
	data,
}: {
	data: AffiliateFeeInfo | undefined;
	isFetching: boolean;
}) => {
	return (
		<Flex
			height={'174px'}
			flexDirection="column"
			gap={4}
			flex={1}
			backgroundColor="white"
			pb={3}
			pt="6px"
			px={4}
			borderRadius={8}
		>
			<Center flexDirection="column" justifyContent={'center'} flex={1}>
				<Flex
					sx={{
						border: `3px solid ${BASE_BORDER_COLOR}`,
						borderRadius: '100%',
						justifyContent: 'center',
						alignItems: 'center',
					}}
					w={'40px'}
					h={'40px'}
				>
					<FiSwapIconColor height={22} />
				</Flex>
				{isShowSkeleton ? (
					<Center height="57px" width="100%">
						<Skeleton height="38px" width="100%" startColor="white" endColor="#bfc3c9" />
					</Center>
				) : (
					<Text fontSize={'32px'}>{data?.totalTransactions ?? 0}</Text>
				)}
				<Center fontSize="xs" fontWeight={600}>
					Transactions
				</Center>
			</Center>
		</Flex>
	);
};
