import { CopyToClipboard } from '@/app-components/common';
import { ChainLogo } from '@/app-components/common/Avatar/TokenLogo';
import { ChainId } from '@/app-constants/chains';
import { ITokenSearch } from '@/app-cores/api/bff';
import { getShortAddress } from '@/app-helpers/address';
import { formatUsd } from '@/app-helpers/number';
import { getTokenInfo } from '@/app-helpers/token';
import { PumpToken } from '@/app-services/pump.fun';
import { Card, CardBody, Flex, Text } from '@chakra-ui/react';
import { useMemo } from 'react';

export default function TokenInformation({
	memeInfo,
	token,
}: {
	memeInfo: PumpToken | undefined;
	token: ITokenSearch;
}) {
	const { volume24hUsd, priceUsd, marketCapUsd } = getTokenInfo(token);
	const fields = useMemo(
		() => [
			{
				name: 'Contract Address',
				value: (
					<Flex gap={2}>
						<ChainLogo chainId={ChainId.SOL} style={{ width: '16px' }} />
						<CopyToClipboard text={memeInfo?.mint}>{getShortAddress(memeInfo?.mint)}</CopyToClipboard>
					</Flex>
				),
			},
			{ name: 'Price', value: priceUsd, dollar: true },
			{ name: 'Market cap', value: memeInfo?.usd_market_cap || marketCapUsd, dollar: true },
			{ name: 'Volume (24h)', value: volume24hUsd, dollar: true },
		],
		[memeInfo?.mint, memeInfo?.usd_market_cap, priceUsd, volume24hUsd, marketCapUsd],
	);

	return (
		<Card>
			<CardBody p={4}>
				<Flex flexDirection={'column'} gap={'12px'}>
					{fields.map((el) => (
						<Flex justifyContent={'space-between'} fontSize={'xs'} key={el.name}>
							<Text as="span">{el.name}</Text>
							<Text as="b">
								{!el.value
									? '--'
									: typeof el.value === 'number' || typeof el.value === 'string'
									? formatUsd(el.value, { showDollar: el.dollar })
									: el.value}
							</Text>
						</Flex>
					))}
				</Flex>
			</CardBody>
		</Card>
	);
}
