import { axiosBotAPI } from '@/app-cores/api/axios';
import { IActivity, ObserverTransaction, WalletActivity } from './types';
import { TEST_NETS } from '@/app-constants/chains';

export type GetActivityParams = {
	chainId?: string;
	tokenAddress?: string;
	includeSpam?: boolean;
	includeTestnet?: boolean;
};
export type GetActivityDetailParams = {
	activityKeys: {
		tobiId: string;
		chainId: string;
	}[];
};
export type GetWalletActivityParams = {
	includeSpam?: boolean;
	excludeChainIds?: string[];
};

class ActivitiesService {
	async getActivities({ includeTestnet, ...payload }: GetActivityParams = {}): Promise<IActivity[]> {
		const response = await axiosBotAPI.get(`/transaction/transactions/me`, {
			params: {
				offset: 0,
				limit: 50,
				excludeChainIds:
					includeTestnet || includeTestnet === undefined ? undefined : TEST_NETS.map((e) => e.id).join(','),
				...payload,
			},
		});
		return response.data.data?.transactions;
	}
	async getWalletActivities({ includeTestnet, ...payload }: GetActivityParams = {}): Promise<WalletActivity[]> {
		const response = await axiosBotAPI.post(`/v2/wallet/portfolio/activity`, {});

		return response.data.data;
	}
}

export const ActivitiesServiceAPI = new ActivitiesService();
