import { TonCenterServiceAPI } from '@/app-cores/api/toncenter/service-toncenter';
import { TonWallet } from '@/app-cores/mpc-wallet/ton/TonWallet';
import { WalletContractV4 } from '@/app-cores/mpc-wallet/ton/WalletContractV4';
import { MpcWallet } from '@/app-cores/mpc-wallet/wallet';
import { isNativeTobiToken, isNativeToken } from '@/app-helpers/address';
import {
	getFinalTonGasFee,
	getTonCoinTransferBalance,
	jettonForwardPayload,
	jettonTransferBody,
	seeIfTransferBounceble,
} from '@/app-helpers/ton';
import { TSentTonTransaction, TransactionType } from '@/app-types';
import { useMutation } from '@tanstack/react-query';
import { Address, Cell, OpenedContract, SendMode, beginCell, comment, internal, toNano } from '@ton/core';
import { useTransactionWatcherStore } from '../../../app-store';
import { ChainId } from '../../../app-constants/chains';
import { TxDetailTokenSend, TxStatus } from '@/app-cores/api/activities';
import { parseUnits } from 'ethers';
import { tryParseAmount } from '@/app-hooks/swap/helper';
import { getTokenInfo } from '@/app-helpers/token';

export const jettonTransferAmount = toNano('0.64');
export const jettonTransferForwardAmount = BigInt('1');

async function transferTon(
	contract: OpenedContract<WalletContractV4>,
	seqno,
	{ autoDeductFee = true, timeout, ...sendData }: TSentTonTransaction,
) {
	const { decimals } = sendData.token;
	const sendAmount = getTonCoinTransferBalance(sendData.amount, decimals);

	const totalUsed = sendAmount + getFinalTonGasFee(sendData.gasFee);

	if (!autoDeductFee && BigInt(sendData.token.balance) < totalUsed)
		throw new Error('Not enough balance to cover gas fee');

	const isMax = totalUsed >= BigInt(sendData.token.balance);

	const receiptAddressStatus = await TonCenterServiceAPI.getAddressState(sendData.to);
	const bounce = seeIfTransferBounceble(sendData.to, receiptAddressStatus.result);
	const payload = {
		seqno: seqno,
		messages: [
			internal({
				to: sendData.to,
				value: sendAmount,
				body: comment(sendData.message || ''),
				bounce,
			}),
		],
		sendMode: isMax
			? SendMode.CARRY_ALL_REMAINING_BALANCE + SendMode.IGNORE_ERRORS
			: SendMode.PAY_GAS_SEPARATELY + SendMode.IGNORE_ERRORS,
		timeout,
	};
	const transfer = contract.createTransfer(payload);
	const cell = await contract.sign(transfer);
	return cell;
}
async function transferJetton(contract: OpenedContract<WalletContractV4>, seqno, sendData: TSentTonTransaction) {
	const { timeout } = sendData;
	const { address, decimals } = sendData.token;
	const jettonWalletAddress = await TonWallet.getUserJettonWalletAddress(address);
	const walletAddress = Address.parse(MpcWallet.getTonWalletAddress());
	const destinationAddress = Address.parse(sendData.to);
	const sendAmount = getTonCoinTransferBalance(sendData.amount, decimals);
	const forwardPayload = jettonForwardPayload(sendData.message);

	const internalMessage = internal({
		to: jettonWalletAddress,
		value: toNano('0.05'),
		bounce: true,
		body: await jettonTransferBody({
			jettonAmount: sendAmount, // Jetton amount (in basic indivisible units)
			toAddress: destinationAddress, // recepient user's wallet address (not Jetton wallet)
			forwardAmount: jettonTransferForwardAmount,
			forwardPayload: forwardPayload, // text comment for Transfer notification message
			responseAddress: walletAddress, // return the TONs after deducting commissions back to the sender's wallet address
		}),
	});

	const transfer = contract.createTransfer({
		seqno: seqno,
		messages: [internalMessage],
		sendMode: SendMode.PAY_GAS_SEPARATELY + SendMode.IGNORE_ERRORS,
		timeout,
	});

	const cell = await contract.sign(transfer);
	return cell;
}

export const prepareSendTonToken = async (params: TSentTonTransaction, customNonce?: number) => {
	const { contract, wallet } = await TonWallet.create('mainnet');
	const seqno = customNonce || (await contract.getSeqno());
	let cell: Cell;
	if (isNativeToken(params.token.address)) {
		cell = await transferTon(contract, seqno, params);
	} else {
		cell = await transferJetton(contract, seqno, params);
	}
	return { cell, seqno, contract, wallet };
};

export function useSentTonToken() {
	const { addPendingTonTransaction } = useTransactionWatcherStore();
	const { mutateAsync: sendTransaction, ...result } = useMutation({
		mutationKey: ['sent-ton-transaction'],
		mutationFn: async (params: TSentTonTransaction) => {
			console.log('sent ton', params);
			const { cell, contract, seqno } = await prepareSendTonToken(params);
			await contract.send(cell);
			const hash = cell.hash().toString('base64');
			return { seqno, hash };
		},
		onSuccess: ({ hash }, { metadata, skipAddPendingTxs }) => {
			if (skipAddPendingTxs) return;
			addPendingTonTransaction({
				hash,
				trackingData: metadata,
			});
		},
	});
	return {
		...result,
		sendTransaction,
	};
}
