import { CSSProperties, useEffect, useMemo, useState } from 'react';

import { BoxProps, Flex, Text } from '@chakra-ui/react';
import { FiArrowRightIcon, FiInfoIcon, FiSwapIcon } from '@/assets/icons';
import { ITokenSearch } from '@/app-cores/api/bff';
import { SelectToken } from '@/app-components/common/SelectToken';
import { SearchMode } from '@/app-components/common/crypto-search';
import { TokenDetailInSwap } from '@/app-views/portfolio/pages/token';
import { getShortAddress, isNativeToken } from '@/app-helpers/address';
import { useSearchParams } from 'react-router-dom';
import { DATADOG_ACTIONS, dataDogAddAction } from '@/app-services/monitor/datadog';
import { ChainId, EVM_CHAINS } from '@/app-constants/chains';
import { getTokenInfo } from '@/app-helpers/token';
const selectTokenStyle: CSSProperties = {
	border: 'none',
	background: 'gray.100',
	borderRadius: '12px',
	padding: '12px',
	height: '50px',
	width: '100%',
};

const tokenLogoSize = 32;

const TokenInfo = ({
	token,
	setSelectToken,
}: {
	token: ITokenSearch | undefined;
	setSelectToken: (v: ITokenSearch | undefined) => void;
}) => {
	if (!token) return null;
	const tokenInfo = getTokenInfo(token);
	return (
		<Flex
			fontSize={'12px'}
			alignItems={'center'}
			gap={'4px'}
			onClick={(e) => {
				setSelectToken(token);
			}}
		>
			<Text as="span" fontSize={'10px'} fontWeight={'unset'}>
				{isNativeToken(tokenInfo?.address)
					? 'Native'
					: getShortAddress(tokenInfo?.address, { start: 5, end: 3 })}
			</Text>
			<FiInfoIcon />
		</Flex>
	);
};

export type SelectTokenGroupProps = {
	onSelectTokenIn: (v: ITokenSearch) => void;
	onSelectTokenOut: (v: ITokenSearch) => void;
	onSwitchClick?: () => void;
	style?: CSSProperties;
	disabledTokenOut?: boolean;
	tokenIn: ITokenSearch | undefined;
	tokenOut: ITokenSearch | undefined;
	enableSwitchToken?: boolean;
	filterChainId?: ChainId | string;
	inputPlaceholder?: string;
	labelIn: string;
	labelOut: string;
	wrapperIds?: {
		from: string;
		to: string;
	};
	chainIds?: ChainId[];
	selectTokenProps?: BoxProps;
	fetchingToken?: boolean;
};
export default function SelectTokenGroup({
	onSelectTokenIn,
	onSelectTokenOut,
	onSwitchClick,
	style,
	disabledTokenOut,
	tokenIn,
	tokenOut,
	enableSwitchToken = true,
	filterChainId,
	chainIds,
	inputPlaceholder,
	labelIn,
	labelOut,
	wrapperIds,
	selectTokenProps,
	fetchingToken,
}: SelectTokenGroupProps) {
	const [selectToken, setSelectToken] = useState<ITokenSearch>();
	const [searchParams] = useSearchParams();

	const props = {
		tokenStyle: {
			logoSize: tokenLogoSize,
			symbolGap: '10px',
		},
		style: selectTokenStyle,
		searchMode: SearchMode.SELECT_ANY_TOKEN,
		chainIds,
		loading: fetchingToken,
	};

	return (
		<Flex
			width={'100%'}
			justifyContent={'space-between'}
			gap={4}
			alignItems={'center'}
			style={style}
			{...selectTokenProps}
		>
			<Flex flexDirection={'column'} gap={'8px'} alignItems={'flex-start'} id={wrapperIds?.from} flex={1}>
				<Flex alignItems={'center'} justifyContent={'space-between'} width={'100%'}>
					<Text fontSize={'12px'} fontWeight={'500'}>
						{labelIn}
					</Text>
					<TokenInfo token={tokenIn} setSelectToken={setSelectToken} />
				</Flex>
				<SelectToken
					{...props}
					token={tokenIn}
					onSelectToken={onSelectTokenIn}
					chainId={filterChainId}
					inputPlaceholder={inputPlaceholder}
				/>
			</Flex>
			{enableSwitchToken &&
				(disabledTokenOut ? (
					<FiArrowRightIcon style={{ height: 16, minWidth: 16, transform: 'translateY(12px)' }} />
				) : (
					<FiSwapIcon
						style={{ height: 16, minWidth: 16, transform: 'translateY(10px)' }}
						onClick={onSwitchClick}
					/>
				))}

			<Flex flexDirection={'column'} gap={'8px'} alignItems={'flex-start'} id={wrapperIds?.to} flex={1}>
				<Flex alignItems={'center'} justifyContent={'space-between'} width={'100%'}>
					<Text fontSize={'12px'} fontWeight={'500'}>
						{labelOut}
					</Text>
					<TokenInfo token={tokenOut} setSelectToken={setSelectToken} />
				</Flex>
				<SelectToken
					{...props}
					token={tokenOut}
					onSelectToken={onSelectTokenOut}
					disabled={disabledTokenOut}
					defaultQuery={searchParams.get('toToken')}
					chainId={filterChainId}
					inputPlaceholder={inputPlaceholder}
				/>
			</Flex>
			<TokenDetailInSwap
				isOpen={!!selectToken}
				token={getTokenInfo(selectToken)}
				onClose={() => setSelectToken(undefined)}
				isNonWallet={false}
			/>
		</Flex>
	);
}
