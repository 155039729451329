import { QUERY_KEYS } from '@/app-constants';
import { ChainId } from '@/app-constants/chains';
import { axiosPumpFun } from '@/app-cores/api/axios';
import { BffServiceAPI, ITokenSearch } from '@/app-cores/api/bff';
import { ChartData } from '@/app-cores/api/bff/chart';
import { SolWallet } from '@/app-cores/mpc-wallet/solana/SolWallet';
import { MpcWallet } from '@/app-cores/mpc-wallet/wallet';
import { getNativeToken, getTokenInfo } from '@/app-helpers/token';
import { ONE_DAY, ONE_HOUR, ONE_MINUTE } from '@/app-hooks/api/portfolio/constant';
import { usePriceNativeToken } from '@/app-hooks/api/portfolio/useTokenPrices';
import {
	MemeOnChainData,
	PumpChartData,
	PumpComment,
	PumpFunSDK,
	PumpHolder,
	PumpToken,
	PumpTrade,
	TokenMetadata,
} from '@/app-services/pump.fun';
import { formatPumpTokenToTobiToken, PUMP_TOKEN_TOTAL_SUPPLY } from '@/app-views/tobi-fun/helpers';
import { PublicKey, VersionedTransaction } from '@solana/web3.js';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { formatUnits, parseUnits } from 'ethers';
import { isEmpty } from 'lodash';

export const useMemeMetaData = (uri: string) => {
	const response = useQuery({
		queryKey: ['get-meme-metadata-info', uri],
		queryFn: async (): Promise<TokenMetadata> => {
			const data = await axios.get(uri);
			return data.data;
		},
		enabled: !!uri,
		gcTime: ONE_HOUR,
		staleTime: ONE_HOUR,
	});
	return response;
};
export const useMemeMarketCapData = (tokenAddress: string) => {
	const { data: price } = usePriceNativeToken({ chainId: ChainId.SOL });
	const response = useQuery({
		queryKey: ['get-meme-market-info', tokenAddress, price],
		queryFn: async (): Promise<MemeOnChainData> => {
			const sdk = await PumpFunSDK.create();
			const bonding = await sdk.getBondingCurveAccount(new PublicKey(tokenAddress));
			const marketCap = await bonding.getMarketCapSOL();
			const formatMarketCap = +formatUnits(marketCap, getNativeToken(ChainId.SOL).decimals);
			return { marketCap: formatMarketCap * price };
		},
		enabled: !!tokenAddress && !!price,
		gcTime: 15_000,
		staleTime: 15_000,
	});
	return response;
};

export const useMemeBondingCurvePercent = (tokenAddress: string, curveAddress: string) => {
	const response = useQuery({
		queryKey: ['useMemeBondingCurvePercent', tokenAddress, curveAddress],
		queryFn: async () => {
			const sdk = await PumpFunSDK.create();
			const percent = sdk.getBondingPercent(new PublicKey(tokenAddress), curveAddress);
			return percent;
		},
		enabled: !!tokenAddress,
		gcTime: 15_000,
		staleTime: 15_000,
	});
	return response;
};

export const useMemeBondingCurveInfo = ({ address }: { address: string }) => {
	const response = useQuery({
		queryKey: ['useMemeBondingCurve', address],
		queryFn: async () => {
			const sdk = await PumpFunSDK.create();
			const estimateAmountOut = await sdk.getBondingCurveProgress(address);
			return estimateAmountOut;
		},
		enabled: !!address,
		gcTime: 15_000,
		staleTime: 15_000,
	});
	return response;
};

export const useGetCoinsByWallet = (wallet: string, enabled: boolean) => {
	const response = useQuery({
		queryKey: [QUERY_KEYS.GET_MY_CREATED_MEMES, wallet],
		queryFn: async (): Promise<PumpToken[]> => {
			try {
				const data = await axiosPumpFun.get(
					`/coins/user-created-coins/${wallet}?offset=0&limit=50&includeNsfw=false`,
				);
				return data.data;
			} catch (error) {
				return [];
			}
		},
		enabled: !!wallet && enabled,
		gcTime: ONE_MINUTE,
		staleTime: ONE_MINUTE,
	});
	return response;
};

export const useGetPortfolioMeme = () => {
	const response = useQuery({
		queryKey: [QUERY_KEYS.GET_MY_MEME_BALANCE],
		queryFn: async () => {
			const data = await axiosPumpFun.get(
				`/balances/${MpcWallet.getSolanaWalletAddress()}?limit=50&offset=0&minBalance=-1`,
			);
			const tokens: PumpToken[] = data.data?.filter((e) => e.balance).map((e) => ({ ...e, address: e.mint }));
			// todo better if backend support search token by list chain address
			let totalUsd = 0;
			const resp = await Promise.allSettled(
				tokens.map((e) =>
					BffServiceAPI.searchTokens({
						query: e.mint,
						chainId: ChainId.SOL,
						limit: 1,
					})
						.then((data) => {
							const item = formatPumpTokenToTobiToken(e as any);
							const meme = data?.[0];
							const balance = item.balance ?? '0';
							const { decimals, priceUsd } = getTokenInfo(meme || item);
							const usdValue = +formatUnits(balance, decimals) * priceUsd;
							totalUsd += usdValue;
							const newToken: ITokenSearch = {
								...(meme || item),
								balance,
								usdValue,
							};
							return newToken;
						})
						.catch(() => formatPumpTokenToTobiToken(e)),
				),
			);
			const formatTokens = resp.map((e) => (e.status === 'fulfilled' ? e.value : null)).filter(Boolean);
			return { tokens: formatTokens, totalUsd };
		},
		enabled: !!MpcWallet.getSolanaWalletAddress(),
		gcTime: 15_000,
		staleTime: 15_000,
	});
	return response;
};

export const useGetListMemeByType = (type: string, pageSize: number) => {
	const response = useQuery({
		queryKey: ['get-trending-info', type, pageSize],
		queryFn: async (): Promise<PumpToken[]> => {
			const params = {
				limit: pageSize,
				includeNsfw: false,
				offset: 0,
				order: 'DESC',
			};
			const data = await axiosPumpFun.get(type, {
				params,
			});
			return data.data;
		},
		gcTime: 15_000,
		staleTime: 15_000,
	});
	return response;
};

export const useSearchMeme = ({ keyword, enabled, limit }: { keyword: string; enabled: boolean; limit: number }) => {
	const response = useQuery({
		queryKey: ['get-search-meme-info', keyword],
		queryFn: async (): Promise<PumpToken[]> => {
			const data = await axiosPumpFun.get(
				`/coins?offset=0&limit=${limit}&sort=market_cap&order=DESC&includeNsfw=false&searchTerm=${keyword}`,
				{},
			);
			return data.data;
		},
		enabled,
		gcTime: ONE_MINUTE,
		staleTime: ONE_MINUTE,
	});
	return response;
};

export const useGetMemeTrades = (address: string) => {
	const response = useQuery({
		queryKey: ['get-created-meme', address],
		queryFn: async (): Promise<PumpTrade[]> => {
			const data = await axiosPumpFun.get(`/trades/all/${address}?limit=200&offset=0&minimumSize=0`);
			return data.data;
		},
		enabled: !!address,
		gcTime: ONE_MINUTE,
		staleTime: ONE_MINUTE,
	});
	return response;
};

export const useGetMemeReply = (address: string) => {
	const response = useQuery({
		queryKey: ['get-cmt-meme', address],
		queryFn: async (): Promise<PumpComment[]> => {
			const data = await axiosPumpFun.get(
				`/replies/${address}?limit=1000&offset=0&user=${MpcWallet.getSolanaWalletAddress()}`,
			);
			return data?.data?.replies?.sort((a: PumpComment, b: PumpComment) => b.timestamp - a.timestamp);
		},
		enabled: !!address,
		gcTime: 15_000,
		staleTime: 15_000,
	});
	return response;
};

type ChartParams = { address: string; limit: number; timeframe: number; offset: number };
export const fetchMemeChart = async (params: ChartParams): Promise<ChartData> => {
	const data = await axiosPumpFun.get(`/candlesticks/${params.address}`, { params });
	const chartData: PumpChartData[] = data?.data ?? [];
	return chartData.map((e) => ({
		timestamp: e.timestamp,
		open: e.open,
		low: e.low,
		high: e.high,
		close: e.close,
	}));
};

export const useGetMemeChart = (params: ChartParams, enabled = true) => {
	const { address, offset, timeframe } = params;
	const response = useQuery({
		queryKey: ['get-chart-meme', address, offset, timeframe],
		queryFn: async (): Promise<ChartData> => {
			return fetchMemeChart(params);
		},
		enabled: !!address && enabled,
		gcTime: 15_000,
		staleTime: 15_000,
	});
	return response;
};

export const useEstimateCreateMeme = ({ buyAmountSol, buyAmountMeme }) => {
	const response = useQuery({
		queryKey: ['est-fee-create-meme', buyAmountSol?.toString(), buyAmountMeme?.toString()],
		queryFn: async () => {
			const sdk = await PumpFunSDK.create();
			const estimateAmountOut = buyAmountSol ? await sdk.getEstAmountOutFirstBuy(buyAmountSol) : buyAmountMeme;
			const estimateAmountSol = buyAmountSol
				? buyAmountSol
				: await sdk.getEstAmountOutFirstBuyViaTokenAmount(buyAmountMeme);
			const gasFee = parseUnits('0.026', getNativeToken(ChainId.SOL).decimals); // gas + fee created
			return { estimateAmountOut, estimateAmountSol, gasFee };
		},
		enabled: !!(buyAmountSol || buyAmountMeme),
		gcTime: 15_000,
		staleTime: 15_000,
	});
	return response;
};

export const useMemeTopHolder = (address) => {
	const response = useQuery({
		queryKey: ['useMemeTopHolder', address],
		queryFn: async (): Promise<PumpHolder[]> => {
			const { connection } = await SolWallet.init('mainnet-beta', { commitment: 'confirmed' });
			const data = await connection.getTokenLargestAccounts(new PublicKey(address));
			const results = await Promise.all(
				data.value.slice(0, 10).map(async (account) => {
					const accountInfo = await connection.getParsedAccountInfo(new PublicKey(account.address));
					const owner = (accountInfo?.value?.data as any).parsed?.info?.owner;
					return {
						address: owner,
						percent: (account.uiAmount / PUMP_TOKEN_TOTAL_SUPPLY) * 100,
					};
				}),
			);
			return results.filter((e) => !!e.address).sort((a, b) => b.percent - a.percent);
		},
		enabled: !!address,
		gcTime: 45_000,
		staleTime: 45_000,
	});
	return response;
};
