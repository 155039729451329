import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { BffServiceAPI, ITokenCatalogDetail, ITokenSearch, QueryTokenParam } from '@/app-cores/api/bff';
import { ONE_MINUTE } from './constant';
import { useMemo } from 'react';
import { ChainId, EVM_CHAINS } from '@/app-constants/chains';
import { DefinedFiResolution } from '@/app-store/settings';

// with market data
export const useTokenDetail = (payload: QueryTokenParam, options?: Omit<UseQueryOptions<ITokenSearch>, 'queryKey'>) => {
	const { chainId, address, tobiId } = payload;
	const response = useQuery({
		queryKey: ['search-token-detail', tobiId, address, chainId],
		queryFn: async () => {
			return BffServiceAPI.getTokenDetail(payload);
		},
		staleTime: ONE_MINUTE * 1,
		enabled: !!tobiId || (!!chainId && !!address),
		...options,
	});
	return response;
};

// search by keyword / address, use for search function
export const useSearchToken = (
	payload: { query: string; limit: number; chainId: ChainId | string; chainIds?: ChainId[] },
	options?: Omit<UseQueryOptions<ITokenSearch[]>, 'queryKey'>,
) => {
	const response = useQuery({
		queryKey: ['search-token-v2', payload.query, payload.limit, payload.chainId],
		queryFn: () => {
			return BffServiceAPI.searchTokens(payload);
		},
		staleTime: ONE_MINUTE * 1,
		...options,
	});
	return response;
};

// search exact by list tobi ids
export const useSearchListToken = (payload: { query: QueryTokenParam[]; staleTime?: number }) => {
	const response = useQuery({
		queryKey: ['search-list-token-v2', ...payload.query.map((e) => e.chainId + e.tobiId + e.address)],
		queryFn: () => {
			return BffServiceAPI.searchExactListTokens(payload);
		},
		staleTime: ONE_MINUTE * (payload.staleTime || 1),
		enabled: payload?.query?.length > 0,
	});
	return response;
};

export const useSearchSingleToken = (
	payload: QueryTokenParam,
	options?: Omit<UseQueryOptions<ITokenSearch>, 'queryKey'>,
) => {
	const { chainId = '', address = '', tobiId = '' } = payload;
	const response = useQuery({
		queryKey: ['search-single-tokenv2', chainId, tobiId, address],
		queryFn: () => BffServiceAPI.searchExactSingleToken(payload),
		staleTime: ONE_MINUTE * 1,
		...options,
		enabled: (options?.enabled !== undefined ? options.enabled : true) && ((!!chainId && !!address) || !!tobiId),
	});
	return response;
};

export const useGetTrendingTokens = ({
	chainId,
	resolution,
}: {
	chainId?: ChainId;
	resolution?: DefinedFiResolution;
}) => {
	const response = useQuery({
		queryKey: ['trending-token', chainId, resolution],
		queryFn: async () => {
			return BffServiceAPI.getTrendingTokens();
		},
		staleTime: ONE_MINUTE * 5,
		retry: false,
	});
	return response;
};
