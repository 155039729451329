import { FeeSwapParams, getPayloadFeeSwap } from '@/app-hooks/swap';
import { getGasInfo } from '@/app-hooks/api/transactions/useQueryGasPrice';
import {
	isChainHasSwapFee,
	isEclipseChain,
	isEvmChain,
	isSolanaChain,
	isSuiChain,
	isTonChain,
	isTronChain,
} from '@/app-helpers/token';
import { estimateGasFeeSendToken, prepareSendEvmToken, prepareSendTonToken } from '@/app-hooks/transactions';
import { ActivitiesServiceAPI, ObserverTransaction } from '@/app-cores/api/activities';
import { SelectedRoute } from '@/app-store/swap';
import { useSentSolToken } from '@/app-hooks/transactions/sol/useMutationSentSolAsset';
import { delay } from '@/app-helpers';
import { ONE_MINUTE } from '@/app-hooks/api/portfolio/constant';
import {
	TSentSolTransaction,
	TSentSUiTransaction,
	TSentTonTransaction,
	TSentTransaction,
	TSentTronTransaction,
} from '@/app-types';
import { prepareSendTronToken } from '@/app-hooks/transactions/tron/useMutationSendTronAsset';
import { truncateToFixed } from '@/app-helpers/number';
import { estimateTonGasFee } from '@/app-hooks/transactions/ton/useEstimateFeeSendTonAsset';
import { DATADOG_ERROR_TAGS, dataDogAddError } from '@/app-services/monitor/datadog';
import { TonWallet } from '@/app-cores/mpc-wallet/ton/TonWallet';
import { useParseBotStartRedirectParams } from '@/app-cores/telegram';
import { ChainId } from '@/app-constants/chains';
import { useSentEclipseToken } from '@/app-hooks/transactions/eclipse/useMutationSentEclipseAsset';
import { createSuiTokenTransferTransaction } from '@/app-hooks/transactions/sui/createTokenTransferTransaction';
import { suiWallet } from '@/app-cores/mpc-wallet/sui';
import { getFullnodeUrl, SuiClient } from '@mysten/sui/client';
import { SwapServiceAPI } from '@/app-cores/api/swap';

const expireTime = 3600; // one hour

export const useTakeFeeSwap = () => {
	const { sendTransaction } = useSentSolToken();
	const { sendTransaction: sendEclipse } = useSentEclipseToken();
	const swapPrams = useParseBotStartRedirectParams();

	const takeFeeSolana = async (payload: ObserverTransaction, payloadSend: TSentSolTransaction) => {
		const totalTime = payload.isSwapSameChain ? ONE_MINUTE * 3 : 30 * ONE_MINUTE;
		const duration = 5000;
		let isSuccess = false;
		for (let i = 0; i <= Math.ceil(totalTime / duration); i++) {
			try {
				await delay(duration);
				const data: string = await SwapServiceAPI.checkSwapTransaction({
					...payload,
					isSameChain: payload.isSwapSameChain,
				});
				if (data === 'swapped') {
					isSuccess = true;
					break;
				}
			} catch (error) {}
		}
		if (!isSuccess) return;
		try {
			const sendFn = isSolanaChain(payload.chainId) ? sendTransaction : sendEclipse;
			const feeHash = await sendFn(payloadSend);
			await SwapServiceAPI.submitMetadataSwapTxs({
				...payload,
				swapResult: 'swapped',
				feeError: '',
				feeHash,
			});
		} catch (error) {
			await SwapServiceAPI.submitMetadataSwapTxs({
				...payload,
				swapResult: 'swapped',
				feeError: error?.toString(),
				feeHash: '',
			});
		}
	};

	const takeFeeTron = async (payload: ObserverTransaction, payloadSend: TSentTronTransaction) => {
		const data = await prepareSendTronToken(payloadSend, expireTime);
		payload.signatureFeeTransaction = JSON.stringify(data);
		return payload;
	};

	const takeFeeSui = async (payload: ObserverTransaction, payloadSend: TSentSUiTransaction) => {
		const tx = await createSuiTokenTransferTransaction(payloadSend);
		const signed = await suiWallet.signTransactionBlock({
			transactionBlock: tx,
		});
		payload.signatureFeeTransaction = JSON.stringify(signed);
		return payload;
	};

	const takeFeeTon = async (payload: ObserverTransaction, payloadSend: TSentTonTransaction) => {
		const { contract } = await TonWallet.create('mainnet');
		const seqno = await contract.getSeqno();
		const gasFee = await estimateTonGasFee(payloadSend); // todo merge

		const payloadWithTimeout = {
			...payloadSend,
			gasFee,
			timeout: Math.floor(Date.now() / 1000) + expireTime,
		};
		const { cell, wallet } = await prepareSendTonToken(payloadWithTimeout, seqno + 1);
		payload.signatureFeeTransaction = cell.toBoc().toString('base64');
		try {
			const { cell } = await prepareSendTonToken(payloadWithTimeout, seqno + 2);
			payload.backupFeeSignature = cell.toBoc().toString('base64');
		} catch (error) {
			console.log('backUpSignatureFeeTransaction err', error);
		}
		payload.walletPublicKey = wallet?.publicKey?.toString('hex');

		return payload;
	};

	const takeFeeEvm = async (
		payload: ObserverTransaction,
		payloadSend: TSentTransaction,
		{ nonce }: { nonce: number; chainId: string },
	) => {
		const { getSignedTransaction } = await prepareSendEvmToken({
			...payloadSend,
			nonce,
		});
		payload.signatureFeeTransaction = await getSignedTransaction(nonce ? nonce : undefined);
		try {
			payload.backupFeeSignature = await getSignedTransaction(nonce ? nonce + 1 : undefined);
		} catch (error) {
			console.log('backUpSignatureFeeTransaction err', error);
		}
		return payload;
	};

	return async (data: {
		route: SelectedRoute;
		hash: string;
		nonce?: number; // evm
		queryId?: number; // ton
	}) => {
		try {
			const { route, nonce } = data;
			const { tokenIn, feeInfo } = route;
			const chainId = tokenIn?.chainId;
			if (!isChainHasSwapFee(chainId)) return;
			console.log('start get fee', { feeInfo, nonce, chainId });
			if (!feeInfo) throw new Error('No fee info');

			const { EVM, SOL, TON, TRON, SUI } = feeInfo.wallets;
			const mapWallet = {
				[ChainId.SUI]: SUI,
				[ChainId.TON]: TON,
				[ChainId.TRON]: TRON,
				[ChainId.SOL]: SOL,
				[ChainId.ECLIPSE]: SOL,
			};
			const feeReceiver = isEvmChain(chainId) ? EVM : mapWallet[chainId];

			if (!feeReceiver) throw new Error(`Empty fee address ${chainId}`);

			const feeAmount = truncateToFixed(feeInfo.fee, tokenIn.decimals);
			let payload = getPayloadFeeSwap({
				...data,
				feeAmount,
				signatureFeeTransaction: '',
				affiliateCode: swapPrams.affiliateCode,
				communityId: swapPrams.communityId,
			});
			const payloadSend = { amount: feeAmount, to: feeReceiver, token: tokenIn, autoDeductFee: true };

			switch (chainId) {
				case ChainId.SUI:
					payload = await takeFeeSui(payload, payloadSend);
					break;
				case ChainId.TRON:
					payload = await takeFeeTron(payload, payloadSend);
					break;
				case ChainId.TON:
					payload = await takeFeeTon(payload, payloadSend);
					break;
				case ChainId.SOL:
				case ChainId.ECLIPSE:
					await takeFeeSolana(payload, payloadSend);
					return;
				default:
					if (isEvmChain(chainId)) {
						payload = await takeFeeEvm(payload, payloadSend, { nonce, chainId });
					}
					break;
			}
			if (!payload.signatureFeeTransaction) throw new Error('Not found signatureFeeTransaction');
			await SwapServiceAPI.submitMetadataSwapTxs(payload);
		} catch (error) {
			console.error('Take fee error:', error);
			dataDogAddError(error, {
				tags: {
					name: DATADOG_ERROR_TAGS.TRADE,
					function: 'takeSwapFee',
				},
			});
		}
	};
};
