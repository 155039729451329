import { CHAIN_CONFIG, ChainId, NATIVE_TOKEN_ADDRESS, STABLE_COINS } from '@/app-constants/chains';
import { NAVIGATE_PATHS } from '@/app-constants/router';
import { BalanceResponse, BffServiceAPI, ITokenSearch, QueryTokenParam } from '@/app-cores/api/bff';
import { compareAddress, compareChain, compareTobiToken, compareToken, isNativeTobiToken } from '@/app-helpers/address';
import { getCachedPortfolioBalance } from '@/app-helpers/data';
import {
	getChainIdByTobiChainName,
	getNativeTobiId,
	getTobiChainName,
	getTokenInfo,
	isTestnetChain,
	selectTokenDefaultFromUniversalToken,
} from '@/app-helpers/token';
import { parseUrlSearchParams, toQueryString } from '@/app-helpers/url';
import { useSearchListToken, useSearchSingleToken } from '@/app-hooks/api/portfolio';
import { usePortfolioBalance, usePortfolioBalanceByCategories } from '@/app-hooks/api/portfolio/usePortfolioBalance';
import { SwapService } from '@/app-hooks/swap';
import { calcAmount } from '@/app-hooks/swap/helper';
import Sol2TcatSwap from '@/app-hooks/swap/sol_2_tcat';
import { MainRouter } from '@/app-router';
import { useSentTokenStore } from '@/app-store';
import { useUserSettingsStore } from '@/app-store/settings';
import { SelectedRoute, useSwapStore } from '@/app-store/swap';
import { useLimitStore } from '@/app-store/swap/limit';
import { RouterNavigateOptions } from '@remix-run/router';
import { parseUnits } from 'ethers';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { NavigateOptions, useNavigate, useSearchParams } from 'react-router-dom';

export type StartAppParamSend = {
	token?: ITokenSearch | string;
	usdAmount?: string; // deprecated
	amountUsd?: string;
	amount?: string;
	teleId?: string;
	chainId?: string;
};

export const useNavigateToSentPage = () => {
	const { setTokenSent } = useSentTokenStore();
	return useCallback(
		({ token = '', usdAmount = '', amountUsd = '', amount = '', teleId = '', chainId = '' }: StartAppParamSend) => {
			if (token && typeof token === 'object') setTokenSent(token); // don't need to call api
			MainRouter.navigate(
				toQueryString(NAVIGATE_PATHS.Wallet.Sent.Sent, {
					token: typeof token === 'string' ? token : '',
					chainId,
					amount,
					userTelegramId: teleId,
					amountUsd: amountUsd || usdAmount,
				}),
			);
		},
		[setTokenSent],
	);
};

export const useSelectTokenMaxPortfolio = () => {
	const {
		data: { mainTokensHasBalance },
	} = usePortfolioBalanceByCategories();

	const getTokenIn = useCallback(
		(tokenOut: ITokenSearch, chainId: string, fallbackToken?: ITokenSearch) => {
			if (!isNativeTobiToken(tokenOut)) {
				const { USDC, USDT } = STABLE_COINS[chainId] || {};

				const tokenSameChain = mainTokensHasBalance.filter(
					(e) => !compareTobiToken(tokenOut, e) && compareChain(getTokenInfo(e).chainId, chainId),
				);

				const native = tokenSameChain.find((e) =>
					compareAddress(getTokenInfo(e).address, NATIVE_TOKEN_ADDRESS),
				);
				const usdc = tokenSameChain.find((e) => compareAddress(getTokenInfo(e).address, USDC));
				const usdt = tokenSameChain.find((e) => compareAddress(getTokenInfo(e).address, USDT));

				const sort = [native, usdc, usdt].filter(Boolean).sort((a, b) => +b.usdValue - +a.usdValue);

				return sort[0] || fallbackToken;
			}

			const maxBalanceFn = (rs: ITokenSearch, token: ITokenSearch) =>
				compareTobiToken(token, tokenOut) ? rs : !rs || token?.usdValue > +rs?.usdValue ? token : rs;

			if (isTestnetChain(tokenOut)) {
				return (
					mainTokensHasBalance.filter((e) => isTestnetChain(e)).reduce(maxBalanceFn, null) || fallbackToken
				);
			}

			const nativeMaxBalance = mainTokensHasBalance
				.filter((e) => !isTestnetChain(e) && isNativeTobiToken(e))
				.reduce(maxBalanceFn, null);

			return nativeMaxBalance || fallbackToken;
		},
		[mainTokensHasBalance],
	);

	return getTokenIn;
};

export const populateBalance = ({
	newTokenIn,
	newTokenOut,
	tokenBalances,
}: {
	newTokenIn: ITokenSearch | undefined;
	newTokenOut: ITokenSearch | undefined;
	tokenBalances: ITokenSearch[];
}) => {
	const populateIn = tokenBalances?.find((e) => compareTobiToken(e, newTokenIn));
	const populateOut = tokenBalances?.find((e) => compareTobiToken(e, newTokenOut));
	if (populateIn && newTokenIn) newTokenIn.balance = populateIn.balance;
	if (populateOut && newTokenOut) newTokenOut.balance = populateOut.balance;
};

const getQuery = (defaultChain: string | ChainId) => {
	return {
		query: defaultChain
			? [
					{
						tobiId: getNativeTobiId(defaultChain),
						chainId: defaultChain?.toString(),
					},
					{ tobiId: getNativeTobiId(ChainId.SOL), chainId: ChainId.SOL },
					{
						tobiId: CHAIN_CONFIG[defaultChain]?.stableCoinTobiId,
						chainId: defaultChain?.toString(),
					},
			  ]
			: [],
	};
};
// select token by rules: https://www.notion.so/tobilabs/Swap-Routes-Fees-9a368e74ca214d6a9ef6aed611d09609?pvs=4#245b629eedc84e6b99382915b211e208
export const useFetchTokensForSwap = (defaultChain: string | ChainId) => {
	// that is preload data to reduce latency
	const queryData = useMemo(() => getQuery(defaultChain), [defaultChain]);

	const { data: tokens } = useSearchListToken(queryData);
	const selectTokenFromPortfolio = useSelectTokenMaxPortfolio();

	return useCallback(
		async (data: { balances?: ITokenSearch[] } & ({ tokenOut: ITokenSearch } | { tokenIn: ITokenSearch })) => {
			const tokenParam: ITokenSearch = data['tokenOut'] || data['tokenIn'];

			const findTokenFn = (tokens: ITokenSearch[]) => {
				const [nativeToken, sol, stable] = tokens || [];

				const targetToken = tokenParam;
				const findToken = selectTokenFromPortfolio(
					targetToken,
					getTokenInfo(targetToken)?.chainId,
					compareTobiToken(nativeToken, targetToken) ? stable || sol : nativeToken,
				);

				return { findToken, targetToken };
			};

			let tokenList = tokens || [];
			if (!tokenList?.length || tokenList?.every((e) => !e)) {
				try {
					// preload slow, call api instead
					tokenList = await BffServiceAPI.searchExactListTokens(
						getQuery(defaultChain || getTokenInfo(tokenParam)?.chainId),
					);
				} catch (error) {}
			}

			const { findToken, targetToken } = findTokenFn(tokenList);

			const isFindTokenIn = data['tokenOut'];
			const newTokenIn = isFindTokenIn ? findToken : targetToken;
			const newTokenOut = isFindTokenIn ? targetToken : findToken;

			populateBalance({
				newTokenIn,
				newTokenOut,
				tokenBalances: data?.balances || getCachedPortfolioBalance()?.balances,
			});
			return { tokenIn: newTokenIn, tokenOut: newTokenOut };
		},
		[selectTokenFromPortfolio, tokens, defaultChain],
	);
};

const getTobiId = (token: string | ITokenSearch) => (typeof token === 'string' ? token : getTokenInfo(token).idTobi);

const getChainId = (token: string | ITokenSearch, chainId: string | ChainId) =>
	chainId || (typeof token === 'string' ? '' : getTokenInfo(token).chainId) || '';

export const useNavigateToSwapPage = () => {
	return useCallback(
		(
			{
				tokenIn = '',
				tokenOut = '',
				chainIn = '',
				chainOut = '',
				amount = '',
				amountUsd = '',
			}: {
				tokenIn?: string | ITokenSearch;
				tokenOut?: string | ITokenSearch;
				chainIn?: string | ChainId;
				chainOut?: string | ChainId;
				amount?: string;
				amountUsd?: string;
			},
			opt?: NavigateOptions,
		) => {
			MainRouter.navigate(
				toQueryString(NAVIGATE_PATHS.Swap.Main, {
					tokenIn: getTobiId(tokenIn),
					tokenOut: getTobiId(tokenOut),
					chainIn: getChainId(tokenIn, chainIn),
					chainOut: getChainId(tokenOut, chainOut),
					amount,
					amountUsd,
				}),
				opt,
			);
		},
		[],
	);
};

const getToken = (token: ITokenSearch) => {
	return !token || getTokenInfo(token)?.chainId ? token : selectTokenDefaultFromUniversalToken(token);
};

export const useInitSwapData = (quickSwap = false) => {
	const { setPair, setAmount } = useSwapStore(quickSwap);
	const [searchParams] = useSearchParams();
	const {
		tokenIn,
		tokenOut,
		chainIn = '',
		chainOut = '',
		amount = '',
		amountUsd = '',
	} = Object.fromEntries(searchParams.entries());

	const selectTokenIn = useFetchTokensForSwap(getChainIdByTobiChainName(tokenOut ? chainOut : chainIn));

	const query = useMemo(() => {
		return {
			query: [
				{ tobiId: tokenOut, chainId: chainOut || undefined },
				{ tobiId: tokenIn, chainId: chainIn || undefined },
			].filter((e) => e.tobiId),
		};
	}, [tokenIn, tokenOut, chainIn, chainOut]);

	const { data: tokens } = useSearchListToken(query);

	const disabledFetch = quickSwap || query?.query?.length === 0;

	const [fetching, setFetching] = useState(!disabledFetch);
	const { data: balances } = usePortfolioBalance();

	const isInit = useRef(false);
	const hasSetTokenOut = useRef(false);

	useEffect(() => {
		if (disabledFetch) return;

		const setPairAndAmount = (tokenInParams: ITokenSearch, tokenOutParams: ITokenSearch) => {
			const tokenOut = getToken(tokenOutParams);
			const tokenIn = getToken(tokenInParams);

			populateBalance({
				newTokenIn: tokenIn,
				newTokenOut: tokenOut,
				tokenBalances: balances?.tokenBalances,
			});
			setPair(tokenIn, tokenOut);
			if (tokenIn && (amount || amountUsd)) {
				const resp = calcAmount({ amount, amountUsd, token: tokenIn });
				setAmount(resp.amount, resp.amountUsd);
			}
			setFetching(!tokenIn || !tokenOut);
		};

		const tokenOutInfo = tokenOut ? tokens?.[0] : undefined;
		const tokenInInfo = tokenOut ? tokens?.[1] : tokens?.[0];
		if (!hasSetTokenOut.current && (tokenInInfo || tokenOutInfo)) {
			setPairAndAmount(tokenInInfo, tokenOutInfo);
			hasSetTokenOut.current = true;
		}
		if (isInit.current || !balances) {
			return;
		}
		if (balances && tokens) isInit.current = true;

		const fn = async function () {
			const newTokenOut = getToken(tokenOutInfo);
			const newTokenIn = getToken(tokenInInfo);
			if (newTokenOut && !newTokenIn) {
				const { tokenIn, tokenOut } = await selectTokenIn({
					tokenOut: newTokenOut,
					balances: balances?.tokenBalances,
				});
				setPairAndAmount(tokenIn, tokenOut);
			} else if (newTokenIn && !newTokenOut) {
				const { tokenIn, tokenOut } = await selectTokenIn({
					tokenOut: newTokenIn,
					balances: balances?.tokenBalances,
				});
				setPairAndAmount(tokenOut, tokenIn);
			} else {
				setPairAndAmount(newTokenIn, newTokenOut);
			}
		};
		fn();
	}, [tokens, quickSwap, amount, balances, amountUsd, selectTokenIn, setAmount, setPair, disabledFetch, tokenOut]);

	return { fetching };
};

export const useNavigateToLimitPage = () => {
	const { data: portfolioBalance } = usePortfolioBalance();
	const { setPair } = useLimitStore();
	const queryData: { query: QueryTokenParam[] } = {
		query: [
			{ tobiId: getNativeTobiId(ChainId.SOL), chainId: ChainId.SOL },
			{ address: STABLE_COINS[ChainId.SOL]?.USDC, chainId: ChainId.SOL },
		],
	};
	// that is preload data to reduce latency
	const { data: dataLimit } = useSearchListToken(queryData);

	return async ({ replace }: { replace?: boolean }) => {
		let newTokenIn = dataLimit?.[0];
		let newTokenOut = dataLimit?.[1];
		if (!newTokenIn && !newTokenOut) {
			try {
				// preload slow, call api instead
				const data = await BffServiceAPI.searchExactListTokens(queryData);
				[newTokenIn, newTokenOut] = data;
			} catch (error) {}
		}
		populateBalance({ newTokenIn, newTokenOut, tokenBalances: portfolioBalance?.tokenBalances });
		setPair(newTokenIn, newTokenOut);
		MainRouter.navigate(
			toQueryString(NAVIGATE_PATHS.Limit.Main, {
				transition: 0,
			}),
			{ replace },
		);
	};
};

export const useNavigateToUrl = (url: string, parentUrl: string) => {
	const navigate = useNavigate();
	return () => {
		navigate(url.startsWith('/') ? url : `${NAVIGATE_PATHS.Dapp.TobiDapp}/?dappUrl=${url}&parentUrl=${parentUrl}`);
	};
};

export const useNavigateToConfirmSwap = () => {
	const { setAmount, setPair, setSelectedRoute } = useSwapStore(false);
	return (route: SelectedRoute, amountInDecimal: string) => {
		const { tokenIn, tokenOut } = route;
		const { amount, amountUsd } = calcAmount({ amount: amountInDecimal, token: tokenIn as any });
		setAmount(amount, amountUsd);
		setPair(tokenIn as any, tokenOut as any);
		setSelectedRoute(route);
		setTimeout(() => {
			MainRouter.navigate(NAVIGATE_PATHS.Swap.ConfirmTransaction);
		}, 500);
	};
};

export const useFetchRouteAndRedirectToSwapConfirm = () => {
	const { slippage } = useUserSettingsStore();
	const navigateToSwapConfirm = useNavigateToConfirmSwap();
	return async ({ toChain, toTobiId, fromTobiId, fromChain, amount }) => {
		const [tokenOut, tokenIn] = await BffServiceAPI.searchExactListTokens({
			query: [
				{ chainId: toChain, tobiId: toTobiId },
				{ chainId: fromChain, tobiId: fromTobiId },
			].filter((e) => e.tobiId && e.chainId),
		});

		const parsedAmount = parseUnits(amount, getTokenInfo(tokenIn).decimals);

		const isSolToTcat = Sol2TcatSwap.isTon2Tcat({
			tokenIn: getTokenInfo(tokenIn),
			tokenOut: getTokenInfo(tokenOut),
		});
		const formatAmount = isSolToTcat ? Sol2TcatSwap.deductTonGas(parsedAmount) : parsedAmount;
		const route = await SwapService.fetchRouteBySwapType({
			paramsSwap: {
				tokenIn: getTokenInfo(tokenIn),
				tokenOut: getTokenInfo(tokenOut),
				amountIn: formatAmount.toString(),
				slippage,
			},
			skipFee: isSolToTcat,
		});
		navigateToSwapConfirm(route, amount);
	};
};

export const navigateToTokenDetail = ({ tobiId }: { tobiId: string }, options?: RouterNavigateOptions) => {
	MainRouter.navigate(
		toQueryString(NAVIGATE_PATHS.Portfolio.asset, {
			tobiId: tobiId || '',
		}),
		options,
	);
};
