import { Box, List } from '@chakra-ui/react';
import { debounce } from 'lodash';
import { CSSProperties, memo, ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { FixedSizeList } from 'react-window';

type Props<T> = {
	items: T[];
	itemRender?: (data: { data: T[]; index: number; style: CSSProperties }) => ReactNode;
	onFetchMore?: () => Promise<T[]>;
	containerId?: string; // if not provided, will use window
	itemKey?: (item: T) => string;
	itemSize?: number; // height of each item
	chunkSize: number; // like page size
};
const maxItem = 500;

export const InfiniteScroll = memo(
	<T,>({ items, itemRender, onFetchMore, containerId, itemKey, itemSize = 55, chunkSize }: Props<T>) => {
		const [visibleItems, setVisibleItems] = useState(items?.slice(0, chunkSize) || []);
		const totalVisible = useRef(chunkSize);
		totalVisible.current = Math.max(visibleItems.length, chunkSize);

		useEffect(() => {
			setVisibleItems(items?.slice(0, totalVisible.current) || []);
		}, [items]);

		const scrolling = useRef(false);

		const onReset = useMemo(
			() =>
				debounce(() => {
					setTimeout(() => {
						scrolling.current = false;
					}, 500);
				}, 500),
			[],
		);

		useEffect(() => {
			const node = containerId ? document.getElementById(containerId) : window;
			if (!node) return;
			const onscroll = async function () {
				// Check if the user has scrolled to the bottom
				if (
					window.innerHeight + window.scrollY >= document.body.offsetHeight &&
					visibleItems.length <= items.length &&
					visibleItems.length < maxItem &&
					!scrolling.current
				) {
					scrolling.current = true;
					if (onFetchMore) {
						await onFetchMore?.();
					}
					setVisibleItems(items.slice(0, visibleItems.length + chunkSize));
					onReset();
				}
			};
			node.addEventListener('scroll', onscroll);
			return () => {
				node.removeEventListener('scroll', onscroll);
			};
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [items, visibleItems.length, onReset, containerId, chunkSize]);

		return (
			<Box style={{ flex: 1 }}>
				{visibleItems.length > 0 && (
					<List>
						<FixedSizeList
							overscanCount={10}
							height={itemSize * visibleItems.length}
							itemCount={visibleItems.length}
							itemSize={itemSize}
							width={'100%'}
							itemData={visibleItems}
							itemKey={itemKey ? (item, data) => itemKey(data[item]) : undefined}
						>
							{itemRender}
						</FixedSizeList>
					</List>
				)}
			</Box>
		);
	},
);
