import { Box, Text } from '@chakra-ui/react';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';
import { StatusBadge } from '@/app-components/common';
import { NAVIGATE_PATHS } from '@/app-constants/router';

export const RequireBackup = () => {
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const { t } = useTranslation();
	if (pathname === NAVIGATE_PATHS.CreateWallet.StoreBackupKey) return null;
	return (
		<Box cursor="pointer" px={4} my={3} onClick={() => navigate(NAVIGATE_PATHS.CreateWallet.StoreBackupKey)}>
			<StatusBadge type="success">
				<Text fontSize="x-small">
					<Trans
						i18nKey="storeBackup.requireBackup"
						values={{ backupText: 'backup' }}
						components={[<Box as="strong" textDecoration="underline"></Box>]}
					/>
				</Text>
			</StatusBadge>
		</Box>
	);
};
