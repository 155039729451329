import { ButtonPrimary, ButtonSecondary, Toast } from '@/app-components/common';
import Bg from '@/assets/images/svg/token-trading-bg.svg';
import {
	Avatar,
	Box,
	Center,
	Drawer,
	DrawerBody,
	DrawerCloseButton,
	DrawerContent,
	DrawerHeader,
	DrawerOverlay,
	Flex,
	Popover,
	PopoverArrow,
	PopoverBody,
	PopoverContent,
	PopoverHeader,
	PopoverTrigger,
	Text,
	useDisclosure,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { TelegramCore } from '../../../../app-cores/telegram';
import { FiInfoIcon } from '../../../../assets/icons';
import { FiUserIcon } from '../../../../assets/icons/fi-user-icon';
import { GroupIcon } from '../../../../assets/images/svg';
import { MegaphoneIcon } from '../../../../assets/images/svg/megaphone-icon';
import { TokenTradingIcon } from '../../../../assets/images/svg/token-trading-icon';
import { DATADOG_ACTIONS, dataDogAddAction } from '@/app-services/monitor/datadog';

export const addTradingBot = (type: 'group' | 'channel') => {
	const whitelistPlatform = ['tdesktop', 'ios', 'android'];

	if (!whitelistPlatform.includes(TelegramCore.WebApp.platform)) {
		toast(<Toast type="error" message={`This feature is not supported on this device`} />);
		return;
	}

	TelegramCore.WebApp.openTelegramLink(
		`https://t.me/${import.meta.env.VITE_BOT_USERNAME}?start${type}&admin=post_messages`,
	);
};

const AddBotDrawer: React.FC<{ isOpen: boolean; onClose: () => void }> = ({ isOpen, onClose }) => {
	return (
		<Drawer placement="bottom" onClose={onClose} isOpen={isOpen}>
			<DrawerOverlay />
			<DrawerContent>
				<DrawerHeader borderBottomWidth="1px" borderBottomColor="rgba(0, 0, 0, 0.08)">
					Add Tobi to Group or Channel
					<DrawerCloseButton />
				</DrawerHeader>
				<DrawerBody px={4} py={5} display={'flex'} alignItems={'center'} gap={2}>
					<Box
						flex={1}
						py={6}
						display={'flex'}
						gap={2.5}
						borderRadius={'1rem'}
						cursor={'pointer'}
						flexDirection={'column'}
						alignItems={'center'}
						backgroundColor={'gray.100'}
						onClick={() => addTradingBot('group')}
					>
						<Avatar bg="blue.100" icon={<GroupIcon />} />
						<Text fontSize={'xs'} fontWeight={510}>
							Add to group
						</Text>
					</Box>
					<Box
						flex={1}
						py={6}
						display={'flex'}
						gap={2.5}
						borderRadius={'1rem'}
						cursor={'pointer'}
						flexDirection={'column'}
						alignItems={'center'}
						backgroundColor={'gray.100'}
						onClick={() => addTradingBot('channel')}
					>
						<Avatar bg="blue.100" icon={<MegaphoneIcon />} />
						<Text fontSize={'xs'} fontWeight={510}>
							Add to channel
						</Text>
					</Box>
				</DrawerBody>
			</DrawerContent>
		</Drawer>
	);
};

export const TokenTrading = () => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const popoverConfig = useDisclosure();
	const { t } = useTranslation();

	const addBotHandler = () => {
		popoverConfig.onClose();
		console.log(`Current device:`, TelegramCore.WebApp.platform);
		const isMobile = ['ios', 'android'].includes(TelegramCore.WebApp.platform);

		if (isMobile) {
			addTradingBot('group');
			return;
		}

		onOpen();
	};

	return (
		<Box
			mt={8}
			position={'relative'}
			backgroundImage={Bg}
			backgroundRepeat={'no-repeat'}
			backgroundSize={'cover'}
			borderRadius={'12px'}
			p={3}
			pr={6}
			gap={3}
		>
			<Flex>
				<Flex flexDirection={'column'} flex={1} gap={3} p={3}>
					<ButtonPrimary
						width={'100%'}
						onClick={() => {
							addBotHandler();
							dataDogAddAction(DATADOG_ACTIONS.BOT_ADD_TO_CHAT);
						}}
						leftIcon={<FiUserIcon />}
					>
						{t('tokenTrading.btnText')}
					</ButtonPrimary>

					<Popover {...popoverConfig}>
						<PopoverTrigger>
							<Text
								color={'white'}
								display={'inline-flex'}
								alignItems={'center'}
								justifyContent={'center'}
								gap={2}
								onClick={() => {
									dataDogAddAction(DATADOG_ACTIONS.BOT_LEAR_MORE);
								}}
							>
								Learn more <FiInfoIcon fill="white" />{' '}
							</Text>
						</PopoverTrigger>
						<PopoverContent borderWidth={'.5px'} sx={{ ':focus-visible': { boxShadow: 'none' } }}>
							<PopoverArrow />
							<PopoverHeader border={'none'} fontWeight={500}>
								{t('tokenTrading.title')}
							</PopoverHeader>
							<PopoverBody>
								{t('tokenTrading.description')}
								<ButtonSecondary onClick={popoverConfig.onClose} my={2} width={'100%'}>
									{t('tokenTrading.btnText2')}
								</ButtonSecondary>
							</PopoverBody>
						</PopoverContent>
					</Popover>
				</Flex>
				<Box px={4} alignItems={'center'} justifyContent={'center'}>
					<TokenTradingIcon display={'block'} />
				</Box>
			</Flex>
			<AddBotDrawer isOpen={isOpen} onClose={onClose} />
		</Box>
	);
};
