import { NAVIGATE_PATHS } from '@/app-constants/router';
import { TelegramCore } from '@/app-cores/telegram';
import { createGoogleAuthUrl } from '@/app-helpers/backup';
import { parseErrorMessage } from '@/app-helpers/error-handling';
import { useOnEventCallback } from '@/app-hooks/common';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Box, Center, Container, Text } from '@chakra-ui/react';
import { useMemo, useState } from 'react';
import { StoreBackupType } from '../../components';
import { FiBackupIcon, FiEmailIcon, FiGoogleDriverIcon, FiTelegramIcon } from '@/assets/icons';
import PageTransition from '@/app-components/layout/PageTransition/PageTransition';
import PageNavigation from '@/app-components/layout/PageWrapper/PageWrapper';
import { Toast } from '@/app-components/common';
import { SendEmail } from '../SentEmail';
import { CreatePassword } from '../CreatePassword';
import { ImportPreviousBackupFile } from './component/ImportPreBackupFile';
import { DATADOG_ACTIONS, DATADOG_ERROR_TAGS, dataDogAddAction, dataDogAddError } from '@/app-services/monitor/datadog';
import { SendTelegram } from './component/SendTelegram';
import { FeatureConfig, useQueryFeatureConfig } from '@/app-hooks/api/configuration';
import { MpcWallet } from '@/app-cores/mpc-wallet/wallet';
import { STORAGE_KEYS } from '@/app-constants/storage';

export enum BackupType {
	TELEGRAM = 'telegram',
	GOOGLE_DRIVE = 'google-drive',
	EMAIL = 'email',
}

interface StoreBackupProps {}

export const ENCRYPT_KEY = 'encrypt_key';

const StoreBackup: React.FunctionComponent<StoreBackupProps> = () => {
	const [searchParams] = useSearchParams();
	const fromSettings = searchParams.get('fromSettings') === 'true';
	const [showPopup, setShowPopup] = useState<'createPassWord' | 'sentEmail' | 'sendTelegram' | null>(null);
	const [backupMethod, setBackupMethod] = useState<string>('');
	const encryptKey = sessionStorage.getItem(ENCRYPT_KEY) ?? '';
	const [encryptPassword, setEncryptPassword] = useState<string>(encryptKey);
	const navigate = useNavigate();
	const { t } = useTranslation();
	const isShowTelegramBackup = useQueryFeatureConfig(FeatureConfig.TELEGRAM_BACKUP);
	const isShowGGDriveBackup = useQueryFeatureConfig(FeatureConfig.BACKUP_G_DRIVE);

	const BACKUP_TYPES = useMemo(() => {
		const DEFAULT_BACKUP_TYPES = [
			{
				img: <FiEmailIcon />,
				type: BackupType.EMAIL,
				title: 'storeBackup.methods.email.title',
				description: 'storeBackup.methods.email.description',
			},
		];
		if (isShowTelegramBackup) {
			DEFAULT_BACKUP_TYPES.push({
				img: <FiTelegramIcon />,
				type: BackupType.TELEGRAM,
				title: 'storeBackup.methods.telegram.title',
				description: 'storeBackup.methods.telegram.description',
			});
		}
		if (isShowGGDriveBackup) {
			DEFAULT_BACKUP_TYPES.push({
				img: <FiGoogleDriverIcon />,
				type: BackupType.GOOGLE_DRIVE,
				title: 'storeBackup.methods.googleDrive.title',
				description: 'storeBackup.methods.googleDrive.description',
			});
		}
		return DEFAULT_BACKUP_TYPES;
	}, [isShowTelegramBackup, isShowGGDriveBackup]);

	const onBackupSelect = useOnEventCallback(async (type: string) => {
		setBackupMethod(type);
		dataDogAddAction(DATADOG_ACTIONS.BACKUP_METHOD[type]);
		if (encryptPassword) {
			return handleCreatePassword(encryptPassword, type);
		}
		setShowPopup('createPassWord');
	});

	const handleCreatePassword = async (password: string, type?: string) => {
		setEncryptPassword(password);
		sessionStorage.setItem(ENCRYPT_KEY, password);
		const backupType = type || backupMethod;
		try {
			if (backupType === BackupType.GOOGLE_DRIVE) {
				const { url, state } = await createGoogleAuthUrl();

				if (!state) {
					throw new Error('State not found');
				}

				TelegramCore.openExternalURL(url);
				return navigate(`${NAVIGATE_PATHS.CreateWallet.BackupGdriveCallback}?state=${state}`);
			}

			if (backupType === BackupType.EMAIL) {
				return setShowPopup('sentEmail');
			}
			setShowPopup('sendTelegram');
		} catch (error) {
			dataDogAddError(error, {
				tags: {
					name: DATADOG_ERROR_TAGS.CREATE_WALLET,
					function: 'createPassword',
				},
			});
			console.log('ERROR', error);
			toast(<Toast type="error" message={`Failed to process: ${parseErrorMessage(error)}`} />);
		}
	};

	const renderContent = () => {
		const unsecuredKey = localStorage.getItem(STORAGE_KEYS.TOBI_UNSECURED_KEY_STORE);

		if (!unsecuredKey)
			return (
				<Text textAlign="center" color="gray.400">
					{t('storeBackup.noBackupKeyshare')}
				</Text>
			);
		return (
			<>
				<Text textAlign="center" color="gray.400">
					{t('storeBackup.description')}
				</Text>
				<Box mt={6} mb={4}>
					{BACKUP_TYPES.map((bkType, i) => (
						<StoreBackupType key={i} backupType={bkType} onClick={() => onBackupSelect(bkType.type)} />
					))}
				</Box>

				<Box mt={6}>
					<Text fontWeight={500}>{t('storeBackup.backupReason.title')}</Text>
					<Box fontSize="xs" color="gray.400" mt={3}>
						<Text>{t('storeBackup.backupReason.descriptions.item1')}</Text>
						<Box mt={3}>
							<strong>{t('storeBackup.backupReason.descriptions.item2')}</strong>
							<Text as="span"> {t('storeBackup.backupReason.descriptions.item3')}</Text>
						</Box>
					</Box>
				</Box>
				{showPopup === 'sentEmail' && (
					<SendEmail
						isOpen={showPopup === 'sentEmail'}
						onClose={() => setShowPopup(null)}
						encryptedPassword={encryptPassword}
					/>
				)}
				{showPopup === 'createPassWord' && (
					<CreatePassword
						isOpen={showPopup === 'createPassWord'}
						onClose={() => setShowPopup(null)}
						onCreatePassword={handleCreatePassword}
					/>
				)}
				{showPopup === 'sendTelegram' && (
					<SendTelegram
						onClose={() => {
							setShowPopup(null);
						}}
						isOpen={showPopup === 'sendTelegram'}
						encryptedPassword={encryptPassword}
					/>
				)}
				<ImportPreviousBackupFile />
			</>
		);
	};

	return (
		<PageTransition>
			<PageNavigation backable={!!fromSettings}>
				<Container p={6} overflow="auto" height="100vh" position="relative">
					<Center>
						<FiBackupIcon />
					</Center>
					<Text as="h1" textAlign="center" fontSize="28px" fontWeight={500} my={2}>
						{t('storeBackup.title')}
					</Text>
					{renderContent()}
				</Container>
			</PageNavigation>
		</PageTransition>
	);
};
export default StoreBackup;
