import { UseQueryOptions, keepPreviousData, useQuery } from '@tanstack/react-query';
import { BffServiceAPI, ITokenSearch, PffTokenDetailArgs } from '@/app-cores/api/bff';
import { useCallback, useMemo } from 'react';
import { compareAddress, compareChain, compareTobiToken } from '@/app-helpers/address';
import { QUERY_KEYS } from '@/app-constants';
import { getTokenId, getTokenInfo, isTestnetChain } from '@/app-helpers/token';
import { usePortfolioBalanceByCategories } from '@/app-hooks/api/portfolio/usePortfolioBalance';
import { useBalanceStore } from '@/app-store/balance';
import { delay } from '@/app-helpers';

export const useUserTokenSettings = ({ type, enabled }: { type?: 'hidden' | 'visible'; enabled: boolean }) => {
	const { setHideTokens, hideTokens } = useBalanceStore();
	const response = useQuery({
		queryKey: [QUERY_KEYS.GET_USER_SETTING_TOKEN, type ?? null],
		queryFn: async () => {
			return BffServiceAPI.getPortfolioBalance(false, type).then((data) => {
				setHideTokens(data.balances ?? []);
				return data;
			});
		},
		placeholderData: (args) => {
			if (hideTokens?.length) return { balances: hideTokens, notFoundTokens: [] };
			return keepPreviousData(args);
		},
		retry: false,
		enabled,
	});
	return response;
};

export type TokenLabelType = 'spam' | 'hidden' | 'imported' | 'testnet';

export const useGetTokenLabels = () => {
	const { data } = usePortfolioBalanceByCategories();
	return useCallback(
		(token: ITokenSearch): TokenLabelType[] => {
			const { spamTokens = [], importTokens, hiddenTokens } = data || {};

			const findToken = (list: ITokenSearch[] = []) =>
				!!list?.some((e) => {
					return getTokenInfo(token).idTobi === getTokenInfo(e).idTobi;
				});
			const labels = [];
			// if (findToken(spamTokens)) labels.push('spam');
			if (findToken(hiddenTokens)) labels.push('hidden');
			// if (findToken(importTokens)) labels.push('imported');
			// if (isTestnetChain(chainId)) labels.push('testnet');
			return labels;
		},
		[data],
	);
};

export const useTokenLabels = (payload: ITokenSearch) => {
	const getTokenLabels = useGetTokenLabels();
	return useMemo(() => getTokenLabels(payload), [getTokenLabels, payload]);
};
