import { FiFireIcon, FiPlusIcon } from '@/assets/icons';
import { Box, Card, Divider, Flex, Tab, TabList, TabPanel, TabPanels, Tabs, Text, VStack } from '@chakra-ui/react';
import { ListGroup } from './ListGroup';
import { BASE_BORDER_COLOR, colors } from '@/app-theme/theme';
import { useTranslation } from 'react-i18next';
import { ButtonSecondary } from '@/app-components/common';
import { FiGroupIconColor } from '@/assets/icons/fi-group-icon';
import { Link } from 'react-router-dom';
import { NAVIGATE_PATHS } from '@/app-constants/router';
import { addTradingBot } from '@/app-views/wallet/components/Portfolio/TokenTrading';
import {
	useCalculateAffiliateTokenInfo,
	useGetAffiliateGroupInfo,
	useGetAffiliateGroupStat,
} from '@/app-hooks/api/user/affiliate';
import { AffiliateGroup, AffiliateServiceAPI, AffiliateUser } from '@/app-services/fee';
import { formatUsd } from '@/app-helpers/number';
import { useCallback, useEffect, useRef, useState } from 'react';
import { uniqBy } from 'lodash';

const pageSize = 20;

type FetchMoreResult<T> = { outOfData: boolean; items: T[] };

export const useFetchMoreGroup = <
	T extends AffiliateGroup | AffiliateUser,
	R extends FetchMoreResult<T> = FetchMoreResult<T>,
>({
	isFetched,
	firstPageInfo,
	fetchMoreFn,
}: {
	isFetched: boolean;
	firstPageInfo: R;
	fetchMoreFn: (v: { page: number; limit: number }) => Promise<R>;
}) => {
	const [groups, setGroups] = useState<T[]>([]);
	const [page, setPage] = useState(1);
	const outOfData = useRef(false);

	useEffect(() => {
		isFetched && setGroups(firstPageInfo?.items ?? []);
	}, [isFetched, firstPageInfo]);

	const loading = useRef(false);
	const onFetchMore = useCallback(async () => {
		if (!isFetched || loading.current || outOfData.current) return;
		try {
			loading.current = true;
			const data = await fetchMoreFn({ page: page + 1, limit: pageSize });
			setPage(page + 1);
			setGroups((v) => uniqBy(v.concat(data.items), 'id'));
			if (data.outOfData) outOfData.current = true;
		} catch (error) {}
		loading.current = false;
	}, [isFetched, page, fetchMoreFn]);

	console.log(groups?.length);

	return { onFetchMore, groups };
};

export const GroupsContent = ({ compact }: { compact?: boolean }) => {
	const { t } = useTranslation();
	const { data } = useGetAffiliateGroupStat();
	const { data: groupInfo, isFetching, isFetched } = useGetAffiliateGroupInfo({ page: 1, limit: pageSize });
	const { groups, onFetchMore } = useFetchMoreGroup<AffiliateGroup>({
		isFetched,
		firstPageInfo: groupInfo,
		fetchMoreFn: AffiliateServiceAPI.getGroupInfo,
	});

	const { data: tokenInfo } = useCalculateAffiliateTokenInfo(data?.totalTokensEarned ?? []);
	return (
		<VStack w={'100%'} spacing={3}>
			<Flex w={'100%'} direction={'column'} gap={4}>
				<Flex alignItems={'flex-end'} justifyContent={'space-between'} gap={2}>
					<Text lineHeight={'24px'} fontWeight={500}>
						Groups
					</Text>
					<ButtonSecondary size={'sm'} pl={2} onClick={() => addTradingBot('group')}>
						<FiPlusIcon height={16} /> Add Tobi to Group
					</ButtonSecondary>
				</Flex>
				<Box bgColor="white" borderRadius={8} p={3}>
					<Flex width={'100%'} justifyContent={'space-between'}>
						<VStack spacing={1} fontSize={'10px'} flex={1}>
							<Flex gap={2} height={'24px'} alignItems={'center'}>
								<FiGroupIconColor />
								<Text as="span" lineHeight={'24px'} fontWeight={600} fontSize={'16px'}>
									{data?.totalGroups ?? 0}
								</Text>
							</Flex>
							<Text>{t('affiliate.totalGroup')}</Text>
						</VStack>

						<Divider
							orientation="vertical"
							h={'40px'}
							borderStyle={'dotted'}
							borderColor={colors.gray[300]}
						/>

						<VStack spacing={1} fontSize={'10px'} flex={1}>
							<Flex
								alignItems={'center'}
								height={'24px'}
								lineHeight={'16px'}
								fontWeight={600}
								fontSize={'16px'}
							>
								{formatUsd(tokenInfo?.totalUsd ?? 0)}
							</Flex>
							<Text>{t('affiliate.feeEarned')}</Text>
						</VStack>

						<Divider
							orientation="vertical"
							h={'40px'}
							borderStyle={'dotted'}
							borderColor={colors.gray[300]}
						/>

						<VStack spacing={1} fontSize={'10px'} flex={1}>
							<Flex
								alignItems={'center'}
								height={'24px'}
								lineHeight={'16px'}
								fontWeight={600}
								fontSize={'16px'}
							>
								{data?.totalTransactions ?? 0}
							</Flex>
							<Text>Transactions</Text>
						</VStack>
					</Flex>
				</Box>
			</Flex>
			<ListGroup<AffiliateGroup>
				pageSize={pageSize}
				isGroup
				compact={compact}
				data={groups}
				isFetching={isFetching}
				onFetchMore={onFetchMore}
			/>
			{compact && groups.length > 0 && (
				<Link to={NAVIGATE_PATHS.Affiliate.Group}>
					<Text textDecoration={'underline'} fontSize={'12px'} fontWeight={600}>
						All Groups
					</Text>
				</Link>
			)}
		</VStack>
	);
};
