import { ContactAccount } from '@/app-cores/api/contact';
import { formatCurrency, formatNumber, formatUnits, formatUsd } from '../number';
import { IEstimateGasFee, TGasFeeType } from '@/app-types';
import { fromNano } from '@ton/core';
import { AUTO_SLIPPAGE } from '@/app-views/swap/components/SlippageSetting';
import { TokenInfo } from '@/app-cores/api/bff';
import { STORAGE_KEYS } from '@/app-constants/storage';

export const getAccountFullName = (account: ContactAccount) => {
	if (!account) {
		return '';
	}

	return `${account.firstName ?? ''} ${account.lastName ?? ''}`.trim();
};
export const displayUserName = (username: string = '') => {
	if (!username) return '';
	return `@${username}`;
};

export const displayCurrency = (value: string) => {
	const valueArr = value?.split('.');
	return {
		value: valueArr[0],
		digit: valueArr[1] || '00',
	};
};

export function displayFee({
	estimateGasFee,
	gasFeeType,
	feePriceUsd,
}: {
	estimateGasFee: IEstimateGasFee;
	gasFeeType: TGasFeeType;
	digit?: number;
	feePriceUsd: number;
}) {
	const gasFeeInNativeToken = +estimateGasFee?.[gasFeeType]?.gasFeeInNativeToken;
	const feeUsd = gasFeeInNativeToken * feePriceUsd;
	return {
		feeUsd: formatUsd(feeUsd),
		feeToken: formatCurrency(gasFeeInNativeToken),
	};
}

export function displayTonFee({ gasFee, feePriceUsd }: { gasFee: bigint; digit?: number; feePriceUsd: number }) {
	if (!gasFee || !feePriceUsd)
		return {
			feeUsd: '',
			feeToken: '',
		};
	const feeUsd = +fromNano(gasFee) * feePriceUsd;
	return {
		feeUsd: formatUsd(feeUsd),
		feeToken: formatCurrency(fromNano(gasFee)),
	};
}

export function displaySolFee({ gasFee, feePriceUsd }: { gasFee: bigint; feePriceUsd: number }) {
	if (!gasFee || !feePriceUsd)
		return {
			feeUsd: '',
			feeToken: '',
		};
	const feeUsd = +fromNano(gasFee) * feePriceUsd;
	return {
		feeUsd: formatUsd(feeUsd),
		feeToken: formatCurrency(fromNano(gasFee)),
	};
}

export const displaySlippage = (slippage: number) => (+slippage === AUTO_SLIPPAGE ? 'Auto' : `${slippage}%`);

// 2$ (3 SOL)
export const displayAmount = ({ amount, token }: { amount: bigint | string; token: TokenInfo }) => {
	return `${formatUsd(+formatUnits(amount, token?.decimals, { withFormat: false }) * token?.priceUsd)} (${formatUnits(
		amount,
		token?.decimals,
	)} ${token?.symbol ?? ''})`;
};

export const getDisplayedTobiIntro = () => {
	return !!localStorage.getItem(STORAGE_KEYS.TOBI_DISPLAYED_INTRO);
};
