import React from 'react';

interface IProps extends React.SVGAttributes<HTMLOrSVGElement> {}

export const FiQuestionIcon: React.FC<IProps> = ({ width = 20, height = 20, ...rest }) => {
	return (
		<svg viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" width={width} height={height} {...rest}>
			<path
				d="M39.401 24.8401C36.7309 35.5538 25.8772 42.074 15.1601 39.4006C4.44308 36.7272 -2.07382 25.8784 0.59961 15.1631C3.27304 4.44935 14.1218 -2.07422 24.8371 0.599208C35.5509 3.27264 42.0744 14.123 39.401 24.8401Z"
				fill="#0B373D"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M20 11C15.0294 11 11 15.0294 11 20C11 24.9706 15.0294 29 20 29C24.9706 29 29 24.9706 29 20C29 15.0294 24.9706 11 20 11ZM9 20C9 13.9249 13.9249 9 20 9C26.0751 9 31 13.9249 31 20C31 26.0751 26.0751 31 20 31C13.9249 31 9 26.0751 9 20Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M20 18C18.8954 18 18 18.8954 18 20C18 21.1046 18.8954 22 20 22C21.1046 22 22 21.1046 22 20C22 18.8954 21.1046 18 20 18ZM16 20C16 17.7909 17.7909 16 20 16C22.2091 16 24 17.7909 24 20C24 22.2091 22.2091 24 20 24C17.7909 24 16 22.2091 16 20Z"
				fill="white"
			/>
		</svg>
	);
};
