import React from 'react';

interface IProps extends React.SVGAttributes<HTMLOrSVGElement> {}

export const FiCreateTokenIcon: React.FC<IProps> = ({ width = 18, height = 18, ...rest }) => {
	return (
		<svg width={width} height={height} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M8.28217 2.28256C9.26721 1.29753 10.6032 0.744141 11.9963 0.744141C13.3893 0.744141 14.7253 1.29753 15.7103 2.28256C16.6954 3.2676 17.2488 4.60359 17.2488 5.99664C17.2488 7.38942 16.6956 8.72517 15.7109 9.71015C15.7107 9.71034 15.7105 9.71053 15.7103 9.71072L10.6561 14.7799C10.5154 14.9211 10.3243 15.0004 10.125 15.0004H3.75C3.33579 15.0004 3 14.6646 3 14.2504V7.87539C3 7.67648 3.07902 7.48572 3.21967 7.34506L8.28217 2.28256ZM11.9963 2.24414C11.001 2.24414 10.0466 2.63949 9.34283 3.34322L4.5 8.18605V13.5004H9.81369L14.6489 8.65085L14.6497 8.65006C15.3534 7.94633 15.7488 6.99187 15.7488 5.99664C15.7488 5.00142 15.3534 4.04695 14.6497 3.34322C13.9459 2.63949 12.9915 2.24414 11.9963 2.24414Z"
				fill="url(#paint0_linear_14496_14064)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12.5303 5.46967C12.8232 5.76256 12.8232 6.23744 12.5303 6.53033L2.03033 17.0303C1.73744 17.3232 1.26256 17.3232 0.96967 17.0303C0.676777 16.7374 0.676777 16.2626 0.96967 15.9697L11.4697 5.46967C11.7626 5.17678 12.2374 5.17678 12.5303 5.46967Z"
				fill="url(#paint1_linear_14496_14064)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M6 11.25C6 10.8358 6.33579 10.5 6.75 10.5H13.125C13.5392 10.5 13.875 10.8358 13.875 11.25C13.875 11.6642 13.5392 12 13.125 12H6.75C6.33579 12 6 11.6642 6 11.25Z"
				fill="url(#paint2_linear_14496_14064)"
			/>
			<defs>
				<linearGradient
					id="paint0_linear_14496_14064"
					x1="3"
					y1="7.87227"
					x2="17.2488"
					y2="7.87227"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#01EEFF" />
					<stop offset="0.51" stopColor="#0537FF" />
					<stop offset="1" stopColor="#FE01F5" />
				</linearGradient>
				<linearGradient
					id="paint1_linear_14496_14064"
					x1="0.75"
					y1="11.25"
					x2="12.75"
					y2="11.25"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#01EEFF" />
					<stop offset="0.51" stopColor="#0537FF" />
					<stop offset="1" stopColor="#FE01F5" />
				</linearGradient>
				<linearGradient
					id="paint2_linear_14496_14064"
					x1="6"
					y1="11.25"
					x2="13.875"
					y2="11.25"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#01EEFF" />
					<stop offset="0.51" stopColor="#0537FF" />
					<stop offset="1" stopColor="#FE01F5" />
				</linearGradient>
			</defs>
		</svg>
	);
};
