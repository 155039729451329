import { Box, Center, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { StatusBadge } from '@/app-components/common';

export const CreateWalletStatus = () => {
	const { t } = useTranslation();
	return (
		<Box px={4} my={3}>
			<StatusBadge type="processing">
				<Center fontSize="x-small">
					{t('onboarding.createWallet.creating')}
					<Box transform="translateY(2px)" className="dot-loader-spinner" w={3} aspectRatio={6} />
				</Center>
			</StatusBadge>
		</Box>
	);
};
