/********** COMMON ***********/

import { ContactAccount } from '@/app-cores/api/contact';

export interface PaginateDTO<T> {
	items: T[];
	totalItems: number;
	itemCount: number;
	itemsPerPage: number;
	totalPages: number;
	currentPage: number;
	totalCount?: number;
}

/********** SERVICE AUTH ***********/

export interface AuthTokenResponse {
	accessToken: string;
}

/********** SERVICE BOT ***********/

export interface ChainInfo {
	name: string;
	chainId: string;
	nativeCurrency: string;
	payMaster: string;
	explorer: string;
	rpcUrls: string[];
}

export enum WalletType {
	EVM = 'evm',
	TON = 'ton-base',
	SOL = 'solana',
	TRON = 'tron',
	SUI = 'sui',
	ECLIPSE = 'eclipse',
}
export interface KeygenArgs {
	payload: string;
	signature: string;
}
export type SyncUserWalletArgs = Omit<Partial<Record<WalletType, string>>, 'evm'>;

export interface ConfirmTransactionArgs {
	txid: string;
	sessionKey: string;
}

/********** SERVICE USER ***********/

export interface UserWallet {
	_id: string;
	address: string;
	formatedAddress: string;
	isAccountAbstraction: boolean;
	type: WalletType;
	addressLowerCase: string;
}

export interface UserProfile {
	id: string;
	telegramId: number;
	username: string;
	firstName: string;
	lastName: string;
	defaultWallet?: string;
	accessFeature?: string[];
	avatar: string;
	referral: boolean;
	wallets: UserWallet[];
	backupEmail: string;
	isLegacyKeyshare?: boolean;
	backupType?: 'email' | 'drive' | 'telegram';
	tgBackupVer: string;
}

export interface EncryptedData {
	encrypted: string;
}

export interface ChangeDefaultWalletArgs {
	address: string;
}

export type UserInviteCode = {
	creator: string;
	code: string;
	usedCount: number;
	remainingUses: number;
	isActive: boolean;
	_id: string;
	createdAt: string;
	updatedAt: string;
};

export type InviteHistory = {
	id: string;
	inviterTelegramId: number;
	inviteeTelegramId: number;
	invitee: ContactAccount;
	isWalletCreated: boolean;
	code: string;
	createdAt: string; // or Date if you plan to convert the string to a Date object
	updatedAt: string; // or Date if you plan to convert the string to a Date object
};

export type InviteCodeError = {
	errorCode: number;
	message: string;
};

export type ClaimInviteCodeArgs = {
	code: string;
};
export type UpdateReferralCodeArgs = {
	referralCode: string;
};
export type UpdateAffiliateCodeArgs = {
	affiliateCode: string;
};
/********** SERVICE REQUEST PAYMENT ***********/

export enum UserRequestPaymentStatus {
	AWAITING = 'AWAITING',
	PAID = 'PAID',
	CANCELLED = 'CANCELLED',
	DECLINED = 'DECLINED',
	REFUNDED = 'REFUNDED',
}

export enum RequestPaymentStatus {
	AWAITING = 'AWAITING',
	COMPLETED = 'COMPLETED',
}

export type CreateRequestPayload = {
	payers: { amount: number; telegramId: number }[];
	message: string;
};

export type UpdateRequestPayload = { id: string; telegramId: string; isPaid: boolean };

export interface UserRequestPayment {
	amount: string;
	telegramId: string;
	username?: string;
	firstName: string;
	lastName: string;
	message: string;
	isPaid: boolean;
	avatar: string;
	hasWallet: boolean;
}

export interface RequestPayment {
	id: string;
	creatorId: string;
	creator: UserRequestPayment;
	message: string;
	payers: UserRequestPayment[];
	createdAt: string;
}

export interface GetRequestPaymentArgs {
	id?: string;
	creator?: string;
	token?: string;
	status?: UserRequestPaymentStatus;
}

export interface GetRequestPaymentPaginateArgs extends GetRequestPaymentArgs {
	page?: number;
	limit?: number;
}

export interface UserPaymentAmount {
	amount: number;
	telegramId: string | number;
}

export interface CallbackUserPaymentArgs {
	userPaymentId: string;
	txid: string;
}

/********** SERVICE TOKEN ***********/
export interface Token {
	chainId: string;
	contractAddress: string;
	user: string;
}

/********** SERVICE TRANSACTION HISTORY ***********/
export interface UserTransactionHistoryParams {
	page?: number;
	limit?: number;
	excludeTypes?: string;
	includeTypes?: string;
}

export interface UserTransactionHistory {
	_id: string;
	from: string;
	to: string;
	asset: string;
	type: string;
	value: string;
	txHash: string;
	timestamp: number;
	chainId: string;
	tokenType: string;
	memo: string;
	gasPrice: number;
	gasLimit: number;
	createdAt: string;
	updatedAt: string;
	metadata: UserTransactionHistoryMetadata[];
}

export type UserTransactionHistoryMetadata = {
	_id: string;
	type: string;
	metadata: UserTransactionHistoryMetadataType;
};

export type UserTransactionHistoryMetadataType = {
	groupOwner: number;
	groupName: string;
};

export interface AddNewLanguageParams {
	lang: 'en';
	resource: JSON;
}

export type NotificationQueryParams = {
	limit?: number;
	page?: number;
	type?: string;
};
export interface NotificationItem {
	_id: string;
	title: string;
	body: string;
	data: any;
	type: 'SUBSCRIPTION_FEE' | 'TIP' | 'SENT' | 'REQUEST_FUND' | 'TRANSFER' | 'MINT' | 'SWAP';
	isRead: boolean;
	createdAt: string;
	isAll: boolean;
	messageId: number;
	status: boolean;
	addresses: string[];
}

export type RequestNotificationResponse = {
	items: NotificationItem[];
	itemCount: number;
	totalItems: number;
	itemsPerPage: number;
	totalPages: number;
	currentPage: number;
};
export type TransactionMemoPayload = {
	txHash: string;
	memo: string;
};
export type TransactionMemoResponse = {
	status: boolean;
	message: string;
};

export enum NotificationType {
	SUBSCRIPTION_FEE = 'Subscription Fee',
	TIP = 'Tip',
	SENT = 'Sent',
	REQUEST_FUND = 'Payment Request',
	TRANSFER = 'Transfer',
	MINT = 'Mint',
	SWAP = 'Swap',
}

export type UnReadNotificationCount = {
	unRead: number;
};

/********** SERVICE CLOUDKIT ***********/
export type CloudKitCreateRecordArgs = {
	version: number;
	data: string;
};

type FieldValue = {
	value: string | number;
	type: 'STRING' | 'INT64';
};

type TimestampInfo = {
	timestamp: number;
	userRecordName: string;
	deviceID: string;
};

type ZoneID = {
	zoneName: string;
	ownerRecordName: string;
	zoneType: string;
};

export type CloudKitRecord = {
	recordName: string;
	recordType: string;
	fields: {
		data: FieldValue;
		version: FieldValue;
		[key: string]: FieldValue;
	};
	pluginFields: Record<string, unknown>;
	recordChangeTag: string;
	created: TimestampInfo;
	modified: TimestampInfo;
	deleted: boolean;
	zoneID: ZoneID;
};

export type PaymentMethod = {
	id: string;
	icon: string;
	fiatCurrency: string;
	name: string;
	provider: string;
};

export type TransferWithoutWallet = {
	credential: string;
	amount: string;
};

export type OnrampCrypto = {
	symbol: string;
	address: string;
	name: string;
	chainId: string;
};
