import { PublicKey } from '@solana/web3.js';
import { CreateEvent, PumpTrade } from './types';

export function toCreateEvent(event): CreateEvent {
	return {
		name: event.name,
		symbol: event.symbol,
		uri: event.uri,
		mint: event.mint.toBase58(),
		bondingCurve: new PublicKey(event.bondingCurve),
		user: event.user?.toBase58(),
		createdAt: Date.now(),
	};
}

export function toTradeEvent(event, signature: string): PumpTrade {
	return {
		signature,
		mint: event.mint?.toBase58(),
		sol_amount: BigInt(event.solAmount).toString(),
		token_amount: BigInt(event.tokenAmount).toString(),
		is_buy: event.isBuy,
		user: event.user?.toBase58(),
		timestamp: Number(event.timestamp),
	};
}
