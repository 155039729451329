import { useSentTokenStore } from '@/app-store';
import { ConfirmSentErc20Asset } from './ConfirmSentErc20Asset';
import { ConfirmSendTonAsset } from './ConfirmSentTonAsset';
import { isEclipseChain, isSolanaChain, isSuiChain, isTonChain, isTronChain } from '@/app-helpers/token';
import { ConfirmSendSolAsset } from './ConfirmSendSolAsset';
import { ConfirmSentTronAsset } from './ConfirmSendTronAsset';
import { ConfirmSendSuiAsset } from './ConfirmSendSuiAsset';
import { ConfirmSendEclipseAsset } from './ConfirmSendEclipseAsset';

export const ConfirmTransaction = () => {
	const { tokenInfo } = useSentTokenStore();
	if (isTonChain(tokenInfo?.chainId)) return <ConfirmSendTonAsset />;
	if (isSolanaChain(tokenInfo?.chainId)) return <ConfirmSendSolAsset />;
	if (isTronChain(tokenInfo?.chainId)) return <ConfirmSentTronAsset />;
	if (isSuiChain(tokenInfo?.chainId)) return <ConfirmSendSuiAsset />;
	if (isEclipseChain(tokenInfo?.chainId)) return <ConfirmSendEclipseAsset />;
	return <ConfirmSentErc20Asset />;
};
