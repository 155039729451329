export const useScanTransaction = () => {};
import { useQuery } from '@tanstack/react-query';
import { TenderlyService, TTenderlySimulateTransaction } from '../../services/Tenderly';

export const useSimulateTransaction = (requestId: number, payload: TTenderlySimulateTransaction) => {
	const res = useQuery({
		queryKey: ['tenderly-scan-transaction', requestId],
		queryFn: async () => {
			const data = await TenderlyService.simulateTransaction(payload);
			return data;
		},
	});
	return res;
};
