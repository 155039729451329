import { uniqBy } from 'lodash-es';
import { Box, Card, CardBody, Divider, Flex, Text } from '@chakra-ui/react';
import { isEmpty } from 'lodash-es';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ContactAccount } from '@/app-cores/api/contact';
import { useQueryContactByWalletAddress, useQueryContactList } from '@/app-hooks/api/accouts';
import { useDebounce } from '@/app-hooks/common';
import { useExtractAddress } from '@/app-hooks/wallet/useEns';
import { useContactStore } from '@/app-store/contact';
import { ButtonSecondary, TextSmall, UserAddress } from '..';
import { TelegramCore } from '../../../app-cores/telegram';
import { useQueryUserInviteCodes } from '../../../app-hooks/api/user/useQueryUserInviteCodes';
import { ShareIcon } from '../../../assets/images/svg';
import { ContactListItem } from './ContactListItem';
import { EmptyContact } from './EmptyContact';
import { getBlockChainNameByAddress, getBlockChainNameByChainId } from '@/app-helpers/address';
import { useSentTokenStore } from '@/app-store';
import { WalletType } from '@/app-cores/api';

export interface ContactProps {
	onSelect?: (contact?: ContactAccount) => void;
	onLoadData?: (loading: boolean) => void;
}

function getContactDataForRender(
	data: ReturnType<typeof useQueryContactList>['data'],
	contactByAddress: ContactAccount,
) {
	const tobiWallets: ContactAccount[] = []
		.concat(data?.walletUsers || [])
		.concat(contactByAddress)
		.filter((c) => c?.defaultWallet?.address);

	const nonTobiWallets = [].concat(data?.nonWalletUsers || []);

	return {
		tobiWallets: uniqBy(tobiWallets, (ct) => ct?.defaultWallet?.address),
		nonTobiWallets,
	};
}
export const Contact: React.FC<ContactProps> = ({ onSelect, onLoadData }) => {
	const { t } = useTranslation();
	const { searchKey, setContact, setAddress, contact, address } = useContactStore();
	const searchTextDebounce = useDebounce(searchKey);

	const { data: contactList, isFetching: isFetchingContact } = useQueryContactList({
		q: searchTextDebounce,
	});
	const { data } = useQueryUserInviteCodes();
	const code = data?.code;

	const { address: extractAddress, isFetching: isFetchingEns } = useExtractAddress(searchTextDebounce, 0);
	const isValidAddress = !!extractAddress;

	const { data: contactByAddress, isFetching: isFetchingContactByAddress } =
		useQueryContactByWalletAddress(extractAddress);
	const { tobiWallets, nonTobiWallets } = getContactDataForRender(contactList, contactByAddress);
	const { token, tokenInfo } = useSentTokenStore();
	const blockChainName = tokenInfo?.chainId
		? getBlockChainNameByChainId(tokenInfo?.chainId)
		: getBlockChainNameByAddress(extractAddress) || WalletType.EVM;
	const isFetching = isFetchingEns || isFetchingContact || isFetchingContactByAddress;
	const onInvite = () => {
		const link = `https://t.me/${import.meta.env.VITE_BOT_USERNAME}${code?.code ? `?start=${code.code}` : ''}`;
		TelegramCore.WebApp.openTelegramLink(
			`https://t.me/share/url?url=${encodeURIComponent(link)}&text=${encodeURIComponent(
				t('referral.tobiInvite'),
			)}`,
		);
	};

	useEffect(() => {
		onLoadData(isFetching);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isFetching]);

	if (!isFetching && isEmpty(tobiWallets) && isEmpty(nonTobiWallets) && !isValidAddress)
		return <EmptyContact searchKey={searchTextDebounce} />;
	if (!isEmpty(tobiWallets) || !isEmpty(nonTobiWallets)) {
		return (
			<Card backgroundColor={'gray.100'}>
				{!isEmpty(tobiWallets) && (
					<>
						<TextSmall ml={4}>Tobi Wallet Users</TextSmall>
						<Box my={3}>
							{tobiWallets?.map((ct, i) => {
								return (
									<Box
										key={ct.telegramId}
										px={4}
										_hover={{
											backgroundColor: 'gray.100',
										}}
										transition="300ms"
										cursor="pointer"
										borderRadius={8}
									>
										{ct?.wallets?.[blockChainName] && (
											<ContactListItem
												selected={contact?.telegramId === ct.telegramId}
												contact={ct}
												onSelect={(contact) => {
													setContact(contact);
													onSelect?.(contact);
													isValidAddress && setAddress(extractAddress);
												}}
												blockChainName={blockChainName}
											/>
										)}
									</Box>
								);
							})}
						</Box>
						<Divider borderColor={'gray.200'} variant={'dashed'} />
					</>
				)}
				<Flex direction={'column'} p={6} gap={3}>
					<CardBody fontSize={'xs'} p={0}>
						<Text fontWeight={'600'} color={'black'}>
							Can't find your buddy? They might not have a Tobi Wallet yet!
						</Text>
						<Text display={'block'} py="3" as="i" color={'gray.400'}>
							Click the button below to send them an invite to join Tobi.
						</Text>
						<ButtonSecondary
							fontSize={'12px'}
							height={'40px'}
							onClick={onInvite}
							px={6}
							leftIcon={<ShareIcon width={20} height={20} />}
						>
							Invite your friend now
						</ButtonSecondary>
					</CardBody>
				</Flex>
			</Card>
		);
	}
	return (
		<UserAddress
			address={extractAddress}
			label="Address"
			selected={!!address}
			onSelect={(address) => {
				setAddress(address);
				setContact(null);
				onSelect?.(contact);
			}}
		/>
	);
};
