import { Box, Container, Heading, Text } from '@chakra-ui/react';
import PageTransition from '@/app-components/layout/PageTransition/PageTransition';
import PageNavigation from '@/app-components/layout/PageWrapper/PageWrapper';
import { Link } from 'react-router-dom';
import { NAVIGATE_PATHS } from '@/app-constants/router';

const PrivacyPolicy = () => {
	return (
		<PageTransition>
			<PageNavigation>
				<Container height="100vh" overflow="auto" py={4} fontSize="sm">
					<Heading as="h1">Terms of Service</Heading>
					<Text mb={3} mt={4}>
						Last Updated: December 12, 2024
					</Text>
					<Text>
						Before using our Services, please read the Terms of Service (the “Terms”) carefully, along with
						any other policies or notices on the Tobi application and website.
					</Text>
					<Box mt={4}>
						<Box as="section">
							<Heading as="h2" fontSize={24} mt={4} mb={2}>
								Agreement to Terms
							</Heading>
							<Text>
								By accessing or using any or all of the Services, you expressly acknowledge that (i) you
								have read and understood these Terms; (ii) you agree to be bound by these Terms; and
								(iii) you are legally competent to enter into these Terms. If you do not agree to be
								bound by these Terms or any updates or modifications to these Terms, you may not access
								or use our Services. WE DO NOT PROVIDE INVESTMENT OR FINANCIAL ADVICE OR CONSULTING
								SERVICES. WE ARE SOLELY THE PROVIDER OF TOBI AND WE DO NOT ADVISE OR MAKE
								RECOMMENDATIONS ABOUT ENGAGING IN DIGITAL ASSET TRANSACTIONS OR OPERATIONS. DECISIONS TO
								ENGAGE IN TRANSACTIONS OR PERFORM OPERATIONS INVOLVING DIGITAL ASSETS SHOULD BE TAKEN ON
								YOUR OWN ACCORD.
							</Text>
						</Box>
						<Box as="section">
							<Heading as="h2" fontSize={24} mt={4} mb={2}>
								Privacy Policy
							</Heading>
							<Text>
								For an explanation on how we collect, use and disclose information from our users please
								see our{' '}
								<Link
									style={{
										color: 'blue',
										textDecoration: 'underline',
									}}
									to={NAVIGATE_PATHS.AccountSettings.PrivacyPolicy}
								>
									Privacy Policy
								</Link>
								. {''}
								You acknowledge and agree that your use of the Services is subject to, and that we can
								collect, use and/or disclose your information (including any personal data you provide
								to us) in accordance with our Privacy Policy.
							</Text>
						</Box>
						<Box as="section">
							<Heading as="h2" fontSize={24} mt={4} mb={2}>
								Updates to Terms of Service
							</Heading>
							<Text>
								We reserve the right to update or modify these Terms at any time at our sole discretion.
								If we do so, we’ll let you know by either posting the revised Terms on our website or
								through other methods of communication which we deem reasonable. Such revised Terms as
								posted will take effect immediately, unless otherwise indicated. You should regularly
								check our website to inform yourself of any such changes and decide whether or not to
								accept the revised version of these Terms. If you continue to use Tobi following any
								update or modification of the Terms you shall be deemed to have accepted the revised
								Terms. If you do not agree to the Terms or any update or modification to the Terms, you
								must cease to access or use our Services. Our Services are evolving over time, we may
								change or discontinue all or any part of the Services, at any time and without prior
								notice, and at our sole discretion.
							</Text>
						</Box>
						<Box as="section">
							<Heading as="h2" fontSize={24} mt={4} mb={2}>
								Eligibility
							</Heading>
							<Text>
								To be eligible to use Tobi: (i) you must be at least eighteen (18) years old and legally
								competent to enter into these Terms; (ii) you must NOT be a resident of sanctioned
								jurisdictions according to any trade embargoes, UN Security Council Resolutions
								(“UNSCR”) or HM Treasury’s financial sanctions regime; and (iii) you must not be
								currently the subject of or subject to economic sanctions such as the United Nations
								Security Council Sanctions List, the list of specially designated nationals maintained
								by OFAC, the denied persons or entity list of the U.S. Department of Commerce or any
								similar list maintained by any other relevant sanctions authority.
							</Text>
							<Text mt={3}>
								If you are using our Services on behalf of a legal entity, you further represent and
								warrant that: (iv) the legal entity is duly organized and validly existing under the
								applicable laws of the jurisdiction of its organization; and (v) you are duly authorized
								by such legal entity to act on its behalf.
							</Text>
							<Text mt={3}>
								You can only use our Services if permitted under the laws of your jurisdiction. For the
								avoidance of doubt, you may not use our Services if you are located in, or a citizen or
								resident of any state, country, territory or other jurisdiction where your use of our
								Services would be illegal or otherwise violate any applicable laws.
							</Text>
							<Text mt={3}>
								Tobi's Services are NOT offered to persons or entities who reside in, are citizens of,
								are incorporated in, or have a registered office in any state, country or region that is
								subject to sanctions enforced by the United States, the United Kingdom or the European
								Union (any such person or entity shall be a “Restricted Person”). If you are a
								Restricted Person, then do not attempt to access or use Tobi's Services. Use of a
								virtual private network (e.g., a VPN) or other means by Restricted Persons to access or
								use the Website, interface or Services is prohibited.
							</Text>
							<Text mt={3}>
								Please make sure that these Terms are in compliance with all laws, rules, and
								regulations that apply to you. You agree that you are only using our Services with
								legally-obtained funds that rightfully belong to you. By using Tobi, you represent and
								warrant that you meet all eligibility requirements that we outline in these Terms. We
								may still refuse to let certain people access or use Tobi, however, and we reserve the
								right to change our eligibility criteria at any time.
							</Text>
						</Box>
						<Box as="section">
							<Heading as="h2" fontSize={24} mt={4} mb={2}>
								Services
							</Heading>
							<Text>
								Tobi is a web-based software that is accessible on your mobile device or other
								internet-connected device. Tobi is currently mainly available within Telegram as a
								Telegram Bot and Telegram Mini-Application, which is an embedded application within the
								Telegram application. Tobi does not make any representations about Telegram and its
								ecosystem – any risks or additional Terms & Conditions that the user needs to accept
								before use are at the user's own discretion. Moreover, Tobi does not make any guarantees
								to the availability or accessibility of the Tobi application within Telegram in the
								event that such Terms change, including Terms which may affect the normal operation of
								Tobi within Telegram. You expressly acknowledge and agree that as Tobi is non-custodial,
								you are solely responsible for your funds, activity and any risk of loss at all times.
								Tobi allows you to:
								<Box as="ul" listStylePosition="inside" transform="translateX(8px)">
									<Box as="li">
										generate blockchain addresses and associated key shares that you may use to send
										and receive digital assets;
									</Box>
									<Box as="li">
										browse and access third party decentralized application(s) (“DApp(s)”) and third
										party decentralized exchanges (“DEX”)
									</Box>
									<Box as="li">
										swap/trade digital assets through DApp functionality made available by third
										party service provider(s);
									</Box>
									<Box as="li">
										view digital asset price information made available by third party service
										provider(s);
									</Box>
									<Box as="li">view historical transactions made in your account;</Box>
									<Box as="li">
										enables certain features within the context of Telegram, including as a bot
										added to Groups, Supergroups, and Channels (Group Chats);
									</Box>
									<Box as="li">
										enables certain features within the context of Telegram, including as a bot
										added to Groups, Supergroups, and Channels
									</Box>
								</Box>
							</Text>
						</Box>
						<Box as="section">
							<Heading as="h2" fontSize={24} mt={4} mb={2}>
								Multi-Party Computation & Non-Custody
							</Heading>
							<Text>
								Tobi makes use of multi-party computation (MPC) algorithms in order to generate
								signatures without the need for users to manage their private keys. MPC works by
								splitting the traditional private keys into multiple pieces (“key shares”), distributing
								them in multiple places to ensure no one person has full access to the traditional
								private key.
							</Text>
							<Text mt={3}>
								You acknowledge and agree that Tobi is non-custodial; that is we do not receive or store
								your password or passkey, encrypted or unencrypted private keys, any secret phrase, or
								sufficient key shares to take any actions associated with your account. If you have not
								safely stored a backup of any blockchain address and key shares maintained in your
								account (which users can back up to third-party services such as Google Drive), you
								accept and acknowledge that any Digital Assets you have associated with such blockchain
								address may become inaccessible if you lose such information. Accordingly, we shall have
								no responsibility or liability whatsoever in the event you are unable to access your
								account for any reason including without limitation your failure to keep your account
								addresses, key shares, and private key information secure.
							</Text>
						</Box>
						<Box as="section">
							<Heading as="h2" fontSize={24} mt={4} mb={2}>
								Use of DApps and DEX
							</Heading>
							<Text>
								If you access or use DApps or DEX, including, without limitation, DApp functionality
								embedded within the Services such as the Buy/Sell/Trade/Swap Digital Assets function,
								you acknowledge and agree that: (i) Tobi is not responsible for your access or use of
								DApps or DEX and shall have no liability whatsoever in connection with your use of DApps
								or DEX, including, without limitation, any transactions you dispute; (ii) the limits of
								amounts that you may exchange via DEX per day shall be subject to any requirements of
								the third-party developed Smart Contracts; (iii) blockchain operations are irrevocable
								meaning when you conduct any transactions via DApps or DEX, you shall be solely
								responsible for the consequences of any issues associated with such transactions,
								including, without limitation, your transfer to an incorrect address or problems
								associated with the node servers selected by you; (iv) when you use DApps or DEX,
								third-party developed Smart Contracts may charge you handling fees and/or service fees
								and any information displayed on Tobi relating to such fees are for your reference only
								as Tobi cannot and does not guarantee its accuracy, applicability, reliability,
								integrity or appropriateness, nor shall Tobi be liable for any loss or damage that may
								be caused directly or indirectly by your use of these contents; and (v) Tobi does not
								currently charge any service fees or handling fees for the use of DApps or DEX, however,
								Tobi may, in its discretion, charge such fees at any time in the future. Any updated
								fees will apply to any transaction that occurs following the effective date of the
								updated fees.
							</Text>
						</Box>
						<Box as="section">
							<Heading as="h2" fontSize={24} mt={4} mb={2}>
								Buy Digital Assets with Supported Credit or Debit Cards
							</Heading>
							<Text>
								If you use Tobi Wallet to purchase Digital Assets with your credit or debit card, a
								third party will convert your chosen amount into the applicable Digital Asset and credit
								it to your wallet. You acknowledge and agree that: (i) Tobi Wallet is not responsible
								for your use of such third party service and shall have no liability whatsoever in
								connection with your use of such service; (ii) you will be subject to any terms and
								conditions imposed by the relevant third party service provider; (iii) Tobi Wallet has
								no ability to change, withdraw or cancel any conversion requests you place; (iv) Tobi
								Wallet is not responsible for any exchange rates offered by the relevant third party
								service provider(s); and (v) Tobi Wallet is not responsible for any fees charged by the
								relevant third party service provider(s) or your bank or credit card issuer(s).
							</Text>
						</Box>
						<Box as="section">
							<Heading as="h2" fontSize={24} mt={4} mb={2}>
								Digital Asset Transactions
							</Heading>
							<Text>
								In order for all proposed Digital Asset transactions to be completed, they must be
								confirmed and recorded in the Digital Asset’s associated public blockchain. Such
								networks are decentralized, peer-to-peer networks supported by independent third
								parties, which we do not own, control, or operate. We have no control over the
								blockchain networks and, therefore, cannot and do not ensure that any transaction
								details that you submit via our Services will be confirmed and processed. By using Tobi,
								you acknowledge and agree that: (i) we do not have the ability to cancel or otherwise
								modify your transaction; (ii) the transaction details you submit may not be completed,
								or may be substantially delayed, by the applicable blockchain networks; (iii) we do not
								store, send, or receive Digital Assets; and (iv) any transfer that occurs in relation to
								any Digital Asset occurs on the relevant blockchain network and not on a network owned
								by us and therefore we do not guarantee the transfer of title or right in any Digital
								Asset.
							</Text>
						</Box>

						<Box as="section">
							<Heading as="h2" fontSize={24} mt={4} mb={2}>
								Accuracy of Information Provided by User
							</Heading>
							<Text>
								You represent and warrant that any information you provide via the Services is accurate
								and complete. You accept and acknowledge that we are not responsible for any errors or
								omissions that you make in connection with any Digital Asset transaction initiated via
								the Services. We strongly encourage you to review your transaction details carefully
								before attempting to transfer a Digital Asset.
							</Text>
						</Box>
						<Box as="section">
							<Heading as="h2" fontSize={24} mt={4} mb={2}>
								Account Registration and Security
							</Heading>
							<Text>
								You will be required to create or import an MPC account to use the Tobi Services. When
								you create a new MPC account through Tobi, your account details will be stored on the
								device used to create the account. Once you have created an account, you will receive
								instructions to back up your key shares. These key shares will allow you to restore your
								account if you lose your device or use it on another device. You may also import an
								existing Tobi account for use on different devices. Once an account has been
								successfully imported, Tobi will display the data associated with that account to the
								User and allow you to use the Tobi Services. To use Tobi, you only need to have a
								Telegram account linked to a Tobi.
							</Text>
							<Text mt={3}>
								You are responsible for maintaining your key shares and preventing others from obtaining
								them. If the users lose all their key shares, Tobi cannot restore access to their
								account, and any assets affiliated with that account may be permanently lost. If anyone
								obtains access to their device and/or key shares and transfers any assets out of their
								account, Tobi will not be able to reverse the transfer, and the assets may be
								permanently lost. Suggested measures include, but are not limited to, the following: (a)
								do not store the key shares in plain text in an unsecured physical or digital location;
								(b) limiting access to your devices and your account; (c) taking all necessary
								precautions against malware on your devices and networks; and (d) promptly notifying us
								if you discover or otherwise suspect any security breaches related to your account.
								Notwithstanding anything to the contrary herein, we shall have no liability whatsoever
								in connection with activities that occur on your account with or without your
								authorization.
							</Text>
						</Box>
						<Box as="section">
							<Heading as="h2" fontSize={24} mt={4} mb={2}>
								Fees
							</Heading>
							<Text>
								Tobi may charge users transaction fees for any number of operations or transactions
								within its ecosystem. Such fees will be clearly stated within the context of any user
								operation that would incur such fees so that the user may make their own determination
								about whether or not to accept them.
							</Text>
							<Text mt={3}>
								Tobi will determine these fees at its sole discretion, and they will be payable in
								either the source digital asset or the destination digital asset at the discretion of
								Tobi team. Such fees are subject to change anytime and for any reason or for no reason
								at our sole discretion.
							</Text>
							<Text mt={3}>
								There may be transaction fees (e.g. mining fees) associated with your virtual currency
								transactions that are required by the virtual currency system or blockchain network that
								you engage with. You must ensure that you have an adequate balance in your account
								and/or “gas” to complete transactions before initiating a transaction. You acknowledge
								and agree that we will not be liable for any failed transactions or losses you incur due
								to incorrectly set transaction fees (i.e. too low or too high) or due to insufficient
								funds or gas associated with your account address. You further acknowledge and agree
								that we do not have access to your or anyone else’s transactions.
							</Text>
						</Box>
						<Box as="section">
							<Heading as="h2" fontSize={24} mt={4} mb={2}>
								Taxes
							</Heading>
							<Text>
								It is your responsibility to determine what, if any, taxes apply to the transactions
								that you have submitted transaction details for via the Services, and it is your
								responsibility to report and remit the correct tax to the appropriate tax authority. You
								agree that we are not responsible for determining whether taxes apply to your
								transactions or for collecting, reporting, withholding, or remitting any taxes arising
								from any Digital Asset-related transactions.
							</Text>
						</Box>
						<Box as="section">
							<Heading as="h2" fontSize={24} mt={4} mb={2}>
								Third Party Services and Content
							</Heading>
							<Text>
								In no event shall a description or reference to a third party’s product or service
								(including, but not limited to, providing a description or reference via hyperlink) be
								construed as an endorsement or promotion of such third party products or services by us.
								We retain the exclusive right to add to, modify, or cancel the availability of any Third
								Party Service. You may agree to receive push notifications from Third Party Content
								providers. In order to receive push notifications, you must opt in to the service. Push
								notifications will not be automatically enabled on your device for Third Party Content.
								We do not control, endorse, or adopt any Third Party Content shared through push
								notifications, and will have no responsibility for Third Party Content including, but
								not limited to, token availability and/or sales. If, to the extent permitted by Tobi,
								you grant express permission to a third party to access or connect to your Tobi account,
								either through the third party’s product or service or through Tobi, you acknowledge
								that granting permission to a third party to take specific actions on your behalf does
								not relieve you of any of your responsibilities under these Terms. You are fully
								responsible for all acts or omissions of any third party with access to your account.
							</Text>
						</Box>
						<Box as="section">
							<Heading as="h1" fontSize={28} mt={4} mb={2}>
								Intellectual Property
							</Heading>
							<Heading as="h2" fontSize={24} mt={4} mb={2}>
								License
							</Heading>
							<Text>
								As long as you agree to and comply with the present Terms, we grant you a non-exclusive,
								non-sublicensable and non-transferable license to use Tobi for your personal use or
								internal business use only. Except as otherwise expressly permitted in these Terms, you
								will not: (a) reproduce, modify, adapt or create derivative works of any part of Tobi;
								(b) rent, lease, distribute, sell, sublicense, transfer, or provide access to Tobi; (c)
								use Tobi for the benefit of any third party; (d) incorporate Tobi into a product or
								service you provide to a third party without our prior written consent; (d) circumvent
								mechanisms in Tobi intended to limit your use; (f) reverse engineer, disassemble,
								decompile, translate, or otherwise seek to obtain or derive the source code, including
								images and texts, underlying ideas, algorithms, file formats or non-public APIs to Tobi,
								except to the extent expressly permitted by applicable law (and then only upon advance
								notice to us); (g) remove or obscure any proprietary or other notices contained in Tobi
								(h) use Tobi for competitive analysis, as part of any other software or project of any
								kind or to build competitive products.
							</Text>
						</Box>
						<Box as="section">
							<Heading as="h2" fontSize={24} mt={4} mb={2}>
								Feedback
							</Heading>
							<Text>
								From time to time, you may choose to submit feedback to us. We may, in connection with
								Tobi, freely use, copy, disclose, license, distribute and exploit any feedback in any
								manner without any obligation, royalty or restriction based on intellectual property
								rights or otherwise. No feedback will be considered your Confidential Information, and
								nothing in this Agreement limits our right to independently use, develop, evaluate, or
								market products or services, whether incorporating feedback or otherwise.
							</Text>
						</Box>
						<Box as="section">
							<Heading as="h2" fontSize={24} mt={4} mb={2}>
								Content
							</Heading>
							<Text>
								Tobi may contain copyrighted material and trademarks including, but not limited to, text
								and graphics (the “Content”), which is protected by copyright law, registered and
								unregistered trademarks, and other intellectual property rights. Unless otherwise
								provided, we exclusively own the Content. Your use of Tobi does not grant you any right,
								title, or interest in the Content. You agree that you will not copy, reproduce, modify,
								republish, upload, post, transmit, distribute, collect, sell, license, create derivative
								works from, or, in any other way, whether manual or automated, exploit any of the
								Content, in whole or in part.
							</Text>
						</Box>
						<Box as="section">
							<Heading as="h2" fontSize={24} mt={4} mb={2}>
								Your Use of Tobi
							</Heading>
							<Text>
								You agree that you will not violate any laws when using our Services. This includes any
								local, provincial, state, federal, national, or international laws that may apply to
								you. You agree that you will not use our Services to pay for, support, or otherwise
								engage in any illegal activities including, but not limited to, illegal or otherwise
								prohibited trade, illegal gambling, fraud, money laundering, or terrorist activities.
								For the avoidance of doubt, if we discover that you have violated these Terms and/or any
								applicable laws or regulatory requirements, including, but not limited to, the Bank
								Secrecy Act, we reserve all of our rights and remedies under these Terms and at law and
								will take all necessary actions against you. You further agree that: (i) you will not
								encourage or induce any third party to engage in any of the activities prohibited under
								this Section; (ii) you will not impersonate someone or use or attempt to use another
								user’s account without authorization or use our Services in any manner that could
								interfere, disrupt, negatively affect, or inhibit other users from fully enjoying it;
								(iii) you will not distribute any virus or other harmful computer code through Tobi;
								(iv) you will not take any action that may impose an unreasonable or disproportionately
								large load on our or any of our third party providers’ infrastructure; (v) you will not
								reverse engineer or bypass, circumvent, or attempt to bypass or circumvent any measures
								that we may use to prevent or restrict access to the Services including, without
								limitation, other accounts, computer systems, or networks connected to the Services; and
								(vi) you will not violate, misappropriate or infringe the rights of Tobi, our users, or
								others, including privacy, publicity, intellectual property, or other proprietary
								rights. Although we have no obligation to monitor any user content, we have absolute
								discretion to take any necessary actions any time and for any reason without notice in
								the event you breach these Terms. Any use of Tobi other than as specifically authorized
								in these Terms, without our prior written permission, is strictly prohibited and we
								shall have the right to terminate your license to use Tobi immediately without notice.
								You acknowledge and accept that by using the Services, you may be exposed to content
								that is offensive, indecent, or objectionable. We take no responsibility and assume no
								liability for any user content, including any loss or damage to any of your user
								content.
							</Text>
						</Box>
						<Box as="section">
							<Heading as="h2" fontSize={24} mt={4} mb={2}>
								Limitation of Liability & Disclaimer of Warranties
							</Heading>
							<Text>
								You acknowledge and agree that we have no control over, and no duty to take any action
								regarding: (i) failures, disruptions, errors, or delays in the processing of Digital
								Assets that you may experience while using the Services; (ii) the risk of failure of
								hardware, software, and Internet connections; (iii) the risk of malicious software being
								introduced or found in the software underlying Tobi; (iv) the risk that third parties
								may obtain unauthorized access to information stored within your account, including, but
								not limited to your blockchain addresses, key shares, and private key information; and
								(v) the risk of unknown vulnerabilities in or unanticipated changes to the the
								applicable blockchain networks. You release us from all liability related to any losses,
								damages, or claims arising from: (a) user error such as forgotten passwords, incorrectly
								constructed transactions, or mistyped Digital Asset addresses; (b) server failure or
								data loss; (c) unauthorized access to the Tobi application; (d) bugs or other errors in
								the Tobi software; and (e) any unauthorized third party activities, including, but not
								limited to, the use of viruses, phishing, brute forcing, or other means of attack
								against Tobi. We make no representations concerning any Third Party Content contained in
								or accessed through our Services. Any other terms, conditions, warranties, or
								representations associated with such content, are solely between you and such
								organizations and/or individuals.
							</Text>
						</Box>
						<Box as="section">
							<Heading as="h2" fontSize={24} mt={4} mb={2}>
								Limitation of Liability
							</Heading>
							<Text>
								TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL WE, OR OUR
								AFFILIATES, OR ANY OF OUR RESPECTIVE SHAREHOLDERS, MEMBERS, DIRECTORS, OFFICERS,
								EMPLOYEES, ATTORNEYS, AGENTS, REPRESENTATIVES, SUPPLIERS OR CONTRACTORS BE LIABLE FOR
								ANY DIRECT DAMAGES OR INCIDENTAL, INDIRECT, SPECIAL, PUNITIVE, CONSEQUENTIAL OR SIMILAR
								DAMAGES OR LIABILITIES WHATSOEVER (INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF
								DATA, INFORMATION, REVENUE, PROFITS OR OTHER BUSINESSES OR FINANCIAL BENEFITS) WHETHER
								UNDER CONTRACT, TORT, NEGLIGENCE, STATUTE, STRICT LIABILITY OR OTHER THEORY EVEN IF WE
								HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, ARISING OUT OF YOUR ACCESS OR USE
								OF THE SERVICES, INCLUDING, BUT NOT LIMITED TO: ANY UNAUTHORIZED USE OF YOUR BLOCKCHAIN
								ADDRESSES, KEY SHARES, AND/OR PRIVATE KEYS DUE TO YOUR FAILURE TO MAINTAIN THE
								CONFIDENTIALITY OF YOUR ACCOUNT, ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR
								FROM THE SERVICES OR ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE THAT ARE FOUND IN THE
								TOBI SOFTWARE OR THAT MAY BE TRANSMITTED TO OR THROUGH OUR SERVICES BY ANY THIRD PARTY
								(REGARDLESS OF THE SOURCE OF ORIGINATION); ANY ACTIONS WE TAKE OR FAIL TO TAKE AS A
								RESULT OF COMMUNICATIONS YOU SEND TO US; HUMAN ERRORS; TECHNICAL MALFUNCTIONS; FAILURES,
								INCLUDING PUBLIC UTILITY OR TELEPHONE OUTAGES; OMISSIONS, INTERRUPTIONS, LATENCY,
								DELETIONS OR DEFECTS OF ANY DEVICE OR NETWORK, PROVIDERS, OR SOFTWARE (INCLUDING, BUT
								NOT LIMITED TO, THOSE THAT DO NOT PERMIT PARTICIPATION IN OUR SERVICES); ANY INJURY OR
								DAMAGE TO COMPUTER EQUIPMENT; INABILITY TO FULLY ACCESS OUR WEBSITE, TOBI OR THE
								SERVICES OR ANY OTHER WEBSITE; THEFT, TAMPERING, DESTRUCTION, OR UNAUTHORIZED ACCESS TO,
								IMAGES OR OTHER CONTENT OF ANY KIND; DATA THAT IS PROCESSED LATE OR INCORRECTLY OR IS
								INCOMPLETE OR LOST; TYPOGRAPHICAL, PRINTING OR OTHER ERRORS, OR ANY COMBINATION THEREOF;
								OR ANY OTHER MATTER RELATING TO THE WEBSITE, TOBI APPLICATION OR ANY OTHER ASPECT OF THE
								SERVICES. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR
								CONSEQUENTIAL OR INCIDENTAL DAMAGES, SO THE ABOVE LIMITATION MAY NOT APPLY TO YOU. IN NO
								EVENT SHALL OUR LIABILITY TO YOU FOR ALL DAMAGES (OTHER THAN MAY BE REQUIRED BY
								APPLICABLE LAW IN CASES INVOLVING PERSONAL INJURY) EXCEED THE AMOUNT OF ONE HUNDRED U.S.
								DOLLARS ($USD100.00) OR ITS EQUIVALENT IN THE LOCAL CURRENCY OF THE APPLICABLE
								JURISDICTION.
							</Text>
						</Box>
						<Box as="section">
							<Heading as="h2" fontSize={24} mt={4} mb={2}>
								Warranty Disclaimer
							</Heading>
							<Text>
								TOBI IS PROVIDED "AS IS" AND “AS AVAILABLE”, AND WITHOUT WARRANTY OF ANY KIND. TO THE
								MAXIMUM EXTENT PERMITTED BY LAW, WE DISCLAIM ALL REPRESENTATIONS AND WARRANTIES, EXPRESS
								OR IMPLIED, RELATING TO THE SERVICES AND UNDERLYING SOFTWARE OR ANY ASPECT OF THE
								INFORMATION, CONTENT, OR THE SERVICES, WHETHER PROVIDED OR OWNED BY US, OR BY ANY THIRD
								PARTY OR CONTAINED IN ANY THIRD PARTY MATERIALS OR ON ANY THIRD PARTY WEBSITES
								ACCESSIBLE OR LINKED TO OUR WEBSITE OR THE SERVICES, INCLUDING WITHOUT LIMITATION,
								WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE,
								NON-INFRINGEMENT, FREEDOM FROM COMPUTER VIRUS, AND ANY IMPLIED WARRANTIES ARISING FROM
								COURSE OF DEALING, COURSE OF PERFORMANCE, OR USAGE IN TRADE, ALL OF WHICH ARE EXPRESSLY
								DISCLAIMED. IN ADDITION, WE DO NOT REPRESENT OR WARRANT THAT THE CONTENT ACCESSIBLE VIA
								THE SERVICES IS ACCURATE, COMPLETE, AVAILABLE, CURRENT, FREE OF VIRUSES OR OTHER HARMFUL
								COMPONENTS, OR THAT THE RESULTS OF USING THE SERVICES WILL MEET YOUR REQUIREMENTS. WE DO
								NOT MAKE ANY REPRESENTATIONS OR GIVE WARRANTIES THAT THE ACCESS TO TOBI OR USE OF THE
								SERVICES AND THE FUNCTIONALITY THEREOF WILL BE CONTINUOUS, UNINTERRUPTED, TIMELY OR
								ERROR-FREE. SOME STATES DO NOT ALLOW THE DISCLAIMER OF IMPLIED WARRANTIES, SO THE
								FOREGOING DISCLAIMERS MAY NOT APPLY TO YOU.
							</Text>
						</Box>
						<Box as="section">
							<Heading as="h2" fontSize={24} mt={4} mb={2}>
								Indemnity
							</Heading>
							<Text>
								To the extent permitted by applicable law, you agree to defend, indemnify, and hold
								harmless us, our affiliates, and our respective shareholders, members, directors,
								officers, employees, attorneys, agents, representatives, suppliers and contractors, from
								and against any and all claims, damages, obligations, losses, liabilities, tort, costs
								or debt, and expenses (including, but not limited to, attorney’s fees) arising from: (a)
								your use of and access to the Services; (b) any feedback or submissions you provide to
								us concerning Tobi; (c) violation of any Third Party Content (d) your violation of the
								Terms; or (e) your violation of any law, rule, or regulation, or the rights of any third
								party.
							</Text>
						</Box>
						<Box as="section">
							<Heading as="h2" fontSize={24} mt={4} mb={2}>
								The Limitation on Claims
							</Heading>
							<Text>
								You agree that any claim you may have arising out of or related to your relationship
								with us must be filed within one year after such claim arises, otherwise, your claim is
								permanently barred.
							</Text>
						</Box>
						<Box as="section">
							<Heading as="h2" fontSize={24} mt={4} mb={2}>
								Governing Law
							</Heading>
							<Text>
								No matter where you’re located, the laws of Panama will govern these Terms and the
								parties’ relationship as if you signed these Terms in Panama, without regard to Panama's
								conflicts of laws rules. If any provisions of these Terms are inconsistent with any
								applicable law, those provisions will be superseded or modified only to the extent such
								provisions are inconsistent. The parties agree to submit to the federal or state courts
								in Panama for exclusive jurisdiction of any dispute arising out of or related to your
								use of the Services or your breach of these Terms. You waive any objection based on lack
								of personal jurisdiction, place of residence, improper venue, or forum non conveniens in
								any such action.
							</Text>
						</Box>
						<Box as="section">
							<Heading as="h2" fontSize={24} mt={4} mb={2}>
								Note to International Users
							</Heading>
							<Text>
								No matter where you’re located, the laws of the State of California will govern these
								Terms and the parties’ relationship as if you signed these Terms in California, without
								regard to California’s conflicts of laws rules. If any provisions of these Terms are
								inconsistent with any applicable law, those provisions will be superseded or modified
								only to the extent such provisions are inconsistent. The parties agree to submit to the
								federal or state courts in California for exclusive jurisdiction of any dispute arising
								out of or related to your use of the Services or your breach of these Terms. You waive
								any objection based on lack of personal jurisdiction, place of residence, improper
								venue, or forum non conveniens in any such action.
							</Text>
						</Box>
						<Box as="section">
							<Heading as="h2" fontSize={24} mt={4} mb={2}>
								Termination
							</Heading>
							<Text>
								In the event of termination concerning your license to use Tobi, your obligations under
								this Agreement will still continue. Your access to the funds in your account after
								termination will depend on your access to your backup of your blockchain address and
								private key.
							</Text>
						</Box>
						<Box as="section">
							<Heading as="h2" fontSize={24} mt={4} mb={2}>
								Discount of Services
							</Heading>
							<Text>
								We may, in our sole discretion and without cost to you, with or without prior notice,
								and at any time, modify or discontinue, temporarily or permanently, any portion of our
								Services. You are solely responsible for storing outside of the Services a backup of any
								blockchain address and private key pair that you maintain in your account. Maintaining
								an external backup of any blockchain address and key shares associated with your account
								will allow you to access the blockchain network upon which your account is secured. Such
								a backup will allow you to fully restore your account at any time without cost or loss
								of your Digital Assets. If you do not maintain a backup of your account data outside of
								the Services, you will not be able to access the Digital Assets associated with your
								account. Tobi shall not be held responsible or liable for any loss of Digital Assets in
								the event that we discontinue all or any part of the Services.
							</Text>
						</Box>
						<Box as="section">
							<Heading as="h2" fontSize={24} mt={4} mb={2}>
								No Waiver
							</Heading>
							<Text>
								Our failure to exercise or delay in exercising any right, power, or privilege under this
								Agreement shall not operate as a waiver; nor shall any single or partial exercise of any
								right, power, or privilege preclude any other or further exercise thereof. The waiver of
								any such right or provision will be effective only if in writing and signed by a duly
								authorized representative of us. Except as expressly set forth in this Agreement, the
								exercise by either party of any of its remedies under this Agreement will be without
								prejudice to its other remedies under this Agreement or otherwise.
							</Text>
						</Box>
						<Box as="section">
							<Heading as="h2" fontSize={24} mt={4} mb={2}>
								Severability
							</Heading>
							<Text>
								If it turns out that any part of this Agreement is invalid, void, or for any reason
								unenforceable, that term will be deemed severable and limited or eliminated to the
								minimum extent necessary. The limitation or elimination of the term shall not affect or
								impair the validity or enforceability of any remaining part of that term, clause or
								provision or any other terms, clauses or provisions of these Terms.
							</Text>
						</Box>
						<Box as="section">
							<Heading as="h2" fontSize={24} mt={4} mb={2}>
								Arbitration & Waiver of Class Action
							</Heading>
							<Text>
								The parties agree to arbitrate any dispute arising from this Agreement or your use of
								the Services on an individual basis. ARBITRATION PREVENTS YOU FROM SUING IN COURT OR
								FROM HAVING A JURY TRIAL. THE PARTIES HEREBY EXPRESSLY WAIVE TRIAL BY JURY. The parties
								agree that: (a) any arbitration will occur in Panama; and (b) the arbitration will be
								conducted confidentially by a single arbitrator in accordance with the rules of American
								Arbitration Association for arbitration of consumer-related disputes, in the English
								language, and with limited discovery. At your request, hearings may be conducted in
								person or by telephone and the arbitrator may provide for submitting and determining
								motions on briefs, without oral hearings. Other than class procedures and remedies
								discussed below, the arbitrator has the authority to grant any remedy that would
								otherwise be available to a court or other tribunal. THE PREVAILING PARTY IN ANY ACTION
								OR PROCEEDING TO ENFORCE THESE TERMS SHALL BE ENTITLED TO COSTS AND ATTORNEYS’ FEES. THE
								ARBITRAL DECISION MAY BE ENFORCED IN ANY COURT. WHETHER THE DISPUTE IS HEARD IN
								ARBITRATION OR IN COURT, YOU AND TOBI WILL NOT COMMENCE AGAINST THE OTHER A CLASS
								ACTION, CLASS ARBITRATION, OR REPRESENTATIVE ACTION OR PROCEEDING.
							</Text>
						</Box>
						<Box as="section">
							<Heading as="h2" fontSize={24} mt={4} mb={2}>
								Force Majeure
							</Heading>
							<Text>
								We shall not be held liable for any delays, failure in performance, or interruptions of
								service which result directly or indirectly from any cause or condition beyond our
								reasonable control, including but not limited to: any delay or failure due to any acts
								of God, acts of civil or military authorities, acts of terrorism, civil or industrial
								disturbances, blockages, embargoes, war, strikes or other labor disputes, fire,
								earthquakes, storms or other nature-related events, interruption in electrical
								telecommunications or Internet services or network provider services, failure of
								hardware equipment and/or software or other utility failures, smart contract bugs or
								weaknesses, technological changes, changes in interest rates or other monetary
								conditions, and, for the avoidance of doubt, changes to any blockchain-related protocol,
								other catastrophe, or any other occurrences which are beyond our reasonable control, and
								shall not affect the validity and enforceability of any remaining provisions. If we are
								unable to perform our Services outlined in the Terms due to factors beyond our control,
								including, but not limited to, the aforementioned force majeure events or changes in
								applicable laws and/or sanctions policies, we shall not be liable for the Services
								provided under these Terms during the time period coincident with the event in question.
							</Text>
						</Box>
						<Box as="section">
							<Heading as="h2" fontSize={24} mt={4} mb={2}>
								Assignment
							</Heading>
							<Text>
								You agree that we may assign any of our rights and/or transfer, sub-contract, or
								delegate any of our obligations under these Terms without any notice or consent from
								you. These Terms will bind and inure to the benefit of the parties, their successors and
								permitted assigns. Your agreement to these Terms is personal to you and you may not
								transfer or assign it to any third party.
							</Text>
						</Box>
						<Box as="section">
							<Heading as="h2" fontSize={24} mt={4} mb={2}>
								No Third Party Beneficiaries
							</Heading>
							<Text>
								You agree that, except as otherwise expressly provided in these Terms, there shall be no
								third party beneficiaries to the Terms.
							</Text>
						</Box>
						<Box as="section">
							<Heading as="h2" fontSize={24} mt={4} mb={2}>
								Entire Agreement
							</Heading>
							<Text>
								This Agreement sets forth the entire understanding and agreement as to the subject
								matter hereof and supersedes any and all prior discussions, agreements, and
								understandings of any kind (including, without limitation, any prior versions of the
								Terms) and every nature between us. Except as provided for above, any modification to
								these Terms must be in writing and must be signed by both parties.
							</Text>
						</Box>
						<Box as="section">
							<Heading as="h2" fontSize={24} mt={4} mb={2}>
								Notices
							</Heading>
							<Text>
								Any notices or other communications provided by us under these Terms, including those
								regarding modifications to it will be given by posting to the Services and/or through
								other electronic communication. You agree and consent to receive electronically all
								communications, agreements, documents, notices and disclosures that we provide in
								connection with your account and your use of the Services.
							</Text>
						</Box>
						<Box as="section">
							<Heading as="h2" fontSize={24} mt={4} mb={2}>
								Questions or Comments
							</Heading>
							<Text>
								If you have any questions relating to these Terms, your rights and obligations arising
								from these Terms and/or your use of Tobi and our Services or any other matter please
								send us a message on our contact page at support@tobilabs.io.
							</Text>
						</Box>
					</Box>
				</Container>
			</PageNavigation>
		</PageTransition>
	);
};
export default PrivacyPolicy;
