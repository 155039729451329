import { NAVIGATE_PATHS } from '@/app-constants/router';
import Swap from '@/app-views/swap/pages';
import LimitOrder from '@/app-views/swap/pages/LimitOrder';
import { RouteObject } from 'react-router';
import { ConfirmTransaction } from '@/app-views/swap/pages/ConfirmTransaction';
import ListOrders from '@/app-views/swap/pages/ListOrders';
import { ConfirmTransactionLimit } from '@/app-views/swap/pages/ConfirmTransactionLimit';
import TobiFun from '@/app-views/tobi-fun';
import TobiFunMemeDetail from '@/app-views/tobi-fun/MemeDetail';
import TobiFunCreaeMeme from '@/app-views/tobi-fun/CreateMeme';
import TobiFunProfile from '@/app-views/tobi-fun/Profile';

export const TradingRouter: RouteObject[] = [
	{
		path: NAVIGATE_PATHS.Swap.Main,
		children: [
			{
				index: true,
				element: <Swap />,
			},
			{
				path: NAVIGATE_PATHS.Swap.ConfirmTransaction,
				element: <ConfirmTransaction />,
			},
		],
	},
	{
		path: NAVIGATE_PATHS.Limit.Main,
		children: [
			{ index: true, element: <LimitOrder /> },
			{
				path: NAVIGATE_PATHS.Limit.LimitHistory,
				element: <ListOrders />,
			},
			{
				path: NAVIGATE_PATHS.Limit.ConfirmTransaction,
				element: <ConfirmTransactionLimit />,
			},
		],
	},
	{
		path: NAVIGATE_PATHS.TobiFun.Main,
		children: [
			{ index: true, element: <TobiFun /> },
			{
				path: NAVIGATE_PATHS.TobiFun.Detail,
				element: <TobiFunMemeDetail />,
			},
			{
				path: NAVIGATE_PATHS.TobiFun.Create,
				element: <TobiFunCreaeMeme />,
			},
			{
				path: NAVIGATE_PATHS.TobiFun.Profile,
				element: <TobiFunProfile />,
			},
		],
	},
];
