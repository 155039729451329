import { TelegramCore } from '@/app-cores/telegram';
import {
	ChangeDefaultWalletArgs,
	ClaimInviteCodeArgs,
	EncryptedData,
	InviteCodeError,
	InviteHistory,
	NotificationQueryParams,
	RequestNotificationResponse,
	TransactionMemoPayload,
	TransactionMemoResponse,
	UpdateAffiliateCodeArgs,
	UpdateReferralCodeArgs,
	UserInviteCode,
	UserProfile,
} from './types';
import { isEmpty, isNil } from 'lodash-es';
import { axiosBotAPI } from '@/app-cores/api/axios';
import keyShareManager from '@/app-cores/mpc-wallet/wallet/keyshare-manager';

export const ServiceUser = {
	getUserProfile: async (): Promise<UserProfile> => {
		const response = await axiosBotAPI.get<UserProfile>('/user/profile');
		return response.data;
	},

	changeDefaultWallet: async ({ address }: ChangeDefaultWalletArgs): Promise<void> => {
		await axiosBotAPI.post(`/user/wallet/default/${address}`, TelegramCore.getInitDataObject());
	},

	resetUserData: async (): Promise<void> => {
		await axiosBotAPI.post(`/user/clear`, TelegramCore.getInitDataObject());
	},

	getUserProfileById: async (userId: number): Promise<UserProfile> => {
		const response = await axiosBotAPI.get<UserProfile>(`/user/wallet/${userId}`);
		return response.data;
	},

	getEncryptedKey: async (): Promise<string> => {
		const response = await axiosBotAPI.get<EncryptedData>('/user/encryptShare');
		return response.data.encrypted;
	},

	calculateEncryptedKeyByQRCode: async (qrcodeForm: FormData): Promise<string> => {
		const response = await axiosBotAPI.post<EncryptedData>('/user/recovery/qrcode', qrcodeForm, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		});
		return response.data.encrypted;
	},

	getNotification: async (queryParams: NotificationQueryParams): Promise<RequestNotificationResponse> => {
		const response = await axiosBotAPI.get('/user/notification/paginate', {
			params: queryParams,
		});
		return response.data;
	},

	getUnReadNotificationCount: async (): Promise<number> => {
		const response = await axiosBotAPI.get('/user/notification/unRead');
		return response.data;
	},

	updateTransactionMemo: async (payload: TransactionMemoPayload): Promise<TransactionMemoResponse> => {
		try {
			const response = await axiosBotAPI.post('/user/transaction/memo', payload);
			return response.data.data;
		} catch (error) {
			return {
				message: 'Update transaction memo error',
				status: false,
			};
		}
	},

	deleteOldWallet: async (): Promise<string> => {
		const response = await axiosBotAPI.post<string>('/user/clear');
		return response.data;
	},

	getBackupType: async (): Promise<string> => {
		const response = await axiosBotAPI.get<{ type: string }>('/user/key-share/backup-type');
		const { type } = response.data;
		return type ? type : 'false';
	},

	setBackupType: async (payload: { type: string; email: string }): Promise<void> => {
		await axiosBotAPI.post('/user/key-share/backup-type', payload);
	},

	deleteBackupType: async (): Promise<void> => {
		await axiosBotAPI.delete('/user/key-share/backup-type');
	},

	getInviteCodes: async (): Promise<UserInviteCode[]> => {
		const response = await axiosBotAPI.get('/invite');
		return response.data;
	},
	getInviteHistory: async (): Promise<InviteHistory[]> => {
		const response = await axiosBotAPI.get('/invite/me/histories');
		return response.data;
	},

	rotateInviteCode: async (): Promise<UserInviteCode> => {
		return await axiosBotAPI.put('/invite');
	},

	inviteTobiUser: async (invitee: number): Promise<{ success: true }> => {
		return axiosBotAPI.post(`/invite/${invitee}`);
	},

	claimInviteCode: async (args: ClaimInviteCodeArgs): Promise<boolean> => {
		try {
			const response = await axiosBotAPI.post<UserInviteCode & InviteCodeError>('/user/referral', args);

			console.log('response', response);

			if (response.status === 400) {
				throw new Error(response.data.message);
			}

			if (isEmpty(response.data) || isNil(response?.data?.usedCount)) {
				throw new Error('Claim invite code error');
			}

			return response?.data?.usedCount > 0;
		} catch (e) {
			console.log(e);
			const { response } = e;

			throw new Error(response.data.message);
		}
	},
	updateReferralCode: async (args: UpdateReferralCodeArgs): Promise<any> => {
		const res = await axiosBotAPI.post<UserInviteCode & InviteCodeError>('/user/update-referral-code', args);
		return res.data;
	},
	updateAffiliateCode: async (args: UpdateAffiliateCodeArgs): Promise<any> => {
		return;
		const res = await axiosBotAPI.post<UserInviteCode & InviteCodeError>('/user/update-affiliate-code', args);
		return res.data;
	},
	shouldMigrateKeyshare: (): boolean => {
		return keyShareManager.shouldMigrate();
	},
	syncBackupTgCloud: async (payload: { tgBackupVer: string }): Promise<void> => {
		await axiosBotAPI.post('user/key-share/backup-tgcloud', payload);
	},
};
