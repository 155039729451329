import { Button, Container, Flex } from '@chakra-ui/react';
import { Portfolio } from './containers/Portfolio';
import { Rewards } from './containers/Rewards';
import { Swap } from './containers/Swap';
import { TrendingTabs } from './containers/Trending';
import PageNavigation from '@/app-components/layout/PageWrapper/PageWrapper';
import { TobiFun } from '@/app-views/home/containers/TobiFun';
// import { Introduction } from '../introduction';
import { useState } from 'react';
import { getDisplayedTobiIntro } from '@/app-helpers/display';
import { Introduction } from '../introduction';

export const HomePage = () => {
	const [isShowIntro, setIsShowIntro] = useState(() => !getDisplayedTobiIntro());
	return (
		<PageNavigation backable={false}>
			{isShowIntro ? (
				<Introduction onDone={() => setIsShowIntro(false)} />
			) : (
				<Container className="overflow-y-auto hide-scrollbar" pb={20}>
					<Flex flexDirection="column" gap={3} mt={3}>
						<Flex gap={3}>
							<Portfolio />
							<Rewards />
						</Flex>
						<TobiFun />
						<Swap />
						<TrendingTabs />
					</Flex>
				</Container>
			)}
		</PageNavigation>
	);
};
