import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Box, Button, Center, Container, Progress, Text } from '@chakra-ui/react';
import Lottie, { LottieRefCurrentProps } from 'lottie-react';
import { Toast } from '@/app-components/common';
import { ServiceUser } from '@/app-cores/api';
import { AuthClient } from '@/app-cores/auth';
import { MpcWallet } from '@/app-cores/mpc-wallet/wallet';
import { useMutationUpdateUserWallet } from '@/app-hooks/api/mpc-wallet';
import CreateWalletAnimationData from '@/assets/animations/create-wallet.json';
import { DATADOG_ACTIONS, DATADOG_ERROR_TAGS, dataDogAddAction, dataDogAddError } from '@/app-services/monitor/datadog';
import { TelegramCore } from '@/app-cores/telegram';
import { useOnEventCallback } from '@/app-hooks/common';
import { STORAGE_KEYS } from '@/app-constants/storage';
import { useWalletStore } from '@/app-store';

interface KeygenProps {
	onSuccess: () => void;
	onFailed?: () => void;
}

type WalletStatus = 'Pending' | 'Successfully' | 'Failed';
interface ProcessBarProps {
	walletStatus: WalletStatus;
	onComplete: (walletStatus: WalletStatus) => void;
}

const ProcessBar: React.FC<ProcessBarProps> = ({ onComplete, walletStatus }) => {
	const [processBar, setProcessBar] = useState(0);
	const timerRef = useRef(null);
	const timerRef1 = useRef(null);

	useEffect(() => {
		if (walletStatus === 'Successfully') return setProcessBar(100);
		timerRef.current = setInterval(() => {
			if (processBar < 50) {
				setProcessBar((process) => process + 1);
			}
		}, 40);
		return () => clearInterval(timerRef.current);
	}, [walletStatus, processBar]);

	useEffect(() => {
		if (processBar < 50) return;
		timerRef1.current = setInterval(() => {
			if (processBar < 90) {
				setProcessBar((process) => process + 1);
			}
		}, 1450);
		return () => clearInterval(timerRef1.current);
	}, [processBar]);

	useEffect(() => {
		if (processBar < 100) return;
		setTimeout(() => {
			onComplete(walletStatus);
		}, 100);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [processBar]);
	return <Progress my={6} width="100%" value={processBar} size="xs" colorScheme="cyan" borderRadius={4} />;
};

const Keygen: React.FunctionComponent<KeygenProps> = ({ onSuccess, onFailed }) => {
	const [createWalletStatus, setCreateWalletStatus] = useState<WalletStatus>('Pending');
	const { setWalletStatus } = useWalletStore();
	const { t, i18n } = useTranslation();
	const { mutateAsync: updateUserWallet } = useMutationUpdateUserWallet();
	const animateRef = useRef<LottieRefCurrentProps>(null);
	const localKeyshare = MpcWallet.getKeyShares();
	const createWallet = useOnEventCallback(async () => {
		try {
			animateRef.current.goToAndPlay(0);
			animateRef.current?.setSpeed(1.5);
			setCreateWalletStatus('Pending');
			setWalletStatus('CREATING');
			const userProfile = await ServiceUser.getUserProfile();
			if (userProfile?.defaultWallet && localKeyshare) {
				toast(<Toast type="success" message="You already have a wallet" />);
				onFailed?.();
				return;
			}

			const [ecdsaWallet, eddsaWallet] = await Promise.all([
				MpcWallet.createEcdsaWallet(),
				MpcWallet.createEddsaWallet(),
			]);
			const payload = JSON.stringify(
				TelegramCore.withInitData({
					address: {
						evm: ecdsaWallet.address,
						'ton-base': eddsaWallet.tonAddress,
						'solana': eddsaWallet.solAddress,
						tron: ecdsaWallet.tronAddress,
						sui: eddsaWallet.suiAddress,
					},
					timestamp: Date.now(),
				}),
			);

			const signature = await MpcWallet.verifyWallet(payload);
			await updateUserWallet({
				payload,
				signature: signature,
			});
			try {
				await AuthClient.authenticate();
			} catch (error) {
				console.error('reauthenticate error', error);
			}
			localStorage.removeItem(STORAGE_KEYS.TOBI_USE_BACKUP_KEYSHARE);
			MpcWallet.storeUnSecuredKeyShare({
				ecdsaKeyShare2: ecdsaWallet.keyShare2,
				eddsaKeyShare2: eddsaWallet.keyShare2,
			});
			MpcWallet.persistKeyShare();
			setWalletStatus('VALIDATED');
			setCreateWalletStatus('Successfully');
			dataDogAddAction(DATADOG_ACTIONS.CREATE_ACCOUNT_SUCCESS);
		} catch (error) {
			setCreateWalletStatus('Failed');
			setWalletStatus('REQUIRE_CREATE');
			dataDogAddError(error, {
				tags: {
					name: DATADOG_ERROR_TAGS.CREATE_WALLET,
					function: 'createWallet',
				},
			});
			console.log('Create wallet Error:', error);
			const errorCode = error?.response?.data?.code;
			const errorMessageKey = `errors.${errorCode}`;
			const errorMessage = i18n.exists(errorMessageKey) ? t(errorMessageKey) : t('errors.createWalletError');
			toast(<Toast type="error" message={errorMessage} />);
		}
	});

	useEffect(() => {
		createWallet();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Container>
			<Box height="100vh">
				<Center height="100%" flexDirection="column">
					<Lottie
						width={180}
						high={180}
						loop={false}
						autoplay={false}
						lottieRef={animateRef as any}
						animationData={CreateWalletAnimationData}
						style={{
							width: '180px',
							height: '180px',
						}}
					/>
					{createWalletStatus !== 'Failed' && (
						<Box px={10}>
							<ProcessBar
								walletStatus={createWalletStatus}
								onComplete={(walletStatus) => {
									console.log('walletStatus:', walletStatus);
									if (walletStatus === 'Successfully') {
										onSuccess();
									}
								}}
							/>
							<Text fontSize="sm" textAlign="center">
								{t('onboarding.createWallet.processInformation.infoLine1')}
							</Text>
							<Text fontSize="sm" textAlign="center">
								{t('onboarding.createWallet.processInformation.infoLine2')}
							</Text>
						</Box>
					)}
					{createWalletStatus === 'Failed' && (
						<Box mt={6}>
							<Text fontSize="sm" textAlign="center">
								{t('onboarding.createWallet.errors.errorLine1')}
							</Text>
							<Text fontSize="sm" textAlign="center">
								{t('onboarding.createWallet.errors.errorLine2')}
							</Text>
							<Button
								mt={6}
								onClick={() => createWallet()}
								size="lg"
								fontWeight="medium"
								width="100%"
								colorScheme="cyan"
							>
								{t('button.retry')}
							</Button>
						</Box>
					)}
				</Center>
			</Box>
		</Container>
	);
};
export default Keygen;
