import {
	Box,
	Center,
	Drawer,
	DrawerBody,
	DrawerCloseButton,
	DrawerContent,
	DrawerHeader,
	DrawerOverlay,
	Flex,
	SlideOptions,
	Text,
} from '@chakra-ui/react';
import { CSSProperties, ChangeEventHandler, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { InputSearch, LocalLoader } from '@/app-components/common';
import NoData from '@/app-components/common/NoData';
import ChainSelect from '@/app-components/common/Select/ChainSelect';
import { SearchResult } from '@/app-components/common/crypto-search/SearchResult';
import { EMPTY_ARRAY, IOS_KEYBOARD_HEIGHT } from '@/app-constants';
import { ITokenSearch } from '@/app-cores/api/bff';
import { compareAddress, compareChain, compareTobiToken, compareToken, isNativeToken } from '@/app-helpers/address';
import { useSearchToken } from '@/app-hooks/api/portfolio';
import { useDebounce } from '@/app-hooks/common';
import NoAsset from '@/app-views/wallet/components/Portfolio/NoAsset';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { get, uniqBy } from 'lodash';
import { CHAIN_CONFIG, ChainId } from '@/app-constants/chains';
import { colors } from '@/app-theme/theme';
import { getTokenId, getTokenInfo, populateChildrenForToken, tokenHasBalance } from '@/app-helpers/token';
import { usePortfolioBalanceByCategories } from '@/app-hooks/api/portfolio/usePortfolioBalance';
import { formatUnits } from '@/app-helpers/number';
import { useBackButton } from '@/app-store';
import { isMobile } from 'react-device-detect';
import { HomeIcon } from '@/app-components/layout/AppLayout/Header/HomeIcon';

const sortUsdValueFn = (x: ITokenSearch, y: ITokenSearch) =>
	!x.usdValue && !y.usdValue ? 0 : -(x.usdValue || 0) + (y.usdValue || 0);

const groupTokens = ({
	mode,
	balances,
	tokens,
	chainId,
}: {
	balances: ITokenSearch[];
	tokens: ITokenSearch[];
	mode: SearchMode;
	chainId: ChainId | string;
}) => {
	// group token by tobiId
	const result: ITokenSearch[] = populateChildrenForToken(tokens);
	const balanceResult = populateChildrenForToken(balances);

	// group token by address if possible
	balanceResult.forEach((element) => {
		if (element.children.length > 1) {
			const existToken = result.findIndex((e) => getTokenInfo(e).idTobi === getTokenInfo(element).idTobi);
			if (existToken !== -1) {
				result[existToken] = element;
			} else result.push(element);
			return;
		}
		const token = element.children[0];
		const existToken = result
			.map((e) => e.children)
			.flat()
			.find((e) => {
				const { address: address1 } = getTokenInfo(token);
				const { address: address2 } = getTokenInfo(e);
				return !isNativeToken(address1) ? compareAddress(address1, address2) : compareTobiToken(token, e);
			});
		if (existToken) {
			existToken.balance = token.balance;
		} else result.push(element);
	});

	const rs = result
		.map((e) => ({
			...e,
			children: e.children?.filter((e) => {
				const value = mode === SearchMode.MY_BALANCE ? tokenHasBalance(e) : true;
				return value && (chainId ? compareChain(e.chainId, chainId) : true);
			}),
		}))
		.filter((e) =>
			chainId
				? compareChain(getTokenInfo(e).chainId, chainId) ||
				  e.children?.some((c) => compareChain(c.chainId, chainId))
				: true,
		)
		.map((e) => {
			const newData = e.children.length === 1 ? e.children[0] : e;
			const { decimals, priceUsd } = getTokenInfo(newData);
			return {
				...newData,
				usdValue: newData.children?.length
					? newData.children?.reduce((acc, e) => acc + e.usdValue, 0)
					: +(formatUnits(newData.balance ?? '0', decimals, { withFormat: false }) || 0) * priceUsd,
			};
		})
		.sort(sortUsdValueFn);

	return rs;
};

const isEqual = (value: string, keyword: string) => value?.toLowerCase().includes(keyword);

export const searchTokenFn = (e: ITokenSearch, keywordDebounced) => {
	const { symbol, address } = getTokenInfo(e);
	return isEqual(symbol, keywordDebounced) || isEqual(address, keywordDebounced);
};

export enum SearchMode {
	MY_BALANCE, // show list portfolio token by default
	SELECT_ANY_TOKEN, // show list portfolio + trending token by default
}

type Props = {
	onSelectToken: (token: ITokenSearch) => void;
	chainIds?: ChainId[];
	chainId?: ChainId | string;
	mode?: SearchMode;
	inputStyle?: CSSProperties;
	syncKeyWordToUrl?: boolean;
	autoFocus?: boolean;
	defaultQuery?: string;
	inputPlaceholder?: string;
	selectedToken?: ITokenSearch;
	hasChildren?: boolean;
	header?: ReactNode;
	showHomeIcon?: boolean;
};
const CryptoSearch = ({
	onSelectToken,
	chainId: chainIdProps,
	chainIds,
	mode,
	inputStyle,
	syncKeyWordToUrl,
	autoFocus = true,
	defaultQuery,
	inputPlaceholder,
	selectedToken,
	hasChildren,
	header,
	showHomeIcon,
}: Props) => {
	const location = useLocation();
	const [keyword, setKeyword] = useState(defaultQuery || location.state?.search || '');
	const [chainId, setChain] = useState(chainIdProps || '');
	const { t } = useTranslation();
	const keywordDebounced = useDebounce(keyword.trim() || '', 400);
	const bodyContentRef = useRef<HTMLDivElement>(null);
	const inputSearchRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		setChain(chainIdProps);
	}, [chainIdProps]);

	const portfolioBalanceOnly = mode === SearchMode.MY_BALANCE;
	const navigate = useNavigate();

	const wrapOnSelectToken = useCallback(
		(token: ITokenSearch) => {
			if (syncKeyWordToUrl)
				navigate(location, {
					state: { search: keywordDebounced },
					replace: true,
				});
			onSelectToken(token);
		},
		[onSelectToken, navigate, syncKeyWordToUrl, keywordDebounced, location],
	);

	const { data: tokenCatalogsByQuery, isFetching: isFetchingCatalog } = useSearchToken({
		query: keywordDebounced,
		chainId,
		limit: 50,
		chainIds,
	});

	const {
		data: { mainTokensHasBalance: portfolioBalances, spamTokens, hiddenTokens },
		isFetched,
	} = usePortfolioBalanceByCategories({
		retry: false,
	});

	const isFetchingPortfolio = !isFetched;

	const tokenCatalogs = useMemo(() => {
		const result: ITokenSearch[] = tokenCatalogsByQuery || [];
		return result.filter((e) => {
			return (
				!spamTokens.some((token) => compareTobiToken(token, e)) &&
				!hiddenTokens.some((token) => compareTobiToken(token, e))
			);
		});
	}, [tokenCatalogsByQuery, hiddenTokens, spamTokens]);

	const isFetching = portfolioBalanceOnly ? isFetchingPortfolio : isFetchingCatalog;

	const filterFn = useCallback((e: ITokenSearch) => searchTokenFn(e, keywordDebounced), [keywordDebounced]);

	const tokensSearchFromPortfolio = useMemo(() => {
		return keywordDebounced ? portfolioBalances.filter(filterFn) : portfolioBalances;
	}, [portfolioBalances, keywordDebounced, filterFn]);

	const selectedTokens = useMemo(() => {
		const selectedTokens = selectedToken && !tokenHasBalance(selectedToken) ? [selectedToken] : [];
		return keywordDebounced ? selectedTokens.filter(filterFn) : selectedTokens;
	}, [selectedToken, keywordDebounced, filterFn]);

	const visibleTokens = useMemo(() => {
		const backendTokens = mode === SearchMode.MY_BALANCE ? [] : tokenCatalogs;
		if (selectedTokens?.[0]) backendTokens.unshift(selectedTokens[0]);
		const tokens = groupTokens({
			balances: tokensSearchFromPortfolio,
			tokens: backendTokens,
			mode,
			chainId,
		});

		let filterToken = tokens;
		if (chainIds?.length) {
			filterToken = filterToken.filter((tk) =>
				chainIds?.some((chain) => getTokenInfo(tk).chainTokens.some((tok) => compareChain(chain, tok.chainId))),
			);
		}

		return filterToken.slice(0, 100);
	}, [mode, tokenCatalogs, tokensSearchFromPortfolio, chainIds, chainId, selectedTokens]);

	const onKeywordChange: ChangeEventHandler<HTMLInputElement> = useCallback((e) => {
		setKeyword(e.target.value);
	}, []);

	const showNotfound = !visibleTokens.length && !isFetching;

	const noAsset = portfolioBalanceOnly && !isFetching && !portfolioBalances?.length;
	return (
		<Flex flexDirection={'column'} sx={{ gap: '8px', height: '100%' }} flex={1}>
			<Flex direction={'column'} px={5} gap={'12px'}>
				{noAsset ? (
					<NoAsset />
				) : (
					<>
						<DrawerHeader px={0} pb={0}>
							<Box transform="translateY(-8px)">{header}</Box>
							<Center gap={1}>
								{showHomeIcon && <HomeIcon />}
								<InputSearch
									placeholder={inputPlaceholder || t('cryptos.searchPlaceholder')}
									border="none"
									value={keyword}
									autoFocus={autoFocus}
									onChange={onKeywordChange}
									onClear={() => {
										setKeyword('');
										inputSearchRef?.current?.focus();
									}}
									fontSize="sm"
									style={inputStyle}
									ref={inputSearchRef as any}
									background="white"
									height={12}
								/>
							</Center>
							<Flex justifyContent={'space-between'} alignItems={'center'} mt={2}>
								<Text fontSize={'small'} color={'gray'} fontWeight={'500'}>
									{portfolioBalanceOnly
										? t('cryptos.yourTokens')
										: keywordDebounced
										? `${visibleTokens.length} ${t('cryptos.results')}`
										: t('cryptos.topCrypto')}
								</Text>
								<ChainSelect
									placement="bottom-end"
									logoSize="22px"
									value={chainId}
									onChange={setChain}
									chainIds={chainIds}
									style={{
										borderRadius: 105,
										border: 'none',
										background: colors.gray[100],
										fontSize: '14px',
										padding: '6px 12px',
									}}
									menuStyle={{
										overflowY: 'scroll',
										maxHeight: '420px',
									}}
								/>
							</Flex>
						</DrawerHeader>
					</>
				)}
			</Flex>
			{!noAsset && (
				<DrawerBody
					px={0}
					flexDirection={'column'}
					gap={'12px'}
					flex={1}
					ref={bodyContentRef}
					transition="all 250ms"
					onScroll={() => {
						if (isMobile && document.activeElement === inputSearchRef.current) {
							inputSearchRef?.current?.blur();
						}
					}}
				>
					{isFetching ? (
						<Box height={'104px'}>
							<LocalLoader />
						</Box>
					) : showNotfound ? (
						<NoData msg={keywordDebounced ? t('cryptos.notFoundToken') : t('cryptos.searchHint')} />
					) : (
						<SearchResult
							{...{
								visibleTokens,
								onSelectToken: wrapOnSelectToken,
								selectedToken,
								hasChildren,
								note: !keywordDebounced.length && mode !== SearchMode.MY_BALANCE && (
									<Text fontSize={'12px'} textAlign={'center'} py={4}>
										{t('cryptos.searchHint')}
									</Text>
								),
							}}
						/>
					)}
				</DrawerBody>
			)}
		</Flex>
	);
};

export const CryptoSearchDrawer = ({
	onSelectToken,
	onClose,
	isOpen,
	mode = SearchMode.SELECT_ANY_TOKEN,
	syncKeyWordToUrl = false,
	placement,
	backButtonCallback,
	skipNavigationWhenBack,
	children,
	...rest
}: {
	onClose: () => void;
	isOpen: boolean;
	placement?: SlideOptions['direction'];
	backButtonCallback?: () => void;
	skipNavigationWhenBack?: boolean;
	children?: ReactNode;
} & Props) => {
	const selectOnlyPortfolio = mode === SearchMode.MY_BALANCE;
	const { t } = useTranslation();
	const { setShow, reset } = useBackButton();

	useEffect(() => {
		if (isOpen && !selectOnlyPortfolio) {
			setShow({
				isShow: true,
				backButtonCallback: backButtonCallback,
				skipNavigation: skipNavigationWhenBack,
			});
		} else {
			reset();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpen, selectOnlyPortfolio]);

	const isShowCloseButton = selectOnlyPortfolio || placement === 'bottom';

	return (
		<Drawer
			isOpen={isOpen}
			onClose={onClose}
			size={'full'}
			placement={placement || selectOnlyPortfolio ? 'bottom' : undefined}
			trapFocus={false}
		>
			<DrawerOverlay />
			<DrawerContent background={'#EBEFF6'} pt={1}>
				{isShowCloseButton && <DrawerCloseButton zIndex={2} />}
				{children || (
					<CryptoSearch
						{...{
							mode,
							onSelectToken,
							syncKeyWordToUrl,
							header: isShowCloseButton ? t('cryptos.selectToken') : '',
							...rest,
						}}
					/>
				)}
			</DrawerContent>
		</Drawer>
	);
};
