import * as React from 'react';
import { useState } from 'react';
import { Box, Center, Container, Flex } from '@chakra-ui/react';
import { Policy } from '../onboarding/pages/WellCome/WellCome';
import { FiArrowLeftIcon, FiArrowRightIcon, FiCheckIcon } from '@/assets/icons';
import { Carousel } from '@/app-components/common';
import { STORAGE_KEYS } from '@/app-constants/storage';
import { useWalletStore } from '@/app-store';

export const images = [
	'assets/images/intro-bg1.svg',
	'assets/images/intro-bg2.svg',
	'assets/images/intro-bg3.svg',
	'assets/images/intro-bg4.svg',
];

interface ActionProps {
	onNext: () => void;
	onPrev: () => void;
	currentPage: number;
	total: number;
	isDone: boolean;
}

interface DotProps {
	isActive: boolean;
}

const Dot: React.FC<DotProps> = ({ isActive }) => {
	return (
		<Box
			borderRadius={isActive ? '10px' : '100%'}
			bgColor={isActive ? 'cyan.400' : 'rgba(255, 255, 255, 0.08)'}
			width={isActive ? 7 : 2}
			height={2}
		></Box>
	);
};
const Action: React.FC<ActionProps> = ({ onNext, currentPage, total, isDone, onPrev }) => {
	const dots = Array.from({ length: total }, (_, index) => index);

	return (
		<Center justifyContent="space-between" position="absolute" zIndex={2} bottom={4} px={8} left={0} width="100%">
			<Flex cursor="pointer" onClick={onPrev} visibility={currentPage < 1 ? 'hidden' : 'visible'}>
				<Center width={16} height={16} borderRadius="100%" bgColor={'rgba(255, 255, 255, 0.08)'}>
					<FiArrowLeftIcon fill="white" />
				</Center>
			</Flex>
			{!isDone && (
				<Flex gap={1}>
					{dots.map((d) => (
						<Dot key={d} isActive={d === currentPage} />
					))}
				</Flex>
			)}
			{isDone && <Policy fontSize="xx-small" color="rgba(255, 255, 255, 0.64)" />}

			<Flex cursor="pointer" onClick={onNext}>
				<Center
					width={16}
					height={16}
					borderRadius="100%"
					bgColor={isDone ? 'cyan.400' : 'rgba(255, 255, 255, 0.08)'}
				>
					{isDone ? <FiCheckIcon fill={isDone ? 'black' : 'white'} /> : <FiArrowRightIcon fill="white" />}
				</Center>
			</Flex>
		</Center>
	);
};

interface IntroductionProps {
	onDone: () => void;
}
export const Introduction: React.FC<IntroductionProps> = ({ onDone }) => {
	const { setDisplayedTobiInto } = useWalletStore();
	const [[page, direction], setPage] = useState(() => [
		+sessionStorage.getItem(STORAGE_KEYS.TOBI_CURRENT_DISPLAYED_PAGE) || 0,
		0,
	]);
	const paginate = (newDirection: number) => {
		const newPage = page + newDirection;
		if (newPage === images.length) {
			setDisplayedTobiInto(true);
			onDone();
			return localStorage.setItem(STORAGE_KEYS.TOBI_DISPLAYED_INTRO, '1');
		}
		sessionStorage.setItem(STORAGE_KEYS.TOBI_CURRENT_DISPLAYED_PAGE, `${newPage}`);
		setPage([newPage, newDirection]);
	};

	return (
		<Container
			p={0}
			width="100%"
			height="100vh"
			position="absolute"
			top={0}
			left={0}
			zIndex={1000}
			bgColor="#22222D"
			overflow="hidden"
			color="white"
		>
			<Carousel images={images} currentPage={page} direction={direction} onPageChange={paginate} />
			<Action
				onNext={() => paginate(1)}
				onPrev={() => paginate(-1)}
				currentPage={page}
				total={images.length}
				isDone={page + 1 === images.length}
			/>
		</Container>
	);
};
