import { Loading } from '@/app-components/common';
import {
	Button,
	Card,
	CardBody,
	Divider,
	Flex,
	HStack,
	Image,
	List,
	ListItem,
	Text,
	VStack,
	useDisclosure,
} from '@chakra-ui/react';
import React, { ReactNode, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TelegramCore } from '../app-cores/telegram';
import { FiArrowRightIcon, FiMailIcon, FiSettingIcon } from '../assets/icons';
import { COMMUNITY_LINK } from '@/app-constants';
import { FiUserIcon } from '@/assets/icons/fi-user-icon';
import { useNavigate } from 'react-router';
import { NAVIGATE_PATHS } from '@/app-constants/router';
import { formatNumber, formatUnits } from '@/app-helpers/number';
import i18n from '@/app-cores/i18n';
import PageLayout from '@/app-components/layout/PageLayout';
import { colors } from '@/app-theme/theme';
import {
	FARM_IDS,
	TobiFarmType,
	useQueryDebridgePoint,
	useQueryKyberPoint,
	useQueryTobiFarm,
} from '@/app-hooks/api/dapps/useTobiFarm';
import { escapeScriptHtml } from '@/app-helpers/string';
import TobiTag from '@/app-components/common/TobiTag';
import { FiFarmIcon } from '@/assets/icons/fi-farm-icon';
import { useNavigateToUrl } from '@/app-helpers/navigate';
import { FaucetTestnet } from '@/app-components/common/TopupToken';
import { ChainId } from '@/app-constants/chains';
import { useTransaction } from '@/app-hooks/wallet/useTransaction';
import { TokenLabel } from '@/app-views/portfolio/components/ListCrypto';
const FarmInfoDebridge = () => {
	const { data } = useQueryDebridgePoint();
	return (
		<Flex gap={2}>
			Points earned: <Text color={colors.green[200]}>{formatNumber(data | 0)} points</Text>
		</Flex>
	);
};

const FarmInfoKyber = () => {
	const { data } = useQueryKyberPoint();
	return (
		<Flex gap={2}>
			Est. rewards: <Text color={colors.green[200]}>{formatUnits(data?.totalReward || 0, 18)} ARB</Text>
		</Flex>
	);
};

const FarmInfoBera = ({ isOpen, onClose }) => {
	return (
		<>
			<TokenLabel type="testnet" />
			<FaucetTestnet isOpen={isOpen} onClose={onClose} chainId={`${ChainId.BERACHAIN_TESTNET}`} />
		</>
	);
};

const mapComponent = {
	[FARM_IDS.BERA]: FarmInfoBera,
	[FARM_IDS.DEBRIDGE]: FarmInfoDebridge,
	[FARM_IDS.KYBER]: FarmInfoKyber,
};
const mapTimeEnd = {
	[FARM_IDS.KYBER]: (
		<TobiTag px={2}>
			Ends on: <b>Sep 15</b>
		</TobiTag>
	),
};

const FarmItem = ({ data: farm }: { data: TobiFarmType }) => {
	const { logo, description, title, ctaUrl, ctaName, key } = farm;
	const navigate = useNavigateToUrl(ctaUrl, NAVIGATE_PATHS.TOBI_FARM);
	const component = mapComponent[key];
	const { onClose, isOpen, onOpen } = useDisclosure();
	const { t } = useTranslation();
	const isBera = key === FARM_IDS.BERA;
	return (
		<Card p={0}>
			<CardBody display={'flex'} flexDirection={'column'} gap={3} p={4}>
				<Flex display={'flex'} justifyContent={'space-between'} alignItems={'center'} gap={4}>
					<HStack spacing={4} align={'flex-start'} flex={1}>
						<Image src={logo} width={'52px'} borderRadius={'50%'} />
						<VStack alignItems={'flex-start'} flex={1}>
							<HStack justifyContent={'space-between'} w={'100%'}>
								<Text fontSize={'16px'} lineHeight={'16px'} fontWeight={'500'} as="div" flex={1}>
									{title}
								</Text>
								{mapTimeEnd[key]}
							</HStack>
							<Text
								fontSize={'12px'}
								dangerouslySetInnerHTML={{ __html: escapeScriptHtml(description) }}
							/>
						</VStack>
					</HStack>
				</Flex>
				<Divider orientation="horizontal" variant={'dashed'} borderColor={colors.gray[200]} />
				<Flex gap={2} fontWeight={'500'} fontSize="14px" flex={1} justify={'space-between'} align={'center'}>
					{component && React.createElement(component, { isOpen, onClose })}
					{ctaUrl && (
						<Button
							minWidth={'70px'}
							width={'70px'}
							background={colors.blue[200]}
							color={'white'}
							onClick={() => (isBera ? onOpen() : navigate())}
						>
							{ctaName || t('button.go')}
						</Button>
					)}
				</Flex>
			</CardBody>
		</Card>
	);
};

const TobiFarm = () => {
	const { t } = useTranslation();
	const { isFetching, data } = useQueryTobiFarm();

	if (isFetching) {
		return <Loading />;
	}

	return (
		<PageLayout
			hasBackground
			header={
				<Flex justify={'center'} align={'center'} gap={2}>
					<FiFarmIcon />
					{t('tobiFarm.tobiFarm')}
				</Flex>
			}
		>
			<Flex direction={'column'} gap={4} py={4}>
				{/* <Flex align={'center'} gap={3}>
					<FiHotIcon />
					{t('tobiFarm.onGoing')}
				</Flex> */}
				<List spacing={4}>
					{data?.map((el) => {
						return (
							<ListItem key={el.id}>
								<FarmItem data={el} />
							</ListItem>
						);
					})}
				</List>
			</Flex>
		</PageLayout>
	);
};

export default TobiFarm;
