import { useEffect, useRef } from 'react';
import { useBotRedirect } from '@/app-features/app-bot-connector/hooks';
import { MpcWallet } from '@/app-cores/mpc-wallet/wallet';
import { useParseBotStartRedirectParams } from '@/app-cores/telegram';
import { useQueryUserProfile } from '@/app-hooks/api/user/useQueryUserProfile';
import { useOnEventCallback } from '@/app-hooks/common';
import { dataDogAddUserInfo } from '@/app-services/monitor/datadog';
import { useTransactionWatcherStore, useWalletStore } from '@/app-store';
import { useWatchTransactions } from '@/app-store/transaction-watcher/evmWatcher';
import { useMutationUpdateAffiliateCode } from '@/app-hooks/api/user/useMutationUpdateAffiliateCode';
import { useShowNotification } from '@/app-hooks/api/notification';
import { WalletConnect } from '@/app-features/app-wallet-connect/views/WalletConnect';
import { TonConnect } from '@/app-features/app-ton-connect/views';

interface BackgroundAppProps {}

export const BackgroundApp: React.FC<BackgroundAppProps> = () => {
	const { walletStatus } = useWalletStore();
	useWatchTransactions(true);
	useShowNotification();

	const { action, affiliateCode } = useParseBotStartRedirectParams();
	const { mutateAsync: updateAffiliateCode } = useMutationUpdateAffiliateCode();
	const checkRedirect = useBotRedirect();

	useEffect(() => {
		if (!affiliateCode || !MpcWallet.getWalletAddress().evmAddress) return;
		updateAffiliateCode({
			affiliateCode: affiliateCode,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [affiliateCode]);

	useEffect(() => {
		useTransactionWatcherStore.persist.rehydrate();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const { setIsNonWallet } = useWalletStore();

	const redirected = useRef(false);
	const onRedirect = useOnEventCallback(() => {
		const isNonWallet = walletStatus !== 'VALIDATED';
		setIsNonWallet(isNonWallet);
		if (action && !redirected.current) {
			checkRedirect(isNonWallet);
			redirected.current = true;
		}
	});

	useEffect(() => {
		onRedirect();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [walletStatus]);

	useEffect(() => {
		if (walletStatus !== 'VALIDATED') return;
		const wallet = MpcWallet.getWalletAddress();
		dataDogAddUserInfo({
			evm: wallet.evmAddress,
			sol: wallet.solAddress,
			ton: wallet.tonAddress,
			tron: wallet.tronAddress,
		});
	}, [walletStatus]);
	const isWalletExits = walletStatus === 'VALIDATED' || walletStatus === 'REQUIRE_BACKUP';
	return (
		isWalletExits && (
			<>
				<TonConnect />
				<WalletConnect />
			</>
		)
	);
};
