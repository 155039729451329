import { ObserverTransaction } from '@/app-cores/api/activities';
import { axiosBotAPI } from '@/app-cores/api/axios';
import { CreateTokenMetadata } from '@/app-services/pump.fun';
import axios from 'axios';

export type SwapFeeResponse = {
	fee: number;
	feeAmount?: string; // bigint
	percentage: number;
	wallets: { EVM: string; SOL: string; TON: string; TRON: string; SUI: string };
};
class SwapService {
	async submitMetadataSwapTxs(payload: ObserverTransaction) {
		return axiosBotAPI.post('/fee/tobi-fee', payload);
	}

	async submitMetadataSwap2LegTxs(payload: {
		metadata: {
			fromChain: string;
			toChain: string;
			fromToken: string;
			toToken: string;
			providerId: string;
			toTokenSymbol: string;
			fromTokenSymbol: string;
		};
		hash: string;
		provider: string;
	}) {
		return axiosBotAPI.post('/swap/create-two-leg-swap', payload);
	}

	async checkSwapTransaction(payload: {
		hash?: string;
		provider?: string;
		providerId?: string;
		isSameChain?: boolean;
		walletAddress?: string;
		chainId?: string;
	}): Promise<any> {
		const data = await axiosBotAPI.post('/fee/check-swap', payload);
		return data.data.data;
	}
	async getSwapFee({ amount }: { amount: string }): Promise<SwapFeeResponse> {
		const data = await axiosBotAPI.get('/fee/tobi-fee', {
			params: { amount },
		});
		return data.data.data;
	}

	async createPumpfunTokenMetadata(create: CreateTokenMetadata) {
		const formData = new FormData();
		formData.append('file', create.file),
			formData.append('name', create.name),
			formData.append('symbol', create.symbol),
			formData.append('description', create.description),
			formData.append('twitter', create.twitter || ''),
			formData.append('telegram', create.telegram || ''),
			formData.append('website', create.website || ''),
			formData.append('showName', 'true');
		const request = await axiosBotAPI.post('/trading/pump-fun', formData);
		return request.data;
	}
}

export const SwapServiceAPI = new SwapService();
