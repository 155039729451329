import { FiFireIcon } from '@/assets/icons';
import { Box, Button, Flex, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react';
import { TrendingToken } from './Trending/TrendingToken';
import { MemeCoin } from './Trending/Memecoin';
import { BASE_BORDER_COLOR, colors } from '@/app-theme/theme';
import { useNavigate } from 'react-router';
import { ChevronRightIcon } from '@/assets/images/svg';
import { NAVIGATE_PATHS } from '@/app-constants/router';
import { useTranslation } from 'react-i18next';
import Bg from '@/assets/images/bg-memepad.png';
import { FiRocketIcon, FiRocketIconSingleColor } from '@/assets/icons/fi-rocket-icon';
import { FiCreateTokenIcon } from '@/assets/icons/fi-create-token-icon';
import { useGetPortfolioMeme } from '@/app-hooks/api/pump.fun';
import { Balance } from '@/app-components/common/Balance';
import { FeatureConfigWrap } from '@/app-helpers/components';
import { FeatureConfig } from '@/app-hooks/api/configuration';

export const LaunchMemeButton = ({ textGradient = false }) => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	return (
		<Button
			_hover={{
				background: 'white',
			}}
			fontSize={'14px'}
			height={'34px'}
			borderRadius={'8px'}
			className="gradient-border"
			onClick={() => navigate(NAVIGATE_PATHS.TobiFun.Create)}
		>
			<FiCreateTokenIcon style={{ marginRight: '4px' }} />
			<span className={textGradient ? 'text-gradient' : undefined}> {t('memepad.launchToken')}</span>
		</Button>
	);
};

export const TobiFun = () => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const { data: portfolio } = useGetPortfolioMeme();
	return (
		<FeatureConfigWrap feature={FeatureConfig.MEMEPAD}>
			<Flex
				direction={'column'}
				borderRadius={8}
				sx={{
					backgroundImage: Bg,
				}}
				backgroundSize={'cover'}
				backgroundRepeat={'no-repeat'}
				backgroundPosition={'center'}
				position={'relative'}
				height={'160px'}
			>
				<Box
					borderRadius={8}
					sx={{
						position: 'absolute',
						inset: 0,
						w: '100%',
						h: '100%',
						backdropFilter: 'blur(8px)',
						WebkitBackdropFilter: 'blur(8px)',
					}}
				/>
				<Flex p={4} flexDir={'column'} h={'100%'} sx={{ position: 'absolute', inset: 0, w: '100%' }}>
					<Flex justifyContent={'space-between'}>
						<Flex
							flex={1}
							alignItems={'center'}
							gap={2}
							fontSize={'12px'}
							fontWeight={'500'}
							onClick={() => navigate(NAVIGATE_PATHS.TobiFun.Main)}
							color={colors.white}
						>
							<FiRocketIconSingleColor width={18} height={18} />
							<Text lineHeight={'24px'} fontSize="xs" fontWeight={600}>
								{t('memepad.title')}
							</Text>
							<ChevronRightIcon />
						</Flex>
					</Flex>
					<Flex px={4} borderRadius={'8px'} flex={1} align={'center'} direction={'column'}>
						<Flex align={'center'} gap={2} onClick={() => navigate(NAVIGATE_PATHS.TobiFun.Profile)}>
							<Balance amount={portfolio?.totalUsd} color={colors.white} />
							<ChevronRightIcon style={{ color: colors.white }} />
						</Flex>

						<Flex justify={'center'} flex={1} w={'100%'} gap={5}>
							<LaunchMemeButton />
						</Flex>
					</Flex>
				</Flex>
			</Flex>
		</FeatureConfigWrap>
	);
};
