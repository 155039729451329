import { useNavigate } from 'react-router';
import { ConfirmSendTransaction, Source, Toast } from '@/app-components/common';
import { toast } from 'react-toastify';
import { NATIVE_TOKEN_ADDRESS } from '@/app-constants/chains';
import { NAVIGATE_PATHS } from '@/app-constants/router';
import { displayTonFee } from '@/app-helpers/display';
import { usePriceNativeToken, useTokenPriceSingleToken } from '@/app-hooks/api/portfolio/useTokenPrices';
import { useSentTonToken } from '@/app-hooks/transactions';
import { useContactStore, useSentTokenStore } from '@/app-store';
import { TransactionType } from '@/app-types';
import { DATADOG_ERROR_TAGS, dataDogAddError } from '@/app-services/monitor/datadog';
import { useEstimateTonGasFee } from '@/app-hooks/transactions/ton/useEstimateFeeSendTonAsset';

interface ConfirmSendTonAssetProps {
	source?: Source;
}
export const ConfirmSendTonAsset: React.FC<ConfirmSendTonAssetProps> = ({ source }) => {
	const navigate = useNavigate();
	const { isPending, sendTransaction } = useSentTonToken();
	const { contact, address } = useContactStore();
	const sentTo = contact?.wallets?.['ton-base']?.address || address;
	const { token, amount, messages, tokenInfo } = useSentTokenStore();
	const { data, isPending: isFetchingGasFee } = useEstimateTonGasFee({
		amount: amount,
		to: sentTo,
		token: tokenInfo,
		message: messages,
	});

	const { data: nativePriceUsd = 0 } = usePriceNativeToken({
		chainId: tokenInfo?.chainId,
	});
	const gasFeeDisplay = displayTonFee({
		feePriceUsd: nativePriceUsd,
		gasFee: data,
	});

	const handleTransfer = async () => {
		try {
			const { hash, seqno } = await sendTransaction({
				amount: amount,
				token: tokenInfo,
				to: sentTo,
				message: messages,
				gasFee: data,
				autoDeductFee: true,
			});
			navigate(
				`${NAVIGATE_PATHS.transactionResult(
					TransactionType.Send,
					tokenInfo.chainId,
					seqno.toString(),
				)}?hash=${hash}`,
			);
		} catch (error) {
			toast(<Toast type="error" title="Error" message={error?.message} />);
			console.error('send ton asset error', error);
			dataDogAddError(error, {
				tags: {
					name: DATADOG_ERROR_TAGS.SEND,
					function: 'transferTonAsset',
				},
			});
		}
	};
	return (
		<ConfirmSendTransaction
			feeToken={gasFeeDisplay.feeToken}
			feeUsd={gasFeeDisplay.feeUsd}
			isLoading={isPending}
			onConfirm={handleTransfer}
			isFetchingGasFee={isFetchingGasFee}
			source={source}
		/>
	);
};
