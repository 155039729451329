import {
	ButtonSecondary,
	CommonInput,
	CopyToClipboard,
	CTAButton,
	RequireWallet,
	Toast,
} from '@/app-components/common';
import { ActionBlock } from '@/app-components/common/ActionBlock';
import { TokenAmountInput } from '@/app-components/common/SelectToken/TokenAmount';
import Warning from '@/app-components/common/Warning';
import PageLayout from '@/app-components/layout/PageLayout';
import { QUERY_KEYS } from '@/app-constants';
import { CHAIN_CONFIG, ChainId, NATIVE_TOKEN_ADDRESS, SOLANA_URL, TobiChainName } from '@/app-constants/chains';
import { NAVIGATE_PATHS } from '@/app-constants/router';
import { TxStatus } from '@/app-cores/api/activities';
import { ITokenSearch } from '@/app-cores/api/bff';
import { SolWallet } from '@/app-cores/mpc-wallet/solana/SolWallet';
import { queryClient } from '@/app-cores/query-client';
import { getShortAddress } from '@/app-helpers/address';
import { displayAmount } from '@/app-helpers/display';
import { parseErrorMessage } from '@/app-helpers/error-handling';
import { countDecimals, formatCurrency, formatUnits, formatUsd } from '@/app-helpers/number';
import { formatTimeRecent } from '@/app-helpers/time';
import { getNativeTobiId, getTokenInfo, tokenHasBalance } from '@/app-helpers/token';
import { parseUrlSearchParams } from '@/app-helpers/url';
import { useSearchSingleToken } from '@/app-hooks/api/portfolio';
import { useTokenBalance } from '@/app-hooks/api/portfolio/usePortfolioBalance';
import { useEstimateCreateMeme } from '@/app-hooks/api/pump.fun';
import { useDebounce } from '@/app-hooks/common';
import useInputMode from '@/app-hooks/common/useInputMode';
import { AmountPayload, ErrorMsg, SwapErrorInfo } from '@/app-hooks/swap';
import { calcAmount, tryParseAmount } from '@/app-hooks/swap/helper';
import { PumpFunSwap } from '@/app-hooks/swap/pump_fun';
import { MAX_FEE_SOL_SWAP, SwapErrorType } from '@/app-hooks/swap/type';
import { CreateEvent, CreateTokenMetadata, PumpFunSDK, TokenMetadata } from '@/app-services/pump.fun';
import { useTransactionWatcherStore } from '@/app-store';
import { useUserSettingsStore } from '@/app-store/settings';
import { InputMode } from '@/app-store/swap';
import { BASE_BORDER_COLOR, colors } from '@/app-theme/theme';
import { TransactionType } from '@/app-types';
import { TokenBalance } from '@/app-views/portfolio/pages/token/components/Balance';
import { SocialItem } from '@/app-views/portfolio/pages/token/components/TokenInformation';
import BuyToken from '@/app-views/swap/components/BuyToken';
import SwapWidget from '@/app-views/swap/components/SwapWidget';
import ConfirmCreateMeme from '@/app-views/tobi-fun/containers/ConfirmCreateMeme';
import Header from '@/app-views/tobi-fun/containers/Header';
import TokenInformation from '@/app-views/tobi-fun/containers/TokenInfomation';
import Trades from '@/app-views/tobi-fun/containers/Trades';
import {
	formatPumpTokenToTobiToken,
	PUMP_TOKEN_DECIMALS,
	TokenMode,
	useValidateInput,
} from '@/app-views/tobi-fun/helpers';
import { GasWarning } from '@/app-views/wallet/components/Portfolio/GasWarning';
import { FiImageIcon, FiPlusIcon, FiSettingIcon, FiSwapIcon } from '@/assets/icons';
import { FiSwapIconColor } from '@/assets/icons/fi-swap-icon-color';
import {
	Box,
	Card,
	CardBody,
	Collapse,
	Divider,
	Flex,
	FormControl,
	FormLabel,
	Image,
	Skeleton,
	Spinner,
	Text,
	useDisclosure,
	VStack,
} from '@chakra-ui/react';
import { VersionedTransaction } from '@solana/web3.js';
import axios from 'axios';
import { ethers } from 'ethers';
import { FormikErrors, useFormik } from 'formik';
import { uniqBy } from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';

const defaultValues: CreateTokenMetadata & { amount: string } = {
	name: '',
	symbol: '',
	description: '',
	twitter: '',
	telegram: '',
	website: '',
	file: null,
	amount: '',
};

export const createMemeFields = [
	{ name: 'name', label: 'Token Name' },
	{ name: 'symbol', label: 'Token Symbol' },
	{ name: 'description', label: 'Token Description' },
	{ name: 'website', label: 'Website', required: false },
	{ name: 'twitter', label: 'Twitter/X', required: false },
	{ name: 'telegram', label: 'Telegram', required: false },
];

const InfoItem = ({ label, value, isFetching }) => {
	return (
		<Flex fontWeight={'500'} fontSize={'12px'} justify={'space-between'} align={'center'}>
			<Text>{label}</Text>
			{isFetching ? <Skeleton opacity="0.1" height={'16px'} width="100px" /> : <Text>{value}</Text>}
		</Flex>
	);
};

export default function TobiFunCreaeMeme() {
	const { data: sol } = useSearchSingleToken({ tobiId: getNativeTobiId(ChainId.SOL), chainId: ChainId.SOL });
	const solInfo = useMemo(() => {
		return getTokenInfo(sol);
	}, [sol]);

	const [amount, setAmount] = useState('');
	const amountInDebounced = useDebounce(amount, 300);
	const [usdAmount, setUsdAmount] = useState('');

	const [tokenMode, setTokenMode] = useState(TokenMode.NATIVE);
	const buyViaNative = tokenMode === TokenMode.NATIVE;

	const toggleToken = () => {
		const newMode = tokenMode === TokenMode.NATIVE ? TokenMode.MEME : TokenMode.NATIVE;
		setTokenMode(newMode);
		if (newMode === TokenMode.MEME) setInputMode(InputMode.AMOUNT);
	};

	const [imageUrl, setImageUrl] = useState('');
	const navigate = useNavigate();

	const { isOpen, onOpen, onClose } = useDisclosure();
	const { inputMode, handleSwitch, setInputMode } = useInputMode(InputMode.USD);
	const { addPendingSolTransaction } = useTransactionWatcherStore();

	const { data, isFetching } = useEstimateCreateMeme({
		buyAmountSol: buyViaNative ? tryParseAmount(amountInDebounced, solInfo.decimals) : 0n,
		buyAmountMeme: buyViaNative ? 0n : tryParseAmount(amountInDebounced, PUMP_TOKEN_DECIMALS),
	});

	const estimateAmountOut = data?.estimateAmountOut ?? 0n;
	const estimateAmountSol = data?.estimateAmountSol ?? 0n;
	const estimateFee = data?.gasFee ?? 0n;
	const totalPay = estimateAmountSol + estimateFee;

	const createTransaction = async (values) => {
		const sdk = await PumpFunSDK.create();
		return await sdk.createAndBuy({
			createTokenMetadata: values,
			buyAmountSol: estimateAmountSol,
			slippageBasisPoints: PumpFunSwap.formatSlippage(slippage),
			estimateOnly: false,
		});
	};

	const { values, errors, setFieldValue, setErrors, setFieldError, submitForm } = useFormik({
		initialValues: defaultValues,
		onSubmit: async (values) => {
			try {
				const { tx, signer } = await createTransaction(values);
				const hash = await SolWallet.sendTransaction({
					data: tx,
					signer,
					transactionType: TransactionType.ContractInteraction,
					callback: ({ status, msg }) => {
						if (status === TxStatus.Failed) {
							const error = parseErrorMessage(msg, t('transactionResult.swap.error'));
							toast(<Toast type="error" message={error} />);
						} else {
							toast(<Toast type="success" message={t('memepad.createTokenSuccess')} />);
							queryClient.invalidateQueries({
								queryKey: [QUERY_KEYS.GET_MY_CREATED_MEMES],
							});
							queryClient.invalidateQueries({
								queryKey: [QUERY_KEYS.GET_MY_MEME_BALANCE],
							});
							onClose();
							navigate(NAVIGATE_PATHS.TobiFun.Profile);
						}
					},
				});
				addPendingSolTransaction({ hash });
				toast(<Toast type="success" message={t('memepad.createTokenSubmitted')} />);
				return true;
			} catch (error) {
				console.error('Create meme error:', error);
				toast(<Toast type="error" message={t('memepad.createTokenError')} />);
				return false;
			}
		},
	});

	const { data: nativeBalance } = useTokenBalance({ tokenAddress: NATIVE_TOKEN_ADDRESS, chainId: solInfo?.chainId });
	const parseNativeBalance = BigInt(nativeBalance?.balance || 0n);

	const validateInput = () => {
		const errors: FormikErrors<typeof defaultValues> = {};
		['name', 'symbol', 'file', 'description'].forEach((key) => {
			if (!values[key]) errors[key] = 'This field is Required';
		});
		if (!estimateAmountSol) {
			errors.amount = 'This field is Required';
		}
		const { name, symbol, description, telegram, twitter, website } = values;
		if (name && name.length > 100) errors.name = 'Name is too long';
		if (symbol && symbol.length > 30) errors.symbol = 'Symbol is too long';
		if (description && description.length > 5000) errors.description = 'Description is too long';
		if (telegram && !telegram.startsWith('https')) errors.telegram = 'Url is invalid';
		if (twitter && !twitter.startsWith('https')) errors.twitter = 'Url is invalid';
		if (website && !website.startsWith('https')) errors.website = 'Url is invalid';

		if (Object.values(errors).length > 0) {
			setErrors(errors);
			return false;
		}

		return true;
	};

	const handleChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
		const files = e.target.files;
		if (files && files[0]) {
			const file = files[0];
			const maxSize = 5; // mb
			if (file.size > maxSize * 1024 * 1024) {
				setFieldError('file', `File size must be less than ${maxSize}MB`);
				return;
			}
			const fileReader = new FileReader();
			fileReader.onload = (e) => {
				const imageUrl = e.target?.result;
				if (typeof imageUrl === 'string') {
					setImageUrl(imageUrl);
					setFieldValue('file', file);
				}
			};
			fileReader.readAsDataURL(files[0]);
		}
	};

	const onSetAmount = (payload: AmountPayload) => {
		const { amount, amountUsd } = calcAmount(payload);
		setAmount(amount);
		setUsdAmount(amountUsd);
		setFieldValue('amount', '');
	};
	const onChangeAmountInput = (value: string) => {
		const token = buyViaNative ? sol : formatPumpTokenToTobiToken(values as any);
		onSetAmount(inputMode === InputMode.AMOUNT ? { token, amount: value } : { token, amountUsd: value });
	};

	const isTokenAmountMode = inputMode === InputMode.AMOUNT;
	const amountInfo = {
		amount: isTokenAmountMode ? amount : usdAmount,
		usdValue: isTokenAmountMode ? usdAmount : amount,
	};

	const onMax = () => {
		if (!parseNativeBalance) return;
		const maxAmount = parseNativeBalance - estimateFee;
		const amount = maxAmount < 0n ? 0n : maxAmount;
		if (!amount) return;
		onSetAmount({
			amount: ethers.formatUnits(amount.toString(), solInfo.decimals),
			token: sol,
			formatUsd: true,
		});
	};

	const { slippage } = useUserSettingsStore();
	const { t } = useTranslation();

	const openUpload = () => {
		document.getElementById('upload')?.click();
	};

	const displayMemeSymbol = values.symbol || 'token';
	const useTokenSymbol = buyViaNative ? solInfo?.symbol : displayMemeSymbol;

	const inputError = useValidateInput({
		amountInDebounced,
		totalPay,
		parseNativeBalance,
		native: solInfo,
		tokenMode,
	});

	const disabledCreate = inputError.hasError;

	const containerRef = useRef(null);
	const [keyboardOpen, setKeyboardOpen] = useState(false);
	const scrollToBottom = () => {
		const scrollable = containerRef.current;
		setTimeout(() => {
			scrollable?.scrollTo({
				top: scrollable.scrollHeight,
				behavior: 'smooth',
			});
		}, 300);
	};

	return (
		<PageLayout
			containerRef={containerRef}
			footer={
				<Flex px={4} py={3} bg={colors.white}>
					<RequireWallet style={{ width: '100%' }}>
						<CTAButton
							isDisabled={disabledCreate}
							colorScheme={'cyan'}
							height="48px"
							fontSize={'14px'}
							width={'100%'}
							flex={1}
							onClick={() => {
								if (disabledCreate || !validateInput()) return;
								onOpen();
							}}
						>
							{t('memepad.launchToken')}
						</CTAButton>
					</RequireWallet>
				</Flex>
			}
			header={<Header />}
		>
			<Flex direction={'column'} gap={3} pb={6}>
				<Flex align={'center'} gap={3}>
					<ActionBlock
						style={{ height: '80px', width: '80px', border: `1px dashed ${colors.gray[200]}` }}
						onClick={openUpload}
					>
						{!values.file ? (
							<FiImageIcon fill={colors.gray[300]} height={'24px'} width={'24px'} />
						) : (
							<Image src={imageUrl} height={'100%'} objectFit={'cover'} />
						)}
						<input
							type="file"
							accept=".jpg,.jpeg,.png,.gif"
							id="upload"
							hidden
							onClick={(e) => ((e.target as any).value = null)}
							onChange={handleChangeFile}
						/>
					</ActionBlock>
					<VStack spacing={'2px'} align={'flex-start'}>
						<ButtonSecondary
							fontSize={'12px'}
							size={'sm'}
							bg={colors.white}
							borderRadius={'8px'}
							onClick={openUpload}
							sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
						>
							<FiPlusIcon width={'16px'} height={'16px'} />
							<Text lineHeight={'16px'}>{values.file ? 'Change' : 'Upload Logo'}</Text>
						</ButtonSecondary>
						<Collapse in={!!errors.file}>
							<Text fontSize={'11px'} color={colors.red[200]}>
								{errors.file as string}
							</Text>
						</Collapse>
					</VStack>
				</Flex>
				{createMemeFields.map((e) => (
					<FormControl key={e.name}>
						<Text mb={2} fontWeight={'500'} fontSize={'.75rem'} textTransform={'capitalize'}>
							{e.label || e.name} {e.required === false ? '(Optional)' : ''}
						</Text>
						<CommonInput
							value={values[e.name]}
							onChange={(event) => setFieldValue(e.name, event.target.value)}
							type={e.name}
						/>
						<Collapse in={errors[e.name]}>
							<Text fontSize={'11px'} mt={'2px'} color={colors.red[200]}>
								{errors[e.name]}
							</Text>
						</Collapse>
					</FormControl>
				))}

				<Divider variant={'dashed'} />
				<Text fontSize={'14px'}>{t('memepad.initBuyDesc', { symbol: displayMemeSymbol })}</Text>
				<Flex direction={'column'} gap={'2px'}>
					<TokenAmountInput
						label={
							<Flex gap={1} align={'center'} onClick={toggleToken}>
								Amount in {useTokenSymbol} <FiSwapIcon width={14} height={14} />
							</Flex>
						}
						allowSwitchAmountMode={buyViaNative}
						showBalance={buyViaNative}
						token={sol}
						prefix={useTokenSymbol}
						value={amountInfo.amount?.toString() ?? ''}
						usdValue={buyViaNative ? amountInfo.usdValue : undefined}
						usdPrice={solInfo.priceUsd}
						onValueChange={({ value }, source) => {
							if (source?.source === 'prop') return;
							onChangeAmountInput(value);
						}}
						onMax={onMax}
						inputMode={inputMode}
						toggleInputMode={handleSwitch}
						onFocus={() => {
							if (!isMobile) return;
							setKeyboardOpen(true);
							scrollToBottom();
						}}
						onBlur={() => isMobile && setKeyboardOpen(false)}
					/>
					<Collapse in={!!errors.amount}>
						<Text fontSize={'11px'} color={colors.red[200]}>
							{errors.amount}
						</Text>
					</Collapse>
				</Flex>

				<Collapse in={!!inputError.messages.length}>
					{inputError.messages.map((e, i) =>
						e.errorType === SwapErrorType.GAS ? (
							<GasWarning chainId={solInfo.chainId} key={i} msg={e?.msg} />
						) : (
							<Warning status={e.type} msg={e.msg} key={i} />
						),
					)}
				</Collapse>

				{!buyViaNative ? (
					<InfoItem
						isFetching={isFetching}
						label={<>You Pay:</>}
						value={displayAmount({ amount: estimateAmountSol, token: solInfo })}
					/>
				) : (
					<InfoItem
						isFetching={isFetching}
						label={<>Receive:</>}
						value={
							<>
								≈ {formatUnits(estimateAmountOut, PUMP_TOKEN_DECIMALS)} {displayMemeSymbol}
							</>
						}
					/>
				)}
				<InfoItem
					isFetching={isFetching}
					label={<>Est. Fee:</>}
					value={<>≈ {displayAmount({ amount: estimateFee, token: solInfo })}</>}
				/>
				{keyboardOpen && <Box height={'100px'} />}
			</Flex>
			<ConfirmCreateMeme
				{...{
					isOpen,
					onOpen,
					onClose,
					onConfirm: submitForm,
					tokenInfo: values,
					logo: imageUrl,
					tokenIn: sol,
					estimateAmountSol,
					estimateAmountOut,
					estimateFee,
				}}
			/>
		</PageLayout>
	);
}
