import React from 'react';

interface IProps extends React.SVGAttributes<HTMLOrSVGElement> {}

export const FiGroupIconColor: React.FC<IProps> = ({ width = 25, height = 24, ...rest }) => {
	return (
		<svg width={width} height={height} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
			<g clipPath="url(#clip0_13946_31226)">
				<path
					d="M9.15666 10.2122C8.54963 10.912 8.18213 11.8238 8.18213 12.8203V17.7422H3.02588C2.63775 17.7422 2.32275 17.4272 2.32275 17.0391V13.5234C2.32275 11.197 4.2151 9.30469 6.5415 9.30469C7.5001 9.30469 8.41416 9.62438 9.15666 10.2122Z"
					fill="#FDBF00"
				/>
				<path
					d="M9.15666 10.2122C8.54963 10.912 8.18213 11.8238 8.18213 12.8203V17.7422H6.5415V9.30469C7.2076 9.30469 7.85213 9.45891 8.43244 9.75047C8.68744 9.87844 8.93025 10.0327 9.15666 10.2122Z"
					fill="#FE9022"
				/>
				<path
					d="M22.0102 13.5234V17.0391C22.0102 17.4272 21.6952 17.7422 21.3071 17.7422H16.1508V12.8203C16.1508 11.8238 15.7833 10.912 15.1768 10.2122C15.9188 9.62391 16.8305 9.30469 17.7914 9.30469C20.1179 9.30469 22.0102 11.197 22.0102 13.5234Z"
					fill="#FF641A"
				/>
				<path
					d="M22.0103 13.5234V17.0391C22.0103 17.4272 21.6953 17.7422 21.3071 17.7422H17.7915V9.30469C18.4707 9.30469 19.1124 9.46594 19.681 9.75234C21.061 10.4461 22.0103 11.8758 22.0103 13.5234Z"
					fill="#F03800"
				/>
				<path
					d="M12.1665 8.60156C9.84029 8.60156 7.94775 10.4941 7.94775 12.8203V18.4453C7.94775 18.8336 8.26257 19.1484 8.65088 19.1484H15.6821C16.0704 19.1484 16.3853 18.8336 16.3853 18.4453V12.8203C16.3853 10.4941 14.4927 8.60156 12.1665 8.60156Z"
					fill="#0097F0"
				/>
				<path
					d="M16.3853 12.8203V18.4453C16.3853 18.8334 16.0703 19.1484 15.6821 19.1484H12.1665V8.60156C12.8776 8.60156 13.5484 8.77828 14.1362 9.09047C15.4726 9.79875 16.3853 11.205 16.3853 12.8203Z"
					fill="#0052C2"
				/>
				<path
					d="M23.4634 17.3906C23.0751 17.3906 22.7603 17.7054 22.7603 18.0938V21.6562H19.1978C18.8094 21.6562 18.4946 21.9711 18.4946 22.3594C18.4946 22.7477 18.8094 23.0625 19.1978 23.0625H23.4634C23.8517 23.0625 24.1665 22.7477 24.1665 22.3594V18.0938C24.1665 17.7054 23.8517 17.3906 23.4634 17.3906Z"
					fill="#00B8F0"
				/>
				<path
					d="M5.13525 21.6562H1.57275V18.0938C1.57275 17.7054 1.25794 17.3906 0.869629 17.3906C0.481316 17.3906 0.166504 17.7054 0.166504 18.0938V22.3594C0.166504 22.7477 0.481316 23.0625 0.869629 23.0625H5.13525C5.52357 23.0625 5.83838 22.7477 5.83838 22.3594C5.83838 21.9711 5.52357 21.6562 5.13525 21.6562Z"
					fill="#5ECEF4"
				/>
				<path
					d="M5.13525 0.9375H0.869629C0.481316 0.9375 0.166504 1.25231 0.166504 1.64062V5.90625C0.166504 6.29456 0.481316 6.60938 0.869629 6.60938C1.25794 6.60938 1.57275 6.29456 1.57275 5.90625V2.34375H5.13525C5.52357 2.34375 5.83838 2.02894 5.83838 1.64062C5.83838 1.25231 5.52357 0.9375 5.13525 0.9375Z"
					fill="#5ECEF4"
				/>
				<path
					d="M23.4634 0.9375H19.1509C18.7626 0.9375 18.4478 1.25231 18.4478 1.64062C18.4478 2.02894 18.7626 2.34375 19.1509 2.34375H22.7603V5.90625C22.7603 6.29456 23.0751 6.60938 23.4634 6.60938C23.8517 6.60938 24.1665 6.29456 24.1665 5.90625V1.64062C24.1665 1.25231 23.8517 0.9375 23.4634 0.9375Z"
					fill="#00B8F0"
				/>
				<path
					d="M12.1665 10.0078C13.5904 10.0078 14.7446 8.85355 14.7446 7.42969C14.7446 6.00583 13.5904 4.85156 12.1665 4.85156C10.7426 4.85156 9.58838 6.00583 9.58838 7.42969C9.58838 8.85355 10.7426 10.0078 12.1665 10.0078Z"
					fill="#FECEC0"
				/>
				<path
					d="M14.7446 7.42969C14.7446 8.06156 14.5159 8.64187 14.1362 9.09047C13.6637 9.65109 12.9559 10.0078 12.1665 10.0078V4.85156C13.5882 4.85156 14.7446 6.00797 14.7446 7.42969Z"
					fill="#FEB99E"
				/>
				<path
					d="M17.7915 10.7109C19.0859 10.7109 20.1353 9.6616 20.1353 8.36719C20.1353 7.07277 19.0859 6.02344 17.7915 6.02344C16.4971 6.02344 15.4478 7.07277 15.4478 8.36719C15.4478 9.6616 16.4971 10.7109 17.7915 10.7109Z"
					fill="#FECEC0"
				/>
				<path
					d="M20.1353 8.36719C20.1353 8.88469 19.9665 9.36375 19.681 9.75234C19.254 10.3331 18.5659 10.7109 17.7915 10.7109V6.02344C19.0838 6.02344 20.1353 7.07484 20.1353 8.36719Z"
					fill="#FEB99E"
				/>
				<path
					d="M6.5415 10.7109C7.83592 10.7109 8.88525 9.6616 8.88525 8.36719C8.88525 7.07277 7.83592 6.02344 6.5415 6.02344C5.24709 6.02344 4.19775 7.07277 4.19775 8.36719C4.19775 9.6616 5.24709 10.7109 6.5415 10.7109Z"
					fill="#FECEC0"
				/>
				<path
					d="M8.88525 8.36719C8.88525 8.88375 8.71697 9.36234 8.43244 9.75047C8.00541 10.3327 7.31682 10.7109 6.5415 10.7109V6.02344C7.83385 6.02344 8.88525 7.07484 8.88525 8.36719Z"
					fill="#FEB99E"
				/>
			</g>
			<defs>
				<clipPath id="clip0_13946_31226">
					<rect width="24" height="24" fill="white" transform="translate(0.166504)" />
				</clipPath>
			</defs>
		</svg>
	);
};

export const FiGroupIcon: React.FC<IProps> = ({ width = 20, height = 20, ...rest }) => {
	return (
		<svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
			<path
				d="M10 10.625C11.3583 10.625 12.5583 10.95 13.5333 11.375C14.4333 11.775 15 12.675 15 13.65V15H5V13.6583C5 12.675 5.56667 11.775 6.46667 11.3833C7.44167 10.95 8.64167 10.625 10 10.625ZM3.33333 10.8333C4.25 10.8333 5 10.0833 5 9.16667C5 8.25 4.25 7.5 3.33333 7.5C2.41667 7.5 1.66667 8.25 1.66667 9.16667C1.66667 10.0833 2.41667 10.8333 3.33333 10.8333ZM4.275 11.75C3.96667 11.7 3.65833 11.6667 3.33333 11.6667C2.50833 11.6667 1.725 11.8417 1.01667 12.15C0.71476 12.279 0.457436 12.494 0.276682 12.7681C0.0959273 13.0422 -0.000286405 13.3633 6.40412e-07 13.6917V15H3.75V13.6583C3.75 12.9667 3.94167 12.3167 4.275 11.75ZM16.6667 10.8333C17.5833 10.8333 18.3333 10.0833 18.3333 9.16667C18.3333 8.25 17.5833 7.5 16.6667 7.5C15.75 7.5 15 8.25 15 9.16667C15 10.0833 15.75 10.8333 16.6667 10.8333ZM20 13.6917C20 13.0167 19.6 12.4167 18.9833 12.15C18.2526 11.8312 17.4639 11.6666 16.6667 11.6667C16.3417 11.6667 16.0333 11.7 15.725 11.75C16.0583 12.3167 16.25 12.9667 16.25 13.6583V15H20V13.6917ZM10 5C11.3833 5 12.5 6.11667 12.5 7.5C12.5 8.88333 11.3833 10 10 10C8.61667 10 7.5 8.88333 7.5 7.5C7.5 6.11667 8.61667 5 10 5Z"
				fill="white"
			/>
		</svg>
	);
};
