import { UIEventHandler, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Box } from '@chakra-ui/react';
import { ITokenSearch } from '@/app-cores/api/bff';
import { useDebounce } from '@/app-hooks/common';
import { formatUnits, toFixed, truncateToFixed } from '@/app-helpers/number';
import { ChainId, EVM_CHAINS, NATIVE_TOKEN_ADDRESS, solana } from '@/app-constants/chains';

import { NAVIGATE_PATHS } from '@/app-constants/router';

import { isShowGasWarning } from '@/app-hooks/swap';
import { calcInput, getLimitType, getOrderTokensLabel, useValidateInput } from '@/app-hooks/limit/helper';

import { compareChain, isNativeToken, isStableCoin } from '@/app-helpers/address';
import { InputMode } from '@/app-store/swap';
import { useTokenBalance } from '@/app-hooks/api/portfolio/usePortfolioBalance';

import Header, { SwapHeaderProps } from '@/app-views/swap/components/Header';

import { useAllowance } from '@/app-hooks/wallet';

import { useGetAllPriceSwap } from '@/app-hooks/swap';

import { useNavigate } from 'react-router';

import { getTokenInfo, getWrapNativeToken, isEvmChain } from '@/app-helpers/token';
import SwapAction from '@/app-views/swap/components/SwapAction';
import { useSearchParams } from 'react-router-dom';
import { calcAmount, tryParseAmount } from '@/app-hooks/swap/helper';
import LimitForm from '@/app-views/swap/components/LimitOrder/LimitForm';
import { DEFAULT_ORDER_MODE, OrderMode, useLimitStore } from '@/app-store/swap/limit';
import { calcOutput, calcRateInput } from '@/app-hooks/limit/helper';
import { LimitOrderService, useGetActiveMakingAmount, useGetLimitOrderConfig } from '@/app-hooks/limit';

import TradeLayout from '@/app-views/swap/components/TradeLayout';
import { SwapErrorType } from '@/app-hooks/swap/type';

import BuySellGroup from '@/app-views/swap/components/LimitOrder/BuySellGroup';
import { useFetchTokensForSwap } from '@/app-helpers/navigate';
import { useWalletStore } from '@/app-store';
import useWrapUnWrapToken from '@/app-hooks/wallet/useWrapUnWrapToken';
import { useSearchSingleToken } from '@/app-hooks/api/portfolio';
import { toast } from 'react-toastify';
import { Toast } from '@/app-components/common';
import { parseErrorMessage } from '@/app-helpers/error-handling';
import { useTranslation } from 'react-i18next';

const chainIds = EVM_CHAINS.map((e) => e.id).concat(ChainId.SOL);

const LimitOrder = () => {
	const [openApprove, setOpenApprove] = useState(false);

	const limitState = useLimitStore();
	const {
		setTokenIn,
		tokenIn,
		tokenOut,
		tokenInfoIn,
		tokenInfoOut,
		setTokenOut,
		amountIn: amount,
		amountOut,
		amountInUsd,
		amountOutUsd,
		inputMode,
		setInputMode,
		rate,
		setRate,
		setAmountIn,
		setAmountOut,
		orderMode,
		setOrderMode,
	} = limitState;

	const isSellMode = orderMode === OrderMode.SELL;
	const tokenAmountMode = inputMode === InputMode.AMOUNT;

	const navigate = useNavigate();

	const amountInDebounced = useDebounce(amount, 300);
	const amountOutDebounced = useDebounce(amountOut, 300);
	const parsedAmount = useMemo(() => {
		return tryParseAmount(amountInDebounced, tokenInfoIn?.decimals);
	}, [amountInDebounced, tokenInfoIn]);

	const parsedAmountOut = useMemo(() => {
		return tryParseAmount(amountOutDebounced, tokenInfoOut?.decimals);
	}, [amountOutDebounced, tokenInfoOut]);

	const containerRef = useRef(null);

	useEffect(() => {
		LimitOrderService.init();
	}, []);

	const { data: limitOrderConfig } = useGetLimitOrderConfig({ tokenIn: tokenInfoIn, tokenOut: tokenInfoOut });
	const routerAddress = limitOrderConfig?.contractAddress;

	const tokenToValidate = isSellMode ? tokenInfoIn : tokenInfoOut;
	const amountToValidate = isSellMode ? parsedAmount : parsedAmountOut;

	const { data: allowance, isFetching: isFetchingAllowance } = useAllowance({
		spenderContract: routerAddress,
		tokenContract: tokenToValidate?.address,
		chainId: tokenToValidate?.chainId,
	});

	const { usdPriceNative, usdPriceTokenIn, usdPriceTokenOut } = useGetAllPriceSwap({
		tokenIn: tokenInfoIn,
		tokenOut: tokenInfoOut,
	});

	const { data: nativeBalance } = useTokenBalance({
		tokenAddress: NATIVE_TOKEN_ADDRESS,
		chainId: tokenInfoIn?.chainId,
	});
	const { data: tokenInBalance } = useTokenBalance({
		tokenAddress: tokenInfoIn?.address,
		chainId: tokenInfoIn?.chainId,
	});
	const { data: tokenOutBalance } = useTokenBalance({
		tokenAddress: tokenInfoOut?.address,
		chainId: tokenInfoOut?.chainId,
	});

	const balanceNativeUsd = useMemo(() => {
		const { decimals, balance } = getTokenInfo(nativeBalance);
		return usdPriceNative * (balance ? +formatUnits(balance, decimals, { withFormat: false }) : 0);
	}, [usdPriceNative, nativeBalance]);

	type Params = {
		amountIn: string;
		amountOut: string;
		amountInUsd: string;
		amountOutUsd: string;
		rate: string;
	};
	const calcInputs = useCallback(
		(payload: Partial<Params>) => {
			if (!tokenIn || !tokenOut) return;

			const result: Params = {
				amountIn: amount,
				amountOut,
				amountInUsd,
				amountOutUsd,
				rate,
				...JSON.parse(JSON.stringify(payload)),
			};
			const payloadCalc = { ...result, tokenIn, tokenOut, inputMode };
			const hasAmountIn = payload.amountIn || payload.amountInUsd;
			const hasAmountOut = payload.amountOut || payload.amountOutUsd;

			const calcAmountInOrOut = (isCalcInput: boolean) => {
				const calcFn = isCalcInput ? calcInput : calcOutput;
				const { amountOut, amountOutUsd, amountIn, amountInUsd } = calcFn(payloadCalc);
				result.amountOut = amountOut;
				result.amountOutUsd = amountOutUsd;
				result.amountIn = amountIn;
				result.amountInUsd = amountInUsd;
			};

			const formatAmountIn = () => {
				const { amount, amountUsd } = calcAmount({
					formatUsd: true,
					token: tokenIn,
					amount: payload.amountIn,
					amountUsd: payload.amountInUsd,
				});
				result.amountIn = amount;
				result.amountInUsd = amountUsd;
			};

			const formatAmountOut = () => {
				const { amount, amountUsd } = calcAmount({
					formatUsd: true,
					token: tokenOut,
					amount: payload.amountOut,
					amountUsd: payload.amountOutUsd,
				});
				result.amountOut = amount;
				result.amountOutUsd = amountUsd;
			};

			if (payload.rate) {
				if (result.amountIn || result.amountInUsd) {
					calcAmountInOrOut(false);
				} else if (result.amountOut || result.amountOutUsd) {
					calcAmountInOrOut(true);
				}
			} else if (hasAmountIn) {
				if (result.rate) {
					calcAmountInOrOut(false);
				} else if (result.amountOut || result.amountOutUsd) {
					formatAmountIn();
					result.rate = calcRateInput(
						tokenAmountMode ? payloadCalc : { ...payloadCalc, amountIn: result.amountIn },
					);
				} else {
					formatAmountIn();
				}
			} else if (hasAmountOut) {
				if (result.rate) {
					calcAmountInOrOut(true);
				} else if (result.amountIn || result.amountInUsd) {
					formatAmountOut();
					result.rate = calcRateInput(
						tokenAmountMode ? payloadCalc : { ...payloadCalc, amountOut: result.amountOut },
					);
				} else {
					formatAmountOut();
				}
			}
			setAmountIn(result.amountIn, result.amountInUsd);
			setAmountOut(result.amountOut, result.amountOutUsd);
			setRate(result.rate);
		},
		[
			amount,
			amountOut,
			rate,
			amountInUsd,
			amountOutUsd,
			setAmountIn,
			setAmountOut,
			setRate,
			tokenIn,
			tokenOut,
			inputMode,
			tokenAmountMode,
		],
	);

	const onChangeAmount = useCallback(
		(amount: string) => {
			calcInputs(!tokenAmountMode ? { amountInUsd: amount } : { amountIn: amount });
		},
		[calcInputs, tokenAmountMode],
	);
	const onChangeAmountOut = useCallback(
		(amount: string) => {
			calcInputs(!tokenAmountMode ? { amountOutUsd: amount } : { amountOut: amount });
		},
		[calcInputs, tokenAmountMode],
	);
	const onChangeRate = useCallback(
		(rate: string) => {
			calcInputs({ rate });
		},
		[calcInputs],
	);

	const marketRate = usdPriceTokenIn / usdPriceTokenOut;
	const onClickMarket = () => {
		onChangeRate(toFixed(marketRate));
	};

	const emptyInput = [tokenIn, tokenOut, parsedAmount, parsedAmountOut].some((e) => !e);

	const toggleInputMode = () => {
		setInputMode(tokenAmountMode ? InputMode.USD : InputMode.AMOUNT);
	};

	const checkPairSupportUsdMode = useCallback(
		(tokenIn, tokenOut) => {
			if (!isStableCoin(tokenIn) && !isStableCoin(tokenOut)) {
				setInputMode(InputMode.AMOUNT);
			}
		},
		[setInputMode],
	);

	const filterChain = [tokenInfoIn, tokenInfoOut].filter((e) => e?.chainId)[0]?.chainId;
	const selectToken = useFetchTokensForSwap(filterChain);
	const onSelectTokenIn = useCallback(
		async (token: ITokenSearch) => {
			setTokenIn(token);
			onChangeAmount(amount);
			setOrderMode(DEFAULT_ORDER_MODE);
			let newTokenOut = tokenOut;
			if (token && !compareChain(getTokenInfo(token)?.chainId, tokenInfoOut?.chainId)) {
				const { tokenOut } = await selectToken({ tokenIn: token });
				setTokenOut(tokenOut);
				newTokenOut = tokenOut;
			}
			checkPairSupportUsdMode(token, newTokenOut);
		},
		[
			setTokenIn,
			tokenOut,
			tokenInfoOut,
			onChangeAmount,
			amount,
			setOrderMode,
			checkPairSupportUsdMode,
			selectToken,
			setTokenOut,
		],
	);

	const { isNonWallet } = useWalletStore();

	const onSelectTokenOut = useCallback(
		async (token: ITokenSearch) => {
			setTokenOut(token);
			let newTokenIn = tokenIn;
			if (token && !compareChain(tokenInfoIn?.chainId, getTokenInfo(token)?.chainId)) {
				const { tokenIn } = await selectToken({ tokenOut: token });
				setTokenIn(tokenIn);
				newTokenIn = tokenIn;
			}
			checkPairSupportUsdMode(newTokenIn, token);
		},
		[setTokenOut, tokenIn, tokenInfoIn, checkPairSupportUsdMode, selectToken, setTokenIn],
	);

	const onMaxBalance = (tokenBalance: ITokenSearch, gasNative: bigint) => {
		if (!tokenBalance) return;
		const { balance = 0, decimals, address } = getTokenInfo(tokenBalance);

		const skipFeeGasFee = !isNativeToken(address);
		const gasFee = (skipFeeGasFee ? 0n : gasNative) || 0n;

		let availBalance = BigInt(balance) - gasFee;
		availBalance = availBalance > 0n ? availBalance : 0n;
		const result = formatUnits(availBalance.toString(), decimals, { withFormat: false });
		return calcAmount({ token: tokenBalance, amount: result });
	};

	const onMaxBalanceIn = () => {
		const result = onMaxBalance(tokenInBalance, gasNative);
		result && onChangeAmount(tokenAmountMode ? result.amount : result.amountUsd);
	};
	const onMaxBalanceOut = () => {
		const result = onMaxBalance(tokenOutBalance, 0n);
		result && onChangeAmountOut(tokenAmountMode ? result.amount : result.amountUsd);
	};

	const parseNativeBalance = useMemo(
		() => (nativeBalance ? BigInt(nativeBalance?.balance) : undefined),
		[nativeBalance],
	);

	const parseTokenInBalance = useMemo(
		() => (tokenInBalance ? BigInt(tokenInBalance?.balance) : undefined),
		[tokenInBalance],
	);

	const parseTokenOutBalance = useMemo(
		() => (tokenOutBalance ? BigInt(tokenOutBalance?.balance) : undefined),
		[tokenOutBalance],
	);

	const balanceToValidate = isSellMode ? parseTokenInBalance : parseTokenOutBalance;

	const gasNative = BigInt(solana.minForGas);

	const showGasWarning = useMemo(
		() =>
			isShowGasWarning({
				compareUsd: false,
				parseNativeBalance,
				gasNative,
				tokenOut: isSellMode ? tokenOut : tokenIn,
				gasUsd: 0,
				balanceNativeUsd,
			}),
		[parseNativeBalance, gasNative, tokenIn, tokenOut, balanceNativeUsd, isSellMode],
	);

	const provider = getLimitType(tokenInfoIn, tokenInfoOut);

	const { data } = useGetActiveMakingAmount({
		tokenIn: tokenToValidate,
		balanceIn: balanceToValidate,
	});
	const remainAmount = data || 0n;

	const errorMsg = useValidateInput({
		tokenIn: tokenInfoIn,
		tokenOut: tokenInfoOut,
		parseTokenInBalance,
		parseTokenOutBalance,
		parsedAmountIn: parsedAmount,
		parsedAmountOut,
		amountInUsd,
		amountOutUsd,
		provider,
		showGasWarning,
		amountInDebounced,
		amountOutDebounced,
		marketRate,
		rate,
		orderMode,
		remainAmount,
	});

	const needApprove = useMemo(() => {
		try {
			if (
				errorMsg.hasError ||
				!balanceToValidate ||
				!amountToValidate ||
				isFetchingAllowance ||
				!isEvmChain(tokenToValidate?.chainId) ||
				isNativeToken(tokenToValidate?.address ?? '')
			)
				return false;
			return allowance < amountToValidate;
		} catch (error) {
			return false;
		}
	}, [allowance, amountToValidate, tokenToValidate, errorMsg, balanceToValidate, isFetchingAllowance]);

	const { mutateAsync: onWrapToken } = useWrapUnWrapToken();
	const showWrap = isEvmChain(tokenToValidate?.chainId) && isNativeToken(tokenToValidate?.address);
	const { data: wrapToken } = useSearchSingleToken({
		chainId: isEvmChain(tokenToValidate?.chainId) ? tokenToValidate?.chainId : '',
		address: getWrapNativeToken(tokenToValidate?.chainId)?.address ?? '',
	});

	const { t } = useTranslation();
	const [wrapping, setWrapping] = useState(false);
	const onClickSwap = async () => {
		if (!showWrap) {
			navigate(NAVIGATE_PATHS.Limit.ConfirmTransaction);
			return;
		}
		if (wrapping) return;
		try {
			setWrapping(true);
			const resp = await onWrapToken({
				tokenIn: tokenToValidate,
				tokenOut: getTokenInfo(wrapToken),
				amount: amountToValidate?.toString(),
				waitUtilCompleted: true,
			});
			toast(<Toast title="Wrap token success" type="success" message={t('limit.wrapSuccess')} />);
			isSellMode ? onSelectTokenIn(wrapToken) : onSelectTokenOut(wrapToken);
		} catch (error) {
			toast(<Toast title="Wrap token error" type="error" message={parseErrorMessage(error)} />);
		}
		setWrapping(false);
	};

	const disabledSwap =
		emptyInput || needApprove || errorMsg.hasError || isFetchingAllowance || showGasWarning || wrapping;

	const lackOfFund = errorMsg?.messages?.some(
		(e) => e.errorType === SwapErrorType.FUND || e.errorType === SwapErrorType.GAS,
	);
	const showBtnApprove = !lackOfFund && needApprove;

	const [showHeaderShadow, setShowHeader] = useState(false);
	const onPageScroll: UIEventHandler<HTMLDivElement> = (e) => {
		const container = e.target as HTMLDivElement;
		setShowHeader(container.scrollTop !== 0);
	};

	const showApproveModal = () => {
		setOpenApprove(true);
	};
	const hideApproveModal = () => {
		setOpenApprove(false);
	};

	const renderSwapAction = () => (
		<Box style={{ borderTop: '1px solid rgba(0, 0, 0, 0.08)' }}>
			<SwapAction
				{...{
					showApproveModal,
					showBtnApprove,
					openApprove,
					disabledSwap,
					tokenIn: tokenInfoIn,
					tokenOut: tokenInfoOut,
					onClickSwap,
					isNonWallet,
					label: isFetchingAllowance
						? 'Checking allowance...'
						: wrapping
						? `Wrapping ${tokenToValidate?.symbol}...`
						: showWrap
						? `Wrap ${tokenToValidate?.symbol}`
						: `${orderMode} ${
								tokenInfoIn && tokenInfoOut ? `${tokenInfoIn?.symbol}/${tokenInfoOut?.symbol}` : ''
						  }`,
					enabledBuyBtn: false,
				}}
			/>
		</Box>
	);

	const renderSwapForm = () => (
		<LimitForm
			{...{
				routerAddress,
				onSelectTokenIn,
				onSelectTokenOut,
				toggleInputMode,
				onMaxBalanceIn,
				onMaxBalanceOut,
				usdPriceTokenIn,
				usdPriceTokenOut,
				errorMsg,
				onChangeAmount: (values, source) => {
					if (source.source === 'prop') return;
					onChangeAmount(values.value);
				},
				onChangeAmountOut: (values, source) => {
					if (source.source === 'prop') return;
					onChangeAmountOut(values.value);
				},
				onChangeRate: (values, source) => {
					if (source.source === 'prop') return;
					onChangeRate(values.value);
				},
				onClickMarket,
				openApprove,
				hideApproveModal,
				parsedAmount,
				isNonWallet,
				marketRate,
				limitOrderConfig,
				containerProps: {
					style: { paddingBottom: '20px' },
				},
				orderMode,
				allowSwitchAmountMode: isStableCoin(tokenInfoIn) || isStableCoin(tokenInfoOut),
			}}
		/>
	);

	const headerProps: SwapHeaderProps = {
		showHeaderShadow,
		onSelectTokenIn,
		onSelectTokenOut,
		tokenIn,
		tokenOut,
		enableSwitchToken: false,
		inputPlaceholder: `Only SOL or EVM tokens available`,
		filterChainId: filterChain,
		chainIds,
		...getOrderTokensLabel(isSellMode),
	};

	return (
		<TradeLayout
			containerRef={containerRef}
			onPageScroll={onPageScroll}
			header={
				<Header {...headerProps} content={<BuySellGroup orderMode={orderMode} setOrderMode={setOrderMode} />} />
			}
			action={renderSwapAction()}
		>
			{renderSwapForm()}
		</TradeLayout>
	);
};
export default LimitOrder;
