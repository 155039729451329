import { useTranslation } from 'react-i18next';
import { fromNano } from '@ton/core';
import { useEffect, useMemo } from 'react';
import BigNumber from 'bignumber.js';
import { Box, Center, Skeleton, Text } from '@chakra-ui/react';
import { useEstimateTonGasFee } from '@/app-hooks/transactions/ton/useEstimateFeeSendTonAsset';
import { useContactStore, useSentTokenStore } from '@/app-store';
import { displayTonFee } from '@/app-helpers/display';
import { usePriceNativeToken, useTokenPriceSingleToken } from '@/app-hooks/api/portfolio/useTokenPrices';
import { NATIVE_TOKEN_ADDRESS } from '@/app-constants/chains';
import { useTokenBalance } from '@/app-hooks/api/portfolio/usePortfolioBalance';
import { isNativeToken } from '@/app-helpers/address';
import { ITokenSearch } from '@/app-cores/api/bff';
import { getTokenInfo, tokenHasBalance } from '@/app-helpers/token';
import { TextSmall } from '../../TextSmall';

export function getTokenBalanceBig(token: ITokenSearch) {
	if (!token) return BigNumber(0);
	const { decimals = 0, balance } = getTokenInfo(token);
	return BigNumber(balance).dividedBy(Math.pow(10, decimals));
}
export const EstimateTonGasFee = () => {
	const { t } = useTranslation();
	const { contact, address } = useContactStore();
	const { amount, messages, tokenInfo, setGasFee, setIsInsufficientGas } = useSentTokenStore();
	const { data: nativePriceUsd = 0 } = usePriceNativeToken({
		chainId: tokenInfo?.chainId,
	});
	const { data: nativeToken } = useTokenBalance({ tokenAddress: NATIVE_TOKEN_ADDRESS, chainId: tokenInfo?.chainId });

	const sentTo = contact?.wallets?.['ton-base']?.address || address;

	const { data: estimateFee, isPending } = useEstimateTonGasFee({
		amount: amount,
		to: sentTo,
		token: tokenInfo,
		message: messages,
	});

	useEffect(() => {
		if (!estimateFee) return;
		setGasFee(fromNano(estimateFee));
	}, [estimateFee, setGasFee]);

	const gasFee = displayTonFee({
		feePriceUsd: nativePriceUsd,
		gasFee: estimateFee,
	});
	const showGasWarning = useMemo(() => {
		try {
			if (!tokenInfo?.address || !estimateFee) return false;
			if (!tokenHasBalance(nativeToken)) return true;
			const nativeTokenAmount = getTokenBalanceBig(nativeToken);
			let nativeAmountSended = BigNumber(0);
			if (isNativeToken(tokenInfo.address)) {
				nativeAmountSended = BigNumber(amount);
			}
			const totalUsed = nativeAmountSended.plus(BigNumber(fromNano(estimateFee)));
			return totalUsed.gt(nativeTokenAmount);
		} catch (error) {
			return false;
		}
	}, [amount, estimateFee, nativeToken, tokenInfo.address]);

	useEffect(() => {
		setIsInsufficientGas(showGasWarning);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showGasWarning]);

	return (
		<Center justifyContent="space-between">
			<TextSmall m={0}>{t('cryptos.estimatedGasFee')}</TextSmall>
			{isPending ? (
				<Skeleton opacity="0.1" height={'21px'} width="160px" />
			) : (
				<>
					<Box fontWeight={'500'} className="animate__flash">
						<Text fontSize="14px">
							<Box as="span">{gasFee.feeUsd} </Box>
							<Box as="span">({gasFee.feeToken} TON )</Box>
						</Text>
					</Box>
				</>
			)}
		</Center>
	);
};
