import React from 'react';

interface IProps extends React.SVGAttributes<HTMLOrSVGElement> {}

export const FiUserIcon: React.FC<IProps> = ({ width = 21, height = 20, fill = 'black', ...rest }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 21 20" fill="none">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M1.72039 12.8869C2.50179 12.1055 3.5616 11.6665 4.66667 11.6665H11.3333C12.4384 11.6665 13.4982 12.1055 14.2796 12.8869C15.061 13.6683 15.5 14.7281 15.5 15.8332V17.4998C15.5 17.9601 15.1269 18.3332 14.6667 18.3332C14.2064 18.3332 13.8333 17.9601 13.8333 17.4998V15.8332C13.8333 15.1701 13.5699 14.5342 13.1011 14.0654C12.6323 13.5966 11.9964 13.3332 11.3333 13.3332H4.66667C4.00363 13.3332 3.36774 13.5966 2.8989 14.0654C2.43006 14.5342 2.16667 15.1701 2.16667 15.8332V17.4998C2.16667 17.9601 1.79357 18.3332 1.33333 18.3332C0.873096 18.3332 0.5 17.9601 0.5 17.4998V15.8332C0.5 14.7281 0.938987 13.6683 1.72039 12.8869Z"
				fill={fill}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M7.99992 3.33317C6.61921 3.33317 5.49992 4.45246 5.49992 5.83317C5.49992 7.21388 6.61921 8.33317 7.99992 8.33317C9.38063 8.33317 10.4999 7.21388 10.4999 5.83317C10.4999 4.45246 9.38063 3.33317 7.99992 3.33317ZM3.83325 5.83317C3.83325 3.53198 5.69873 1.6665 7.99992 1.6665C10.3011 1.6665 12.1666 3.53198 12.1666 5.83317C12.1666 8.13436 10.3011 9.99984 7.99992 9.99984C5.69873 9.99984 3.83325 8.13436 3.83325 5.83317Z"
				fill={fill}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M16.3599 12.4001C16.475 11.9545 16.9295 11.6865 17.3751 11.8016C18.269 12.0324 19.0609 12.5535 19.6265 13.2831C20.1922 14.0128 20.4994 14.9096 20.5001 15.8328L20.5001 17.5001C20.5001 17.9603 20.127 18.3334 19.6668 18.3334C19.2065 18.3334 18.8335 17.9603 18.8335 17.5001V15.8341C18.8335 15.834 18.8335 15.8341 18.8335 15.8341C18.833 15.2802 18.6486 14.742 18.3093 14.3043C17.9699 13.8665 17.4948 13.5538 16.9585 13.4153C16.5128 13.3002 16.2449 12.8457 16.3599 12.4001Z"
				fill={fill}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M13.026 2.40174C13.1402 1.95588 13.5941 1.68699 14.04 1.80114C14.9363 2.03062 15.7307 2.55188 16.2979 3.28272C16.8652 4.01356 17.1732 4.91243 17.1732 5.8376C17.1732 6.76278 16.8652 7.66165 16.2979 8.39249C15.7307 9.12333 14.9363 9.64458 14.04 9.87406C13.5941 9.98822 13.1402 9.71932 13.026 9.27347C12.9118 8.82761 13.1807 8.37364 13.6266 8.25948C14.1643 8.12179 14.641 7.80904 14.9814 7.37053C15.3217 6.93203 15.5065 6.39271 15.5065 5.8376C15.5065 5.2825 15.3217 4.74318 14.9814 4.30467C14.641 3.86617 14.1643 3.55342 13.6266 3.41573C13.1807 3.30157 12.9118 2.84759 13.026 2.40174Z"
				fill={fill}
			/>
		</svg>
	);
};

export const FiUserColorIcon: React.FC<IProps> = ({ width = 25, height = 24, ...rest }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 25 24" fill="none">
			<path
				d="M17.7759 5.78009C17.7759 7.36853 17.2063 8.74347 16.0826 9.86737C14.9589 10.9911 13.5837 11.5607 11.9953 11.5607H11.9926C10.4061 11.5602 9.03192 10.9905 7.9082 9.86737C6.7843 8.74347 6.21484 7.36853 6.21484 5.78009C6.21484 4.1922 6.7843 2.81708 7.9082 1.69336C9.03137 0.570007 10.4056 0.000549319 11.9926 0H11.9953C13.5832 0 14.9583 0.569641 16.0826 1.69336C17.2063 2.81708 17.7759 4.1922 17.7759 5.78009Z"
				fill="#FFBB85"
			/>
			<path
				d="M22.1588 19.8721C22.1588 21.1252 21.7601 22.1398 20.975 22.8871C20.199 23.6255 19.1727 23.9996 17.9248 23.9996H6.36865C5.12079 23.9996 4.09448 23.6255 3.31903 22.8871C2.53333 22.1393 2.13525 21.1249 2.13525 19.8721C2.13525 19.3901 2.15118 18.9133 2.18304 18.4548C2.21545 17.9861 2.28101 17.476 2.37769 16.9378C2.4751 16.3953 2.60034 15.8824 2.75031 15.4136C2.90594 14.9284 3.11652 14.4498 3.37708 13.9913C3.64771 13.5154 3.96558 13.1008 4.3219 12.7595C4.69543 12.4023 5.1521 12.1154 5.67999 11.9062C6.20605 11.6982 6.78925 11.5928 7.41327 11.5928C7.65808 11.5928 7.8952 11.6929 8.35223 11.9907C8.63824 12.1772 8.96783 12.3896 9.33167 12.6222C9.64587 12.8225 10.0714 13.0104 10.5975 13.1801C11.0598 13.3297 11.5291 13.4132 11.9927 13.4277C12.0438 13.4295 12.0949 13.4304 12.146 13.4304C12.6609 13.4304 13.1816 13.346 13.6951 13.1801C14.2211 13.0104 14.6472 12.8225 14.9612 12.6222C15.3289 12.3873 15.6585 12.175 15.9403 11.9912C16.3977 11.6929 16.6345 11.5928 16.8798 11.5928C17.5033 11.5928 18.0865 11.6982 18.6129 11.9062C19.1408 12.1154 19.5975 12.4028 19.9706 12.7595C20.3275 13.1008 20.6454 13.5154 20.9158 13.9913C21.1766 14.4501 21.3875 14.9284 21.5426 15.4131C21.6927 15.8824 21.8184 16.3953 21.9158 16.9382C22.0119 17.4769 22.0776 17.987 22.1099 18.4545C22.1423 18.9115 22.1582 19.3883 22.1588 19.8721Z"
				fill="#6AA9FF"
			/>
			<path
				d="M11.9954 11.5607H11.9927V0H11.9954C13.5833 0 14.9584 0.569641 16.0827 1.69336C17.2064 2.81708 17.7761 4.1922 17.7761 5.78009C17.7761 7.36853 17.2064 8.74347 16.0827 9.86737C14.959 10.9911 13.5839 11.5607 11.9954 11.5607Z"
				fill="#F5A86C"
			/>
			<path
				d="M22.1587 19.8721C22.1587 21.1252 21.7601 22.1398 20.9749 22.8871C20.1989 23.6255 19.1726 23.9996 17.9247 23.9996H11.9927V13.4277C12.0438 13.4295 12.0948 13.4304 12.1459 13.4304C12.6608 13.4304 13.1816 13.346 13.695 13.1801C14.2211 13.0104 14.6472 12.8225 14.9612 12.6222C15.3289 12.3873 15.6584 12.175 15.9402 11.9912C16.3976 11.6929 16.6344 11.5928 16.8798 11.5928C17.5032 11.5928 18.0864 11.6982 18.6129 11.9062C19.1407 12.1154 19.5974 12.4028 19.9706 12.7595C20.3275 13.1008 20.6453 13.5154 20.9158 13.9913C21.1765 14.4501 21.3875 14.9284 21.5425 15.4131C21.6927 15.8824 21.8183 16.3953 21.9157 16.9382C22.0118 17.4769 22.0776 17.987 22.1098 18.4545C22.1422 18.9115 22.1581 19.3883 22.1587 19.8721Z"
				fill="#2682FF"
			/>
		</svg>
	);
};
