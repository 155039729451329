import { ITokenSearch } from '@/app-cores/api/bff';
import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

const initializeData = {
	balances: [],
	hideTokens: [],
};
interface IContactStore {
	balances: ITokenSearch[];
	hideTokens: ITokenSearch[];
	setBalances: (value: ITokenSearch[]) => void;
	setHideTokens: (v: ITokenSearch[]) => void;
}
export const useBalanceStore = create<IContactStore>()(
	persist(
		(set) => ({
			...initializeData,
			setBalances: (balances) => set({ balances }),
			setHideTokens: (hideTokens) => set({ hideTokens }),
		}),
		{
			name: 'balance-storage',
			storage: createJSONStorage(() => localStorage),
		},
	),
);
