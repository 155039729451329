import { BlockHeader, InputSearch } from '@/app-components/common';
import ChainSelector from '@/app-views/wallet/components/Portfolio/ChainSelector';
import NoAsset from '@/app-views/wallet/components/Portfolio/NoAsset';
import { Box, Card, Flex, Spinner, Text } from '@chakra-ui/react';
import { CSSProperties, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePortfolioState } from '../../../wallet/components/Portfolio/state';
import { FiRefreshIcon } from '@/assets/icons/fi-refresh-icon';
import { BffServiceAPI, ITokenSearch } from '@/app-cores/api/bff';
import { ELEMENT_IDS, QUERY_KEYS } from '@/app-constants';
import { isMobile } from 'react-device-detect';
import { useDebounce, usePullToRefresh } from '@/app-hooks/common';
import { queryClient } from '@/app-cores/query-client';
import { compareChain, isNativeToken } from '@/app-helpers/address';
import { getTokenInfo, isTestnetChain } from '@/app-helpers/token';
import { FeatureConfig, useQueryFeatureConfig } from '@/app-hooks/api/configuration';
import { FiPortfolioIcon } from '@/assets/icons/fi-portfolio-icon';
import { TOUR_GUIDE_STEPS_TARGET } from '../../../wallet/components/TourGuide/stepConfigs';
import { copilotManager } from '@/app-features/copilot/helper';
import { usePortfolioBalanceByCategories } from '@/app-hooks/api/portfolio/usePortfolioBalance';
import ListTokens from '@/app-views/affiliate/containers/FeeEarn/ListTokens';
import { FilterTokens } from '@/app-views/affiliate/containers/FeeEarn/Filter';
import { SelectCustom } from '@/app-components/common/BaseSelect';
import { colors } from '@/app-theme/theme';
import { searchTokenFn } from '@/app-components/common/crypto-search';
import { navigateToTokenDetail } from '@/app-helpers/navigate';
import { ListCryptoItem } from '@/app-views/portfolio/components/ListCrypto';
import { formatCurrency } from '@/app-helpers/number';

const WrapListCryptoItem = ({
	data,
	index,
	style,
}: {
	data: (ITokenSearch & { totalTransactions: number; amount: string })[];
	index: number;
	style: CSSProperties;
}) => {
	const item = data[index];

	const handleSelectToken = useCallback((token) => {
		const { idTobi } = getTokenInfo(token);
		navigateToTokenDetail({ tobiId: idTobi });
	}, []);

	return (
		<ListCryptoItem
			onClick={handleSelectToken}
			style={style}
			data={item}
			priceRender={() => {
				return (
					<Text fontSize="xs" whiteSpace={'nowrap'} gap={'4px'} color={'gray.400'} fontWeight={'500'}>
						{item.totalTransactions} Transactions
					</Text>
				);
			}}
			amountRender={() => formatCurrency(item.amount)}
		/>
	);
};
export const MyEarning = ({
	data,
	isFetching,
	onRefresh,
}: {
	data: {
		tokens: ITokenSearch[];
		totalUsd: number;
	};
	isFetching: boolean;
	onRefresh;
}) => {
	const { t } = useTranslation();
	const [keyword, setKeyword] = useState('');
	const keywordDebounced = useDebounce(keyword, 500);

	const visibleBalances = useMemo(() => {
		return keywordDebounced ? data?.tokens?.filter((e) => searchTokenFn(e, keywordDebounced)) : data?.tokens;
	}, [keywordDebounced, data]);

	const emptyPortfolio = visibleBalances?.length === 0;

	return (
		<Box mt={8} id={ELEMENT_IDS.HOME_CONTAINER}>
			<BlockHeader
				leftContent={
					<Flex alignItems={'center'} gap={2} role="button">
						<Flex justify={'center'} align={'center'} gap={2}>
							<FiPortfolioIcon />
							{t('affiliate.myEarning')}
						</Flex>
					</Flex>
				}
				to={''}
			/>

			<Card padding={4} border={'1px'} borderRadius={'1rem'} borderColor={'#00000014'} gap={'20px'}>
				<Flex alignItems={'center'} gap={4}>
					<InputSearch
						value={keyword}
						onChange={(e) => setKeyword(e.target.value)}
						sx={{ border: 'none', background: colors.gray[100], borderRadius: '8px' }}
						placeholder={t('cryptos.searchPlaceholder')}
					/>
					<FilterTokens onRefreshTokens={onRefresh} />
				</Flex>
				<ListTokens
					balances={visibleBalances}
					isLoading={isFetching}
					emptyPortfolio={emptyPortfolio}
					itemRender={WrapListCryptoItem}
				/>
			</Card>
		</Box>
	);
};
