import { isEmpty } from 'lodash';
import { memo, useEffect, useRef, useState } from 'react';
import bs58 from 'bs58';
import { UserProfile, WalletType } from '@/app-cores/api';
import { useWalletStore } from '@/app-store';
import { MpcWallet } from '@/app-cores/mpc-wallet/wallet';
import { TelegramCore } from '@/app-cores/telegram';
import { useSyncUserWallet } from '@/app-hooks/api/mpc-wallet';
import { AuthClient } from '@/app-cores/auth';
import { compareAddress } from '@/app-helpers/address';
import { DATADOG_ACTIONS, dataDogAddAction } from '@/app-services/monitor/datadog';
import { BackgroundApp } from '@/app-components/layout/BackGround';
import { useMutationCreateWallet } from '@/app-hooks/mpc';
import { useOnEventCallback } from '@/app-hooks/common';
interface WalletCreationProps {
	userProfile: UserProfile;
}
export const WalletCreation: React.FC<WalletCreationProps> = memo(({ userProfile }) => {
	const { dklsInitializeStatus, setWalletStatus } = useWalletStore();
	const { mutateAsync: syncUserWallet } = useSyncUserWallet();
	const { createMpcWallet } = useMutationCreateWallet();

	const initializeRef = useRef(false);

	const createWallet = useOnEventCallback(async () => {
		try {
			await createMpcWallet();
		} catch (error) {
			setWalletStatus('CREATE_FAILED');
			console.error('Create wallet error', error);
		}
	});

	useEffect(() => {
		async function initWallet() {
			console.time('precomputed_wallet');
			setWalletStatus('INITIALIZING');
			const userTonAddress = userProfile.wallets?.find((w) => w.type === WalletType.TON)?.formatedAddress;
			const userEvmAddress = userProfile.wallets?.find((w) => w.type === WalletType.EVM)?.formatedAddress;
			const walletStatus = await MpcWallet.walletStatus(
				{
					evmWallet: userEvmAddress,
					tonWallet: userTonAddress,
				},
				userProfile?.backupType,
			);
			setWalletStatus(walletStatus);
			if (walletStatus === 'REQUIRE_CREATE') {
				return await createWallet();
			}
			MpcWallet.saveToCloudIfNeeded();
		}

		if (dklsInitializeStatus !== 'SUCCESS') return;
		if (isEmpty(userProfile) || initializeRef.current) return;
		initializeRef.current = true;
		initWallet();
	}, [dklsInitializeStatus, setWalletStatus, syncUserWallet, userProfile, createWallet]);

	return <BackgroundApp />;
});
