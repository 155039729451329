import { ChainId } from '@/app-constants/chains';
import { TxStatus } from '@/app-cores/api/activities';
import { TokenInfo } from '@/app-cores/api/bff';
import { tronWallet } from '@/app-cores/mpc-wallet/tron';
import { MpcWallet } from '@/app-cores/mpc-wallet/wallet';
import { compareChain, isNativeToken, isWrapNativeToken } from '@/app-helpers/address';
import { formatUsdAmountInput, truncateToFixed } from '@/app-helpers/number';
import {
	getNativeToken,
	getTokenInfo,
	isEclipseChain,
	isEvmChain,
	isSolanaChain,
	isSuiChain,
	isTonChain,
	isTronChain,
} from '@/app-helpers/token';
import { ONE_MINUTE } from '@/app-hooks/api/portfolio/constant';
import { AmountPayload, SwapService } from '@/app-hooks/swap';
import { ArgsGetRoute, PayloadExtractRoute, SwapProvider, SwapType } from '@/app-hooks/swap/type';
import { GenericRoute, SelectedRoute } from '@/app-store/swap';
import { toNano } from '@ton/core';
import { ethers, parseUnits } from 'ethers';

export function promiseWithTimeout<T>(promise: Promise<T>, timeout: number = 10_000): Promise<T> {
	const timeoutPromise = new Promise<T>((_, reject) => {
		setTimeout(() => {
			reject(new Error(`Timeout: Promise took longer than ${timeout} `));
		}, timeout);
	});
	return Promise.race([promise, timeoutPromise]);
}

export const tryParseAmount = (amount: string, decimals?: number) => {
	try {
		return parseUnits(amount, decimals);
	} catch (error) {}
};

export const filterParams = <T>(params: T) => {
	Object.keys(params).forEach((key) => {
		if (['', null, undefined].includes(params[key])) delete params[key];
	});
};

export const getMyWalletAddressByChain = (chainId: ChainId | string) => {
	const { evmAddress, solAddress, tonAddress, tronAddress, suiAddress } = MpcWallet.getWalletAddress();
	return isEvmChain(chainId)
		? evmAddress
		: isTonChain(chainId)
		? tonAddress
		: isSolanaChain(chainId) || isEclipseChain(chainId)
		? solAddress
		: isTronChain(chainId)
		? tronAddress
		: isSuiChain(chainId)
		? suiAddress
		: undefined;
};

const _calcAmountIn = ({ amount, amountUsd, token }: AmountPayload) => {
	try {
		const { priceUsd: tokenPrice, decimals } = getTokenInfo(token);
		if (amount && amountUsd) {
			return { amount: truncateToFixed(amount || '', decimals), amountUsd: String(amountUsd || '') };
		}
		if (amount) {
			return { amount: truncateToFixed(amount || '', decimals), amountUsd: String(+tokenPrice * +amount || 0) };
		}
		if (amountUsd && decimals) {
			const floatAmount = +amountUsd / +tokenPrice;
			return { amount: truncateToFixed(floatAmount, decimals), amountUsd: String(amountUsd || '') };
		}
		if (!token) {
			return { amount: String(amount || ''), amountUsd: String(amountUsd || '') };
		}
	} catch (error) {}
	return { amount: '', amountUsd: '' };
};

export const calcAmount = ({ formatUsd, ...payload }: AmountPayload) => {
	const { amount, amountUsd } = _calcAmountIn(payload);
	return { amount, amountUsd: formatUsd ? formatUsdAmountInput(amountUsd) : amountUsd };
};

export const getSwapType = (tokenIn: TokenInfo, tokenOut: TokenInfo) => {
	if (!tokenIn || !tokenOut) return SwapType.NOT_SUPPORT;

	const bothEvmChain = isEvmChain(tokenIn.chainId) && isEvmChain(tokenOut.chainId);

	// cross chain
	if (!compareChain(tokenIn.chainId, tokenOut.chainId)) {
		if (bothEvmChain) return SwapType.CROSSCHAIN_EVM;

		if (isEvmChain(tokenIn.chainId) && isSuiChain(tokenOut.chainId)) return SwapType.CROSSCHAIN_EVM_SUI;
		if (isSolanaChain(tokenIn.chainId) && isSuiChain(tokenOut.chainId)) return SwapType.CROSSCHAIN_SOL_SUI;
		if (isTonChain(tokenIn.chainId) && isSuiChain(tokenOut.chainId)) return SwapType.CROSSCHAIN_TON_SUI;
		if (isTronChain(tokenIn.chainId) && isSuiChain(tokenOut.chainId)) return SwapType.CROSSCHAIN_TRON_SUI;
		if (isSuiChain(tokenIn.chainId) && isEvmChain(tokenOut.chainId)) return SwapType.CROSSCHAIN_SUI_EVM;
		if (isSuiChain(tokenIn.chainId) && isSolanaChain(tokenOut.chainId)) return SwapType.CROSSCHAIN_SUI_SOL;
		if (isSuiChain(tokenIn.chainId) && isTonChain(tokenOut.chainId)) return SwapType.CROSSCHAIN_SUI_TON;
		if (isSuiChain(tokenIn.chainId) && isTronChain(tokenOut.chainId)) return SwapType.CROSSCHAIN_SUI_TRON;

		if (isTronChain(tokenIn.chainId) && isEvmChain(tokenOut.chainId)) return SwapType.CROSSCHAIN_TRON_EVM;
		if (isTronChain(tokenIn.chainId) && isSolanaChain(tokenOut.chainId)) return SwapType.CROSSCHAIN_TRON_SOL;
		if (isTronChain(tokenIn.chainId) && isTonChain(tokenOut.chainId)) return SwapType.CROSSCHAIN_TRON_TON;

		if (isEvmChain(tokenIn.chainId) && isTronChain(tokenOut.chainId)) return SwapType.CROSSCHAIN_EVM_TRON;
		if (isSolanaChain(tokenIn.chainId) && isTronChain(tokenOut.chainId)) return SwapType.CROSSCHAIN_SOL_TRON;
		if (isTonChain(tokenIn.chainId) && isTronChain(tokenOut.chainId)) return SwapType.CROSSCHAIN_TON_TRON;

		if (isEvmChain(tokenIn.chainId) && isTonChain(tokenOut.chainId)) return SwapType.CROSSCHAIN_EVM_TON;
		if (isEvmChain(tokenIn.chainId) && isSolanaChain(tokenOut.chainId)) return SwapType.CROSSCHAIN_EVM_SOL;

		if (isSolanaChain(tokenIn.chainId) && isEvmChain(tokenOut.chainId)) return SwapType.CROSSCHAIN_SOL_EVM;
		if (isSolanaChain(tokenIn.chainId) && isTonChain(tokenOut.chainId)) return SwapType.CROSSCHAIN_SOL_TON;

		if (isTonChain(tokenIn.chainId) && isEvmChain(tokenOut.chainId)) return SwapType.CROSSCHAIN_TON_EVM;
		if (isTonChain(tokenIn.chainId) && isSolanaChain(tokenOut.chainId)) return SwapType.CROSSCHAIN_TON_SOL;

		if (isEvmChain(tokenIn.chainId) && isEclipseChain(tokenOut.chainId)) return SwapType.CROSSCHAIN_EVM_ECLIPSE;
		if (isEclipseChain(tokenIn.chainId) && isEvmChain(tokenOut.chainId)) return SwapType.CROSSCHAIN_ECLIPSE_EVM;

		return SwapType.NOT_SUPPORT;
	}

	// same chain

	if (isTronChain(tokenIn.chainId)) return SwapType.SWAP_TRON;
	if (isTonChain(tokenIn.chainId)) return SwapType.SWAP_TON;
	if (isSolanaChain(tokenIn.chainId)) return SwapType.SWAP_SOL;
	if (isSuiChain(tokenIn.chainId)) return SwapType.SWAP_SUI;
	if (isEclipseChain(tokenIn.chainId)) return SwapType.SWAP_ECLIPSE;

	if (isNativeToken(tokenIn?.address) && isWrapNativeToken(tokenOut?.address, tokenOut?.chainId))
		return bothEvmChain ? SwapType.WRAP_EVM : SwapType.NOT_SUPPORT;

	if (isNativeToken(tokenOut?.address) && isWrapNativeToken(tokenIn?.address, tokenIn?.chainId))
		return bothEvmChain ? SwapType.UNWRAP_EVM : SwapType.NOT_SUPPORT;

	if (compareChain(tokenIn.chainId, ChainId.BERACHAIN_TESTNET)) return SwapType.SWAP_BERA;

	return SwapType.SWAP_EVM;
};

export const calcRateSwap = ({
	amountOut,
	amountIn,
	tokenIn,
	tokenOut,
}: {
	amountOut: string;
	amountIn: string;
	tokenOut: TokenInfo;
	tokenIn: TokenInfo;
}) => {
	let rate;
	try {
		rate =
			+ethers.formatUnits(amountOut, tokenOut?.decimals).toString() /
			+ethers.formatUnits(amountIn, tokenIn?.decimals).toString();
	} catch (error) {}
	return rate;
};

export const calcMinAmountOutFromSlippage = ({
	amountOut,
	tokenOut,
	slippage,
}: {
	slippage: number;
	amountOut: string;
	tokenOut: TokenInfo;
}) => {
	const minAmountFloat = +ethers.formatUnits(amountOut, tokenOut.decimals) * (1 - slippage / 100);
	const minOut = parseUnits(truncateToFixed(minAmountFloat, tokenOut.decimals), tokenOut.decimals);
	return minOut;
};

export const getMinAmount = (type: SwapProvider, isNative?: boolean): bigint | undefined => {
	switch (type) {
		case SwapProvider.STON:
			return toNano('0.3');

		case SwapProvider.DEDUST:
			return isNative ? toNano('0.15') : toNano('0.2');

		case SwapProvider.SUNSWAP:
			return parseUnits('5', getNativeToken(ChainId.TRON).decimals); // TRX
	}
};

// map third party status to our status
export const MAP_SWAP_STATUS = {
	DONE: TxStatus.Success,
	SUCCESS: TxStatus.Success,

	FAILED: TxStatus.Failed,
	failed: TxStatus.Failed,
	submitted_failed: TxStatus.Failed,

	STARTED: TxStatus.Pending,
	PENDING: TxStatus.Pending,
	ACTION_REQUIRED: TxStatus.Pending,
	CONFIRMING: TxStatus.Pending,

	NOT_STARTED: TxStatus.Waiting,
};

const calcProfitOfRoute = (data: PayloadExtractRoute) => {
	return BigInt(SwapService.extractRouteInfo(data)?.amountOut || '0');
};

export const formatListRoutesBestReturn = <T extends GenericRoute>(
	routes: SelectedRoute<T>[],
	forceSortAmount = false,
) => {
	routes ||= [];
	routes = routes.filter(Boolean);
	let maxReturn = 0n;
	let lowestFee = null;
	routes.forEach((route) => {
		const profit = calcProfitOfRoute({ route });
		const { gasNative } = SwapService.extractRouteInfo({ route });
		maxReturn = profit > maxReturn ? profit : maxReturn;
		lowestFee = !lowestFee || gasNative < lowestFee ? gasNative : lowestFee;
	});

	const result = routes.map((route) => {
		route.bestReturn = calcProfitOfRoute({ route }) === maxReturn;
		route.lowestFee = SwapService.extractRouteInfo({ route }).gasNative === lowestFee;
		return route;
	});

	const listDisabled = result.filter((e) => e.disabled);
	const listEnabled = result.filter((e) => !e.disabled);
	const listFast = result.filter(
		(e) => !e.disabled && (SwapService.extractRouteInfo({ route: e })?.duration || 0) * 1000 < 5 * ONE_MINUTE,
	);
	const listSlow = result.filter(
		(e) => !e.disabled && (SwapService.extractRouteInfo({ route: e })?.duration || 0) * 1000 >= 5 * ONE_MINUTE,
	);

	const sortFn = (x, y) => (calcProfitOfRoute({ route: x }) > calcProfitOfRoute({ route: y }) ? -1 : 1);

	return forceSortAmount
		? listEnabled.sort(sortFn).concat(listDisabled.sort(sortFn))
		: listFast.sort(sortFn).concat(listSlow.sort(sortFn)).concat(listDisabled.sort(sortFn));
};

export const combineRouteMultiProvider = async (
	promises: Promise<SelectedRoute>[],
	timeout = 10_000,
	filterFn?: (v: SelectedRoute[]) => SelectedRoute[],
	forceSortAmount = false,
): Promise<SelectedRoute<GenericRoute>> => {
	const data = await Promise.allSettled(
		promises.filter(Boolean).map((e) => promiseWithTimeout<SelectedRoute>(e, timeout)),
	);
	const mapData = data.map((e) => (e.status === 'fulfilled' ? e.value : null)).filter(Boolean);

	if (!mapData.length) throw new Error(data?.[0]?.status === 'rejected' ? data?.[0]?.reason : 'Empty route');

	if (mapData.length === 1) return mapData[0];
	let allRoutes: SelectedRoute[] = [];
	mapData.forEach((el) => {
		if (!el.allRoutes?.length) allRoutes.push(el);
		else allRoutes = allRoutes.concat(el.allRoutes);
	});
	if (filterFn) allRoutes = filterFn(allRoutes);
	allRoutes = formatListRoutesBestReturn(allRoutes, forceSortAmount);

	return { ...allRoutes[0], allRoutes };
};

export const formatStepsSwap = ({ steps, stepNum, isFailed }) => {
	steps.forEach((step) => {
		const hasChildren = step.steps?.length;
		const currentStep = hasChildren ? Math.floor(stepNum) : stepNum;
		if (currentStep > step.id) {
			step.status = TxStatus.Success;
			step.steps?.forEach((e) => (e.status = TxStatus.Success));
		} else if (currentStep === step.id) {
			// current step
			step.status = isFailed ? TxStatus.Failed : TxStatus.Pending;
			step.steps?.forEach((e) => {
				if (stepNum > e.id) e.status = TxStatus.Success;
				if (stepNum < e.id) e.status = TxStatus.Waiting;
				if (stepNum === e.id) e.status = isFailed ? TxStatus.Failed : TxStatus.Pending;
			});
		} else {
			step.status = TxStatus.Waiting;
			step.steps?.forEach((e) => (e.status = TxStatus.Waiting));
		}
	});
	return steps;
};

export const filterRouteSolTon = (allRoutes: SelectedRoute[]) => {
	const routeRetro = allRoutes.find((e) => e.provider === SwapProvider.RETROBRIDGE && !e.disabled);
	if (routeRetro) {
		return [routeRetro].concat(allRoutes.filter((e) => e.id !== routeRetro.id));
	}
	return allRoutes;
};
