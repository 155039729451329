import { ChainId } from '@/app-constants/chains';
import { TokenInfo } from '@/app-cores/api/bff';
import { SolWallet } from '@/app-cores/mpc-wallet/solana/SolWallet';
import { isNativeToken } from '@/app-helpers/address';
import { uniqueId } from '@/app-helpers/random';
import { getNativeToken } from '@/app-helpers/token';
import { displayMaxFee } from '@/app-hooks/swap';
import { BuildRouteDataKyber, GetRouteDataKyber, getExtractRouteKyberSwap } from '@/app-hooks/swap/kyberswap';
import {
	ArgsGetRoute,
	ExtractRouteInfo,
	SwapAbstract,
	SwapProvider,
	UpdateStatusFn,
	UsdRouteInfo,
} from '@/app-hooks/swap/type';
import { useTakeFeeSwap } from '@/app-hooks/swap/useTakeFeeSwap';
import { useSubmitSolTransaction } from '@/app-hooks/transactions/sol/useMutationSentSolAsset';
import { PumpFunSDK } from '@/app-services/pump.fun';
import { SelectedRoute } from '@/app-store/swap';
import { TransactionType } from '@/app-types';
import { AUTO_SLIPPAGE } from '@/app-views/swap/components/SlippageSetting';
import { useMutation } from '@tanstack/react-query';

export type RoutePumpFun = {
	fee: bigint;
	amountOut: string;
	amountIn: string;
	tokenIn: TokenInfo;
	tokenOut: TokenInfo;
};

const formatRoute = (routeData: RoutePumpFun, params: ArgsGetRoute): SelectedRoute =>
	routeData
		? {
				routerAddress: '',
				tokenIn: params.tokenIn,
				tokenOut: params.tokenOut,
				route: routeData,
				id: uniqueId(),
				provider: SwapProvider.PUMPFUN,
				timestamp: Date.now(),
				params,
		  }
		: undefined;

const getExtractRoute = (
	selectedRoute: SelectedRoute<RoutePumpFun>,
	{ usdPriceIn, usdPriceNative }: UsdRouteInfo,
): ExtractRouteInfo => {
	const routeSwap = selectedRoute?.route;
	const tokenIn = selectedRoute?.tokenIn;
	const tokenOut = selectedRoute?.tokenOut;

	const gasNative = routeSwap ? routeSwap?.fee : 0n;

	return {
		amountOut: routeSwap?.amountOut,
		amountIn: routeSwap?.amountIn,
		gasUsd: undefined,
		tokenIn,
		tokenOut,
		gasNative,
		gasDisplay: displayMaxFee({
			usdPriceNative,
			chainId: ChainId.SOL,
			gasNative,
		}),
		dappInfo: {
			logo: '/icons/brands/pumpfun.png',
			domain: SwapProvider.PUMPFUN,
		},
	};
};

class Pump extends SwapAbstract<RoutePumpFun> {
	provider = SwapProvider.PUMPFUN;
	public sdk: PumpFunSDK;
	extractRoute(params: SelectedRoute<RoutePumpFun>, prices: UsdRouteInfo): ExtractRouteInfo {
		return getExtractRoute(params, prices);
	}

	formatSlippage(slippage: string | number): number | string {
		return +slippage === AUTO_SLIPPAGE ? 500 : (+slippage * 100) | 0;
	}

	getPumpParams(paramsSwap: ArgsGetRoute) {
		const { tokenIn, amountIn, slippage, tokenOut } = paramsSwap;
		return {
			tokenIn,
			tokenOut,
			isBuy: isNativeToken(tokenIn?.address),
			amountIn,
			slippageBasisPoints: BigInt(this.formatSlippage(slippage)),
		};
	}

	async getRoute(paramsSwap: ArgsGetRoute) {
		const { tokenIn, amountIn, slippage, tokenOut } = paramsSwap;
		if (!isNativeToken(tokenIn?.address) && !isNativeToken(tokenOut?.address)) {
			return;
		}
		this.sdk = await PumpFunSDK.create();
		const { amountOut, fee } = await this.sdk.estimateSwap(this.getPumpParams(paramsSwap));
		const route: RoutePumpFun = {
			amountOut,
			fee,
			amountIn,
			tokenIn,
			tokenOut,
		};
		return formatRoute(route, paramsSwap);
	}
}

export const PumpFunSwap = new Pump();

export const useExecuteRoutePumpFun = () => {
	const takeFee = useTakeFeeSwap();
	const { sendTransaction: submitSolTxs } = useSubmitSolTransaction();
	const response = useMutation({
		mutationKey: ['exe-route-pump-fun'],

		mutationFn: async ({
			onUpdateStatus,
			route: routeData,
		}: {
			route: SelectedRoute<RoutePumpFun>;
			onUpdateStatus: UpdateStatusFn;
		}) => {
			const tx = await PumpFunSwap.sdk.swap(PumpFunSwap.getPumpParams(routeData.params));
			const hash = await submitSolTxs({
				data: tx,
				metadata: routeData,
				transactionType: TransactionType.Swap,
				callback: onUpdateStatus,
			});
			return { hash };
		},
		onSuccess: ({ hash }, { route }) => {
			takeFee({ hash, route });
		},
	});
	return response;
};
