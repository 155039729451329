import { useQuery } from '@tanstack/react-query';
import { TonAPIService } from '../services/tonAPI';

export const useEventsEmulate = (params, id, enable) => {
	const response = useQuery({
		queryKey: ['events-emulate', id, params],
		queryFn: async () => {
			return await TonAPIService.eventsEmulate(params);
		},
		enabled: !!params && enable,
	});
	return response;
};
