import React from 'react';

interface IProps extends React.SVGAttributes<HTMLOrSVGElement> {}

export const FiFilterIcon: React.FC<IProps> = ({ width = 20, height = 20, ...rest }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20" fill="none" {...rest}>
			<g id="react-icons/md/MdFilterList">
				<path
					id="Vector"
					d="M8.33333 15H11.6667V13.3333H8.33333V15ZM2.5 5V6.66667H17.5V5H2.5ZM5 10.8333H15V9.16667H5V10.8333Z"
					fill="black"
				/>
			</g>
		</svg>
	);
};
