import { ONE_DAY, ONE_HOUR } from '@/app-hooks/api/portfolio/constant';

// 1 day vs 2 days
const padS = (n, unit) => {
	return `${n} ${unit}${n > 1 ? 's' : ''}`;
};

// ex: 60 => 1 minute
export const formatTimeDuration = (seconds: number) => {
	if (seconds < 60) return `1 min`;

	const min = Math.ceil(seconds / 60);
	if (min < 60) return padS(min, 'min');

	const hour = Math.ceil((seconds * 1000) / ONE_HOUR);
	if (hour < 24) return padS(hour, 'hour');

	const day = Math.ceil((seconds * 1000) / ONE_DAY);
	if (day < 7) return padS(day, 'day');

	const week = Math.ceil((seconds * 1000) / (ONE_DAY * 7));
	if (week < 4) return padS(week, 'week');

	const month = Math.ceil((seconds * 1000) / (ONE_DAY * 30));
	return padS(month, 'month');
};

// 123232323232 => 1 mins ago
export const formatTimeRecent = (timestamp: number, postfix = '') => {
	const delta = (Date.now() - timestamp) / 1000;
	if (delta < 60) return `a few secs ${postfix}`;
	return `${formatTimeDuration(delta)} ${postfix}`;
};
