import { axiosTonCenterService, axiosTonCenterServiceAPI, axiosTonXService } from '@/app-cores/api/axios';
import { GetTransactionPayload, TonEstimateFee, TonEstimateFeeRes, TonGetAddressState, TonTransaction } from './type';

class TonCenterService {
	async estimateFee(payload: TonEstimateFee): Promise<{ data: TonEstimateFeeRes }> {
		const rqPayload = {
			id: 1,
			jsonrpc: '2.0',
			method: 'estimateFee',
			params: payload,
		};
		try {
			const response = await axiosTonXService.post('/', rqPayload);
			return response.data;
		} catch (error) {
			const response = await axiosTonCenterService.post('/', rqPayload);
			return response.data;
		}
	}
	async getAddressState(address: string): Promise<TonGetAddressState> {
		const rqPayload = {
			id: 1,
			jsonrpc: '2.0',
			method: 'getAddressState',
			params: {
				address: address,
			},
		};
		try {
			const response = await axiosTonXService.post('/', rqPayload);
			return response.data;
		} catch (error) {
			const response = await axiosTonCenterService.post('/', rqPayload);
			return response.data;
		}
	}

	async getTransactions({ address, archival = false, limit = 10, ...rest }: GetTransactionPayload) {
		const response = await axiosTonCenterServiceAPI.get<{
			ok: boolean;
			result: TonTransaction[];
		}>('/getTransactions', {
			params: {
				address,
				archival,
				limit,
				...rest,
			},
		});

		return response.data.result;
	}
}

export type ITonCenterServiceAPI = typeof TonCenterService;

export const TonCenterServiceAPI = new TonCenterService();
