import { TransactionType } from '@/app-types';
import { TransactionResponse } from 'ethers';
import { useMemo } from 'react';
import { create } from 'zustand';
import { GetActivityParams, GetWalletActivityParams, IActivity } from '../../../../app-cores/api/activities';
import { useQueyWalletActivities } from '../../../../app-hooks/api/activities';
import useChainList from '@/app-hooks/wallet/useChainList';
import { compareChain } from '@/app-helpers/address';
import { isTestnetChain } from '@/app-helpers/token';

export interface IActivitiesStore {
	currentTx: TransactionResponse | undefined;
	currentTxType: TransactionType | undefined;
	cancelActivity: (activity?: Partial<TransactionResponse>, type?: TransactionType) => void;
}

export const useActivitiesState = create<IActivitiesStore>((set) => ({
	currentTx: undefined,
	currentTxType: undefined,
	cancelActivity: (currentTx?: TransactionResponse, currentTxType?: TransactionType) => {
		return set(() => ({ currentTx, currentTxType }));
	},
}));

export const useActivitiesV2 = (enabled: boolean, payload: GetWalletActivityParams = {}) => {
	const { isFetching, refetch, data, error } = useQueyWalletActivities(payload, { enabled });

	return {
		completeActivities: data ?? [],
		isFetching: isFetching && !data,
		refetch,
		error: error,
	};
};
