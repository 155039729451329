import { Box } from '@chakra-ui/react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InputSearch } from '@/app-components/common';
import { useLocation, useNavigate } from 'react-router';
import { CryptoSearchDrawer } from '@/app-components/common/crypto-search';
import { getTokenInfo } from '@/app-helpers/token';
import { navigateToTokenDetail } from '@/app-helpers/navigate';

export type SearchProps = { children?: React.ReactNode };

export const GlobalSearch = (props?: SearchProps) => {
	const ref = useRef<HTMLInputElement>(null);
	const { t } = useTranslation();
	const navigate = useNavigate();
	const location = useLocation();
	const [searchState, setSearchState] = useState(false);

	useEffect(() => {
		if (location.state && 'search' in location.state) {
			setSearchState(true);
		} else {
			setSearchState(false);
		}
	}, [location.state]);

	const openSearch = useCallback(() => {
		const search = new URLSearchParams(location.search);
		search.delete('page');
		search.delete('action');
		navigate(
			{
				...location,
				search: search.toString(),
			},
			{
				state: {
					search: '',
				},
			},
		);
	}, [location, navigate]);

	const closeSearch = useCallback(() => {
		const search = new URLSearchParams(location.search);
		navigate(
			{
				...location,
				search: search.toString(),
			},
			{
				state: undefined,
			},
		);
	}, [location, navigate]);

	const handleSelectToken = useCallback((token) => {
		const { idTobi } = getTokenInfo(token);
		navigateToTokenDetail({ tobiId: idTobi });
	}, []);

	return (
		<Box flex={1}>
			<InputSearch
				onClick={() => {
					openSearch();
					ref.current?.focus();
				}}
				ref={ref}
				placeholder={t('search')}
				background="white"
				border="none"
				height={12}
			/>
			<CryptoSearchDrawer
				syncKeyWordToUrl
				isOpen={searchState}
				onClose={closeSearch}
				onSelectToken={handleSelectToken}
				showHomeIcon
			>
				{props?.children}
			</CryptoSearchDrawer>
		</Box>
	);
};
