import { PublicKey, Connection, ConnectionConfig } from '@solana/web3.js';
import { MpcWallet } from '../wallet';
import { eclipse } from '@/app-constants/chains';

export class EclipseWallet {
	static async init(network: 'testnet' | 'mainnet', commitmentOrConfig: ConnectionConfig) {
		const fromPubKey = new PublicKey(MpcWallet.getSolanaWalletAddress());
		const rpcUrl =
			network === 'mainnet' ? eclipse.rpcUrls?.default?.http : 'https://testnet.dev2.eclipsenetwork.xyz';
		const wssUrl = network === 'mainnet' ? eclipse.rpcUrls.default.wss : 'wss://testnet.dev2.eclipsenetwork.xyz';

		const connection = new Connection(rpcUrl, {
			...commitmentOrConfig,
			wsEndpoint: wssUrl,
		});
		const blockHash = (await connection.getLatestBlockhash('finalized')).blockhash;
		return { fromPubKey, connection, blockHash };
	}
}
