import { useQuery } from '@tanstack/react-query';
import { suiWallet } from '@/app-cores/mpc-wallet/sui';
import { ONE_MINUTE } from '@/app-hooks/api/portfolio/constant';
import { CoinStruct } from '@mysten/sui/client';
import { isNativeToken } from '@/app-helpers/address';
import { SUI_TYPE_ARG } from '@mysten/sui/utils';

export const fetchSuiCoins = async (tokenAddress: string) => {
	const address = isNativeToken(tokenAddress) ? SUI_TYPE_ARG : tokenAddress;
	let allCoins: CoinStruct[] = [];
	let nextCursor = null;
	let hasNextPage = true;

	while (hasNextPage) {
		const response = await suiWallet.client.getCoins({
			owner: suiWallet.getAddress(),
			coinType: address,
			limit: 100,
			cursor: nextCursor,
		});
		allCoins = [...allCoins, ...response.data];
		nextCursor = response.nextCursor;
		hasNextPage = response.hasNextPage;
	}
	return allCoins;
};

export const useGetCoins = (tokenAddress: string) => {
	const response = useQuery({
		queryKey: ['use-get-sui-coins', tokenAddress],
		queryFn: async () => {
			return fetchSuiCoins(tokenAddress);
		},
		enabled: !!tokenAddress,
		staleTime: ONE_MINUTE * 2,
	});

	return response;
};
