import { Container, Flex } from '@chakra-ui/react';
import { TotalFee } from './containers/TotalFee';
import { Transactions } from './containers/Transaction';
import { GroupsContent } from './containers/Group/GroupsContent';
import PageNavigation from '@/app-components/layout/PageWrapper/PageWrapper';
import { UserContent } from '@/app-views/affiliate/containers/Group/UserContent';
import { ELEMENT_IDS } from '@/app-constants';

const Group = () => {
	return (
		<PageNavigation>
			<Container
				id={ELEMENT_IDS.HOME_CONTAINER}
				className="overflow-y-auto hide-scrollbar"
				sx={{ gap: 6, display: 'flex', flexDirection: 'column' }}
				pt={4}
				pb={20}
			>
				<GroupsContent />
			</Container>
		</PageNavigation>
	);
};
export default Group;
