import { useNavigate } from 'react-router';
import { ConfirmSendTransaction, Source, Toast } from '@/app-components/common';
import { toast } from 'react-toastify';
import { NAVIGATE_PATHS } from '@/app-constants/router';
import { displaySolFee } from '@/app-helpers/display';
import { usePriceNativeToken } from '@/app-hooks/api/portfolio/useTokenPrices';
import { useContactStore, useSentTokenStore } from '@/app-store';
import { TransactionType } from '@/app-types';
import { DATADOG_ERROR_TAGS, dataDogAddError } from '@/app-services/monitor/datadog';
import { useEstimateEclipseGasFee } from '@/app-hooks/transactions/eclipse/useEstimateFeeSendEclipseAsset';
import { useSentEclipseToken } from '@/app-hooks/transactions/eclipse/useMutationSentEclipseAsset';

interface ConfirmSendSolAssetProps {
	source?: Source;
}

export const ConfirmSendEclipseAsset: React.FC<ConfirmSendSolAssetProps> = ({ source }) => {
	const navigate = useNavigate();
	const { isPending, sendTransaction } = useSentEclipseToken();
	const { contact, address } = useContactStore();
	const sentTo = contact?.wallets?.['solana']?.address || address;
	const { amount, messages, tokenInfo } = useSentTokenStore();
	const { data: estimateFee, isPending: isPendingGetGasFee } = useEstimateEclipseGasFee({
		amount: amount,
		to: sentTo,
		token: tokenInfo,
		message: messages,
		autoDeductFee: true,
	});
	const { data: nativePriceUsd = 0 } = usePriceNativeToken({
		chainId: tokenInfo?.chainId,
	});
	const gasFeeDisplay = displaySolFee({
		feePriceUsd: nativePriceUsd,
		gasFee: BigInt(estimateFee || 0),
	});

	const handleTransfer = async () => {
		try {
			const tx = await sendTransaction({
				amount: amount,
				token: tokenInfo,
				to: sentTo,
				message: messages,
				gasFee: BigInt(estimateFee || 0),
				autoDeductFee: true,
			});
			navigate(NAVIGATE_PATHS.transactionResult(TransactionType.Send, tokenInfo.chainId, tx));
		} catch (error) {
			toast(<Toast type="error" title="Error" message={error?.message} />);
			console.error('send eclipse asset error', error);
			dataDogAddError(error, {
				tags: {
					name: DATADOG_ERROR_TAGS.SEND,
					function: 'transferEclipseAsset',
				},
			});
		}
	};
	return (
		<ConfirmSendTransaction
			feeToken={gasFeeDisplay.feeToken}
			feeUsd={gasFeeDisplay.feeUsd}
			isLoading={isPending}
			onConfirm={handleTransfer}
			isFetchingGasFee={isPendingGetGasFee}
			source={source}
		/>
	);
};
