import { ChevronRightIcon } from '@/assets/images/svg';
import { Box, BoxProps, Button, ButtonProps, Flex, SystemStyleObject, Text } from '@chakra-ui/react';
import { CSSProperties, useCallback, useState } from 'react';

// work both cursor or number paging
export const usePagination = <T,>({
	page,
	setPage,
	//
	data,
	setCursor,
	cursor,
}: {
	page: number; // start at 1
	setPage: (v: number) => void;
	//
	data?: Array<T>;
	setCursor?: (v: T) => void;
	cursor?: T;
}) => {
	const [prevCursor, setPrevCursor] = useState<T>();

	const onNext = () => {
		setPage(page + 1);
		if (setPrevCursor && setCursor && data) {
			setPrevCursor(cursor);
			setCursor(data[data.length - 1]);
		}
	};
	const onBack = () => {
		setPage(page - 1);
		setCursor?.(prevCursor);
	};

	const reset = useCallback(() => {
		setPage(1);
		if (setPrevCursor && setCursor) {
			setPrevCursor(undefined);
			setCursor(undefined);
		}
	}, [setCursor, setPage]);

	return { onNext, onBack, reset };
};

// pagination with back next button
export default function CursorPagination({
	onBack,
	onNext,
	totalCount,
	currentPage, // start from 1
	pageSize,
	style = {},
	showPageNumber = true,
}: {
	onNext: () => void;
	onBack: () => void;
	totalCount: number;
	currentPage: number;
	pageSize: number;
	style?: SystemStyleObject;
	showPageNumber?: boolean;
}) {
	if (totalCount <= pageSize) {
		return null;
	}

	const disableBack = currentPage === 1;
	const totalPage = Math.ceil(totalCount / pageSize);
	const disableNext = currentPage >= totalPage;

	const props: ButtonProps = {
		size: 'sm',
		borderRadius: '100%',
		width: '32px',
		height: '32px',
		display: 'flex',
		justifyContent: 'center',
	};
	return (
		<Flex sx={style} align={'center'} justify={'center'} gap={'12px'}>
			<Button {...props} onClick={onBack} isDisabled={disableBack}>
				<ChevronRightIcon style={{ transform: 'scaleX(-1)', minWidth: '18px' }} />
			</Button>
			{showPageNumber && <Text fontSize={'12px'}>{`${totalPage ? currentPage : '-'}/${totalPage || '-'}`}</Text>}
			<Button {...props} onClick={onNext} isDisabled={disableNext}>
				<ChevronRightIcon style={{ minWidth: '18px' }} />
			</Button>
		</Flex>
	);
}
