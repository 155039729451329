import { useRef, useState } from 'react';
import { Avatar, Box, Button, Center, Divider, Flex, Skeleton, Spinner, Text, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import TokenLogo from '@/app-components/common/Avatar/TokenLogo';

import NoData from '@/app-components/common/NoData';

import { BASE_BORDER_COLOR, colors } from '@/app-theme/theme';
import dayjs from 'dayjs';
import { AffiliateGroup, AffiliateUser } from '@/app-services/fee';
import { useCalculateAffiliateTokenInfo } from '@/app-hooks/api/user/affiliate';
import { formatUsd } from '@/app-helpers/number';
import { InfiniteScroll } from '@/app-components/common/InfinitiScroll';
import { ELEMENT_IDS } from '@/app-constants';
import { FiUserColorIcon, FiUserIcon } from '@/assets/icons/fi-user-icon';
import { FiGroupIcon, FiGroupIconColor } from '@/assets/icons/fi-group-icon';

export const GroupInfo = ({
	logo,
	name,
	isGroup,
	totalMember,
	dateJoin,
	telegramId,
	onClick,
}: {
	logo: string;
	name;
	isGroup: boolean;
	totalMember?: number;
	dateJoin?: string;
	telegramId;
	onClick?: () => void;
}) => {
	const { t } = useTranslation();
	return (
		<Flex w="100%" justify={'space-between'} onClick={onClick}>
			<Flex gap={3}>
				<Avatar
					width={'40px'}
					height={'40px'}
					src={logo}
					bg={'#0B373D'}
					icon={isGroup ? <FiGroupIcon fill={colors.white} /> : <FiUserIcon fill={colors.white} />}
				/>
				<VStack spacing={0} justify={telegramId ? 'flex-start' : 'center'} align={'flex-start'}>
					<Text fontSize="12px" fontWeight={600}>
						{name}
					</Text>
					{telegramId && <Text fontSize="12px">@{telegramId}</Text>}
				</VStack>
			</Flex>

			<VStack spacing={0} alignItems={'flex-end'}>
				{!isGroup
					? dateJoin && (
							<>
								<Text fontSize={'10px'}>{t('referral.joinedOn')}</Text>
								<Text fontSize={'14px'} whiteSpace={'nowrap'}>
									{dateJoin ? dayjs(dateJoin).format('MMM DD, YYYY') : ''}
								</Text>
							</>
					  )
					: totalMember && (
							<>
								<Text fontSize="12px">Members</Text>
								<Text fontSize="14px" fontWeight={500}>
									{totalMember}
								</Text>
							</>
					  )}
			</VStack>
		</Flex>
	);
};

const GroupItem = ({
	data,
	isGroup,
	compact,
}: {
	data: AffiliateGroup | AffiliateUser;
	isGroup: boolean;
	compact: boolean;
}) => {
	const { t } = useTranslation();
	const { title, photoUrl, totalMembers, telegramId, totalTokensEarned } = data as AffiliateGroup;
	const { name, dateJoined } = data as AffiliateUser;
	const { data: tokenInfo } = useCalculateAffiliateTokenInfo(totalTokensEarned ?? []);

	return (
		<Box
			backgroundColor="gray.100"
			display="flex"
			justifyContent="space-between"
			alignItems="center"
			padding={3}
			borderRadius="8px"
			w={'100%'}
			mb={compact ? undefined : 3}
			flexDirection={'column'}
			gap={4}
			cursor={'pointer'}
			position={'relative'}
		>
			<GroupInfo
				logo={photoUrl}
				name={title || name}
				isGroup={isGroup}
				totalMember={totalMembers}
				dateJoin={dateJoined}
				telegramId={isGroup ? null : telegramId}
			/>

			<Divider borderStyle={'dotted'} borderColor={colors.gray[300]} />

			<Flex width={'100%'} justifyContent={'space-between'}>
				<VStack spacing={1} fontSize={'10px'} flex={1}>
					<Text>{t('affiliate.feeEarned')}</Text>
					<Text fontWeight={600} fontSize={'12px'}>
						{formatUsd(tokenInfo?.totalUsd ?? 0)}
					</Text>
				</VStack>
				<Divider orientation="vertical" h={'40px'} borderStyle={'dotted'} borderColor={colors.gray[300]} />
				<VStack spacing={1} fontSize={'10px'} flex={1}>
					<Text>Transactions</Text>
					<Text fontWeight={600} fontSize={'12px'}>
						{data?.totalTransactions}
					</Text>
				</VStack>
				<Divider orientation="vertical" h={'40px'} borderStyle={'dotted'} borderColor={colors.gray[300]} />
				<VStack spacing={1} fontSize={'10px'} flex={1}>
					<Text>% Fee</Text>
					<Text fontWeight={600} fontSize={'12px'}>
						{data?.affiliatePercentage}
					</Text>
				</VStack>
			</Flex>
		</Box>
	);
};

export function ListGroup<T extends AffiliateGroup | AffiliateUser>({
	compact,
	data,
	isFetching,
	isGroup,
	onFetchMore,
	pageSize,
}: {
	compact?: boolean;
	data: T[];
	isFetching: boolean;
	isGroup?: boolean;
	onFetchMore;
	pageSize: number;
}) {
	const { t } = useTranslation();

	return (
		<Flex direction={'column'} gap={3} w={'100%'}>
			{isFetching ? (
				new Array(compact ? 1 : 3)
					.fill('')
					.map((_, el) => (
						<Skeleton key={el} endColor={'gray.200'} height="135px" width={'100%'} borderRadius="1rem" />
					))
			) : !data?.length ? (
				<Center width={'100%'}>
					<NoData msg={t('affiliate.noData')} />
				</Center>
			) : (
				<InfiniteScroll
					containerId={ELEMENT_IDS.HOME_CONTAINER}
					chunkSize={pageSize}
					itemSize={compact ? 138 : 150}
					onFetchMore={compact ? undefined : onFetchMore}
					items={compact ? data?.slice(0, 1) : data}
					itemRender={({ data, index }) => (
						<GroupItem data={data[index] as T} isGroup={isGroup} compact={compact} />
					)}
				/>
			)}
		</Flex>
	);
}
