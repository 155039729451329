import { CopyToClipboard, Loading } from '@/app-components/common';
import TokenLogo, { ChainLogo } from '@/app-components/common/Avatar/TokenLogo';
import { AppHeader } from '@/app-components/layout/AppLayout';
import PageLayout from '@/app-components/layout/PageLayout';
import { ChainId, TobiChainName } from '@/app-constants/chains';
import { ITokenSearch } from '@/app-cores/api/bff';
import { getShortAddress } from '@/app-helpers/address';
import { formatTimeRecent } from '@/app-helpers/time';
import { getTokenInfo } from '@/app-helpers/token';
import { parseUrlSearchParams } from '@/app-helpers/url';
import { useSearchToken } from '@/app-hooks/api/portfolio';
import { useSearchMeme } from '@/app-hooks/api/pump.fun';
import { CreateEvent, PumpFunSDK, PumpToken, TokenMetadata } from '@/app-services/pump.fun';
import { BASE_BORDER_COLOR, colors } from '@/app-theme/theme';
import { TokenBalance } from '@/app-views/portfolio/pages/token/components/Balance';
import { SocialItem } from '@/app-views/portfolio/pages/token/components/TokenInformation';
import SwapWidget from '@/app-views/swap/components/SwapWidget';
import Comments from '@/app-views/tobi-fun/containers/Comments';
import CurveProgress from '@/app-views/tobi-fun/containers/CurveProgress';
import Header from '@/app-views/tobi-fun/containers/Header';
import Holders from '@/app-views/tobi-fun/containers/Holders';
import { SwapMeme } from '@/app-views/tobi-fun/containers/Swap';
import TokenInformation from '@/app-views/tobi-fun/containers/TokenInfomation';
import Trades from '@/app-views/tobi-fun/containers/Trades';
import {} from '@/app-views/tobi-fun/CreateMeme';
import { formatPumpTokenToTobiToken } from '@/app-views/tobi-fun/helpers';
import { FiSettingIcon } from '@/assets/icons';
import { FiSwapIconColor } from '@/assets/icons/fi-swap-icon-color';
import { Card, CardBody, Divider, Flex, Image, Text } from '@chakra-ui/react';
import { uniqBy } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

const BasicInfo = ({ memeInfo, token }: { memeInfo: PumpToken | undefined; token: ITokenSearch }) => {
	const {
		name,
		description,
		image_uri: image,
		symbol,
		creator,
		created_timestamp = Date.now(),
		twitter,
		telegram,
		website,
	} = memeInfo ?? {};
	const { symbol: backupSymbol, name: backupName } = getTokenInfo(token);
	const socials = { twitter, telegram, homepage: website };
	return (
		<Card>
			<CardBody p={4}>
				<Flex fontSize={'14px'} gap={3}>
					<TokenLogo logo={image} size={40} chainId={ChainId.SOL} />
					<Flex direction={'column'} flex={1} gap={2}>
						<Flex justifyContent={'space-between'} flex={1}>
							<Text fontWeight={'600'}>
								{name || backupName || '??'} (${symbol || backupSymbol || '??'})
							</Text>
							<Text
								whiteSpace={'nowrap'}
								fontSize={'12px'}
								textAlign={'right'}
								color={colors.gray[400]}
								minWidth={'60px'}
							>
								{formatTimeRecent(created_timestamp)}
							</Text>
						</Flex>
						{creator && (
							<Flex fontSize={'12px'} gap={2}>
								<Text color={colors.gray[400]} as="span">
									Created by:
								</Text>{' '}
								<CopyToClipboard text={creator}>{getShortAddress(creator)}</CopyToClipboard>
							</Flex>
						)}

						{description && (
							<Text as="span" fontSize={'12px'} color={colors.gray[400]}>
								{description}
							</Text>
						)}

						{Object.values(socials).find((e) => e) && (
							<Flex gap={2}>
								{Object.keys(socials).map((key) => {
									return (
										<SocialItem
											type={key}
											key={key}
											url={socials[key]}
											style={{ width: '20px', height: '20px' }}
										/>
									);
								})}
							</Flex>
						)}

						<Divider borderStyle={'dotted'} borderColor={colors.gray[300]} />

						<Flex justify={'space-between'} fontSize={'12px'}>
							<Flex gap={1} align={'center'}>
								<Text color={colors.gray[400]}>Chain:</Text>{' '}
								<ChainLogo chainId={ChainId.SOL} style={{ width: '16px' }} /> Solana
							</Flex>
							<Flex gap={1} align={'center'}>
								<Text color={colors.gray[400]}>Platform:</Text>{' '}
								<Image src="/icons/brands/pumpfun.png" style={{ width: '20px' }} /> Pump.fun
							</Flex>
						</Flex>
					</Flex>
				</Flex>
			</CardBody>
		</Card>
	);
};

export default function TobiFunMemeDetail() {
	const [searchParams] = useSearchParams();
	const mint = searchParams.get('mint');
	const { data, isFetching } = useSearchMeme({ keyword: mint, enabled: true, limit: 1 });
	const { data: searchData } = useSearchToken({ query: mint, chainId: ChainId.SOL, limit: 1 });

	const { token, tokenSearch } = useMemo(() => {
		const token: PumpToken = data?.[0];
		const tokenSearch = searchData?.[0] || formatPumpTokenToTobiToken(token);
		return { token, tokenSearch };
	}, [data, searchData]);

	const props = { token: tokenSearch, memeInfo: token };

	return (
		<PageLayout header={<Header />}>
			{isFetching ? (
				<Loading />
			) : (
				<Flex direction={'column'} gap={3} pb={6}>
					<BasicInfo {...props} />
					<TokenInformation {...props} />
					<CurveProgress {...props} />
					<SwapMeme {...props} />
					<TokenBalance data={tokenSearch} style={{ marginTop: 0 }} />
					<Trades {...props} />
					<Comments {...props} />
					<Holders {...props} />
				</Flex>
			)}
		</PageLayout>
	);
}
