import { CopyToClipboard } from '@/app-components/common';
import { ChainId } from '@/app-constants/chains';
import { compareAddress, getShortAddress } from '@/app-helpers/address';
import { formatNumber, formatUnits } from '@/app-helpers/number';
import { formatTimeRecent } from '@/app-helpers/time';
import { getNativeToken } from '@/app-helpers/token';
import { PumpHolder, PumpToken, PumpTrade, TokenMetadata } from '@/app-services/pump.fun';
import { colors } from '@/app-theme/theme';
import { Avatar, Flex, Text } from '@chakra-ui/react';

export default function HolderItem({ data: { address, percent }, token }: { data: PumpHolder; token: PumpToken }) {
	return (
		<Flex w={'100%'} direction={'column'} fontSize={'12px'} gap={2}>
			<Flex justify={'space-between'} align={'center'}>
				<Flex fontSize={'14px'} fontWeight={'500'} gap={2}>
					<CopyToClipboard text={address}>{getShortAddress(address)}</CopyToClipboard>

					{compareAddress(token?.creator, address) ? (
						<>
							<Text color={colors.gray[200]}>•</Text>
							<Text className="text-gradient">Creator</Text>
						</>
					) : (
						compareAddress(token?.bonding_curve, address) && (
							<>
								<Text color={colors.gray[200]}>•</Text>
								<Text className="text-gradient">Bonding Curve</Text>
							</>
						)
					)}
				</Flex>
				<Text fontWeight={'500'}>{percent.toFixed(1)}%</Text>
			</Flex>
		</Flex>
	);
}
