import React from 'react';

interface IProps extends React.SVGAttributes<HTMLOrSVGElement> {}

export const FiEmailIcon: React.FC<IProps> = ({ width = 48, height = 48, ...rest }) => {
	return (
		<svg width={width} height={height} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_12520_20880)">
				<path
					d="M24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48Z"
					fill="#002147"
				/>
				<path
					d="M23.9944 25.1848L11.3438 18.8735V31.4135C11.3455 32.0493 11.5988 32.6585 12.0483 33.108C12.4979 33.5576 13.1071 33.8109 13.7428 33.8126H34.2572C34.8929 33.8109 35.5021 33.5576 35.9517 33.108C36.4012 32.6585 36.6545 32.0493 36.6562 31.4135V19.0057L23.9944 25.1848Z"
					fill="white"
				/>
				<path
					d="M24.0056 22.8148L36.6562 16.6404V16.5861C36.6545 15.9503 36.4012 15.3411 35.9517 14.8916C35.5021 14.4421 34.8929 14.1887 34.2572 14.187H13.7428C13.1217 14.1882 12.5251 14.4297 12.078 14.8608C11.6308 15.2918 11.3677 15.8792 11.3438 16.4998L24.0056 22.8148Z"
					fill="white"
				/>
			</g>
			<defs>
				<clipPath id="clip0_12520_20880">
					<rect width="48" height="48" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};
