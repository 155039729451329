import { STORAGE_KEYS } from '@/app-constants/storage';
import { TGasFeeType } from '@/app-types';
import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

export enum ChartMode {
	LINE = 'Line',
	CANDLE = 'Candle',
}

export enum TimeFrame {
	ONE_H = '1H',
	ONE_D = '1D',
	ONE_W = '1W',
	ONE_M = '1M',
	ONE_Y = '1Y',
}

export enum DefinedFiResolution {
	ONE_MIN = '1',
	FIVE_MIN = '5',
	FIFTEEN_MIN = '15',
	THIRTY_MIN = '30',
	ONE_HOUR = '60',
	FOUR_HOURS = '240',
	TWELVE_HOURS = '720',
	ONE_DAY = '1D',
	SEVEN_DAYS = '7D',
}

export enum ChartInterval {
	ONE_MIN = '1_minute',
	FIFTEEN_MIN = '15_minutes',
	ONE_HOUR = '1_hour',
	TWO_HOUR = '2_hours',
	THREE_HOUR = '3_hours',
	FOUR_HOURS = '4_hours',
	SIX_HOURS = '6_hours',
	EIGHT_HOURS = '8_hours',
	ONE_DAY = '1_day',
	SEVEN_DAYS = '1_week',
}

export const ChartIntervalNumber = {
	[ChartInterval.ONE_MIN]: 1,
	[ChartInterval.FIFTEEN_MIN]: 15,
	[ChartInterval.ONE_HOUR]: 60,
	[ChartInterval.TWO_HOUR]: 120,
	[ChartInterval.THREE_HOUR]: 180,
	[ChartInterval.FOUR_HOURS]: 240,
	[ChartInterval.SIX_HOURS]: 360,
	[ChartInterval.EIGHT_HOURS]: 480,
	[ChartInterval.ONE_DAY]: 1440,
};

type ChartConfig = {
	mode: ChartMode;
	interval: ChartInterval;
	showMa: boolean;
	showVolume: boolean;
};

export const CHART_CONFIG_DEFAULT = {
	mode: ChartMode.CANDLE,
	interval: ChartInterval.ONE_DAY,
	showVolume: true,
	showMa: true,
};

const initializeData = {
	gasFeeType: 'low' as TGasFeeType,
	slippage: 2,
	showSpamActivity: false,
	showTestnetActivity: false,
	chartConfig: CHART_CONFIG_DEFAULT,
};
interface IUserSettingsStore {
	showSpamActivity: boolean;
	showTestnetActivity: boolean;
	setShowSpamActivity: (v: boolean) => void;
	setShowTestnetActivity: (v: boolean) => void;

	slippage: number;
	setSlippage: (v: number) => void;
	gasFeeType: TGasFeeType;
	setGasFeeType: (type: TGasFeeType) => void;

	chartConfig: ChartConfig;
	setChartConfig: (v: Partial<ChartConfig>) => void;
}
export const useUserSettingsStore = create<IUserSettingsStore>()(
	persist(
		(set) => ({
			...initializeData,
			setGasFeeType: (type) => set({ gasFeeType: type }),
			setSlippage: (slippage) => set({ slippage }),
			setShowSpamActivity: (showSpam) => set({ showSpamActivity: showSpam }),
			setShowTestnetActivity: (showTestnet) => set({ showTestnetActivity: showTestnet }),
			setChartConfig: (chartConfig) =>
				set((state) => ({ chartConfig: { ...state.chartConfig, ...chartConfig } })),
		}),
		{
			name: STORAGE_KEYS.TOBI_USER_SETTINGS_STORAGE,
			storage: createJSONStorage(() => localStorage),
		},
	),
);
