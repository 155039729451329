import { InputMode } from '@/app-store/swap';
import { useState } from 'react';

const useInputMode = (modeDefault: InputMode) => {
	const [inputMode, setInputMode] = useState<InputMode>(modeDefault);
	const handleSwitch = () => {
		setInputMode(inputMode === InputMode.AMOUNT ? InputMode.USD : InputMode.AMOUNT);
	};
	return { inputMode, handleSwitch, setInputMode };
};
export default useInputMode;
