import { Box, Center, Divider, Flex, Skeleton, Text } from '@chakra-ui/react';
import { FiBillIcon, FiDollarSignIcon, FiRightIcon, FiStartIcon, FiSwapIcon } from '@/assets/icons';
import { useTranslation } from 'react-i18next';
import { BASE_BORDER_COLOR, colors } from '@/app-theme/theme';
import { FiSwapIconColor } from '@/assets/icons/fi-swap-icon-color';
import { useNavigate } from 'react-router';
import { NAVIGATE_PATHS } from '@/app-constants/router';
import { useCalculateAffiliateTokenInfo, useGetAffiliateInfo } from '@/app-hooks/api/user/affiliate';
import { formatUsd } from '@/app-helpers/number';
import { CommingSoon } from '@/app-components/common';
import { FeatureConfig, useQueryFeatureConfig } from '@/app-hooks/api/configuration';
export const RewardsComingSoon = () => {
	return (
		<CommingSoon
			display="flex"
			flexDirection="column"
			flex={1}
			backgroundColor="white"
			py={3}
			px={4}
			borderRadius={8}
			overflow="hidden"
		>
			<Box>
				<Center justifyContent="start" fontSize="xs" fontWeight={600}>
					Rewards <FiRightIcon width={20} height={20} fill="gray" />
				</Center>
			</Box>
			<Box mt={3}>
				<Text fontSize="x-small" color="gray.400">
					Points Earned
				</Text>
				<Center mt={1}>
					<FiStartIcon />
					<Text ml={2} fontSize="xs" fontWeight={700}>
						0
					</Text>
					<Text flex={1} textAlign="end" fontSize="xs" fontWeight={500} color="gray.200">
						Points
					</Text>
				</Center>
				<Divider mt={2} borderColor="gray.200" variant={'dashed'} />
			</Box>
			<Box mt={3}>
				<Text fontSize="x-small" color="gray.400">
					Fees Earned
				</Text>
				<Center mt={1}>
					<FiBillIcon />
					<Text ml={2} fontSize="xs" fontWeight={700}>
						$0
					</Text>
					<Text flex={1} textAlign="end" fontSize="xs" fontWeight={600} color="gray.200">
						Fees
					</Text>
				</Center>
			</Box>
		</CommingSoon>
	);
};

export const Rewards = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const isSupportFeeEarn = useQueryFeatureConfig(FeatureConfig.FEE_EARN);
	const { isFetching: showSkeleton, data } = useGetAffiliateInfo(isSupportFeeEarn);
	const { data: tokenInfo } = useCalculateAffiliateTokenInfo(data?.tokenList ?? []);

	if (!isSupportFeeEarn) return <RewardsComingSoon />;

	return (
		<Box
			display="flex"
			flexDirection="column"
			flex={1}
			backgroundColor="white"
			py={3}
			px={4}
			borderRadius={8}
			overflow="hidden"
			onClick={() => navigate(NAVIGATE_PATHS.Affiliate.Main)}
		>
			<Box>
				<Center justifyContent="start" fontSize="xs" fontWeight={600}>
					{t('affiliate.refferalFee')} <FiRightIcon width={20} height={20} fill="gray" />
				</Center>
			</Box>

			<Box mt={3}>
				<Text fontSize="x-small" color="gray.400">
					{t('affiliate.feeEarned')}
				</Text>

				{showSkeleton ? (
					<Skeleton height="24px" width="100%" startColor="white" endColor="#bfc3c9" />
				) : (
					<Center mt={1}>
						<Flex
							sx={{
								border: `2px solid ${BASE_BORDER_COLOR}`,
								borderRadius: '100%',
								justifyContent: 'center',
								alignItems: 'center',
							}}
							w={'24px'}
							h={'24px'}
						>
							<FiDollarSignIcon height={12} />
						</Flex>
						<Text ml={2} fontSize="xs" fontWeight={700}>
							{formatUsd(tokenInfo?.totalUsd || 0)}
						</Text>
						<Text flex={1} textAlign="end" fontSize="xs" fontWeight={500} color={colors.gray[400]}>
							USD
						</Text>
					</Center>
				)}

				<Divider mt={2} borderColor="gray.200" variant={'dashed'} />
			</Box>

			<Box mt={3}>
				<Text fontSize="x-small" color="gray.400">
					#Transactions
				</Text>
				{showSkeleton ? (
					<Skeleton height="24px" width="100%" startColor="white" endColor="#bfc3c9" />
				) : (
					<Center mt={1}>
						<Flex
							sx={{
								border: `2px solid ${BASE_BORDER_COLOR}`,
								borderRadius: '100%',
								justifyContent: 'center',
								alignItems: 'center',
							}}
							w={'24px'}
							h={'24px'}
						>
							<FiSwapIconColor width={16} />
						</Flex>
						<Text ml={2} fontSize="xs" fontWeight={700}>
							{data?.totalTransactions || 0}
						</Text>
						<Text flex={1} textAlign="end" fontSize="xs" fontWeight={500} color={colors.gray[400]}>
							Txs
						</Text>
					</Center>
				)}
			</Box>
		</Box>
	);
};
