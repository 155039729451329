import { Link, useNavigate } from 'react-router-dom';
import { Box, BoxProps, Button, Center, Flex, Skeleton, Text } from '@chakra-ui/react';
import { NAVIGATE_PATHS } from '@/app-constants/router';
import { useDepositStore } from '@/app-features/deposit/store';
import { usePortfolioBalance, usePortfolioBalanceByCategories } from '@/app-hooks/api/portfolio/usePortfolioBalance';
import { FiDollarSignIcon, FiPlusIcon, FiRightIcon } from '@/assets/icons';
import { RequireWallet } from '@/app-components/common';
import { displayCurrency } from '@/app-helpers/display';
import { formatUsd } from '@/app-helpers/number';
import { useWalletStore } from '@/app-store';
import { useTranslation } from 'react-i18next';
import { BASE_BORDER_COLOR } from '@/app-theme/theme';
import { AffiliateFeeInfo } from '@/app-services/fee';
import { useCalculateAffiliateTokenInfo } from '@/app-hooks/api/user/affiliate';

function calculateFontSize(value: number) {
	return -3 * value + 44;
}
interface BalanceProps extends BoxProps {
	amount: number;
	useCustomSize?: boolean;
}

export const Balance: React.FC<BalanceProps> = ({ amount, ...boxProps }) => {
	const parse = displayCurrency(formatUsd(amount || 0, { showDollar: false }));
	const numberDigit = parse?.value?.length;
	return (
		<Box display="flex" gap={1} justifyContent="center" {...boxProps}>
			<Box>
				<Text
					fontSize={numberDigit > 4 ? `${calculateFontSize(numberDigit)}` : boxProps.fontSize || '32px'}
					as="span"
					fontWeight={400}
				>
					${parse.value}
				</Text>
				<Text fontSize="xl" as="span">
					.{parse.digit}
				</Text>
			</Box>
		</Box>
	);
};

export const TotalFee = ({ isFetching, data }: { data: AffiliateFeeInfo | undefined; isFetching: boolean }) => {
	const navigate = useNavigate();
	const { data: tokenInfo } = useCalculateAffiliateTokenInfo(data?.tokenList ?? []);

	const totalUsd = tokenInfo?.totalUsd || 0;

	const { t } = useTranslation();

	return (
		<Flex
			onClick={() => navigate(NAVIGATE_PATHS.Affiliate.FeeEarn)}
			h={'174px'}
			flexDirection="column"
			gap={4}
			flex={1}
			backgroundColor="white"
			pb={3}
			pt="6px"
			px={4}
			borderRadius={8}
			cursor="pointer"
		>
			<Center flexDirection="column" justifyContent={'center'} flex={1}>
				<Flex
					sx={{
						border: `3px solid ${BASE_BORDER_COLOR}`,
						borderRadius: '100%',
						justifyContent: 'center',
						alignItems: 'center',
					}}
					w={'40px'}
					h={'40px'}
				>
					<FiDollarSignIcon height={20} />
				</Flex>
				{isFetching ? (
					<Center height="57px" width="100%">
						<Skeleton height="38px" width="100%" startColor="white" endColor="#bfc3c9" />
					</Center>
				) : (
					<Balance amount={totalUsd} justifyContent="start" fontSize="32px" />
				)}
				<Center fontSize="xs" fontWeight={600}>
					{t('affiliate.feeEarned')} <FiRightIcon width={20} height={20} fill="gray" />
				</Center>
			</Center>
		</Flex>
	);
};
