import { ChainId, NATIVE_TOKEN_ADDRESS, TobiChainName } from '@/app-constants/chains';
import { axiosBotAPI } from '@/app-cores/api/axios';
import { compareToken, isNativeToken } from '@/app-helpers/address';
import { BlockIPInfo, ITokenCatalogDetail, ITokenSearch } from './types';
import { getNativeTobiId, getTobiChainName, getTokenId, getTokenInfo } from '@/app-helpers/token';
import { ONE_MINUTE } from '@/app-hooks/api/portfolio/constant';

// chainId is real chain id, not tobi chain id
export type QueryTokenParam = { tobiId?: string; chainId?: string | ChainId; address?: string };
export type BalanceResponse = {
	balances: ITokenSearch[];
	notFoundTokens: { tobiId: any; chainId: string; amount: string; idTobi: string }[];
};

class BffService {
	cachedToken: Record<string, ITokenSearch> = {};
	lastUpdate = Date.now();
	async getPortfolioBalance(forceRefresh?: boolean, type?: 'hidden' | 'visible'): Promise<BalanceResponse> {
		const response = await (type
			? axiosBotAPI.post('/v2/wallet/portfolio/filtered-balance', { type })
			: axiosBotAPI.post('v2/wallet/portfolio/balance', {
					isRefresh: forceRefresh,
			  }));
		if (forceRefresh || Date.now() - this.lastUpdate > ONE_MINUTE) {
			this.cachedToken = {};
			this.lastUpdate = Date.now();
		}

		const balances: { amount: string; chainId: string; idTobi: string }[] =
			response.data.data?.filter((e) => e.amount && e.amount !== '0') ?? [];

		const formattedBalances = balances.map((e) => ({
			...e,
			tobiId: e.idTobi,
		}));

		const getKey = (e) => e.tobiId || e.idTobi;

		const query = formattedBalances.filter((e) => !this.cachedToken[getKey(e)]).map((e) => ({ tobiId: e.tobiId }));

		const findTokens = await this.searchExactListTokens({ query });
		findTokens.forEach((e) => {
			this.cachedToken[getKey(getTokenInfo(e))] = e;
		});

		const notFoundTokens = [];
		const result = formattedBalances
			.map((e) => {
				const tokenInfo = this.cachedToken[getKey(e)];
				if (tokenInfo) return { ...e, ...tokenInfo, balance: e?.amount || '0' };
				notFoundTokens.push(e);
			})
			.filter(Boolean)
			.sort((a, b) => (BigInt(a.balance || 0n) > BigInt(b.balance || 0n) ? 1 : -1)); // to make sure return the same order, dont care desc or asc
		return { balances: result, notFoundTokens };
	}

	async getTokenDetail(payload: QueryTokenParam): Promise<ITokenSearch> {
		if (payload.address) payload.address = isNativeToken(payload.address) ? NATIVE_TOKEN_ADDRESS : payload.address;
		const response = await axiosBotAPI.get(`v2/token/detail`, {
			params: payload,
		});
		return response.data?.data ?? null;
	}
	async searchTokens({
		chainId,
		chainIds,
		...payload
	}: {
		query: string;
		limit: number;
		chainId?: ChainId | string;
		chainIds?: ChainId[];
	}): Promise<ITokenSearch[]> {
		const params: Record<string, any> = { ...payload, chainNameOnTobi: getTobiChainName(chainId) };
		if (!chainId && chainIds?.length) {
			params.chainNameOnTobi = chainIds.map((e) => getTobiChainName(e));
		}
		const response = await axiosBotAPI.get(`v2/token/search`, { params });
		return response.data?.data ?? [];
	}

	async searchExactListTokens({ query }: { query: QueryTokenParam[] }): Promise<Array<ITokenSearch>> {
		if (!query.length) return [];
		const formatQuery = query.map((e) => ({ ...e, chainId: getTobiChainName(e.chainId) || e.chainId }));
		const response = await axiosBotAPI.post(`v2/token/details`, { tokens: formatQuery });
		const findTokens = response.data?.data?.filter(Boolean) ?? [];
		return findTokens;
	}

	async searchExactSingleToken(payload: QueryTokenParam): Promise<ITokenSearch> {
		payload.chainId = getTobiChainName(payload.chainId) || payload.chainId;
		const tokens = await this.searchExactListTokens({ query: [payload] });
		return tokens?.[0] ?? null;
	}

	async getTrendingTokens(): Promise<ITokenSearch[]> {
		const response = await axiosBotAPI.get(`/v2/token/trending`);
		return response.data.data;
	}

	async getPortfolioChart(): Promise<Array<{ timestamp: number; totalUsd: number }>> {
		const response = await axiosBotAPI.get(`/api/v1/balances/me/histories`);
		return response.data?.data?.balances?.reverse();
	}

	async getBlockIPInfo(): Promise<BlockIPInfo> {
		const response = await axiosBotAPI.get(`/trading/ips/me`);
		return response.data;
	}

	async getJupiterAta(tokenAddress: string): Promise<string> {
		const response = await axiosBotAPI.post(`/trading/solana/ata`, { tokenAddress }, { timeout: 5000 });
		return response.data?.data?.ataAddress;
	}
}

export const BffServiceAPI = new BffService();
