import { QUERY_KEYS } from '../../app-constants';
import { ChainId } from '../../app-constants/chains';
import {
	ActivitiesServiceAPI,
	IActivity,
	TxDetailTokenContractInteraction,
	TxDetailTokenSend,
	TxStatus,
	WalletActivity,
} from '../../app-cores/api/activities';
import { queryClient } from '../../app-cores/query-client';
import { compareAddress } from '@/app-helpers/address';
import { ONE_DAY, ONE_HOUR } from '@/app-hooks/api/portfolio/constant';
import { MetadataEvm, TransactionWatcher, useTransactionWatcherStore } from '@/app-store/transaction-watcher';
import { useCallback, useRef } from 'react';
import { useInterval } from '@chakra-ui/react';
import { SelectedRoute } from '@/app-store/swap';
import { SwapService } from '@/app-hooks/swap';
import { TransactionType } from '@/app-types';
import { TokenInfo } from '@/app-cores/api/bff';
import { getMyWalletAddressByChain, tryParseAmount } from '@/app-hooks/swap/helper';
import { truncateToFixed } from '@/app-helpers/number';
import { ChainType } from '@/app-contexts/wallet-provider/type';
import { TonWallet } from '@/app-cores/mpc-wallet/ton/TonWallet';
import { TonTransaction } from '@/app-cores/api/toncenter/type';
import { isEclipseChain, isEvmChain, isSolanaChain, isSuiChain, isTonChain, isTronChain } from '@/app-helpers/token';
import { getSigner } from '@/app-helpers/web3';

export const useWatchTransactions = async (enable: boolean) => {
	const {
		pendingEvmTransactions,
		pendingSolTransactions,
		pendingTonTransactions,
		pendingTronTransactions,
		pendingSuiTransactions,
		pendingEclipseTransactions,
		removePendingTransaction,
	} = useTransactionWatcherStore();

	// new chain, only put here and useAddPendingTransaction
	const transactions = [
		{ txs: pendingEvmTransactions, chain: ChainType.EVM },
		{ txs: pendingSolTransactions, chain: ChainType.SOLANA },
		{ txs: pendingTonTransactions, chain: ChainType.TON },
		{ txs: pendingTronTransactions, chain: ChainType.TRON },
		{ txs: pendingSuiTransactions, chain: ChainType.SUI },
		{ txs: pendingEclipseTransactions, chain: ChainType.ECLIPSE },
	];

	const hasPending = transactions
		.map((e) => e.txs)
		.some((e) => {
			return !!Object.keys(e ?? {}).length;
		});

	const checkSingleTxsFn = (
		{
			hash,
			type,
			tonCenterTxs,
			activities,
		}: { type: ChainType; hash: string; tonCenterTxs: TonTransaction[]; activities: WalletActivity[] },
		originTxs: TransactionWatcher<MetadataEvm | IActivity>,
	) => {
		const {
			metadata: { time },
			callbackFns,
		} = originTxs;

		const getTransaction = () => {
			if (type === ChainType.TON) {
				// search by boc hash or query id
				const realHash = TonWallet.getRealTransactionHash(tonCenterTxs, hash);
				console.log('ton', { realHash, hash });
				return activities?.find((e) => compareAddress(realHash, e.transactionHash));
			}
			return activities?.find((e) => compareAddress(hash, e.transactionHash));
		};
		const transaction = getTransaction();

		const callback = (status: TxStatus) => {
			[
				QUERY_KEYS.GET_TOKEN_ALLOWANCE,
				QUERY_KEYS.GET_PORTFOLIO_BALANCE,
				QUERY_KEYS.GET_WALLET_ACTIVITY,
				QUERY_KEYS.GET_TOBI_FARM_POINT,
				QUERY_KEYS.GET_NOTIFICATIONS,
				QUERY_KEYS.GET_MY_MEME_BALANCE,
			].forEach((key) =>
				queryClient.invalidateQueries({
					queryKey: [key],
				}),
			);
			callbackFns?.forEach((fn) => fn?.({ status }));
			removePendingTransaction({ hash, type });
		};

		const handlePending = () => {
			if (Date.now() - time * 1000 > ONE_HOUR * 2) {
				callback(TxStatus.Failed);
			}
			console.log('txs pending', hash);
		};

		if (!transaction || transaction.transactionStatus === TxStatus.Pending) {
			handlePending();
			return;
		}

		callback(transaction.transactionStatus);
	};

	const checking = useRef(false);
	const checkTransactions = async () => {
		if (checking.current) return;
		try {
			checking.current = true;
			const activities = await ActivitiesServiceAPI.getWalletActivities({});

			const checkListTxs = async (data: Record<string, TransactionWatcher<any>> = {}, type: ChainType) => {
				return Promise.allSettled(
					Object.keys(data).map(async (hash) => {
						const tonCenterTxs = type === ChainType.TON ? await TonWallet.getTonCenterTxs() : [];
						return checkSingleTxsFn({ hash, type, tonCenterTxs, activities }, data[hash]);
					}),
				);
			};

			await Promise.allSettled(transactions.map(({ txs, chain }) => checkListTxs(txs, chain)));
		} catch (error) {
			console.log('watch txs err', error);
		} finally {
			checking.current = false;
		}
	};

	useInterval(checkTransactions, hasPending && enable ? 5000 : null);
};

export const useAddPendingTransaction = () => {
	const {
		addPendingEvmTransaction,
		addPendingTonTransaction,
		addPendingSolTransaction,
		addPendingTronTransaction,
		addPendingSuiTransaction,
		addPendingEclipseTransaction,
	} = useTransactionWatcherStore();

	return useCallback(
		({
			chainId,
			hash,
			callback,
			transactionType,
		}: {
			chainId: ChainId | string;
			hash: string;
			transactionType: TransactionType;
			callback: (data: { status: TxStatus; msg?: string }) => void;
		}) => {
			if (!hash || !chainId) return;

			if (isSolanaChain(chainId)) {
				return addPendingSolTransaction({ hash, callback });
			}
			if (isEclipseChain(chainId)) {
				return addPendingEclipseTransaction({ hash, callback });
			}
			if (isSuiChain(chainId)) {
				return addPendingSuiTransaction({ hash, callback });
			}
			if (isTonChain(chainId)) {
				return addPendingTonTransaction({ hash, callback });
			}
			if (isTronChain(chainId)) {
				return addPendingTronTransaction({ hash, callback });
			}
			if (isEvmChain(chainId)) {
				const { provider } = getSigner(chainId);
				provider.getTransaction(hash).then((receipt) => {
					addPendingEvmTransaction({
						transaction:
							receipt || useTransactionWatcherStore.getState().pendingEvmTransactions[hash]?.metadata,
						callback,
						metadata: { transactionType },
					});
				});
			}
		},
		[
			addPendingEvmTransaction,
			addPendingSolTransaction,
			addPendingTonTransaction,
			addPendingTronTransaction,
			addPendingSuiTransaction,
			addPendingEclipseTransaction,
		],
	);
};

const getCommonPayload = (chainId: ChainId | string) => ({
	id: Date.now().toString(),
	gasUsd: 0,
	walletAddress: getMyWalletAddressByChain(chainId),
	chainId,
	gas: '',
	status: TxStatus.Pending,
	time: (Date.now() / 1000) | 0,
});

export const getPendingContractInteractionTxs = ({
	contract = '',
	hash,
	chainId,
}: {
	contract?: string;
	hash: string;
	chainId: ChainId | string;
}): IActivity => {
	return {
		hash,
		...getCommonPayload(chainId),
		details: [
			{
				type: TransactionType.ContractInteraction,
				contracts: [{ address: contract }],
				sent: [],
				received: [],
			} as TxDetailTokenContractInteraction,
		],
	};
};
