import { CHAIN_CONFIG, ChainId, WRAP_BERA_TEST_NET_ADDRESS, ZERO_ADDRESS } from '@/app-constants/chains';
import { TokenInfo } from '@/app-cores/api/bff';
import { compareAddress, isNativeToken } from '@/app-helpers/address';

import { commonGetRoute, isRouteParamsEmpty } from '@/app-hooks/swap';
import { ArgsGetRoute, ExtractRouteInfo, SwapAbstract, SwapProvider, UsdRouteInfo } from '@/app-hooks/swap/type';
import { GenericRoute, SelectedRoute } from '@/app-store/swap';
import { useMutation } from '@tanstack/react-query';
import axios, { AxiosRequestConfig } from 'axios';
import { Interface, ethers, formatUnits, parseEther, parseUnits } from 'ethers';
import { uniqueId } from '@/app-helpers/random';
import { calcMinAmountOutFromSlippage, calcRateSwap, filterParams } from '@/app-hooks/swap/helper';

import { getSignerContract } from '@/app-helpers/web3';
import { truncateToFixed } from '@/app-helpers/number';
import { bufferGas } from '@/app-hooks/transactions';
import { useUserSettingsStore } from '@/app-store/settings';
import { AUTO_SLIPPAGE } from '@/app-views/swap/components/SlippageSetting';
import ROUTER_ABI from '@/app-constants/json/bera_router.abi.json';
const ROUTER_ADDRESS = '0x21e2C0AFd058A89FCf7caf3aEA3cB84Ae977B73D';

const getTokenAddress = (token: TokenInfo) =>
	isNativeToken(token?.address) ? WRAP_BERA_TEST_NET_ADDRESS : token.address;

const getRouteParamsSwap = (args: ArgsGetRoute, signal?: AbortSignal): AxiosRequestConfig => {
	const { tokenIn, tokenOut, amountIn } = args;

	if (isRouteParamsEmpty(args)) return;
	const params = {
		fromAsset: getTokenAddress(tokenIn),
		toAsset: getTokenAddress(tokenOut),
		amount: amountIn,
	};

	filterParams(params);
	return { url: `https://bartio-bex-router.berachain-devnet.com/dex/route`, params, signal };
};

type Step = {
	poolIdx: string;
	base: string;
	isBuy: string;
	quote: string;
};
export type RouteBera = {
	steps: Step[];
	// custom
	amountIn: string;
	amountOut: string;
	minAmountOut: string;
	tokenIn: TokenInfo;
	tokenOut: TokenInfo;
};

class Bera extends SwapAbstract<RouteBera> {
	provider = SwapProvider.BERA;
	formatSlippage(slippage: string | number): number {
		return +slippage === AUTO_SLIPPAGE ? 1 : +slippage;
	}

	async _getRate(steps, amount) {
		const { contract } = getSignerContract(ROUTER_ADDRESS, ChainId.BERACHAIN_TESTNET, ROUTER_ABI);
		return contract.previewMultiSwap(steps, amount);
	}
	async getRoute(paramsSwap: ArgsGetRoute, signal: AbortSignal) {
		const payload = getRouteParamsSwap(paramsSwap, signal);
		if (!payload) return;
		const data = await commonGetRoute(payload, paramsSwap);
		if (!data?.steps) throw new Error('Empty route');
		const { amountIn } = paramsSwap;
		const rateInfo = await this._getRate(data?.steps, amountIn);
		const [minAmountOut, amountOut] = rateInfo;
		return formatRouteBera({ ...data, amountIn, amountOut, minAmountOut }, paramsSwap);
	}

	extractRoute(params: SelectedRoute<RouteBera>, prices: UsdRouteInfo): ExtractRouteInfo {
		return getExtractRoute(params, prices);
	}
}
export const BeraSwap = new Bera();

const formatRouteBera = (routeData: RouteBera, paramsSwap: ArgsGetRoute): SelectedRoute<RouteBera> =>
	routeData
		? {
				...routeData,
				routerAddress: ROUTER_ADDRESS,
				route: routeData,
				id: uniqueId(),
				provider: SwapProvider.BERA,
				timestamp: Date.now(),
				params: paramsSwap,
		  }
		: undefined;

const getExtractRoute = (
	selectedRoute: SelectedRoute<RouteBera>,
	{ usdPriceIn, usdPriceOut, usdPriceNative }: UsdRouteInfo = {},
): ExtractRouteInfo => {
	const routeSwap = selectedRoute?.route;
	const tokenIn = selectedRoute?.tokenIn;
	const tokenOut = selectedRoute?.tokenOut;

	const amountIn = routeSwap?.amountIn;
	const amountOut = routeSwap?.amountOut;

	return {
		amountOut,
		amountIn,
		minAmountOut: routeSwap?.minAmountOut,
		tokenIn,
		tokenOut,
		gasUsd: undefined,
		gasNative: BigInt(CHAIN_CONFIG[ChainId.BERACHAIN_TESTNET].minForGas),
		gasDisplay: '< 0.01$',
		dappInfo: {
			logo: '/icons/brands/bera.png',
			domain: SwapProvider.BERA,
		},
	};
};

const replaceNativeAddress = (step: Step) => {
	if (compareAddress(step.base, WRAP_BERA_TEST_NET_ADDRESS)) {
		step.base = ZERO_ADDRESS;
	}
	if (compareAddress(step.quote, WRAP_BERA_TEST_NET_ADDRESS)) {
		step.quote = ZERO_ADDRESS;
	}
	return step;
};

export const useExecuteRouteBera = () => {
	const { slippage } = useUserSettingsStore();
	const response = useMutation({
		mutationKey: ['exe-route-berachain'],
		mutationFn: async (routeData: SelectedRoute<RouteBera>) => {
			const { tokenIn, tokenOut, route } = routeData;
			const { amountIn, amountOut } = route;
			const { contract } = getSignerContract(ROUTER_ADDRESS, tokenIn.chainId, ROUTER_ABI);
			const steps = [...route.steps];

			if (isNativeToken(tokenIn.address)) {
				steps[0] = replaceNativeAddress(steps[0]);
			} else if (isNativeToken(tokenOut.address)) {
				steps[steps.length - 1] = replaceNativeAddress(steps[steps.length - 1]);
			}

			const minOut = calcMinAmountOutFromSlippage({
				tokenOut,
				amountOut,
				slippage: BeraSwap.formatSlippage(slippage),
			});

			let gasLimit = 1_000_000n;
			const params = [steps, BigInt(amountIn), minOut];

			try {
				gasLimit = await contract.multiSwap.estimateGas(...params);
			} catch (error) {}
			const resp = await contract.multiSwap(...params, {
				gasLimit: bufferGas(gasLimit),
				value: isNativeToken(tokenIn?.address) ? BigInt(amountIn) : undefined,
			});
			return { hash: resp.hash };
		},
	});
	return response;
};

// const iface = new Interface(ROUTER_ABI);

// const txData =
// 	'0x0b2f6f3f000000000000000000000000000000000000000000000000000000000000006000000000000000000000000000000000000000000000000002c68af0bb1400000000000000000000000000000000000000000000000000001243556439274d7000000000000000000000000000000000000000000000000000000000000000010000000000000000000000000000000000000000000000000000000000008ca00000000000000000000000000e4aaf1351de4c0264c5c7056ef3777b41bd8e0300000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000';

// const txData2 =
// 	'0x0b2f6f3f000000000000000000000000000000000000000000000000000000000000006000000000000000000000000000000000000000000000000002c68af0bb1400000000000000000000000000000000000000000000000000001240f9a1e2b1d39d00000000000000000000000000000000000000000000000000000000000000010000000000000000000000000000000000000000000000000000000000008ca00000000000000000000000000e4aaf1351de4c0264c5c7056ef3777b41bd8e0300000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000';

// const checkFn = (txData) => {
// 	// Extract the function selector (first 4 bytes of the data)
// 	const functionSelector = txData.slice(0, 10);

// 	// Find the function in the ABI that matches the function selector
// 	const functionFragment = iface.fragments.find((fragment) => {
// 		return fragment.type === 'function' && iface.getFunction(fragment.name).selector === functionSelector;
// 	});

// 	if (!functionFragment) {
// 		console.log('Function not found in ABI');
// 	} else {
// 		console.log('Function found:', functionFragment.name);

// 		// Decode the data
// 		const decimalsIn = 18;
// 		const decimalsOut = 18;
// 		const decodedData = iface.decodeFunctionData(functionFragment, txData);
// 		console.log('Decoded data:', decodedData);
// 		console.log('amount in ', formatUnits(decodedData[1], decimalsIn));
// 		console.log('minAMount ', formatUnits(decodedData[2], decimalsOut));
// 		console.log('steps', decodedData[0][0]);
// 	}
// };

// checkFn(txData);
// checkFn(txData2);
