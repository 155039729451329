import { Box, Button, Center, Text } from '@chakra-ui/react';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';
import { StatusBadge } from '@/app-components/common';
import { NAVIGATE_PATHS } from '@/app-constants/router';
import { useMutationCreateWallet } from '@/app-hooks/mpc';
import { useWalletStore } from '@/app-store';

export const CreateWalletFailed = () => {
	const { createMpcWallet } = useMutationCreateWallet();
	const { setWalletStatus } = useWalletStore();

	return (
		<Box
			cursor="pointer"
			px={4}
			my={3}
			onClick={async () => {
				try {
					await createMpcWallet();
				} catch (error) {
					console.error('Create wallet error', error);
					setWalletStatus('CREATE_FAILED');
				}
			}}
		>
			<StatusBadge type="fail">
				<Text fontSize="x-small">
					<Trans
						i18nKey="onboarding.createWallet.error"
						values={{ retry: 'Retry' }}
						components={[<Box as="strong" textDecoration="underline"></Box>]}
					/>
				</Text>
			</StatusBadge>
		</Box>
	);
};
