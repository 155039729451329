import { TextSmall } from '@/app-components/common';
import { formatUsd } from '@/app-helpers/number';
import {
	usePortfolioBalance,
	usePortfolioBalanceByCategories,
	usePortfolioChart,
} from '@/app-hooks/api/portfolio/usePortfolioBalance';
import { BASE_BORDER_COLOR } from '@/app-theme/theme';
import { BalanceChart } from '@/app-views/wallet/components/WalletBalance/BalanceChart';
import { ChevronRightIcon } from '@/assets/images/svg';
import {
	Box,
	Center,
	Drawer,
	DrawerBody,
	DrawerCloseButton,
	DrawerContent,
	DrawerHeader,
	DrawerOverlay,
	Flex,
	Skeleton,
	Text,
	useDisclosure,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Balance } from '../../../../app-components/common/Balance';
import { FeatureConfigWrap } from '../../../../app-helpers/components';
import { FeatureConfig } from '../../../../app-hooks/api/configuration';
import { usePortfolioState } from '@/app-views/wallet/components/Portfolio/state';

interface WalletBalanceProps {
	totalUsd?: number;
}

export const WalletBalance: React.FC<WalletBalanceProps> = ({ totalUsd }) => {
	const { t } = useTranslation();

	return (
		<Box>
			<Center>
				<Text fontSize={'12px'}>{t('affiliate.totalFeeEarned')}</Text>
			</Center>
			<Balance amount={totalUsd} />
		</Box>
	);
};
