import { isEclipseChain, isSolanaChain, isSuiChain, isTonChain, isTronChain } from '@/app-helpers/token';
import { useSentTokenStore } from '@/app-store';
import { EstimateTonGasFee } from './EstimateTonGasFee';
import { EstimateSolGasFee } from './EstimateSolGasFee';
import { EstimateTronGasFee } from './EstimateTronGasFee';
import { EstimateSuiGasFee } from './EstimateSuiGasFee';
import { EstimateEclipseGasFee } from './EstimateEclipseGasFee';

export const EstimateGasFee = () => {
	const { tokenInfo } = useSentTokenStore();
	const { chainId } = tokenInfo ?? {};
	const isTon = isTonChain(chainId);
	const isSol = isSolanaChain(chainId);
	const isTron = isTronChain(chainId);
	const isSui = isSuiChain(chainId);
	const isEclipse = isEclipseChain(chainId);
	return (
		<>
			{isTon && <EstimateTonGasFee />}
			{isSol && <EstimateSolGasFee />}
			{isTron && <EstimateTronGasFee />}
			{isSui && <EstimateSuiGasFee />}
			{isEclipse && <EstimateEclipseGasFee />}
		</>
	);
};
