import { useNavigate, useParams } from 'react-router';

import React, { CSSProperties, useEffect, useState } from 'react';
import { TokenBalance } from './components/Balance';
import { BasicInformation } from './components/BasicInformation';
import { PriceChart } from './components/PriceChart.tsx';
import { TokenInformation } from './components/TokenInformation';
import { MenuAction } from './components/MenuAction';
import {
	Box,
	Button,
	Center,
	Container,
	Drawer,
	DrawerBody,
	DrawerCloseButton,
	DrawerContent,
	DrawerHeader,
	DrawerOverlay,
	Flex,
} from '@chakra-ui/react';
import { useTokenDetail } from '@/app-hooks/api/portfolio';
import { Loading, LocalLoader } from '@/app-components/common';
import PageTransition from '@/app-components/layout/PageTransition/PageTransition';
import PageNavigation from '@/app-components/layout/PageWrapper/PageWrapper';

import { TokenInfo } from '@/app-cores/api/bff';
import { CHART_CONFIG_DEFAULT, useUserSettingsStore } from '@/app-store/settings';
import { getTokenInfo, isTonChain } from '@/app-helpers/token';
import { useWalletStore } from '@/app-store';
import { useSearchParams } from 'react-router-dom';
import { AppHeader } from '@/app-components/layout/AppLayout';
import { ActionBlock } from '@/app-components/common/ActionBlock';
import { FiShareIcon } from '@/assets/icons';
import { useTranslation } from 'react-i18next';
import { formatUsd } from '@/app-helpers/number';
import { TelegramCore, toStartParam } from '@/app-cores/telegram';
import { Actions } from '@/app-features/app-bot-connector/hooks';
import { useQueryUserInviteCodes } from '@/app-hooks/api/user/useQueryUserInviteCodes';
import { useSharingStore } from '@/app-components/common/Sharing';

export const TokenDetailInSwap = ({
	onClose,
	token,
	isNonWallet,
	isOpen,
}: {
	onClose: () => void;
	token: TokenInfo | undefined;
	isNonWallet: boolean;
	isOpen: boolean;
}) => {
	const { chainId, address: contractAddress, idTobi: tobiId } = token || {};
	const { isFetching, data } = useTokenDetail(tobiId ? { tobiId } : { chainId, address: contractAddress });

	const commonProps = {
		chainId,
		contractAddress,
		data,
		tobiId,
	};

	return (
		<Drawer isOpen={isOpen} placement="bottom" onClose={onClose}>
			<DrawerOverlay />
			<DrawerContent>
				<DrawerCloseButton />
				<DrawerHeader fontSize={'16px'} textAlign={'center'} borderBottomWidth={'1px'} py={3}>
					{token?.symbol} Information
				</DrawerHeader>
				<DrawerBody style={{ maxHeight: '80vh', minHeight: '80vh', overflowX: 'hidden' }}>
					<Container style={{ padding: 0 }}>
						{isFetching ? (
							<LocalLoader height={'80vh'} />
						) : (
							<>
								<BasicInformation {...commonProps} showSetting={false} />
								<Chart commonProps={commonProps} style={{ width: '100vw', marginLeft: -6 }} />
								<TokenBalance {...commonProps} />
								<TokenInformation {...commonProps} />
							</>
						)}
					</Container>
				</DrawerBody>
			</DrawerContent>
		</Drawer>
	);
};

const Chart = ({ commonProps, style }) => {
	const { idTobi } = getTokenInfo(commonProps?.data ?? {});
	if (!idTobi) return null;
	return (
		<Flex direction={'column'} gap={0} sx={style}>
			<PriceChart {...commonProps} />
		</Flex>
	);
};

const TokenDetail = ({ style }: { style?: CSSProperties }) => {
	const [searchParams] = useSearchParams();
	const { chainId, contractAddress, tobiId } = Object.fromEntries(searchParams.entries());
	const { isFetching, data } = useTokenDetail(tobiId ? { tobiId } : { chainId, address: contractAddress });

	const { isNonWallet } = useWalletStore();

	const commonProps = {
		chainId,
		contractAddress,
		data,
		tobiId,
	};
	const {
		symbol,
		percentChange24h,
		name,
		imageUrl,
		priceUsd,
		volume24hUsd,
		fullyDilutedValueUsd,
		marketCapUsd,
		idTobi,
	} = getTokenInfo(data) || {};

	const { t } = useTranslation();
	const { data: inviteData } = useQueryUserInviteCodes();
	const { onOpen } = useSharingStore();
	const onShareToken = () => {
		const message = t('sharing.tokenDetail', {
			symbol: symbol.toUpperCase(),
			name,
			usdPrice: priceUsd ? formatUsd(priceUsd) : 'Unknown',
			marketCapUsd: marketCapUsd ? formatUsd(marketCapUsd) : 'Unknown',
			fdvUsd: fullyDilutedValueUsd ? formatUsd(fullyDilutedValueUsd) : 'Unknown',
			volume24hUsd: volume24hUsd ? formatUsd(volume24hUsd) : 'Unknown',
		});

		const startParams = toStartParam({
			act: Actions.TOKEN_DETAIL,
			tobiId: idTobi,
			inviter: !isNonWallet ? TelegramCore.getUserId() : undefined,
			invitationCode: inviteData?.code?.code,
		});

		onOpen({
			link: `https://t.me/${import.meta.env.VITE_BOT_USERNAME}/wallet?startapp=${startParams}`,
			message,
		});
	};

	if (isFetching) return <Loading />;
	return (
		<PageTransition>
			<PageNavigation>
				<AppHeader
					nodes={
						<ActionBlock onClick={onShareToken}>
							<FiShareIcon height={'16px'} />
						</ActionBlock>
					}
				/>
				<Container style={style} className="overflow-y-auto hide-scrollbar" pb={8}>
					<BasicInformation {...commonProps} showSetting={!isNonWallet} />
					<Chart
						commonProps={commonProps}
						style={{
							width: '100vw',
							marginLeft: '-16px',
						}}
					/>
					<MenuAction {...commonProps} isNonWallet={isNonWallet} />
					<TokenBalance {...commonProps} />
					<TokenInformation {...commonProps} />
				</Container>
			</PageNavigation>
		</PageTransition>
	);
};
export default TokenDetail;
