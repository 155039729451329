import TokenLogo from '@/app-components/common/Avatar/TokenLogo';
import { ChainId } from '@/app-constants/chains';
import { NAVIGATE_PATHS } from '@/app-constants/router';
import { getShortAddress } from '@/app-helpers/address';
import { formatUsd } from '@/app-helpers/number';
import { formatTimeRecent } from '@/app-helpers/time';
import { toQueryString } from '@/app-helpers/url';
import { useMemeBondingCurvePercent, useMemeMarketCapData, useMemeMetaData } from '@/app-hooks/api/pump.fun';
import { CreateEvent, MemeOnChainData, PumpToken, TokenMetadata } from '@/app-services/pump.fun';
import { BASE_BORDER_COLOR, colors } from '@/app-theme/theme';
import { Card, CardBody, Collapse, Flex, Progress, Skeleton, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router';

export function MemeToken({ data }: { data: PumpToken }) {
	const {
		name,
		symbol,
		image_uri,
		creator,
		usd_market_cap,
		mint,
		created_timestamp,
		description,
		uri,
		complete,
		bonding_curve,
	} = data;

	const tokenMissingInfo = uri;
	const { data: metadata, isFetching: isLoadingMeta } = useMemeMetaData(tokenMissingInfo ? uri : '');
	const { data: marketData, isFetching } = useMemeMarketCapData(tokenMissingInfo ? mint : '');
	const { data: percentData, isFetching: loadingPercent } = useMemeBondingCurvePercent(
		!complete ? mint : '',
		bonding_curve,
	);

	const navigate = useNavigate();

	const onClickMeme = () => {
		navigate(
			toQueryString(NAVIGATE_PATHS.TobiFun.Detail, {
				mint,
			}),
		);
	};

	const percent = complete ? 100 : Math.floor(percentData || 0);
	return (
		<Collapse in={tokenMissingInfo ? !isLoadingMeta : true}>
			<Card onClick={onClickMeme}>
				<CardBody p={3}>
					<Flex fontSize={'14px'} gap={3}>
						<TokenLogo
							loading={isLoadingMeta}
							logo={image_uri || metadata?.image}
							size={40}
							chainId={ChainId.SOL}
						/>
						<Flex direction={'column'} flex={1} gap={2}>
							<Flex justifyContent={'space-between'} flex={1}>
								<Text fontWeight={'600'}>
									{name} (${symbol})
								</Text>
								<Text
									whiteSpace={'nowrap'}
									fontSize={'12px'}
									textAlign={'right'}
									color={colors.gray[400]}
									minWidth={'60px'}
								>
									{formatTimeRecent(created_timestamp)}
								</Text>
							</Flex>
							<Text fontSize={'12px'}>
								<Text color={colors.gray[400]} as="span">
									Created by:
								</Text>{' '}
								{getShortAddress(creator)}
							</Text>
							{description && (
								<Text
									as="span"
									fontSize={'12px'}
									color={colors.gray[400]}
									sx={{
										wordBreak: 'break-all',
										display: '-webkit-box' /* Establishes the flex container for text truncation */,
										WebkitBoxOrient: 'vertical' /* Specifies the block progression for text flow */,
										overflow: 'hidden' /* Hides the overflowing text */,
										textOverflow: 'ellipsis' /* Adds ellipsis to the truncated text */,
										lineClamp: 3,
										WebkitLineClamp: 3,
										lineHeight: '16px' /* Adjust line height for consistent spacing */,
										maxHeight: 16 * 3 + 'px',
									}}
								>
									{description}
								</Text>
							)}

							<Flex justify={'space-between'} fontSize={'12px'}>
								<Flex gap={1} align={'center'}>
									<Text color={colors.gray[400]} as="span">
										Market Cap:
									</Text>{' '}
									{isFetching ? (
										<Skeleton
											opacity="0.3"
											borderRadius={'4px'}
											style={{ height: '12px', width: '60px' }}
										/>
									) : (
										formatUsd(usd_market_cap || marketData?.marketCap)
									)}
								</Flex>
								{complete ? (
									<Text className="text-gradient ">Complete!</Text>
								) : (
									!loadingPercent && <Text>{percent}%</Text>
								)}
							</Flex>
							{!complete && (
								<Progress
									isIndeterminate={loadingPercent}
									background={BASE_BORDER_COLOR}
									value={percent}
									width={'100%'}
									size="xs"
									colorScheme={'cyan'}
								/>
							)}
						</Flex>
					</Flex>
				</CardBody>
			</Card>
		</Collapse>
	);
}
