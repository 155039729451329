import { tenderlyAPI } from '@/app-cores/api/axios';

export interface TTenderlySimulateTransaction {
	save?: boolean; // if true simulation is saved and shows up in the dashboard
	save_if_fails?: boolean; // if true, reverting simulations show up in the dashboard
	simulation_type?: 'full' | 'abi' | 'quick'; // full, abi or quick (full is default)
	network_id: string; // network to simulate on
	// Standard EVM Transaction object
	from: string;
	to: string;
	input: string;
	gas: number;
	gas_price?: number;
	value?: number;
}
class Tenderly {
	public async simulateTransaction(payload: TTenderlySimulateTransaction): Promise<any> {
		const resp = await tenderlyAPI.post('/', payload);
		return resp.data;
	}
}
export const TenderlyService = new Tenderly();
