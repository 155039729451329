import { useCallback, useMemo, useState } from 'react';
import {
	Card,
	Center,
	Divider,
	Drawer,
	DrawerBody,
	DrawerContent,
	DrawerFooter,
	DrawerHeader,
	DrawerOverlay,
	Flex,
	Image,
	Text,
} from '@chakra-ui/react';
import { approveEIP155Request, rejectEIP155Request } from '../utils/EIP155RequestHandlerUtil';
import { getChainId, getTransactionFee } from '../utils/HelperUtil';
import { web3wallet } from '../utils/WalletConnectUtil';
import { useModalStore } from '../store/ModalStore';
import { toast } from 'react-toastify';
import { CHAIN_CONFIG } from '@/app-constants/chains';
import { useGasFeeData } from '@/app-hooks/wallet';
import { Loading, Toast } from '@/app-components/common';
import { useScanEvmTransaction } from '../hooks/blow-fish/useScanEvmTransaction';
import { MpcWallet } from '@/app-cores/mpc-wallet/wallet';
import { BlowFishWaning } from './BlowFishWaning';
import { TokenAmount } from '../components/TokenAmount';
import { CommonTransactionInfo } from '../components/CommonTransactionInfo';
import { isEmpty } from 'lodash';
import { ActionConfirm } from '../components/ActionConfirm';
import { TransactionItemInfo } from '../components/TransactionItemInfo';
import { RawTransaction } from '../components/RawTransaction';
import { useSimulateTransaction } from '../hooks/tenderly/useSimulateTransaction';

export default function SessionSendTransactionModal() {
	const [isLoadingApprove, setIsLoadingApprove] = useState(false);
	const [isLoadingReject, setIsLoadingReject] = useState(false);
	const { data, open, onClose } = useModalStore();
	const requestEvent = data?.requestEvent;
	const requestSession = data?.requestSession;
	const { topic, params } = requestEvent;
	const chainId = getChainId(params.chainId);
	const { data: gasFeeData } = useGasFeeData(chainId);
	const request = params?.request;
	const transaction = request?.params[0];
	const { peer } = requestSession;
	const walletAddress = MpcWallet.getWalletAddress().evmAddress;

	const { data: tenderlyData, isLoading: tenderlyLoading } = useSimulateTransaction(data.requestEvent.id, {
		network_id: `${Number.parseInt(transaction.chainId, 16)}`,
		from: transaction.from,
		to: transaction.to,
		gas: Number.parseInt(transaction.gas, 16),
		input: transaction.data,
		gas_price: Number.parseInt(transaction.gasPrice, 16),
		value: Number.parseInt(transaction.value, 16),
	});

	// Handle approve action
	const onApprove = useCallback(async () => {
		if (isLoadingApprove) return;
		if (requestEvent && topic) {
			setIsLoadingApprove(true);
			try {
				const response = await approveEIP155Request(requestEvent);
				await web3wallet.respondSessionRequest({
					topic,
					response,
				});
				if (!(response as any)?.error?.message) {
					toast(
						<Toast type="success" title="Approval Success" message="Approval transaction successfully" />,
					);
				}
			} catch (e) {
				setIsLoadingApprove(false);
				toast(<Toast type="error" message={(e as Error).message} />);
			} finally {
				setIsLoadingApprove(false);
				onClose();
			}
		}
		// eslint-disable-next-line
	}, [requestEvent, topic]);

	// Handle reject action
	const onReject = useCallback(async () => {
		if (isLoadingReject) return;
		if (requestEvent && topic) {
			setIsLoadingReject(true);
			const response = rejectEIP155Request(requestEvent);
			try {
				await web3wallet.respondSessionRequest({
					topic,
					response,
				});
				// toast(<Toast type="success" message="Reject transaction successfully" />);
			} catch (e) {
				setIsLoadingReject(false);
			}
			setIsLoadingReject(false);
			onClose();
		}
		// eslint-disable-next-line
	}, [requestEvent, topic]);

	const assetView = useMemo(() => {
		return tenderlyData?.transaction_info?.asset_changes?.map((asset, i) => {
			const isSend = asset.from?.toLowerCase() === walletAddress?.toLowerCase();
			const isReceive = asset.to?.toLowerCase() === walletAddress?.toLowerCase();
			if ((isSend || isReceive) && asset.token_info.decimals) {
				return (
					<TokenAmount
						key={i}
						amount={asset.raw_amount}
						decimals={asset.token_info.decimals}
						image={asset.token_info.logo}
						symbol={asset.token_info.symbol?.toUpperCase()}
						type={isReceive ? 'IN' : 'OUT'}
						dollarValuePerToken={1}
					/>
				);
			}
			return null;
		});
	}, [tenderlyData?.transaction_info?.asset_changes, walletAddress]);

	if (tenderlyLoading) return <Loading />;

	return request && requestSession ? (
		<Drawer isOpen={open} placement="bottom" onClose={onClose} trapFocus={false} closeOnOverlayClick={false}>
			<DrawerOverlay />
			<DrawerContent>
				<DrawerHeader borderBottomWidth="1px" borderColor="rgba(0, 0, 0, 0.08)">
					<Center>
						<Text fontSize={'16px'} fontWeight={'500'}>
							Confirm Transaction
						</Text>
					</Center>
				</DrawerHeader>

				<DrawerBody>
					<Flex mt={2} flexDirection={'column'} alignItems={'center'} gap={'20px'}>
						<Flex flexDirection="column" width="100%">
							<Card px={4} py={2} width={'100%'}>
								<Center gap={2} borderRadius={52} px={2} py={1} mb={2}>
									<Image
										width={8}
										height={8}
										src={peer?.metadata?.icons?.[0]}
										alt=""
										borderRadius="100%"
										fallbackSrc="/assets/images/dapp-fallback.svg"
									/>
									<Text fontSize="sm">{peer?.metadata?.url}</Text>
								</Center>
								<Divider borderBottom="1px dashed rgba(0, 0, 0, 0.16)" />
								{tenderlyData?.transaction_info?.method === 'approve' && (
									<TransactionItemInfo
										title="Method"
										value={
											<Center gap={2}>
												<Text>Approve</Text>
											</Center>
										}
									/>
								)}
								{assetView && assetView}
								<CommonTransactionInfo
									chainId={chainId}
									gasFee={getTransactionFee(transaction?.gas, gasFeeData?.gasPrice, chainId)}
									walletAddress={walletAddress}
								>
									<RawTransaction rawData={JSON.stringify(transaction, null, 2)} />
								</CommonTransactionInfo>
							</Card>
						</Flex>
					</Flex>
				</DrawerBody>

				<DrawerFooter display="flex" gap={2}>
					<ActionConfirm
						onApprove={onApprove}
						onReject={onReject}
						isLoadingApprove={isLoadingApprove}
						isLoadingReject={isLoadingReject}
					/>
				</DrawerFooter>
			</DrawerContent>
		</Drawer>
	) : null;
}
