import { ChainId } from '@/app-constants/chains';
import { axiosBotAPI } from '@/app-cores/api/axios';
import { delay } from '@/app-helpers';

export type AffiliateFeeInfo = {
	totalTransactions: number;
	tokenList: AffiliateTokenInfo[];
};

export type AffiliateStatInfo = {
	totalGroups: number;
	totalUsers: number;
	totalTransactions: number;
	totalTokensEarned: AffiliateTokenInfo[];
};

export type AffiliateGroup = {
	id: string;
	telegramId: string;
	title: string;
	photoUrl: string;
	totalTransactions: number;
	totalTokensEarned: AffiliateTokenInfo[];
	totalMembers: number;
	affiliatePercentage: string;
};

export type AffiliateGroupInfo = {
	totalPage: number;
	currentPage: number;
	// custom
	items: AffiliateGroup[];
	outOfData: boolean;
};

export type AffiliateUser = {
	id: string;
	telegramId: string;
	name: string;
	photoUrl: string;
	totalTransactions: number;
	totalTokensEarned: AffiliateTokenInfo[];
	dateJoined: string; // Assuming date format will be validated separately, keeping as string for flexibility
	affiliatePercentage: string;
};

export type AffiliateUserInfo = {
	totalPage: number;
	currentPage: number;
	// custom
	items: AffiliateUser[];
	outOfData: boolean;
};

export type AffiliateTokenInfo = {
	tobiId: string;
	chainId: ChainId;
	amount: string; // decimals amount
	totalTransactions: number;
};

export type AffiliateGroupFilterItem = { chatId: string; photoUrl: string; title: string };
export type AffiliateUserFilterItem = { id: string; name: string; username: string; photoUrl: string };
export type AffiliateTokenFilerParams = {
	communityIds?: string[];
	userIds?: string[];
};

const formatData = (resp, key: 'groups' | 'users') => {
	if (resp) {
		resp.items = resp[key];
		resp.outOfData = resp.currentPage === resp.totalPage;
	}
	return resp;
};
class AffiliateService {
	async getFeeInfo(): Promise<AffiliateFeeInfo> {
		const data = await axiosBotAPI.get('/affiliate/total-fees-earned', {});
		return data.data;
	}
	async getGroupStat(): Promise<AffiliateStatInfo> {
		const data = await axiosBotAPI.get('/affiliate/groups', {});
		return data.data;
	}
	async getGroupInfo(params: { page: number; limit: number }): Promise<AffiliateGroupInfo> {
		const data = await axiosBotAPI.get('/affiliate/groups/list', { params });
		// console.log('call', params);
		// await delay(2000);
		// return {
		// 	totalPage: 5,
		// 	currentPage: params.page,
		// 	items: new Array(params.limit).fill(1).map((e, i) => ({
		// 		id: Math.random().toString(),
		// 		telegramId: Math.random(),
		// 		title: i + (params.page - 1) * params.limit,
		// 		totalTransactions: 1,
		// 		totalMembers: 1,
		// 		totalTokensEarned: [],
		// 		photoUrl: '',
		// 		affiliatePercentage: 1,
		// 	})),
		// 	outOfData: params.page === 3,
		// };
		return formatData(data.data, 'groups');
	}
	async getUserStat(): Promise<AffiliateStatInfo> {
		const data = await axiosBotAPI.get('/affiliate/users', {});
		return data.data;
	}
	async getUserInfo(params: { page: number; limit: number }): Promise<AffiliateUserInfo> {
		const data = await axiosBotAPI.get('/affiliate/users/list', { params });
		// console.log('call', params);
		// await delay(2000);
		// return {
		// 	totalPage: 5,
		// 	currentPage: params.page,
		// 	items: new Array(params.limit).fill(1).map((e, i) => ({
		// 		id: Math.random().toString(),
		// 		telegramId: Math.random(),
		// 		name: i + params.page * params.limit,
		// 		totalTransactions: 1,
		// 		totalMembers: 1,
		// 		totalTokensEarned: [],
		// 		photoUrl: '',
		// 		affiliatePercentage: 1,
		// 	})),
		// 	outOfData: params.page === 3,
		// };
		return formatData(data.data, 'users');
	}
	async getTokensInfo(params: AffiliateTokenFilerParams): Promise<{ tokens: AffiliateTokenInfo[] }> {
		const data = await axiosBotAPI.get('/affiliate/tokens', { params });
		return data.data;
	}

	async getGroupFilter(params: {
		communityIds?: string;
		userIds?: string;
	}): Promise<{ groups: AffiliateGroupFilterItem[] }> {
		const data = await axiosBotAPI.get('/affiliate/communities', { params });
		return data.data;
	}
	async getUserFilter(params: {
		communityIds?: string;
		userIds?: string;
	}): Promise<{ users: AffiliateUserFilterItem[] }> {
		const data = await axiosBotAPI.get('/affiliate/referred-users', { params });
		return data.data;
	}
}

export const AffiliateServiceAPI = new AffiliateService();
