import { ITokenSearch } from '@/app-cores/api/bff';
import { ListCryptoItem } from '@/app-views/portfolio/components/ListCrypto';
import { Box, Button, ButtonProps, Flex, List, Stack, Text, useDisclosure } from '@chakra-ui/react';

import { LocalLoader } from '@/app-components/common';
import NoData from '@/app-components/common/NoData';
import { useTranslation } from 'react-i18next';

import NoAsset from '@/app-views/wallet/components/Portfolio/NoAsset';

import { CSSProperties, ReactNode, useCallback } from 'react';
import { navigateToTokenDetail } from '@/app-helpers/navigate';
import { getTokenId, getTokenInfo } from '@/app-helpers/token';
import { InfiniteScroll } from '@/app-components/common/InfinitiScroll';
import { ELEMENT_IDS } from '@/app-constants';
import { formatCurrency } from '@/app-helpers/number';

// todo move
export default function ListTokens({
	balances: visibleBalances,
	isLoading,
	emptyPortfolio,
	itemRender,
}: {
	balances: ITokenSearch[] | undefined;
	isLoading: boolean;
	emptyPortfolio?: boolean;
	itemRender;
}) {
	const { t } = useTranslation();

	return (
		<Flex gap={'12px'} direction={'column'}>
			{isLoading ? (
				<LocalLoader height="150px" />
			) : !visibleBalances?.length ? (
				emptyPortfolio ? (
					<NoAsset style={{ border: 'none', padding: 0 }} />
				) : (
					<NoData msg={t('cryptos.noTokenInThisList')} />
				)
			) : (
				<InfiniteScroll
					chunkSize={30}
					itemKey={getTokenId}
					containerId={ELEMENT_IDS.HOME_CONTAINER}
					items={visibleBalances}
					itemRender={itemRender}
				/>
			)}
		</Flex>
	);
}
