import { Avatar, Box, Flex, Image, ListItem, Skeleton, Text } from '@chakra-ui/react';
import { CHAIN_CONFIG, ChainId } from '@/app-constants/chains';
import { CSSProperties, useState } from 'react';
import { Badge } from '..';
import { ITransactionStatus } from '@/app-types';
import { STATUS_BADGE_CONFIG } from '@/app-constants';
import { BASE_BORDER_COLOR } from '@/app-theme/theme';
import { FiQuestionIcon } from '@/assets/icons/fi-question';
import { getChainInfo } from '@/app-helpers/token';

interface AvatarProps extends React.HtmlHTMLAttributes<HTMLSpanElement> {
	symbol?: string;
	logo?: string;
	size?: number | string;
	chainId?: ChainId | string;
	style?: CSSProperties;
	status?: ITransactionStatus;
	chainStyle?: CSSProperties;
	tokenStyle?: CSSProperties;
	loading?: boolean;
}

const BAD_SRC: { [url: string]: 1 } = {};

const TokenLogo: React.FunctionComponent<AvatarProps> = ({
	symbol = '',
	className,
	logo: logoUrl,
	chainId,
	size,
	status,
	chainStyle,
	style: containerStyle,
	tokenStyle,
	loading,
}) => {
	const [, refresh] = useState<number>(0);
	const style = { width: size, height: size, minWidth: size, borderRadius: '100%', ...tokenStyle };

	return (
		<Box
			position={'relative'}
			sx={{ width: 'fit-content', height: 'fit-content', ...containerStyle }}
			className={className}
		>
			{loading ? (
				<Skeleton opacity="0.3" style={style} borderRadius={'100%'} />
			) : BAD_SRC[logoUrl] || !logoUrl ? (
				<FiQuestionIcon height={size} width={size} />
			) : (
				<img
					alt={symbol}
					src={logoUrl}
					style={style}
					onError={() => {
						BAD_SRC[logoUrl] = 1;
						refresh((i) => i + 1);
					}}
				/>
			)}
			{!!chainId && (
				<ChainLogo
					style={{
						position: 'absolute',
						bottom: '-2px',
						right: '-4px',
						height: chainStyle?.width || '18px',
						width: chainStyle?.height || '18px',
						border: `0.5px solid white`,
						background: 'white',
						borderRadius: '100%',
					}}
					chainId={chainId}
				/>
			)}

			{status && (
				<Badge
					position="absolute"
					bottom="-2px"
					right={-1}
					background={STATUS_BADGE_CONFIG[status]?.bgColor}
					type="success"
				>
					{STATUS_BADGE_CONFIG[status]?.icon}
				</Badge>
			)}
		</Box>
	);
};

export const ChainLogo = ({ chainId, style }: { chainId: string | ChainId; style?: CSSProperties }) => {
	const { logo } = getChainInfo(chainId);
	return logo ? <Image src={logo} style={style} /> : <FiQuestionIcon style={style} />;
};

export default TokenLogo;
