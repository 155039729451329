import { LocalLoader } from '@/app-components/common';
import BaseSelect from '@/app-components/common/BaseSelect';
import { AppHeader } from '@/app-components/layout/AppLayout';
import PageLayout from '@/app-components/layout/PageLayout';
import { NAVIGATE_PATHS } from '@/app-constants/router';
import i18n from '@/app-cores/i18n';
import { toQueryString } from '@/app-helpers/url';
import { useGetListMemeByType } from '@/app-hooks/api/pump.fun';
import { useDebounce } from '@/app-hooks/common';
import { CreateEvent, PumpFunEventHandlers, PumpFunSDK, PumpToken, PumpTrade } from '@/app-services/pump.fun';
import { colors } from '@/app-theme/theme';
import { LaunchMemeButton } from '@/app-views/home/containers/TobiFun';
import Header from '@/app-views/tobi-fun/containers/Header';
import { MemeToken } from '@/app-views/tobi-fun/containers/MemeItem';
import { FiCreateTokenIcon } from '@/assets/icons/fi-create-token-icon';
import { Button, Flex } from '@chakra-ui/react';
import { debounce, uniqBy } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';

const convertToPumpToken = (e: CreateEvent): PumpToken => {
	return { ...e, created_timestamp: e.createdAt, creator: e.user } as any as PumpToken;
};

export const ListMemeType = {
	TRENDING: 'coins/for-you',
	NEW_CREATED: 'coins?sort=created_timestamp',
	LAST_TRADE: 'coins?sort=last_trade_timestamp',
	TOP_MARKET_CAP: 'coins?sort=market_cap',
	LAST_REPLY: 'coins?sort=last_reply',
};

const maxItems = 30;
export default function TobiFun() {
	const [tokens, setTokens] = useState<PumpToken[]>([]);
	const [params] = useSearchParams();
	const listType = params.get('filter') || ListMemeType.TRENDING;
	const { data, isFetching } = useGetListMemeByType(listType, maxItems);

	const LIST_LABEL = {
		[ListMemeType.TRENDING]: i18n.t('memepad.trendingTokens'),
		[ListMemeType.NEW_CREATED]: i18n.t('memepad.justCreatedTokens'),
		[ListMemeType.LAST_TRADE]: i18n.t('memepad.lastTradeTokens'),
		[ListMemeType.TOP_MARKET_CAP]: i18n.t('memepad.topMarketCapTokens'),
		[ListMemeType.LAST_REPLY]: i18n.t('memepad.lastReplyTokens'),
	};

	const listOptions = Object.values(ListMemeType).map((v) => ({ label: LIST_LABEL[v], value: v }));

	// const onCreate = useCallback((event: CreateEvent) => {
	// 	const token = convertToPumpToken(event);
	// 	setTokens((v) => uniqBy([token].concat(v), 'mint').slice(0, maxItems));
	// }, []);

	// useSubscribePumpFun({ callback: onCreate, disabled: listType !== ListMemeType.NEW_CREATED, event: 'createEvent' });

	useEffect(() => {
		setTokens(data?.slice(0, maxItems) ?? []);
	}, [data]);

	const tokensDebounce = tokens;
	const navigate = useNavigate();

	return (
		<PageLayout header={<Header />}>
			<Flex direction={'column'} gap={3} pb={6}>
				<Flex gap={2} justifyContent={'space-between'}>
					<LaunchMemeButton textGradient />
					<BaseSelect
						fontSize={'14px'}
						height={'36px'}
						value={listType}
						width={'fit-content'}
						background={'white'}
						borderRadius={'8px'}
						onChange={(e) => {
							navigate(toQueryString(NAVIGATE_PATHS.TobiFun.Main, { filter: e.target.value }), {
								replace: true,
							});
						}}
					>
						{listOptions.map((el) => (
							<option value={el.value} key={el.value}>
								{el.label}
							</option>
						))}
					</BaseSelect>
				</Flex>

				{isFetching ? (
					<LocalLoader height="200px" />
				) : (
					(tokensDebounce || tokens)?.map((e) => <MemeToken key={e.mint} data={e} />)
				)}
			</Flex>
		</PageLayout>
	);
}
