import { fromNano } from '@ton/core';
import { useMutation } from '@tanstack/react-query';
import { MpcWallet } from '@/app-cores/mpc-wallet/wallet';
import { isNativeToken } from '@/app-helpers/address';
import { getMinimumBalanceForRentExemption } from '@/app-helpers/solana';
import { TSentSolTransaction } from '@/app-types';
import { TransactionMessage, VersionedTransaction } from '@solana/web3.js';
import i18n from '@/app-cores/i18n';
import { EclipseWallet } from '@/app-cores/mpc-wallet/eclipse';
import { buildEclipseSLPTransaction, buildEclipseTransaction } from '@/app-helpers/eclipse';

const INSUFFICIENT_FUNDS_FOR_RENT = 'insufficient funds for rent';
async function sendEclipseNativeToken(sendData: TSentSolTransaction) {
	const { connection, fromPubKey, blockHash } = await EclipseWallet.init('mainnet', {
		commitment: 'confirmed',
	});
	try {
		const transferTransaction = await buildEclipseTransaction({
			connection: connection,
			fromPubKey: fromPubKey,
			sendData,
		});
		const messageV0 = new TransactionMessage({
			payerKey: fromPubKey,
			recentBlockhash: blockHash,
			instructions: transferTransaction.instructions,
		}).compileToV0Message();

		const tx = new VersionedTransaction(messageV0);
		tx.addSignature(fromPubKey, await MpcWallet.signEddsaMessage(Buffer.from(tx.message.serialize())));
		const txnSignature = await connection.sendRawTransaction(tx.serialize());
		console.log('eclipse sign txSignature', txnSignature);
		return txnSignature;
	} catch (error) {
		if ((error?.message as string)?.includes(INSUFFICIENT_FUNDS_FOR_RENT)) {
			const minBalanceForRent = await getMinimumBalanceForRentExemption(connection);
			const errorMgs = i18n.t('errors.send.insufficientFundsForRent', {
				amount: fromNano(minBalanceForRent),
				token: 'ETH',
			});
			error.message = errorMgs;
			throw error;
		}
		throw error;
	}
}

async function sendEclipseSLPToken(sendData: TSentSolTransaction) {
	const { connection, fromPubKey, blockHash } = await EclipseWallet.init('mainnet', {
		commitment: 'confirmed',
	});

	const transferTransaction = await buildEclipseSLPTransaction({
		connection: connection,
		fromPubKey: fromPubKey,
		sendData: sendData,
	});
	const messageV0 = new TransactionMessage({
		payerKey: fromPubKey,
		recentBlockhash: blockHash,
		instructions: transferTransaction.instructions,
	}).compileToV0Message();

	const tx = new VersionedTransaction(messageV0);
	tx.addSignature(fromPubKey, await MpcWallet.signEddsaMessage(Buffer.from(tx.message.serialize())));
	const txnSignature = await connection.sendRawTransaction(tx.serialize());
	console.log('eclipse sign txSignature', txnSignature);
	return txnSignature;
}

// for transfer token
export function useSentEclipseToken() {
	const { mutateAsync: sendTransaction, ...result } = useMutation({
		mutationKey: ['sent-eclipse-transaction'],
		mutationFn: async (params: TSentSolTransaction): Promise<string> => {
			if (isNativeToken(params.token.address) || params.token.isNative) {
				return await sendEclipseNativeToken(params);
			} else {
				return await sendEclipseSLPToken(params);
			}
		},
	});
	return {
		...result,
		sendTransaction,
	};
}
