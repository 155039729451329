import { CopyToClipboard } from '@/app-components/common';
import { ChainId } from '@/app-constants/chains';
import { getShortAddress } from '@/app-helpers/address';
import { formatUnits } from '@/app-helpers/number';
import { formatTimeRecent } from '@/app-helpers/time';
import { getNativeToken } from '@/app-helpers/token';
import { PumpToken, PumpTrade, TokenMetadata } from '@/app-services/pump.fun';
import { colors } from '@/app-theme/theme';
import { PUMP_TOKEN_DECIMALS } from '@/app-views/tobi-fun/helpers';
import { Avatar, Flex, Text } from '@chakra-ui/react';

export default function TradeItem({
	data: { is_buy: isBuy, sol_amount: solAmount, token_amount: tokenAmount, timestamp, signature },
	token,
}: {
	data: PumpTrade;
	token: PumpToken;
}) {
	const native = getNativeToken(ChainId.SOL);

	return (
		<Flex w={'100%'} direction={'column'} fontSize={'12px'} gap={2}>
			<Flex justify={'space-between'}>
				<Flex fontSize={'14px'} fontWeight={'500'} gap={2}>
					<CopyToClipboard text={signature}>{getShortAddress(signature)}</CopyToClipboard>
				</Flex>
				<Text fontWeight={'500'}>
					+ {formatUnits(isBuy ? tokenAmount : solAmount, isBuy ? PUMP_TOKEN_DECIMALS : native.decimals)}{' '}
					{isBuy ? token?.symbol : native.symbol}
				</Text>
			</Flex>
			<Flex justify={'space-between'}>
				<Flex gap={2}>
					<Text color={isBuy ? colors.green[200] : colors.red[200]}>{isBuy ? 'Buy' : 'Sell'} </Text>
					<Text>•</Text>
					<Text color={colors.gray[300]}>{formatTimeRecent(timestamp * 1000)}</Text>
				</Flex>
				<Text fontWeight={'500'}>
					- {formatUnits(!isBuy ? tokenAmount : solAmount, !isBuy ? PUMP_TOKEN_DECIMALS : native.decimals)}{' '}
					{!isBuy ? token?.symbol : native.symbol}
				</Text>
			</Flex>
		</Flex>
	);
}
