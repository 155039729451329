import { EddsaKeyshare, Keyshare, KeyshareV0 } from '@telifi/dkls-wasm';
import { getWalletAddress } from './helper';
import { decodeHex, encodeHex } from '../mpc/lib';
import { MpcWallet, PRE_COMPUTE_KEYSHARE_ERROR } from '.';
import isEmpty from 'lodash/isEmpty';
import { TGCloudStorage } from './tg-cloud-storage';
import { compareAddress } from '@/app-helpers/address';
import { DATADOG_ACTIONS, dataDogAddAction } from '@/app-services/monitor/datadog';
import { STORAGE_KEYS } from '@/app-constants/storage';
import { WalletContractV4 } from '../ton/WalletContractV4';
import { Address } from '@ton/core';

export const MAX_ITEM_SIZE = 4096;
export type UserWallet = { evmWallet: string; tonWallet: string };
export type KeySharesStore = {
	ecdsaKeyShare?: string;
	eddsaKeyShare?: string;
};
export type KeyShares2Store = {
	ecdsaKeyShare2: string;
	eddsaKeyShare2: string;
};
export const TC_KEY_SHARE_METADATA = 'KeyShare-Metadata';
export abstract class AbstractKeyShareManager {
	public readonly TC_KEY_SHARE_METADATA = TC_KEY_SHARE_METADATA;
	public readonly TC_KEY_SHARE_PART = 'KeyShare-Part';
	readonly KEY_SHARE_V0 = 'Telifi-KeyShare';
	readonly KEY_SHARE = STORAGE_KEYS.TOBI_KEYSHARE;
	readonly OLD_KEY_SHARE = 'Telifi-KeyShare-V1';
	keyShareV0: KeyshareV0 = undefined;
	keyShareV0Hex: string = undefined;
	keyShares: {
		ecdsaKeyShare: Keyshare;
		eddsaKeyShare: EddsaKeyshare;
	} = {
		ecdsaKeyShare: undefined,
		eddsaKeyShare: undefined,
	};

	public setKeyShare(keyShare: Keyshare) {
		this.keyShares.ecdsaKeyShare = keyShare;
	}

	public setEddsaKeyShare(keyShare: EddsaKeyshare) {
		this.keyShares.eddsaKeyShare = keyShare;
	}

	getAddress(): {
		ecdsaAddress: string;
		eddsaAddress: string;
	} {
		if (!this.keyShares?.ecdsaKeyShare)
			return {
				ecdsaAddress: '0x',
				eddsaAddress: '',
			};
		return {
			ecdsaAddress: getWalletAddress(encodeHex(this.keyShares?.ecdsaKeyShare?.publicKey())),
			eddsaAddress: this.keyShares?.eddsaKeyShare ? encodeHex(this.keyShares?.eddsaKeyShare?.publicKey()) : '',
		};
	}

	abstract getKeyshareVersion(): number;
	private compareWalletAddress(keyShare: Keyshare, currentWalletAddress: string): boolean {
		return compareAddress(getWalletAddress(encodeHex(keyShare?.publicKey())), currentWalletAddress);
	}
	/**
	preComputeLocalKeyShare(
		keyShareStored: string,
		{ evmWallet, tonWallet }: UserWallet,
	): {
		ecdsaKeyShare: Keyshare;
		eddsaKeyShare: EddsaKeyshare;
	} {
		try {
			console.log('pre compute local keyShare');
			dataDogAddAction(DATADOG_ACTIONS.START_APP.RECOMPUTE_LOCAL_KEYSHARE);
			//Try to pre compute keyshare stored with obj type in new version
			const keyShareObj = JSON.parse(keyShareStored) as KeySharesStore;
			const ecdsaKeyShareBytes = decodeHex(keyShareObj.ecdsaKeyShare);
			const ecdsaKeyShare = Keyshare.fromBytes(ecdsaKeyShareBytes);
			// Incase delete wallet for testing
			// The other device still stores the keyshare of the previous wallet
			if (!this.compareWalletAddress(ecdsaKeyShare, evmWallet)) {
				throw new Error(PRE_COMPUTE_KEYSHARE_ERROR);
			}
			this.keyShares.ecdsaKeyShare = ecdsaKeyShare;
			if (keyShareObj.eddsaKeyShare) {
				const eddsaKeyShareBytes = decodeHex(keyShareObj.eddsaKeyShare);
				this.keyShares.eddsaKeyShare = EddsaKeyshare.fromBytes(eddsaKeyShareBytes);
			}
			if (tonWallet && MpcWallet.getTonWalletAddress() !== tonWallet) {
				throw new Error(PRE_COMPUTE_KEYSHARE_ERROR);
			}
			if (this.keyShares) {
				dataDogAddAction(DATADOG_ACTIONS.START_APP.RECOMPUTE_LOCAL_KEYSHARE_SUCCESS);
			}
			return this.keyShares;
		} catch (error) {
			dataDogAddAction(DATADOG_ACTIONS.START_APP.RECOMPUTE_LOCAL_KEYSHARE_ERROR);
			console.log('Precomputed keyshare local try with obj keyshare ', error);
		}
		try {
			//Try to pre compute keyshare stored with string type in old version
			const keyShareBytes = decodeHex(keyShareStored);
			const ecdsaKeyShare = Keyshare.fromBytes(keyShareBytes);
			if (!this.compareWalletAddress(ecdsaKeyShare, evmWallet)) {
				throw new Error(PRE_COMPUTE_KEYSHARE_ERROR);
			}
			if (tonWallet && MpcWallet.getTonWalletAddress() !== tonWallet) {
				throw new Error(PRE_COMPUTE_KEYSHARE_ERROR);
			}
			this.keyShares.ecdsaKeyShare = ecdsaKeyShare;
			return this.keyShares;
		} catch (error) {
			console.log('Precomputed keyshare local try with string keyshare ', error);
		}
	}
	preComputeTGCloudKeyShare = async () => {
		try {
			console.log('pre compute TG Cloud keyShare');
			dataDogAddAction(DATADOG_ACTIONS.START_APP.RECOMPUTE_TG_KEYSHARE);
			const metadata = await TGCloudStorage.getItem<string>(this.TC_KEY_SHARE_METADATA);
			if (isEmpty(metadata)) throw new Error(PRE_COMPUTE_KEYSHARE_ERROR);
			const { version, totalParts } = JSON.parse(metadata);
			console.time('startLoadKeyShareTGCloud');
			const keyShareStored = await this.loadFromCloud(version, totalParts);
			console.timeEnd('startLoadKeyShareTGCloud');
			console.log('load keyshare from cloud successfully');
			try {
				const keyShareObj = JSON.parse(keyShareStored) as KeySharesStore;
				const ecdsaKeyShareBytes = decodeHex(keyShareObj.ecdsaKeyShare);
				this.keyShares.ecdsaKeyShare = Keyshare.fromBytes(ecdsaKeyShareBytes);
				if (keyShareObj.eddsaKeyShare) {
					const eddsaKeyShareBytes = decodeHex(keyShareObj.eddsaKeyShare);
					this.keyShares.eddsaKeyShare = EddsaKeyshare.fromBytes(eddsaKeyShareBytes);
				}
				localStorage.setItem(this.KEY_SHARE, keyShareStored);
				dataDogAddAction(DATADOG_ACTIONS.START_APP.RECOMPUTE_TG_KEYSHARE_SUCCESS);
				return;
			} catch (error) {
				const keyShareBytes = decodeHex(keyShareStored);
				const keyshare = Keyshare.fromBytes(keyShareBytes);
				this.keyShares.ecdsaKeyShare = keyshare;
				localStorage.setItem(
					this.KEY_SHARE,
					JSON.stringify({
						ecdsaKeyShare: keyShareStored,
					}),
				);
			}
		} catch (error) {
			console.log('preComputeKeyShare 1 error ', error);
			dataDogAddAction(DATADOG_ACTIONS.START_APP.RECOMPUTE_TG_KEYSHARE_ERROR);
			throw new Error(PRE_COMPUTE_KEYSHARE_ERROR);
		}
	};
     */

	private decodeAndValidateKeyShares(
		keyShareStored: string,
		evmWallet: string,
		tonWallet?: string,
	): { ecdsaKeyShare: Keyshare; eddsaKeyShare: EddsaKeyshare } {
		const keyShareObj = JSON.parse(keyShareStored) as KeySharesStore;
		const ecdsaKeyShareBytes = decodeHex(keyShareObj.ecdsaKeyShare);
		const ecdsaKeyShare = Keyshare.fromBytes(ecdsaKeyShareBytes);
		const eddsaKeyShareBytes = decodeHex(keyShareObj.eddsaKeyShare);
		const eddsaKeyShare = EddsaKeyshare.fromBytes(eddsaKeyShareBytes);
		const wallet = WalletContractV4.create({
			workchain: 0,
			publicKey: Buffer.from(eddsaKeyShare?.publicKey()),
		});
		const tonLocalAddress = wallet.address.toRawString();
		if (
			!this.compareWalletAddress(ecdsaKeyShare, evmWallet) ||
			(tonWallet && tonLocalAddress !== Address.parse(tonWallet).toRawString())
		) {
			throw new Error(PRE_COMPUTE_KEYSHARE_ERROR);
		}
		this.keyShares.ecdsaKeyShare = ecdsaKeyShare;
		this.keyShares.eddsaKeyShare = eddsaKeyShare;
		return this.keyShares;
	}

	preComputeLocalKeyShare(
		keyShareStored: string,
		{ evmWallet, tonWallet }: UserWallet,
	): { ecdsaKeyShare: Keyshare; eddsaKeyShare: EddsaKeyshare } {
		try {
			console.log('pre compute local keyShare');
			dataDogAddAction(DATADOG_ACTIONS.START_APP.RECOMPUTE_LOCAL_KEYSHARE);

			const keyShares = this.decodeAndValidateKeyShares(keyShareStored, evmWallet, tonWallet);

			dataDogAddAction(DATADOG_ACTIONS.START_APP.RECOMPUTE_LOCAL_KEYSHARE_SUCCESS);
			return keyShares;
		} catch (error) {
			dataDogAddAction(DATADOG_ACTIONS.START_APP.RECOMPUTE_LOCAL_KEYSHARE_ERROR);
			console.error('Error during preComputeLocalKeyShare:', error);
		}
	}

	preComputeTGCloudKeyShare = async ({ evmWallet, tonWallet }: UserWallet) => {
		try {
			console.log('pre compute TG Cloud keyShare');
			dataDogAddAction(DATADOG_ACTIONS.START_APP.RECOMPUTE_TG_KEYSHARE);

			const metadata = await TGCloudStorage.getItem<string>(this.TC_KEY_SHARE_METADATA);
			if (isEmpty(metadata)) throw new Error(PRE_COMPUTE_KEYSHARE_ERROR);

			const { version, totalParts } = JSON.parse(metadata);
			console.time('startLoadKeyShareTGCloud');
			const keyShareStored = await this.loadFromCloud(version, totalParts);
			console.timeEnd('startLoadKeyShareTGCloud');

			console.log('load keyshare from cloud successfully');
			this.decodeAndValidateKeyShares(keyShareStored, evmWallet, tonWallet);

			localStorage.setItem(this.KEY_SHARE, keyShareStored);
			dataDogAddAction(DATADOG_ACTIONS.START_APP.RECOMPUTE_TG_KEYSHARE_SUCCESS);
		} catch (error) {
			console.error('Error during preComputeTGCloudKeyShare:', error);
			dataDogAddAction(DATADOG_ACTIONS.START_APP.RECOMPUTE_TG_KEYSHARE_ERROR);
			throw new Error(PRE_COMPUTE_KEYSHARE_ERROR);
		}
	};

	getKeyshareStored() {
		let keyShareStored = localStorage.getItem(this.KEY_SHARE);
		if (isEmpty(keyShareStored)) {
			keyShareStored = localStorage.getItem(this.OLD_KEY_SHARE);
		}
		return keyShareStored;
	}

	preComputeKeyShare = async ({ evmWallet, tonWallet }: UserWallet): Promise<void> => {
		const keyShareStored = this.getKeyshareStored();
		let rs;
		if (keyShareStored) {
			rs = this.preComputeLocalKeyShare(keyShareStored, {
				evmWallet,
				tonWallet,
			});
		}
		if (!rs) {
			await this.preComputeTGCloudKeyShare({
				evmWallet,
				tonWallet,
			});
		}
	};

	persistKeyShare = async () => {
		const ecdsaKeyShareHex = encodeHex(this.keyShares.ecdsaKeyShare.toBytes());
		const eddsaKeyShareHex = encodeHex(this.keyShares.eddsaKeyShare.toBytes());
		localStorage.setItem(
			this.KEY_SHARE,
			JSON.stringify({
				ecdsaKeyShare: ecdsaKeyShareHex,
				eddsaKeyShare: eddsaKeyShareHex,
			}),
		);

		this.removeLocalMetadata();
		await this.removeCloudKeyShare();
		console.log('Start Uploading TG Key Share');
		await this.saveKeyshareToTGCloud();
	};
	getKeyShares() {
		try {
			const keyShares = this.getKeyshareStored();
			return JSON.parse(keyShares);
		} catch (error) {
			return this.getKeyshareStored();
		}
	}
	persistEddsaKeyShare = async () => {
		const keyShare = this.getKeyShares();
		const keyShares = {
			ecdsaKeyShare: keyShare,
			eddsaKeyShare: encodeHex(this.keyShares.eddsaKeyShare.toBytes()),
		};
		localStorage.setItem(this.KEY_SHARE, JSON.stringify(keyShares));
		//TODO: need to upload key shares to TG cloud storage
		// this.removeLocalMetadata();
		// await this.removeCloudKeyShare();
		// this.saveToCloud(true);
	};
	removeKeyShare = () => {};
	removeCloudKeyShare = async () => {
		const metadata = await TGCloudStorage.getItem<string>(this.TC_KEY_SHARE_METADATA);
		console.log('start remove pre TG keyShare', metadata);
		if (!isEmpty(metadata)) {
			const { totalParts } = JSON.parse(metadata);
			await TGCloudStorage.removeItems([
				this.TC_KEY_SHARE_METADATA,
				...Array.from({ length: totalParts }).map((_, i) => `${this.TC_KEY_SHARE_PART}${i + 1}`),
			]);
			console.log('remove TG complete');
		}
	};
	removeLocalMetadata = () => {
		localStorage.removeItem(this.TC_KEY_SHARE_METADATA);
	};

	saveToCloudIfNeeded = async () => {
		// Write local keyshare to telegram cloud storage
		const metadata = (await TGCloudStorage.getItem(this.TC_KEY_SHARE_METADATA)) as string;

		// In fact, if the version is lower than the current version, we also need to write back
		if (isEmpty(metadata)) {
			console.log('Continue updating keyshare to TGC');
			this.saveKeyshareToCloudIfNeeded();
		} else if (JSON.parse(metadata).version < this.getKeyshareVersion()) {
			await TGCloudStorage.removeItems([
				this.TC_KEY_SHARE_METADATA,
				...Array.from({ length: JSON.parse(metadata).totalParts }).map(
					(_, i) => `${this.TC_KEY_SHARE_PART}${i + 1}`,
				),
			]);

			this.removeLocalMetadata();
			this.saveKeyshareToTGCloud();
		}
	};

	abstract shouldMigrate(): boolean;

	abstract saveKeyshareToTGCloud();
	abstract saveKeyshareToCloudIfNeeded();

	abstract loadFromCloud(version: number, totalParts: number): Promise<string>;
}
