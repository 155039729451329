export type MpcWalletStatus =
	| 'CREATING'
	| 'CREATE_FAILED'
	| 'INITIALIZING'
	| 'VALIDATED'
	| 'MISSING_REMOTE_KEY'
	| 'MISSING_LOCAL_KEY'
	| 'ERROR'
	| 'REQUIRE_BACKUP'
	| 'REQUIRE_CREATE'
	| 'REQUIRE_RECOVERY';

export const PRE_COMPUTE_KEYSHARE_ERROR = 'Invalid keyShare';
export const BACKUP_KEYSHARE_VERSION_1 = '1.0.0';
export const BACKUP_KEYSHARE_VERSION_2 = '2.0.0';
export enum VALIDATE_KEYSHARE_CODE {
	VALID = 'VALID',
	KEY_SHARES_ARE_THE_SAME = 'KEY_SHARES_ARE_THE_SAME',
	INVALID_KEY_SHARE = 'INVALID_KEY_SHARE',
	INVALID_KEY_SHARE_2 = 'INVALID_KEY_SHARE_2',
	DIFFERENT_PUBLIC_KEY = 'DIFFERENT_PUBLIC_KEY',
}
export const VALIDATE_KEYSHARE_ERROR_MESSAGES = {
	[VALIDATE_KEYSHARE_CODE.INVALID_KEY_SHARE]: 'Invalid key share',
	[VALIDATE_KEYSHARE_CODE.INVALID_KEY_SHARE_2]: 'Invalid key share 2',
	[VALIDATE_KEYSHARE_CODE.DIFFERENT_PUBLIC_KEY]: 'KeyShares other than public key',
};
export const REQUIRE_IMPORT_PRE_BACKUP_KEY_SHARE = 'require-import-pre-backup-key-share';
