import { CHAIN_CONFIG, ChainId, NATIVE_TOKEN_ADDRESS, ONE_ADDRESS, ZERO_ADDRESS } from '@/app-constants/chains';
import { TokenInfo } from '@/app-cores/api/bff';
import { compareAddress, compareChain, isNativeToken } from '@/app-helpers/address';
import { calculatePriceImpact, commonGetRoute, isRouteParamsEmpty } from '@/app-hooks/swap';
import {
	ArgsGetRoute,
	FEE_ACCOUNTS,
	JUPITER_REFERRAL_PUBLIC_KEY,
	MAX_FEE_SOL_SWAP,
	SWAP_FEE_PERCENT,
	SwapAbstract,
	UpdateStatusFn,
	UsdRouteInfo,
} from '@/app-hooks/swap/type';
import { ExtractRouteInfo, SwapProvider } from '@/app-hooks/swap/type';
import { SelectedRoute, SwapDisableType } from '@/app-store/swap';
import { useMutation } from '@tanstack/react-query';
import { AxiosError, AxiosRequestConfig } from 'axios';
import { ethers } from 'ethers';

import { getNativeToken, isEvmChain, isSolanaChain, isTonChain } from '@/app-helpers/token';
import { AUTO_SLIPPAGE } from '@/app-views/swap/components/SlippageSetting';
import { estimateGasEvm, setDefaultGasFeeData, useSubmitEVMTransaction } from '@/app-hooks/transactions';
import { useSubmitSolTransaction } from '@/app-hooks/transactions/sol/useMutationSentSolAsset';
import { delay } from '@/app-helpers';
import { uniqueId } from '@/app-helpers/random';
import { TTransactionRequest, TransactionType } from '@/app-types';
import { queryClient } from '@/app-cores/query-client';
import { QUERY_KEYS } from '@/app-constants';
import { EIP155GasPrice } from '@/app-cores/api/infura/type';
import { getSigner } from '@/app-helpers/web3';
import isEmpty from 'lodash/isEmpty';
import { displayMaxFee } from '@/app-hooks/swap';
import { calcRateSwap, filterParams, getMyWalletAddressByChain } from '@/app-hooks/swap/helper';
import { getGasInfo } from '@/app-hooks/api/transactions/useQueryGasPrice';
import { useTakeFeeSwap } from '@/app-hooks/swap/useTakeFeeSwap';

const REFERRAL_CODE = 22522;
const DEBRIDGE_API_DOMAIN = 'https://api.dln.trade';

const getAddress = (token: TokenInfo) =>
	isNativeToken(token?.address) ? (isSolanaChain(token?.chainId) ? ONE_ADDRESS : ZERO_ADDRESS) : token?.address;

const getChainId = (token: TokenInfo) => (isSolanaChain(token?.chainId) ? 7565164 : token?.chainId);

class Debridge extends SwapAbstract<RouteDebridge> {
	provider = SwapProvider.DEBRIDGE;
	async init() {
		this.checkIP(SwapProvider.DEBRIDGE);
	}
	async getRoute(paramsSwap: ArgsGetRoute, signal: AbortSignal, isRetry = false) {
		const { tokenIn, tokenOut, slippage } = paramsSwap;
		const isSameChain = compareChain(tokenIn?.chainId, tokenOut?.chainId);
		const payload = isSameChain
			? getRouteParamsSameChain(paramsSwap, signal, this.formatSlippage(slippage))
			: getRouteParamsCrossChain(paramsSwap, signal, this.formatSlippage(slippage));
		if (!payload) return;
		try {
			const chainId = tokenIn?.chainId?.toString();
			const needEstimateGas = isSameChain && isEvmChain(chainId);

			const [data, gasInfo] = await Promise.all([
				commonGetRoute(payload, paramsSwap),
				needEstimateGas ? getGasInfo(chainId) : null,
			]);

			let formatData = formatRoute(data, paramsSwap);
			if (!isSameChain) {
				const { amountOut } = this.extractRoute(formatData, {});
				if (amountOut === '0') throw new Error('Amount in < fixed fee');
			}
			if (needEstimateGas) {
				formatData = await this._estimateGasEvm(
					formatData as SelectedRoute<RouteDebridgeSameChain>,
					chainId,
					gasInfo,
				);
			}
			return formatData;
		} catch (error) {
			const needRetry = ((error as AxiosError)?.response?.data as any)?.errorId === 'RATE_OUTDATED';
			if (needRetry && !isRetry) {
				await delay(1000);
				return this.getRoute(paramsSwap, signal, true);
			}
			throw error;
		}
	}
	formatSlippage(slippage: string | number): number | string {
		return +slippage === AUTO_SLIPPAGE ? undefined : +slippage;
	}

	private async _estimateGasEvm(
		routeData: SelectedRoute<RouteDebridgeSameChain>,
		chainId: string,
		{ feeData, gasPrice }: { feeData: EIP155GasPrice; gasPrice: bigint },
	) {
		const {
			route: { tx },
		} = routeData;
		const payload = await estimateGasEvm(getEvmPayload(tx, chainId), feeData, false);
		routeData.route.tx.gasNative = BigInt(payload.gasLimit) * gasPrice;
		return routeData;
	}
	extractRoute(params: SelectedRoute<RouteDebridge>, prices: UsdRouteInfo): ExtractRouteInfo {
		return getExtractRouteDebridge(params, prices);
	}
}

export const DebridgeSwap = new Debridge();

const getRouteParamsCrossChain = (args: ArgsGetRoute, signal: AbortSignal, slippage): AxiosRequestConfig => {
	const { tokenIn, tokenOut, amountIn } = args;
	if (isRouteParamsEmpty(args)) return;

	const params = {
		slippage,
		srcChainId: getChainId(tokenIn),
		srcChainTokenIn: getAddress(tokenIn),
		srcChainTokenInAmount: amountIn,
		dstChainId: getChainId(tokenOut),
		dstChainTokenOut: getAddress(tokenOut),
		referralCode: REFERRAL_CODE,
		affiliateFeePercent: SWAP_FEE_PERCENT,
		affiliateFeeRecipient: isEvmChain(tokenIn?.chainId)
			? FEE_ACCOUNTS.EVM
			: isSolanaChain(tokenIn?.chainId)
			? JUPITER_REFERRAL_PUBLIC_KEY
			: isTonChain(tokenIn?.chainId)
			? FEE_ACCOUNTS.TON
			: undefined,

		senderAddress: getMyWalletAddressByChain(tokenIn?.chainId),
		srcChainOrderAuthorityAddress: getMyWalletAddressByChain(tokenIn?.chainId),
		dstChainTokenOutRecipient: getMyWalletAddressByChain(tokenOut?.chainId),
		dstChainOrderAuthorityAddress: getMyWalletAddressByChain(tokenOut?.chainId),

		prependOperatingExpenses: false,
		enableEstimate: false,
	};

	filterParams(params);
	return { url: `${DEBRIDGE_API_DOMAIN}/v1.0/dln/order/create-tx`, params, signal };
};

const getRouteParamsSameChain = (args: ArgsGetRoute, signal: AbortSignal, slippage): AxiosRequestConfig => {
	const { tokenIn, tokenOut, amountIn } = args;
	if (isRouteParamsEmpty(args)) return;

	const params = {
		chainId: getChainId(tokenIn),
		tokenIn: getAddress(tokenIn),
		tokenInAmount: amountIn,
		slippage,
		tokenOut: getAddress(tokenOut),
		affiliateFeePercent: SWAP_FEE_PERCENT,
		affiliateFeeRecipient: isEvmChain(tokenIn?.chainId)
			? FEE_ACCOUNTS.EVM
			: isSolanaChain(tokenIn?.chainId)
			? JUPITER_REFERRAL_PUBLIC_KEY
			: isTonChain(tokenIn?.chainId)
			? FEE_ACCOUNTS.TON
			: undefined,
		tokenOutRecipient: getMyWalletAddressByChain(tokenIn?.chainId),
		referralCode: REFERRAL_CODE,
	};

	filterParams(params);
	return { url: `${DEBRIDGE_API_DOMAIN}/v1.0/chain/transaction`, params, signal };
};

type Token = {
	name: string;
	symbol: string;
	chainId: number;
	address?: string;
	decimals: number;
	amount: string;
	approximateOperatingExpense?: string;
	mutatedWithOperatingExpense?: boolean;
	maxRefundAmount?: string;
	recommendedAmount?: string;
	maxTheoreticalAmount?: string;
};

type CostsDetails = {
	chain: string;
	tokenIn: string;
	tokenOut: string;
	amountIn: string;
	amountOut: string;
	type: string;
	payload?: {
		feeAmount: string;
		feeBps?: string;
	};
};

type Estimation = {
	srcChainTokenIn: Token;
	srcChainTokenOut: Token;
	dstChainTokenOut: Token;
	costsDetails: CostsDetails[];
	recommendedSlippage: number;
};

type Order = {
	approximateFulfillmentDelay: number;
};

export type RouteDebridge = RouteDebridgeCrossChain | RouteDebridgeSameChain;

type RouteDebridgeCrossChain = {
	estimation: Estimation;
	prependedOperatingExpenseCost: string;
	order: Order;
	fixFee: string;
	userPoints: number;
	integratorPoints: number;
	tx: { allowanceTarget: string; data: string; to: string; value: string };
	// custom
	tokenIn: TokenInfo;
	tokenOut: TokenInfo;
};

type TokenInfoSameChain = {
	address: string;
	name: string;
	symbol: string;
	decimals: number;
	amount?: string;
	minAmount?: string;
};

export type RouteDebridgeSameChain = {
	tokenIn: TokenInfoSameChain;
	tokenOut: TokenInfoSameChain;
	tx: {
		from: string;
		to: string;
		data: string;
		value: string;
		gas: number;
		gasPrice: string;
		gasNative?: bigint; // custom
	};
};

const formatRoute = (routeData: RouteDebridge, params: ArgsGetRoute): SelectedRoute<RouteDebridge> =>
	routeData
		? ({
				...routeData,
				route: routeData,
				routerAddress: routeData?.tx?.to,
				id: uniqueId(),
				provider: SwapProvider.DEBRIDGE,
				timestamp: Date.now(),
				disabled: DebridgeSwap.isBlocked ? SwapDisableType.LOCATION : undefined,
				params,
		  } as SelectedRoute<RouteDebridge>)
		: undefined;

const getExtractRouteDebridge = (
	selectedRoute: SelectedRoute<RouteDebridge>,
	prices: UsdRouteInfo = {},
): ExtractRouteInfo => {
	const { tokenIn, tokenOut } = selectedRoute;
	const isSameChain = compareChain(tokenIn?.chainId, tokenOut?.chainId);
	return isSameChain
		? getExtractRouteDebridgeSameChain(selectedRoute as SelectedRoute<RouteDebridgeSameChain>, prices)
		: getExtractRouteDebridgeCrossChain(selectedRoute as SelectedRoute<RouteDebridgeCrossChain>, prices);
};

const dappInfo = {
	logo: '/icons/brands/debridge.svg',
	domain: SwapProvider.DEBRIDGE,
};

const getExtractRouteDebridgeCrossChain = (
	selectedRoute: SelectedRoute<RouteDebridgeCrossChain>,
	{ usdPriceIn, usdPriceOut, usdPriceNative }: UsdRouteInfo = {},
): ExtractRouteInfo => {
	const routeSwap = selectedRoute?.route;
	const tokenIn = selectedRoute?.tokenIn;
	const tokenOut = selectedRoute?.tokenOut;

	const estimate = routeSwap?.estimation;
	const marketMakerGasFee = estimate?.srcChainTokenIn?.approximateOperatingExpense ?? '0';

	const amountIn = estimate?.srcChainTokenIn?.amount;
	const amountOut = estimate?.dstChainTokenOut?.recommendedAmount;
	const amountInUsd = usdPriceIn * +ethers.formatUnits(amountIn, tokenIn?.decimals);
	const amountOutUsd = usdPriceOut * +ethers.formatUnits(amountOut, tokenOut?.decimals);

	const rate = calcRateSwap({ amountIn, amountOut, tokenIn, tokenOut });

	const isNative = isNativeToken(tokenIn?.address);
	const gasNative = BigInt(routeSwap?.fixFee) + (isNative ? BigInt(marketMakerGasFee) : 0n);
	const nativeToken = getNativeToken(tokenIn?.chainId);

	return {
		amountInUsd,
		amountOutUsd,
		rate,
		amountOut: estimate?.dstChainTokenOut?.amount,
		amountIn: amountIn?.toString(),
		tokenIn,
		tokenOut,
		gasUsd:
			usdPriceNative && gasNative
				? +ethers.formatUnits(gasNative, nativeToken?.decimals) * usdPriceNative
				: undefined,
		duration: routeSwap?.order?.approximateFulfillmentDelay,
		gasNative,
		dappInfo,
	};
};

const formatToken = (token: TokenInfoSameChain, tokenCatalog: TokenInfo): TokenInfo | undefined => {
	if (!token) return;
	const rs = Object.assign(tokenCatalog || token, {
		...token,
		address: compareAddress(token?.address, ONE_ADDRESS) ? NATIVE_TOKEN_ADDRESS : token?.address,
	} as TokenInfo);
	return rs;
};
const getExtractRouteDebridgeSameChain = (
	selectedRoute: SelectedRoute<RouteDebridgeSameChain>,
	{ usdPriceIn, usdPriceOut, usdPriceNative }: UsdRouteInfo = {},
): ExtractRouteInfo => {
	const routeSwap = selectedRoute?.route;
	const tokenIn = formatToken(routeSwap?.tokenIn, selectedRoute?.tokenIn);
	const tokenOut = formatToken(routeSwap?.tokenOut, selectedRoute?.tokenOut);

	const amountIn = routeSwap?.tokenIn?.amount;
	const amountOut = routeSwap?.tokenOut?.amount;

	const native = getNativeToken(tokenIn?.chainId);
	return {
		amountOut,
		amountIn,
		minAmountOut: routeSwap?.tokenOut?.minAmount,
		tokenIn,
		tokenOut,
		duration: undefined,
		...(isSolanaChain(tokenIn?.chainId)
			? {
					gasNative: BigInt(CHAIN_CONFIG[ChainId.SOL].minForGas),
					gasDisplay: displayMaxFee({
						usdPriceNative,
						chainId: ChainId.SOL,
						gasNative: MAX_FEE_SOL_SWAP,
					}),
					gasUsd: undefined,
			  }
			: {
					gasNative: routeSwap?.tx?.gasNative,
					gasUsd: routeSwap?.tx?.gasNative
						? +ethers.formatUnits(routeSwap?.tx?.gasNative, native?.decimals) * usdPriceNative
						: undefined,
			  }),
		dappInfo,
	};
};

const getEvmPayload = (tx, chainId) => {
	return { to: tx.to, data: tx.data, value: tx.value, gasLevel: 'aggressive', chainId } as TTransactionRequest;
};

export const useExecuteRouteDebridge = (chainId: ChainId | string) => {
	const { sendTransaction: submitSolTxs } = useSubmitSolTransaction();
	const { sentTransaction } = useSubmitEVMTransaction(chainId);
	const takeFee = useTakeFeeSwap();
	const response = useMutation({
		mutationKey: ['build-route-debridge'],
		mutationFn: async ({
			route: routeData,
			onUpdateStatus,
		}: {
			route: SelectedRoute<RouteDebridge>;
			onUpdateStatus: UpdateStatusFn;
		}) => {
			const { tokenIn, route } = routeData;
			const { tx } = route;
			if (!tx?.data) throw new Error('Missing data');
			if (isEvmChain(tokenIn?.chainId)) {
				if (!tx.to) throw new Error('Unknown to address');
				return sentTransaction({
					...getEvmPayload(tx, chainId),
					transactionType: TransactionType.Swap,
					metadata: routeData,
				});
			}
			if (isSolanaChain(tokenIn?.chainId)) {
				const hash = await submitSolTxs({
					data: Buffer.from(tx.data.slice(2), 'hex'),
					metadata: routeData,
					transactionType: TransactionType.Swap,
					callback: onUpdateStatus,
				});
				return { hash, nonce: undefined };
			}
			throw new Error('Unsupported chain: ' + tokenIn?.chainId);
		},
		onSuccess: ({ hash, nonce }, { route }) => {
			takeFee({ hash, route, nonce });
		},
	});
	return response;
};
