import { CopyToClipboard } from '@/app-components/common';
import { ChainLogo } from '@/app-components/common/Avatar/TokenLogo';
import { ChainId } from '@/app-constants/chains';
import { ITokenSearch } from '@/app-cores/api/bff';
import { getShortAddress } from '@/app-helpers/address';
import { formatCurrency, formatUnits, formatUsd } from '@/app-helpers/number';
import { getNativeToken, getTokenInfo } from '@/app-helpers/token';
import { usePriceNativeToken } from '@/app-hooks/api/portfolio/useTokenPrices';
import { useMemeBondingCurveInfo, useMemeBondingCurvePercent } from '@/app-hooks/api/pump.fun';
import { PumpToken } from '@/app-services/pump.fun';
import { BASE_BORDER_COLOR } from '@/app-theme/theme';
import { Card, CardBody, Collapse, Flex, Progress, Text, VStack } from '@chakra-ui/react';
import { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

export default function CurveProgress({ memeInfo }: { memeInfo: PumpToken | undefined; token: ITokenSearch }) {
	const complete = memeInfo?.complete;
	const { data: progress, isFetching: isFetchingProgress } = useMemeBondingCurveInfo({
		address: complete ? '' : memeInfo?.mint,
	});
	const { data: percentData, isFetching } = useMemeBondingCurvePercent(
		complete ? '' : memeInfo?.mint,
		memeInfo?.bonding_curve,
	);

	const { t } = useTranslation();
	const sol = getNativeToken(ChainId.SOL);
	const { data } = usePriceNativeToken({ chainId: ChainId.SOL });
	const percent = complete ? 100 : Math.floor(percentData || 0);
	return (
		<Card>
			<CardBody p={4} fontSize={'12px'} display={'flex'} flexDirection={'column'} gap={3}>
				<VStack align={'flex-start'}>
					<Text>
						{t('memepad.bondingProgress')}:{' '}
						<Text as="span" fontWeight={'500'}>
							{percent}%
						</Text>
					</Text>
					<Progress
						isIndeterminate={isFetching}
						background={BASE_BORDER_COLOR}
						value={percent}
						width={'100%'}
						size="xs"
						colorScheme={'cyan'}
					/>
				</VStack>
				<Collapse in={complete || !isFetchingProgress}>
					<Text>
						{!complete ? (
							t('memepad.bondingDesc', {
								targetMCapUsd: formatUsd(data * +progress?.targetMCap),
								targetMCap: formatCurrency(progress?.targetMCap),
								native: sol.symbol,
								tokenLeft: formatCurrency(progress?.tokenLeft, { compact: true }),
								symbol: memeInfo?.symbol,
							})
						) : (
							<Trans
								i18nKey="memepad.completedBondingDesc"
								values={{
									link: `here`,
								}}
								components={[
									<a
										href={`https://www.geckoterminal.com/solana/pools/${memeInfo?.raydium_pool}`}
										target="_blank"
										style={{ textDecoration: 'underline' }}
									/>,
								]}
							/>
						)}
					</Text>
				</Collapse>
			</CardBody>
		</Card>
	);
}
