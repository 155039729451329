import { InputSearch, LocalLoader } from '@/app-components/common';
import { ActionBlock } from '@/app-components/common/ActionBlock';
import NoData from '@/app-components/common/NoData';
import { AppHeader } from '@/app-components/layout/AppLayout';
import { Activity } from '@/app-components/layout/AppLayout/Header/Activity';
import { GlobalSearch } from '@/app-components/layout/AppLayout/Header/GlobalSearch';
import { HomeIcon } from '@/app-components/layout/AppLayout/Header/HomeIcon';
import { NAVIGATE_PATHS } from '@/app-constants/router';
import { toQueryString } from '@/app-helpers/url';
import { useGetListMemeByType, useSearchMeme } from '@/app-hooks/api/pump.fun';
import { useDebounce } from '@/app-hooks/common';
import { PumpToken } from '@/app-services/pump.fun';
import { ListMemeType } from '@/app-views/tobi-fun';
import { MemeToken } from '@/app-views/tobi-fun/containers/MemeItem';
import NoAsset from '@/app-views/wallet/components/Portfolio/NoAsset';
import { FiUserColorIcon, FiUserIcon } from '@/assets/icons/fi-user-icon';
import { Box, Center, DrawerBody, DrawerHeader, Flex, Text } from '@chakra-ui/react';
import { Global } from '@emotion/react';
import { ChangeEventHandler, useCallback, useMemo, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';

const CryptoSearch = () => {
	const [keyword, setKeyword] = useState('');
	const { t } = useTranslation();
	const keywordDebounced = useDebounce(keyword.trim() || '', 400);
	const bodyContentRef = useRef<HTMLDivElement>(null);
	const inputSearchRef = useRef<HTMLDivElement>(null);

	const { data: dataSearch, isFetching: isFetchingCatalog } = useSearchMeme({
		keyword: keywordDebounced,
		limit: 20,
		enabled: !!keywordDebounced,
	});

	const { data: dataTrending, isFetching: isFetchingTrending } = useGetListMemeByType(ListMemeType.TRENDING, 10);

	const isFetching = isFetchingCatalog || isFetchingTrending;

	const visibleTokens = useMemo(() => {
		return (keywordDebounced ? dataSearch : dataTrending) ?? [];
	}, [dataTrending, keywordDebounced, dataSearch]);

	const onKeywordChange: ChangeEventHandler<HTMLInputElement> = useCallback((e) => {
		setKeyword(e.target.value);
	}, []);

	const showNotfound = !visibleTokens.length && !isFetching;

	return (
		<Flex flexDirection={'column'} sx={{ gap: '8px', height: '100%' }} flex={1}>
			<Flex direction={'column'} px={5} gap={'12px'}>
				<DrawerHeader px={0} pb={0}>
					<Center gap={1}>
						<HomeIcon />
						<InputSearch
							placeholder={t('cryptos.searchPlaceholder')}
							border="none"
							value={keyword}
							autoFocus
							onChange={onKeywordChange}
							onClear={() => {
								setKeyword('');
								inputSearchRef?.current?.focus();
							}}
							fontSize="sm"
							ref={inputSearchRef as any}
							background="white"
							height={12}
						/>
					</Center>
					<Flex justifyContent={'space-between'} alignItems={'center'} mt={2}>
						<Text fontSize={'small'} color={'gray'} fontWeight={'500'}>
							{keywordDebounced
								? `${visibleTokens.length} ${t('cryptos.results')}`
								: t('cryptos.topCrypto')}
						</Text>
					</Flex>
				</DrawerHeader>
			</Flex>
			<DrawerBody
				pt={0}
				px={0}
				flexDirection={'column'}
				gap={'12px'}
				flex={1}
				ref={bodyContentRef}
				transition="all 250ms"
				onScroll={() => {
					if (isMobile && document.activeElement === inputSearchRef.current) {
						inputSearchRef?.current?.blur();
					}
				}}
			>
				{isFetching ? (
					<Box height={'104px'}>
						<LocalLoader />
					</Box>
				) : showNotfound ? (
					<NoData msg={keywordDebounced ? t('cryptos.notFoundToken') : t('cryptos.searchHint')} />
				) : (
					<Flex direction={'column'} px={5} pt={0} gap={3}>
						{visibleTokens?.map((item, i) => {
							return <MemeToken data={item} key={item.mint} />;
						})}
					</Flex>
				)}
			</DrawerBody>
		</Flex>
	);
};

export default function Header() {
	const navigate = useNavigate();
	return (
		<AppHeader
			search={false}
			activity
			nodes={
				<>
					<GlobalSearch>
						<CryptoSearch />
					</GlobalSearch>
					<ActionBlock onClick={() => navigate(NAVIGATE_PATHS.TobiFun.Profile)}>
						<FiUserColorIcon />
					</ActionBlock>
				</>
			}
			style={{ paddingBottom: 0 }}
		/>
	);
}
