import { BoxProps, Center } from '@chakra-ui/react';

interface StatusBadgeProps extends BoxProps {
	type: 'processing' | 'success' | 'fail';
}

enum Color {
	processing = '#242F3A',
	success = '#00A560',
	fail = '#E32A2A',
}

export const StatusBadge: React.FC<StatusBadgeProps> = ({ type, ...rest }) => {
	return <Center py={3} borderRadius="8px" color="white" bgColor={Color[type]} {...rest}></Center>;
};
