import {
	Commitment,
	ComputeBudgetProgram,
	Connection,
	Finality,
	Keypair,
	PublicKey,
	SendTransactionError,
	Transaction,
	TransactionMessage,
	VersionedTransaction,
	VersionedTransactionResponse,
} from '@solana/web3.js';
import { PriorityFee, TransactionResult } from './types';
import { MpcWallet } from '@/app-cores/mpc-wallet/wallet';

export const DEFAULT_COMMITMENT: Commitment = 'finalized';
export const DEFAULT_FINALITY: Finality = 'finalized';

export const calculateWithSlippageBuy = (amount: bigint, basisPoints: bigint) => {
	return amount + (amount * basisPoints) / 10000n;
};

export const calculateWithSlippageSell = (amount: bigint, basisPoints: bigint) => {
	return amount - (amount * basisPoints) / 10000n;
};

export async function buildTx(
	connection: Connection,
	tx: Transaction,
	payer: PublicKey,
	signers: Keypair[],
	priorityFees?: PriorityFee,
) {
	const newTx = new Transaction();

	if (priorityFees?.unitLimit)
		newTx.add(
			ComputeBudgetProgram.setComputeUnitLimit({
				units: priorityFees.unitLimit,
			}),
		);
	if (priorityFees?.unitPrice)
		newTx.add(
			ComputeBudgetProgram.setComputeUnitPrice({
				microLamports: priorityFees.unitPrice,
			}),
		);

	newTx.add(tx);

	const versionedTx = await buildVersionedTx(connection, payer, newTx, DEFAULT_COMMITMENT);
	versionedTx.sign(signers);
	return versionedTx;
}

export const buildVersionedTx = async (
	connection: Connection,
	payer: PublicKey,
	tx: Transaction,
	commitment: Commitment = DEFAULT_COMMITMENT,
): Promise<VersionedTransaction> => {
	const blockHash = (await connection.getLatestBlockhash(commitment)).blockhash;

	const messageV0 = new TransactionMessage({
		payerKey: payer,
		recentBlockhash: blockHash,
		instructions: tx.instructions,
	}).compileToV0Message();

	return new VersionedTransaction(messageV0);
};

export const getTxDetails = async (
	connection: Connection,
	sig: string,
	commitment: Commitment = DEFAULT_COMMITMENT,
	finality: Finality = DEFAULT_FINALITY,
): Promise<VersionedTransactionResponse | null> => {
	const latestBlockHash = await connection.getLatestBlockhash();
	await connection.confirmTransaction(
		{
			blockhash: latestBlockHash.blockhash,
			lastValidBlockHeight: latestBlockHash.lastValidBlockHeight,
			signature: sig,
		},
		commitment,
	);

	return connection.getTransaction(sig, {
		maxSupportedTransactionVersion: 0,
		commitment: finality,
	});
};
