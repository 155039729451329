import { isEmpty } from 'lodash-es';
import { useQuery } from '@tanstack/react-query';
import { isNativeToken } from '@/app-helpers/address';
import { TSentSolTransaction } from '@/app-types';
import { getTokenId } from '@/app-helpers/token';
import { EclipseWallet } from '@/app-cores/mpc-wallet/eclipse';
import { buildEclipseSLPTransaction, buildEclipseTransaction } from '@/app-helpers/eclipse';

export const estimateEclipseGasFee = async (sendData: TSentSolTransaction) => {
	const { connection, fromPubKey, blockHash } = await EclipseWallet.init('mainnet', {
		commitment: 'confirmed',
	});
	if (isNativeToken(sendData.token.address)) {
		const transferTransaction = await buildEclipseTransaction({
			connection: connection,
			fromPubKey: fromPubKey,
			sendData: sendData,
		});
		transferTransaction.feePayer = fromPubKey;
		transferTransaction.recentBlockhash = blockHash;
		const gasFee = await transferTransaction.getEstimatedFee(connection);
		return { gasFee, connection };
	} else {
		const transferTransaction = await buildEclipseSLPTransaction({
			connection: connection,
			fromPubKey: fromPubKey,
			sendData: sendData,
		});
		transferTransaction.feePayer = fromPubKey;
		transferTransaction.recentBlockhash = blockHash;
		const gasFee = await transferTransaction.getEstimatedFee(connection);
		return { gasFee, connection };
	}
};

export const useEstimateEclipseGasFee = (sendData: TSentSolTransaction) => {
	const response = useQuery({
		queryKey: ['estimate-transfer-eclipse-asset', sendData.to, getTokenId(sendData.token), sendData?.message],
		queryFn: async () => {
			const { gasFee } = await estimateEclipseGasFee(sendData);
			return gasFee;
		},
		enabled: !isEmpty(sendData.amount) && !isEmpty(sendData.to) && !isEmpty(sendData.token),
		staleTime: 1000 * 30,
	});
	return response;
};
