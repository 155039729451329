import CursorPagination, { usePagination } from '@/app-components/common/Pagination';
import { compareAddress } from '@/app-helpers/address';
import { useGetMemeTrades } from '@/app-hooks/api/pump.fun';
import { PumpFunSDK, PumpToken, PumpTrade, TokenMetadata } from '@/app-services/pump.fun';
import { colors } from '@/app-theme/theme';
import TradeItem from '@/app-views/tobi-fun/containers/TradeItem';
import { Box, Card, CardBody, Center, Divider, Flex, Text } from '@chakra-ui/react';
import { uniqBy } from 'lodash';
import { Fragment, useCallback, useEffect, useState } from 'react';

const pageSize = 4;
export default function Trades({ memeInfo: token }: { memeInfo: PumpToken | undefined }) {
	const [tokens, setTokens] = useState<PumpTrade[]>([]);

	const { data } = useGetMemeTrades(token?.mint);
	const [page, setPage] = useState(1);
	const { onBack, onNext } = usePagination({ page, setPage, data: tokens });
	const start = (page - 1) * pageSize;

	// const onCreate = useCallback(
	// 	(event: PumpTrade) => {
	// 		if (compareAddress(event.mint, token?.mint)) setTokens((v) => uniqBy([event].concat(v), 'signature'));
	// 	},
	// 	[token?.mint],
	// );

	// useSubscribePumpFun({ callback: onCreate, event: 'tradeEvent' });

	useEffect(() => {
		setTokens(data ?? []);
	}, [data]);

	return (
		<Box>
			<Box display="flex" marginY={3} justifyContent="space-between">
				<Text fontWeight={500}>Recent Trades</Text>
			</Box>
			<Card>
				<CardBody display={'flex'} flexDirection={'column'} gap={2} p={4}>
					{tokens.slice(start, start + pageSize).map((el, i) => (
						<Fragment key={el.signature}>
							<TradeItem data={el} token={token} />
							{i !== tokens.length - 1 && (
								<Divider borderStyle={'dotted'} borderColor={colors.gray[300]} />
							)}
						</Fragment>
					))}
					{!tokens.length && <Center fontSize={'12px'}>No recent trades</Center>}
				</CardBody>
				<CursorPagination
					style={{ marginBottom: 3 }}
					totalCount={tokens?.length}
					pageSize={pageSize}
					currentPage={page}
					onNext={onNext}
					onBack={onBack}
				/>
			</Card>
		</Box>
	);
}
