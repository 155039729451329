import { useQueryUserProfile } from '@/app-hooks/api/user/useQueryUserProfile';
import { Avatar } from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router';
import { NAVIGATE_PATHS } from '@/app-constants/router';
import { HomeIcon } from '../HomeIcon';
import { ActionBlock } from '@/app-components/common/ActionBlock';

export const UserAvatarOrHome = () => {
	const { data: userProfile } = useQueryUserProfile();
	const navigate = useNavigate();
	const { pathname } = useLocation();
	if (pathname === NAVIGATE_PATHS.Home)
		return (
			<ActionBlock onClick={() => navigate(NAVIGATE_PATHS.AccountSettings.Menu)}>
				<Avatar name={userProfile?.firstName || userProfile?.lastName} src={userProfile?.avatar} size="sm" />
			</ActionBlock>
		);
	return <HomeIcon />;
};
