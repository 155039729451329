import { TransactionInstruction, SystemProgram, PublicKey, Transaction, Connection } from '@solana/web3.js';
import {
	createTransferInstruction,
	getAssociatedTokenAddress,
	getAccount,
	createAssociatedTokenAccountInstruction,
	ASSOCIATED_TOKEN_PROGRAM_ID,
} from '@solana/spl-token';
import { TSentSolTransaction } from '@/app-types';
import { parseTransferTokenAmount } from '../token';
import { getFinalSolGasFee, getMinimumBalanceForRentExemption } from '../solana';

export async function buildEclipseTransaction({
	connection,
	fromPubKey,
	sendData,
}: {
	connection: Connection;
	fromPubKey: PublicKey;
	sendData: TSentSolTransaction;
}) {
	let amountTransfer = parseTransferTokenAmount(sendData.amount, sendData.token.decimals);
	const minBalanceForRent = await getMinimumBalanceForRentExemption(connection);
	const totalUsed = amountTransfer + getFinalSolGasFee(sendData.gasFee) + BigInt(minBalanceForRent);
	const balance = BigInt(sendData.token.balance ?? 0n);
	if (totalUsed >= balance) {
		amountTransfer = balance - getFinalSolGasFee(sendData.gasFee) - BigInt(minBalanceForRent);
		if (!sendData.autoDeductFee) {
			console.log('Not enough balance to cover gas fee', { totalUsed, balance });
			throw new Error('Not enough balance to cover gas fee');
		}
	}
	const transferTransaction = new Transaction().add(
		SystemProgram.transfer({
			fromPubkey: fromPubKey,
			toPubkey: new PublicKey(sendData.to),
			lamports: amountTransfer,
		}),
	);
	if (sendData.message) {
		transferTransaction.add(
			new TransactionInstruction({
				keys: [{ pubkey: fromPubKey, isSigner: true, isWritable: true }],
				data: Buffer.from(sendData.message, 'utf-8'),
				programId: new PublicKey('MemoSq4gqABAXKb96qnH8TysNcWxMyWCqXgDLGmfcHr'),
			}),
		);
	}
	return transferTransaction;
}

export async function checkIfTokenAccountExists(
	connection: Connection,
	receiverTokenAccountAddress,
	tokenProgram: PublicKey,
) {
	try {
		const data = await getAccount(
			connection,
			receiverTokenAccountAddress,
			'confirmed',
			// TOKEN_2022_PROGRAM_ID
			tokenProgram,
		);
		return true;
	} catch (error) {
		if ((error as Error).name === 'TokenAccountNotFoundError') return false;
		throw error;
	}
}

// export async function buildEclipseSLPTransaction({
// 	connection,
// 	fromPubKey,
// 	sendData,
// }: {
// 	connection: Connection;
// 	fromPubKey: PublicKey;
// 	sendData: TSentSolTransaction;
// }) {
// 	const tokenMint = new PublicKey(sendData.token.address);
// 	const fromTokenAccount = await getAssociatedTokenAddress(tokenMint, fromPubKey);
// 	const toTokenAccount = await getAssociatedTokenAddress(tokenMint, new PublicKey(sendData.to));
// 	const isTokenAccountAlreadyMade = await checkIfTokenAccountExists(connection, toTokenAccount);
// 	console.log('🚀 ~ isTokenAccountAlreadyMade:', isTokenAccountAlreadyMade);
// 	const transferTransaction = new Transaction();
// 	if (!isTokenAccountAlreadyMade) {
// 		const createAccountInstruction = createAssociatedTokenAccountInstruction(
// 			fromPubKey,
// 			toTokenAccount,
// 			new PublicKey(sendData.to),
// 			tokenMint,
// 			TOKEN_2022_PROGRAM_ID,
// 			ASSOCIATED_TOKEN_PROGRAM_ID,
// 		);
// 		transferTransaction.add(createAccountInstruction);
// 	}
// 	const amountTransfer = parseTransferTokenAmount(sendData.amount, sendData.token.decimals);
// 	const transferInstruction = await createTransferInstruction(
// 		fromTokenAccount,
// 		toTokenAccount,
// 		fromPubKey,
// 		amountTransfer,
// 	);
// 	transferTransaction.add(transferInstruction);
// 	if (sendData.message) {
// 		transferTransaction.add(
// 			new TransactionInstruction({
// 				keys: [{ pubkey: fromPubKey, isSigner: true, isWritable: true }],
// 				data: Buffer.from(sendData.message, 'utf-8'),
// 				programId: new PublicKey('MemoSq4gqABAXKb96qnH8TysNcWxMyWCqXgDLGmfcHr'),
// 			}),
// 		);
// 	}
// 	return transferTransaction;
// }
export async function buildEclipseSLPTransaction({
	connection,
	fromPubKey,
	sendData,
}: {
	connection: Connection;
	fromPubKey: PublicKey;
	sendData: TSentSolTransaction;
}) {
	const tokenMint = new PublicKey(sendData.token.address);
	const tokenAccountInfo = await connection.getAccountInfo(tokenMint);
	const tokenProgram = tokenAccountInfo.owner;
	const fromTokenAccount = await getAssociatedTokenAddress(
		tokenMint,
		fromPubKey,
		true,
		// TOKEN_2022_PROGRAM_ID,
		tokenProgram,
		ASSOCIATED_TOKEN_PROGRAM_ID,
	);
	const toTokenAccount = await getAssociatedTokenAddress(
		tokenMint,
		new PublicKey(sendData.to),
		true,
		// TOKEN_2022_PROGRAM_ID,
		tokenProgram,
		ASSOCIATED_TOKEN_PROGRAM_ID,
	);
	const isTokenAccountAlreadyMade = await checkIfTokenAccountExists(connection, toTokenAccount, tokenProgram);
	const transferTransaction = new Transaction();
	if (!isTokenAccountAlreadyMade) {
		const createAccountInstruction = createAssociatedTokenAccountInstruction(
			fromPubKey,
			toTokenAccount,
			new PublicKey(sendData.to),
			tokenMint,
			// TOKEN_2022_PROGRAM_ID,
			tokenProgram,
			ASSOCIATED_TOKEN_PROGRAM_ID,
		);
		transferTransaction.add(createAccountInstruction);
	}
	const amountTransfer = parseTransferTokenAmount(sendData.amount, sendData.token.decimals);
	const transferInstruction = await createTransferInstruction(
		fromTokenAccount,
		toTokenAccount,
		fromPubKey,
		amountTransfer,
		[],
		// TOKEN_2022_PROGRAM_ID,
		tokenProgram,
	);
	transferTransaction.add(transferInstruction);
	if (sendData.message) {
		transferTransaction.add(
			new TransactionInstruction({
				keys: [{ pubkey: fromPubKey, isSigner: true, isWritable: true }],
				data: Buffer.from(sendData.message, 'utf-8'),
				programId: new PublicKey('MemoSq4gqABAXKb96qnH8TysNcWxMyWCqXgDLGmfcHr'),
			}),
		);
	}
	return transferTransaction;
}
