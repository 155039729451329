import { QueryClientProvider } from '@tanstack/react-query';
import { WebAppProvider } from '@vkruglikov/react-telegram-web-app';
import { useLayoutEffect } from 'react';
import { isAndroid } from 'react-device-detect';
import { ToastContainer } from 'react-toastify';
import { WalletProvider } from './app-contexts/wallet-provider';
import { queryClient } from './app-cores/query-client';
import { InitializeApp } from './app-views/apps/InitializeApp';

console.log('App version: ', import.meta.env.VITE_VERSION);

const App = () => {
	useLayoutEffect(() => {
		isAndroid ? document.body.classList.add('tobi_android') : (document.body.style.overflowY = 'hidden');
	}, []);
	return (
		<WebAppProvider>
			<QueryClientProvider client={queryClient}>
				<WalletProvider>
					<InitializeApp />
					<ToastContainer
						position="top-center"
						hideProgressBar
						bodyClassName="small"
						closeButton={false}
						newestOnTop
						draggableDirection="y"
						draggablePercent={50}
						icon={false}
						closeOnClick
						autoClose={3000}
					/>
				</WalletProvider>
			</QueryClientProvider>
		</WebAppProvider>
	);
};

export default App;
