import { NATIVE_TOKEN_ADDRESS } from '@/app-constants/chains';
import { ITokenSearch } from '@/app-cores/api/bff';
import { isNativeToken } from '@/app-helpers/address';
import { formatCurrency, formatUsd } from '@/app-helpers/number';
import {
	getNativeToken,
	getTokenInfo,
	isEvmChain,
	isSolanaChain,
	isTonChain,
	tokenHasBalance,
} from '@/app-helpers/token';
import { useTokenBalance } from '@/app-hooks/api/portfolio/usePortfolioBalance';
import { useEstimateGasFee } from '@/app-hooks/transactions/useEstimateGasFee';
import { useContactStore, useSentTokenStore } from '@/app-store';
import { useUserSettingsStore } from '@/app-store/settings';
import { IEstimateGasFee, IEstimateGasFeeItem, INetworkStatus, TGasFeeType } from '@/app-types';
import { FiInfoIcon, FiRightCircleIcon, FiTriangleIcon } from '@/assets/icons';
import {
	Box,
	Center,
	Drawer,
	DrawerBody,
	DrawerCloseButton,
	DrawerContent,
	DrawerHeader,
	DrawerOverlay,
	Flex,
	Skeleton,
	Text,
} from '@chakra-ui/react';
import lt from 'lodash/lt';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { formatUnits } from 'ethers';
import { TextSmall } from '../../TextSmall';

const FillColor = {
	'low': {
		fillColor: '#FF4D00',
		bgColor: '#FF4D0014',
		color: '#FF4D00',
	},
	'market': {
		fillColor: '#00C170',
		bgColor: '#00C17014',
		color: '#00C170',
	},
	'aggressive': {
		fillColor: '#00E9DB',
		bgColor: '#00E9DB14',
		color: '#00C170',
	},
};

const getEstimateTime = (type: string) => (type === 'aggressive' ? 15 : 30);

interface GasOptionProps {
	type: TGasFeeType;
	title: string;
	gasFeeData: IEstimateGasFeeItem;
	isSelected?: boolean;
	onSelect: (type: TGasFeeType) => void;
	tokenPriceUsd?: number;
	chainId: string;
	isGasSmallest?: boolean;
}
export const GasOption: React.FC<GasOptionProps> = ({
	type,
	title,
	gasFeeData,
	isSelected,
	onSelect,
	chainId,
	tokenPriceUsd = 0,
	isGasSmallest,
}) => {
	const { t } = useTranslation();

	return (
		<Center
			justifyContent="space-between"
			cursor="pointer"
			p={4}
			borderRadius={16}
			borderStyle="solid"
			borderWidth={isSelected ? '2px' : '1px'}
			borderColor={isSelected ? '#00E9DB' : 'rgba(0, 0, 0, 0.08)'}
			onClick={() => onSelect(type)}
		>
			<Center gap={2}>
				<Box borderRadius="50%" padding={2} backgroundColor={FillColor[type].bgColor}>
					<FiRightCircleIcon fill={FillColor[type].fillColor} />
				</Box>
				<Box>
					<Text fontSize="sm" fontWeight={600} textTransform={'capitalize'}>
						{t(title)}
					</Text>
					<Box className="animate__flash" key={gasFeeData?.gasFeeInNativeToken}>
						{isGasSmallest ? (
							<Text fontSize="xs">{`< 0.000001 ${getNativeToken(chainId)?.symbol}`}</Text>
						) : (
							<Text fontSize="xs" className="">
								{formatCurrency(gasFeeData?.gasFeeInNativeToken)} {getNativeToken(chainId)?.symbol} ≈
								{formatUsd(Number(gasFeeData?.gasFeeInNativeToken) * tokenPriceUsd)}
							</Text>
						)}
					</Box>
				</Box>
			</Center>
			<Center gap={4}>
				<Box>
					<TextSmall m={0}>{t('time')}</TextSmall>
					<Text fontSize="xs" color={FillColor[type].color}>
						{getEstimateTime(type)} {t('sec')}
					</Text>
				</Box>
				<FiInfoIcon width={24} height={24} />
			</Center>
		</Center>
	);
};

interface NetWorkStatusProps {
	networkStatus: INetworkStatus;
}
export const NetWorkStatus: React.FC<NetWorkStatusProps> = ({ networkStatus }) => {
	const { t } = useTranslation();
	const latestPriorityFeeRange = networkStatus?.latestPriorityFeeRange || [''];
	const networkCongestion = 59;
	return (
		<Flex justifyContent="space-between" p={4} borderRadius={16} border="1px solid rgba(0, 0, 0, 0.08)">
			<Center flexDirection="column" alignItems="flex-start" borderRight="1px solid" flex={1}>
				<Text fontSize="xs" m={0}>
					{networkStatus?.baseFee?.toFixed()} GWEI
				</Text>
				<Text fontSize="xs" mt={1} fontWeight={500}>
					{t('cryptos.baseFee')}
				</Text>
			</Center>
			<Center flexDirection="column" borderRight="1px solid" flex={1.5}>
				<Text fontSize="xs" m={0}>
					{(+latestPriorityFeeRange[0])?.toFixed()} - {(+latestPriorityFeeRange[1])?.toFixed()} GWEI
				</Text>
				<Text fontSize="xs" m={1} fontWeight={500}>
					{t('cryptos.priorityFee')}
				</Text>
			</Center>
			<Center flexDirection="column" flex={1.5}>
				<Box
					position="relative"
					height={1}
					borderRadius={44}
					width="80%"
					background="linear-gradient(90deg, #00C170 0%, #FF4D00 100%)"
				>
					<Box position="absolute" top="-5px" left={`${networkCongestion}%`}>
						<FiTriangleIcon />
					</Box>
				</Box>
				<Text fontSize="xs" mt={1} fontWeight={500}>
					{networkCongestion < 50 ? t('cryptos.stable') : t('cryptos.busy')}
				</Text>
			</Center>
		</Flex>
	);
};

const options = ['low', 'market', 'aggressive'];
const GasOptions = ({
	gasFeeType,
	onSelect,
	tokenPriceUsd,
	estimateGasFee,
	chainId,
	isGasSmallest,
}: {
	gasFeeType: TGasFeeType;
	onSelect: (v: TGasFeeType) => void;
	tokenPriceUsd: number | undefined;
	estimateGasFee: IEstimateGasFee | undefined;
	chainId: string;
	isGasSmallest: boolean;
}) => {
	return (
		<Flex flexDirection="column" gap={2}>
			{options.map((opt: TGasFeeType) => (
				<GasOption
					chainId={chainId}
					key={opt}
					isSelected={gasFeeType === opt}
					type={opt}
					title={opt}
					gasFeeData={estimateGasFee?.[opt]}
					onSelect={onSelect}
					tokenPriceUsd={tokenPriceUsd}
					isGasSmallest={isGasSmallest}
				/>
			))}
		</Flex>
	);
};

export const GasSetting = ({
	open,
	onClose,
	estimateGasFee,
	token,
	feePriceUsd,
	isGasSmallest,
}: {
	open: boolean;
	onClose: () => void;
	estimateGasFee: IEstimateGasFee;
	token: ITokenSearch;
	feePriceUsd: number;
	isGasSmallest: boolean;
}) => {
	const { gasFeeType, setGasFeeType } = useUserSettingsStore();
	const handleSelectGasFee = (type: TGasFeeType) => {
		setGasFeeType(type);
		onClose();
	};
	const { t } = useTranslation();
	const { chainId } = getTokenInfo(token);
	return (
		<>
			<Drawer isOpen={open} placement="bottom" onClose={onClose} trapFocus={false}>
				<DrawerOverlay />
				{open && (
					<DrawerContent>
						<DrawerCloseButton />
						<DrawerHeader borderBottom="1px solid" borderColor="gray.100">
							{t('cryptos.editGasFee')}
						</DrawerHeader>

						<DrawerBody px={4}>
							<TextSmall mb={4} mt={3} color="black">
								{t('cryptos.gasOptions')}
							</TextSmall>
							<GasOptions
								chainId={chainId}
								tokenPriceUsd={feePriceUsd}
								gasFeeType={gasFeeType}
								onSelect={handleSelectGasFee}
								estimateGasFee={estimateGasFee}
								isGasSmallest={isGasSmallest}
							/>
							<TextSmall mb={4} color="black">
								{t('cryptos.networkStatus')}
							</TextSmall>
							<NetWorkStatus networkStatus={estimateGasFee?.networkStatus} />
							<Box my={6} textAlign="center" fontSize="sm" color="black">
								<Text
									as="a"
									target="_blank"
									href="https://support.metamask.io/hc/en-us/articles/360022895972-How-to-customize-gas-settings"
									textDecoration="underline"
								>
									{t('learnMore')}
								</Text>{' '}
								{t('cryptos.aboutGas')}
							</Box>
						</DrawerBody>
					</DrawerContent>
				)}
			</Drawer>
		</>
	);
};

export const useEstimateFeeSentToken = () => {
	const { amount, tokenInfo, setIsInsufficientGas, setGasFee, isInsufficient, isInsufficientGas } =
		useSentTokenStore();
	const { contact, address } = useContactStore();
	const sentTo = contact?.wallets?.evm?.address || address;
	const { data: estimateGasFee } = useEstimateGasFee({
		to: sentTo,
		amount: isInsufficient ? '0' : amount,
		token: tokenInfo,
	});
	const { gasFeeType } = useUserSettingsStore();
	const estimateGasUsed = estimateGasFee?.[gasFeeType]?.gasFeeInNativeToken;
	const { data: nativeToken } = useTokenBalance({ tokenAddress: NATIVE_TOKEN_ADDRESS, chainId: tokenInfo?.chainId });
	const showGasWarning = useMemo(() => {
		try {
			if (!isEvmChain(tokenInfo?.chainId)) return isInsufficientGas;
			if (!tokenInfo?.address) return false;
			if (!tokenHasBalance(nativeToken)) return true;
			const nativeAmountSended = isNativeToken(tokenInfo.address) ? +amount : 0;
			const totalUsed = +estimateGasUsed + nativeAmountSended;
			return lt(+formatUnits(nativeToken.balance, getTokenInfo(nativeToken).decimals), totalUsed);
		} catch (error) {
			return false;
		}
	}, [amount, estimateGasUsed, nativeToken, isInsufficientGas, tokenInfo]);

	useEffect(() => {
		if (!estimateGasUsed || isTonChain(tokenInfo?.chainId) || isSolanaChain(tokenInfo?.chainId)) return;
		setGasFee(estimateGasUsed);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [estimateGasUsed, tokenInfo]);

	useEffect(() => {
		setIsInsufficientGas(showGasWarning);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showGasWarning]);

	const feeWithNativeToken = formatCurrency(estimateGasFee?.[gasFeeType]?.gasFeeInNativeToken);

	const isGasSmallest = +feeWithNativeToken <= 0;

	return { estimateGasFee, gasFeeType, showGasWarning, isGasSmallest, feeWithNativeToken };
};

export const EstimateEvmGasFee = ({
	feePriceUsd,
	estimateGasFee,
	isGasSmallest,
	feeWithNativeToken,
	onClick,
}: {
	feePriceUsd: number;
	estimateGasFee: IEstimateGasFee;
	isGasSmallest: boolean;
	feeWithNativeToken: string;
	onClick: () => void;
}) => {
	const { t } = useTranslation();
	const { tokenInfo } = useSentTokenStore();
	const { gasFeeType } = useUserSettingsStore();

	return (
		<Flex justifyContent={'space-between'} gap={1}>
			<TextSmall m={0}>{t('cryptos.estimatedGasFee')}</TextSmall>
			{!feeWithNativeToken ? (
				<Skeleton opacity="0.1" height={'21px'} width="160px" />
			) : (
				<Box
					onClick={onClick}
					fontWeight={'500'}
					className="animate__flash"
					key={+estimateGasFee?.[gasFeeType]?.gasFeeInNativeToken}
				>
					{isGasSmallest ? (
						<Text fontSize="14px">{`< 0.000001 ${getNativeToken(tokenInfo?.chainId)?.symbol}`}</Text>
					) : (
						<>
							<Text fontSize="14px">
								{formatUsd(+estimateGasFee?.[gasFeeType]?.gasFeeInNativeToken * feePriceUsd)} (
								{feeWithNativeToken} {getNativeToken(tokenInfo?.chainId)?.symbol})
							</Text>
							<Text
								fontSize="xs"
								color={FillColor[gasFeeType].color}
								textTransform={'capitalize'}
								textAlign={'right'}
							>
								{gasFeeType} ({getEstimateTime(gasFeeType)} {t('sec')})
							</Text>
						</>
					)}
				</Box>
			)}
		</Flex>
	);
};
