import { Loading } from '@/app-components/common';
import { useWalletAddressStore } from '@/app-store';
import WalletAddressPopup from '@/app-components/common/AddressByChain/AddressByChainPopup';
import { useMonitorView } from '@/app-hooks/monitor';
import { useDepositStore } from '@/app-features/deposit/store';
import { Deposit } from '@/app-features/deposit';
import { ScanQrCodePopup } from '@/app-features/app-wallet-connect/views/ScanQrCodePopup';
import { GlobalToast } from '@/app-components/common/Toast/GlobalToast';
import { useModalStore } from '@/app-features/app-wallet-connect/store/ModalStore';
import { FeatureConfigWrap } from '@/app-helpers/components';
import { FeatureConfig } from '@/app-hooks/api/configuration';
import { AppCopilot, CopilotFallback } from '@/app-features/copilot';
import { BotConnector } from '@/app-features/app-bot-connector/views/WalletConnect';
import { SharingDrawer } from '@/app-components/common/Sharing';

export const Global = () => {
	const { isOpen: isOpenDeposit } = useDepositStore();
	const { isOpen: isOpenWalletPopup } = useWalletAddressStore();
	const { isWaiting: isWaitingWalletConnection } = useModalStore();
	useMonitorView();
	return (
		<>
			<BotConnector />
			{isOpenDeposit && <Deposit />}
			<SharingDrawer />
			{isOpenWalletPopup && <WalletAddressPopup />}
			{isWaitingWalletConnection && <Loading opacity="0.8" />}
			<ScanQrCodePopup />
			<GlobalToast />
			<FeatureConfigWrap feature={FeatureConfig.IN_APP_COPILOT} fallbackComponent={<CopilotFallback />}>
				<AppCopilot />
			</FeatureConfigWrap>
		</>
	);
};
