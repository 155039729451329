import { CHAIN_CONFIG, ChainId, TON_NATIVE_ADDRESS } from '@/app-constants/chains';
import { TokenInfo } from '@/app-cores/api/bff';
import { isNativeToken } from '@/app-helpers/address';
import { commonGetRoute, isRouteParamsEmpty } from '@/app-hooks/swap';
import { ArgsGetRoute, FEE_ACCOUNTS, SwapAbstract, UsdRouteInfo } from '@/app-hooks/swap/type';
import { ExtractRouteInfo, SwapProvider } from '@/app-hooks/swap/type';
import { SelectedRoute } from '@/app-store/swap';
import { useMutation } from '@tanstack/react-query';
import axios, { AxiosRequestConfig } from 'axios';
import { DEX, pTON } from '@ston-fi/sdk';
import { ethers } from 'ethers';
import { MpcWallet } from '@/app-cores/mpc-wallet/wallet';
import { useSubmitTonTransaction } from '@/app-features/app-ton-connect/hooks/useMutationSentTransaction';
import { Cell, fromNano, SenderArguments, toNano } from '@ton/core';
import { TON_CENTER_API_KEY, TON_CENTER_BASE_API_URL } from '@/app-cores/api/axios';
import { AUTO_SLIPPAGE } from '@/app-views/swap/components/SlippageSetting';
import { uniqueId } from '@/app-helpers/random';
import { formatUsd } from '@/app-helpers/number';
import { filterParams, getMinAmount } from '@/app-hooks/swap/helper';
import { TransactionType } from '@/app-types';
import { TonClient } from '@ton/ton';
import { useTakeFeeSwap } from '@/app-hooks/swap/useTakeFeeSwap';

const STON_API_DOMAIN = 'https://api.ston.fi';

const getAddress = (token: TokenInfo) => (isNativeToken(token?.address) ? TON_NATIVE_ADDRESS : token.address);

class Ston extends SwapAbstract<RouteSton> {
	provider = SwapProvider.STON;
	async getRoute(paramsSwap: ArgsGetRoute, signal: AbortSignal) {
		const payload = getRouteParamsSwapSton(paramsSwap, signal, this.formatSlippage(paramsSwap.slippage));
		if (!payload) return;
		const data = await commonGetRoute(payload, paramsSwap);
		return formatRouteSton(data, paramsSwap);
	}
	formatSlippage(slippage: string | number): number | string {
		return +slippage === AUTO_SLIPPAGE ? '0.01' : +slippage / 100;
	}
	extractRoute(params: SelectedRoute<RouteSton>, prices: UsdRouteInfo): ExtractRouteInfo {
		return getExtractRouteSton(params, prices);
	}
}
export const StonSwap = new Ston();
const getRouteParamsSwapSton = (args: ArgsGetRoute, signal: AbortSignal, slippage) => {
	const { tokenIn, tokenOut, amountIn } = args;

	if (isRouteParamsEmpty(args)) return;
	const params = {
		offer_address: getAddress(tokenIn),
		ask_address: getAddress(tokenOut),
		units: amountIn,
		slippage_tolerance: slippage,
		referral_address: FEE_ACCOUNTS.TON,
		dex_v2: true,
	};

	filterParams(params);
	const config: AxiosRequestConfig = { url: `${STON_API_DOMAIN}/v1/swap/simulate`, params, signal, method: 'POST' };
	return config;
};

export type RouteSton = {
	offer_address: string;
	ask_address: string;
	router_address: string;
	pool_address: string;
	offer_units: string;
	ask_units: string;
	slippage_tolerance: string;
	min_ask_units: string;
	swap_rate: string;
	price_impact: string;
	fee_address: string;
	fee_units: string;
	fee_percent: string;
	// custom
	tokenIn: TokenInfo;
	tokenOut: TokenInfo;
};

const formatRouteSton = (routeData: RouteSton, params: ArgsGetRoute): SelectedRoute<RouteSton> =>
	routeData
		? {
				...routeData,
				route: routeData,
				routerAddress: routeData.router_address,
				id: uniqueId(),
				provider: SwapProvider.STON,
				timestamp: Date.now(),
				params,
		  }
		: undefined;

const getExtractRouteSton = (
	selectedRoute: SelectedRoute<RouteSton>,
	{ usdPriceNative }: UsdRouteInfo = {},
): ExtractRouteInfo => {
	const routeSwap = selectedRoute?.route;
	const tokenIn = selectedRoute?.tokenIn;
	const tokenOut = selectedRoute?.tokenOut;

	const gasNative = getMinAmount(SwapProvider.STON) + BigInt(CHAIN_CONFIG[ChainId.TON].minForGas);
	const gasStr = '0.05-0.3 TON';
	return {
		amountOut: routeSwap?.ask_units,
		amountIn: routeSwap?.offer_units,
		minAmountOut: routeSwap?.min_ask_units,
		tokenIn,
		tokenOut,
		gasUsd: undefined,
		gasNative,
		gasDisplay: usdPriceNative ? `max ${formatUsd(0.3 * usdPriceNative)} (${gasStr})` : gasStr,
		dappInfo: {
			logo: '/icons/brands/ston.fi.jpeg',
			domain: SwapProvider.STON,
		},
	};
};

export const useExecuteRouteSton = () => {
	const { mutateAsync } = useSubmitTonTransaction();
	const takeFee = useTakeFeeSwap();
	const response = useMutation({
		mutationKey: ['build-route-ston'],
		mutationFn: async (routeData: SelectedRoute<RouteSton>) => {
			const { tokenOut, route, tokenIn } = routeData;
			const { offer_units, min_ask_units, router_address } = route;

			const routerInfo = await axios.get(`${STON_API_DOMAIN}/v1/routers/${router_address}`);
			const { pton_master_address, pton_version, major_version } = routerInfo.data.router;

			const isV1 = major_version === 1;
			const isV2 = major_version === 2;
			if (!isV1 && !isV2) throw new Error(`Unknown router version ${major_version}`);

			console.log('router version', pton_version, { isV1, isV2 });

			const client = new TonClient({
				endpoint: `${TON_CENTER_BASE_API_URL}/jsonRPC`,
				apiKey: TON_CENTER_API_KEY,
			});

			const router = client.open(isV2 ? DEX.v2_1.Router.create(router_address) : new DEX.v1.Router());
			const proxyTon: any = isV2 ? pTON.v2_1.create(pton_master_address) : new pTON.v1();

			let swapTxParams: SenderArguments;
			const payload = {
				minAskAmount: min_ask_units,
				userWalletAddress: MpcWallet.getWalletAddress().tonAddress,
				offerAmount: offer_units,
				referralAddress: FEE_ACCOUNTS.TON,
			};

			if (isNativeToken(tokenIn?.address)) {
				// ton - jetton
				swapTxParams = await router.getSwapTonToJettonTxParams({
					proxyTon,
					askJettonAddress: tokenOut.address,
					...payload,
				});
			} else if (isNativeToken(tokenOut?.address)) {
				// jetton to ton
				swapTxParams = await router.getSwapJettonToTonTxParams({
					offerJettonAddress: tokenIn.address,
					proxyTon,
					...payload,
				});
			} else
				swapTxParams = await router.getSwapJettonToJettonTxParams({
					// jetton - jetton
					offerJettonAddress: tokenIn.address,
					askJettonAddress: tokenOut.address,
					...payload,
				});

			const resp = await mutateAsync({
				to: swapTxParams.to.toString(),
				value: fromNano(swapTxParams.value),
				body: swapTxParams.body,
				metadata: routeData,
				transactionType: TransactionType.Swap,
			});
			return resp;
		},
		onSuccess({ hash }, route) {
			takeFee({ hash, route });
		},
	});
	return response;
};
