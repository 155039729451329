import { useTranslation } from 'react-i18next';
import { fromNano } from '@ton/core';
import { useEffect, useMemo } from 'react';
import BigNumber from 'bignumber.js';
import { Box, Center, Skeleton, Text } from '@chakra-ui/react';
import { useContactStore, useSentTokenStore } from '@/app-store';
import { displaySolFee } from '@/app-helpers/display';
import { usePriceNativeToken } from '@/app-hooks/api/portfolio/useTokenPrices';
import { NATIVE_TOKEN_ADDRESS } from '@/app-constants/chains';
import { useTokenBalance } from '@/app-hooks/api/portfolio/usePortfolioBalance';
import { isNativeToken } from '@/app-helpers/address';
import { tokenHasBalance } from '@/app-helpers/token';
import { useEstimateSuiGasFee } from '@/app-hooks/transactions/sui/useEstimateFeeSendSuiAsset';
import { getTokenBalanceBig } from '../EstimateTonGasFee';
import { TextSmall } from '../../TextSmall';

export const EstimateSuiGasFee = () => {
	const { t } = useTranslation();
	const { contact, address } = useContactStore();
	const { tokenInfo, amount, messages, setGasFee, setIsInsufficientGas } = useSentTokenStore();
	const { data: nativePriceUsd = 0 } = usePriceNativeToken({
		chainId: tokenInfo?.chainId,
	});
	const { data: nativeToken } = useTokenBalance({ tokenAddress: NATIVE_TOKEN_ADDRESS, chainId: tokenInfo?.chainId });
	const sentTo = contact?.wallets?.['sui']?.address || address;

	const { data: estimateFee, isPending } = useEstimateSuiGasFee({
		amount: amount,
		to: sentTo,
		token: tokenInfo,
		message: messages,
	});

	useEffect(() => {
		if (!estimateFee) return;
		setGasFee(fromNano(estimateFee));
	}, [estimateFee, setGasFee]);

	const gasFee = displaySolFee({
		feePriceUsd: nativePriceUsd,
		gasFee: BigInt(estimateFee || 0),
	});
	const showGasWarning = useMemo(() => {
		try {
			if (!tokenInfo?.address || !estimateFee) return false;
			if (!tokenHasBalance(nativeToken)) return true;
			const nativeTokenAmount = getTokenBalanceBig(nativeToken);
			let nativeAmountSended = BigNumber(0);
			if (isNativeToken(tokenInfo.address)) {
				nativeAmountSended = BigNumber(amount);
			}
			const totalUsed = nativeAmountSended.plus(BigNumber(fromNano(estimateFee)));
			return totalUsed.gt(nativeTokenAmount);
		} catch (error) {
			return false;
		}
	}, [amount, estimateFee, nativeToken, tokenInfo.address]);

	useEffect(() => {
		setIsInsufficientGas(showGasWarning);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showGasWarning]);

	return (
		<Center justifyContent="space-between">
			<TextSmall m={0}>{t('cryptos.estimatedGasFee')}</TextSmall>
			{isPending ? (
				<Skeleton opacity="0.1" height={'21px'} width="160px" />
			) : (
				<>
					<Box fontWeight={'500'} className="animate__flash">
						<Text fontSize="14px">
							<Box as="span">{gasFee.feeUsd} </Box>
							<Box as="span">({gasFee.feeToken} SUI )</Box>
						</Text>
					</Box>
				</>
			)}
		</Center>
	);
};
