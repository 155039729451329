import { BASE_BORDER_COLOR, colors } from '@/app-theme/theme';
import { Flex, SystemStyleObject, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react';
import { CSSProperties } from 'react';

export default function TobiTabs<T = number>({
	tabs,
	value,
	onChange,
	activeStyle,
	style,
	containerStyle,
	mode = 'button',
	itemStyle,
}: {
	tabs: { onClick?: () => void; label: string; value?: T }[];
	value?: T;
	onChange?: (v: T) => void;
	activeStyle?: SystemStyleObject;
	style?: CSSProperties;
	containerStyle?: CSSProperties;
	itemStyle?: SystemStyleObject;
	mode?: 'underline' | 'button';
}) {
	const index = Math.max(
		0,
		tabs.findIndex((e) => e.value === value),
	);
	const isUnderlineMode = mode === 'underline';
	return (
		<Tabs
			variant="unstyled"
			sx={{
				borderRadius: '10px',
				...containerStyle,
			}}
			index={index}
		>
			<TabList sx={{ borderRadius: '10px', ...style }}>
				{tabs.map((e, i) => {
					const isLast = i === tabs.length - 1;
					const isFirst = i === 0;
					return (
						<Tab
							onClick={() => {
								onChange?.(e.value);
								e?.onClick?.();
							}}
							key={e.value?.toString() ?? i}
							sx={{
								fontSize: '14px',
								fontWeight: '500',
								whiteSpace: 'nowrap',
								...itemStyle,
								...(isUnderlineMode
									? {
											background: 'none',
											color: 'gray.400',
											borderRight: !isLast ? `1px dashed ${colors.gray[200]}` : undefined,
											height: '26px',
											justifyContent: 'flex-start',
											paddingLeft: isFirst ? 0 : undefined,
											paddingRight: isLast ? 0 : undefined,
									  }
									: {
											flex: 1,
											borderRadius:
												i === 0 ? '10px 0 0 10px' : isLast ? '0 10px 10px 0' : undefined,
											background: 'gray.100',
											height: '32px',
									  }),
							}}
							_selected={
								isUnderlineMode
									? { textDecoration: 'underline', color: 'black', ...activeStyle }
									: { bg: 'cyan.400', ...activeStyle }
							}
						>
							{e.label}
						</Tab>
					);
				})}
			</TabList>
		</Tabs>
	);
}
