import { InputCustom, Toast } from '@/app-components/common';
import ChainSelect from '@/app-components/common/Select/ChainSelect';
import PageLayout from '@/app-components/layout/PageLayout';
import { CHAIN_CONFIG } from '@/app-constants/chains';
import { MpcWallet } from '@/app-cores/mpc-wallet/wallet';
import { toGwei } from '@/app-helpers/token';
import { getSigner } from '@/app-helpers/web3';
import { useQueryGasPrice } from '@/app-hooks/api/transactions/useQueryGasPrice';
import { bufferGas } from '@/app-hooks/transactions';
import { Box, Button, Text } from '@chakra-ui/react';
import { ethers } from 'ethers';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

export const CancelPendingTransactions = () => {
	const { t } = useTranslation();
	const [chainId, setChainId] = useState('1');
	const [nonce, setNonce] = useState('');
	const { data: gasPriceData } = useQueryGasPrice(+chainId);
	const [loading, setLoading] = useState(false);

	const handleCancel = async () => {
		if (loading) return;
		try {
			setLoading(true);
			const chainConfig = CHAIN_CONFIG[chainId];
			const { signer, provider } = getSigner(chainId);
			const to = MpcWallet.getWalletAddress().evmAddress;
			const gaslimit = await provider.estimateGas({
				to,
				value: '0',
				chainId: +chainConfig.id,
				data: '0x',
			});
			const transactionRaw = await signer.signTransaction({
				to: MpcWallet.getWalletAddress().evmAddress,
				value: ethers.parseEther('0'),
				gasLimit: bufferGas(gaslimit, 40n) || 21000,
				chainId: chainId,
				nonce: +nonce,
				...(chainConfig.isEIP1559 && {
					maxPriorityFeePerGas: toGwei(gasPriceData.high.suggestedMaxPriorityFeePerGas),
					maxFeePerGas: toGwei(gasPriceData.high.suggestedMaxFeePerGas),
					type: 2,
				}),
			});
			const transactionRs = await provider.broadcastTransaction(transactionRaw);
			toast(<Toast type="success" title="Success" message={t('toast.cancelAllSuccess')}></Toast>);

			console.log('🚀 ~ handleCancel ~ transactionRs:', transactionRs);
		} catch (error) {
			toast(<Toast type="error" title="Error" message={error?.toString()}></Toast>);
			console.log('🚀 ~ handleCancel ~ error:', error);
		} finally {
			setLoading(false);
		}
	};
	return (
		<PageLayout
			header={
				<Text as="h3" fontWeight={500} textAlign="center" mb={3}>
					Cancel all transitions
				</Text>
			}
		>
			<Box>
				<ChainSelect
					value={chainId}
					onChange={(v) => {
						setChainId(v);
					}}
				/>
			</Box>
			<InputCustom
				placeholder="nonce"
				placeHolderStyle={{
					paddingLeft: '1rem',
				}}
				my={4}
				onChange={(e) => setNonce(e.target.value as string)}
				value={nonce}
			></InputCustom>
			<Button
				isLoading={loading}
				isDisabled={loading}
				size="lg"
				fontWeight="medium"
				colorScheme="cyan"
				width="100%"
				onClick={handleCancel}
			>
				Confirm
			</Button>
		</PageLayout>
	);
};
