import { useMutation } from '@tanstack/react-query';
import axios, { AxiosRequestConfig } from 'axios';
import { parseUnits } from 'ethers';
import { GenericRoute, SelectedRoute } from '@/app-store/swap';
import { commonGetRoute, isRouteParamsEmpty } from '@/app-hooks/swap/index';
import { ArgsGetRoute, ExtractRouteInfo, SwapAbstract, SwapProvider, UsdRouteInfo } from '@/app-hooks/swap/type';
import { useSubmitEVMTransaction } from '@/app-hooks/transactions';
import { uniqueId } from '@/app-helpers/random';
import { filterParams, getMyWalletAddressByChain } from '@/app-hooks/swap/helper';
import { useTakeFeeSwap } from '@/app-hooks/swap/useTakeFeeSwap';
import { getNativeToken } from '@/app-helpers/token';
import { AUTO_SLIPPAGE } from '@/app-views/swap/components/SlippageSetting';
import { EclipseWallet } from '@/app-cores/mpc-wallet/eclipse';
import { Message, MessageV0, Transaction, VersionedTransaction } from '@solana/web3.js';
import { MpcWallet } from '@/app-cores/mpc-wallet/wallet';
import { TokenInfo } from '@/app-cores/api/bff';
import { isNativeToken } from '@/app-helpers/address';
import { ChainId, WRAP_SOL_ADDRESS } from '@/app-constants/chains';

const formatRoute = (routeData: RouteOrca, params: ArgsGetRoute): SelectedRoute =>
	routeData
		? {
				...params,
				...routeData,
				route: routeData,
				id: uniqueId(),
				provider: SwapProvider.ORCA,
				routerAddress: '',
				timestamp: Date.now(),
				params,
		  }
		: undefined;

const API_DOMAIN = 'https://pools-api-eclipse.mainnet.orca.so';

class Orca extends SwapAbstract<RouteOrca> {
	provider = SwapProvider.ORCA;
	swapData: {
		message: Uint8Array;
		signatures: Array<Uint8Array[]>;
	} = null;

	async getRoute(paramsSwap: ArgsGetRoute, signal: AbortSignal) {
		const payload = getRouteParamsSwap(paramsSwap, signal);
		if (!payload) return;
		const data = await commonGetRoute(payload, paramsSwap);
		return formatRoute(data, paramsSwap);
	}

	formatSlippage(slippage: string | number): number | string {
		return +slippage === AUTO_SLIPPAGE ? 0.005 : +slippage / 100;
	}

	async buildRoute({
		route,
		slippage,
	}: {
		route: SelectedRoute<RouteOrca>;
		slippage: number;
	}): Promise<SelectedRoute<GenericRoute>> {
		const { data } = await axios.post(`${API_DOMAIN}/swap-prepare`, {
			isLegacy: false,
			amountIsInput: true,
			wallet: getMyWalletAddressByChain(route.tokenIn?.chainId),
			slippage: this.formatSlippage(slippage),
			swap: route?.route?.swap,
			computeBudget: {
				type: 'none',
			},
		});
		this.swapData = data.data;
		return route;
	}

	extractRoute(params: SelectedRoute<RouteOrca>, prices: UsdRouteInfo): ExtractRouteInfo {
		return getExtractRoute(params, prices);
	}
}
export const OrcaSwap = new Orca();

const getAddress = (token: TokenInfo) => (isNativeToken(token?.address) ? WRAP_SOL_ADDRESS : token.address);

const getRouteParamsSwap = (args: ArgsGetRoute, signal?: AbortSignal): AxiosRequestConfig => {
	if (isRouteParamsEmpty(args)) return;
	const { tokenIn, tokenOut, amountIn } = args;

	const params = {
		from: getAddress(tokenIn),
		to: getAddress(tokenOut),
		includeData: true,
		isLegacy: false,
		amountIsInput: true,
		amount: amountIn,
		wallet: getMyWalletAddressByChain(tokenIn?.chainId),
	};

	filterParams(params);
	return { url: `${API_DOMAIN}/swap-quote`, params, signal };
};

export type RouteOrca = {
	request: {
		from: string;
		to: string;
		amount: string;
		amountIsInput: string;
		isLegacy: string;
		maxSplits: string;
		percentIncrement: string;
		numTopSplits: string;
		numTopPartials: string;
	};
	swap: {
		inputAmount: string;
		outputAmount: string;
		split: Array<
			Array<{
				pool: string;
				input: {
					mint: string;
					amount: string;
				};
				output: {
					mint: string;
					amount: string;
				};
			}>
		>;
	};
	data: {
		pools: Array<{
			address: string;
			updatedSlot: number;
			whirlpoolsConfig: string;
			whirlpoolBump: number[];
			tickSpacing: number;
			tickSpacingSeed: number[];
			feeRate: number;
			protocolFeeRate: number;
			liquidity: string;
			sqrtPrice: string;
			tickCurrentIndex: number;
			protocolFeeOwedA: string;
			protocolFeeOwedB: string;
			tokenMintA: string;
			tokenVaultA: string;
			feeGrowthGlobalA: string;
			tokenMintB: string;
			tokenVaultB: string;
			feeGrowthGlobalB: string;
			rewardLastUpdatedTimestamp: string;
			rewardInfos: Array<{
				mint: string;
				vault: string;
				authority: string;
				emissionsPerSecondX64: string;
				growthGlobalX64: string;
			}>;
		}>;
	};
};

const getExtractRoute = (
	selectedRoute: SelectedRoute<RouteOrca>,
	{ usdPriceNative }: UsdRouteInfo,
): ExtractRouteInfo => {
	const routeSwap = selectedRoute?.route?.swap;
	const tokenIn = selectedRoute?.tokenIn;
	const tokenOut = selectedRoute?.tokenOut;

	const gasUsd = 0.01;
	const native = getNativeToken(tokenIn?.chainId);
	const gasNative =
		usdPriceNative && gasUsd
			? parseUnits((gasUsd / usdPriceNative)?.toFixed(native.decimals), native.decimals)
			: undefined;

	return {
		amountOut: routeSwap?.outputAmount,
		amountIn: routeSwap?.inputAmount,
		gasUsd,
		tokenIn,
		tokenOut,
		gasNative,
		dappInfo: {
			logo: '/icons/brands/orca.png',
			domain: SwapProvider.ORCA,
		},
	};
};

export const useExecuteRouteOrca = () => {
	const takeFee = useTakeFeeSwap();
	const response = useMutation({
		mutationKey: ['exe-route-orca'],
		mutationFn: async (routeData: SelectedRoute<RouteOrca>) => {
			const { connection, fromPubKey } = await EclipseWallet.init('mainnet', {
				commitment: 'confirmed',
			});
			const { signatures, message } = OrcaSwap.swapData;

			const messageV0 = MessageV0.deserialize(message);

			const tx = new VersionedTransaction(messageV0);

			if (signatures.length) tx.signatures = signatures[0].map((e) => new Uint8Array(e));

			tx.addSignature(fromPubKey, await MpcWallet.signEddsaMessage(Buffer.from(tx.message.serialize())));

			const txnSignature = await connection.sendRawTransaction(tx.serialize());

			return { hash: txnSignature };
		},
		onSuccess: ({ hash }, route) => {
			takeFee({ hash, route });
		},
	});
	return response;
};
